import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import { Provider } from 'mobx-react';
import i18n from './config/i18n';
import { I18nextProvider } from 'react-i18next';
import Location from '@btc-frontend/middleware/utils/Location';
import Router from '@btc-frontend/router';
import { checkLocation } from '@btc-frontend/router/location';
import Context from '@btc-frontend/context';
import { removeParam } from '@btc-frontend/middleware/utils/url';
import AppContainer from '@btc-frontend/containers/AppContainer';
import jss from 'jss';
import preset from 'jss-preset-default';
import increaseSpecificity from 'jss-increase-specificity';
import { JssProvider } from 'react-jss';
import ThemeProvider from '@beautycounter/counter-ui/src/ThemeProvider';

const context = new Context();

/**
 * Switch off the native scroll restoration behavior and handle it manually
 * https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
 */

const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

let onRenderComplete = function initialRenderComplete() {
    const elem = document.getElementById('css');
    if (elem) elem.parentNode.removeChild(elem);
    onRenderComplete = function renderComplete(route, location) {
        /**
         * Update necessary tags in <head> at runtime here, ie:
         * updateMeta('keywords', route.keywords);
         * updateCustomMeta('og:url', route.canonicalUrl);
         * updateCustomMeta('og:image', route.imageUrl);
         * updateLink('canonical', route.canonicalUrl);
         * etc.
         */

        const scrollX = 0;
        let scrollY = 0;

        const targetHash = location.hash.substr(1);
        if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
                scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
        }

        window.scrollTo(scrollX, scrollY);
    };
};

const container = document.getElementById('app');
let currentLocation = Location;

async function onLocationChange(location, action) {
    const stores = require('@btc-frontend/stores').default;
    const CLIENT_BREAKDOWN_URL = '/btc/customers/client-breakdown';
    const MEMBER_BREAKDOWN_URL = '/btc/customers/member-breakdown';

    const isInitialRender = stores.interfaceStore.initialRender;
    scrollPositionsHistory[currentLocation.key] = {
        scrollX: window.pageXOffset,
        scrollY: window.pageYOffset,
    };

    if (action === 'PUSH') {
        delete scrollPositionsHistory[location.key];
    }

    currentLocation = location;

    try {
        /*
         * Traverses the list of routes in the order they are defined until
         * it finds the first route that matches provided URL path string
         * and whose action method returns anything other than `undefined`.
         */
        const query = queryString.parse(location.search);
        const { cstoken } = query;
        await stores.flagStore.rebucket();
        // login via counter support token passed in query.
        if (cstoken) {
            await stores.userStore.loginWithToken(cstoken);
            removeParam('cstoken');
        }

        // redirect client-breakdown to member-breakdown (SELL-32)
        if (location.pathname === CLIENT_BREAKDOWN_URL) {
            window.location.assign(MEMBER_BREAKDOWN_URL);
        }

        const params = {
            context,
            path: location.pathname,
            localizedPath: location.pathname,
            query,
            isServer: false,
            stores,
            isInitialRender,
        };

        const router = Router();
        const route = await router.resolve(params);
        let resolvedRoute = route;
        if (currentLocation.key !== location.key) return;
        if (route.redirect) {
            resolvedRoute = await router.resolve({
                ...params,
                path: route.redirect,
            });
        }

        if (!resolvedRoute.config.disableAuth) {
            if (!stores.userStore.isLogged) {
                return;
            }
        }

        if (route.replace) {
            window.history.replaceState({}, {}, route.replace);
        }

        if (!stores.teamStore.isInitial) stores.teamStore.init();

        jss.setup(preset()).use(increaseSpecificity());

        const renderReactApp = isInitialRender && !__DEV__ ? ReactDOM.hydrate : ReactDOM.render;
        const appInstance = (
            <JssProvider jss={jss}>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider>
                        <Provider key="provider" {...stores}>
                            <AppContainer
                                useLayout={!resolvedRoute.config.disableLayout}
                                context={context}
                                path={location.pathname}
                                key="app"
                            >
                                {resolvedRoute.component}
                            </AppContainer>
                        </Provider>
                    </ThemeProvider>
                </I18nextProvider>
            </JssProvider>
        );

        renderReactApp(appInstance, container, () => onRenderComplete(route, location));

        setTimeout(() => stores.interfaceStore.updateRenderState(), 200);
    } catch (error) {
        if (__DEV__) {
            throw error;
        }

        console.error(error);

        if (currentLocation.key === location.key) {
            window.location.reload();
        }
    }
}

checkLocation();

/*
 * Handle client-side navigation by using HTML5 History API
 * For more information visit https://github.com/mjackson/history#readme
 */

Location.listen(onLocationChange);

if (!window.location.origin) {
    window.location.origin = `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
    }`;
}

/*
 * Force refresh on Safari backstate to prevent state caching
 * which can cause issues in a single page app
 */

window.onpageshow = event => {
    if (event.persisted) {
        window.location.reload();
    }
};

if (!__DEV__ && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js');
    });
}

if (module.hot) {
    const stores = require('@btc-frontend/stores').default;
    module.hot.decline([
        './src/stores/index.js',
        ...Object.keys(stores).map(store => `./src/stores/${store}/${store}.js`),
    ]);
    module.hot.accept();
}
