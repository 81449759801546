import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { SPACEBAR, ENTER } from '@beautycounter/constants/keyvalues';
import s from './Checkbox.scss';

@withStyles(s)
class Checkbox extends Component {
    static propTypes = {
        onChange: pt.func,
        title: pt.oneOfType([pt.string, pt.object]),
        checked: pt.oneOfType([pt.string, pt.bool]),
        className: pt.string,
        labelClassName: pt.string,
        iconClassName: pt.string,
        name: pt.string,
        disabled: pt.bool,
        inactive: pt.bool,
        value: pt.oneOfType([pt.string, pt.bool]),
        small: pt.bool,
        passback: pt.object,
        tabindex: pt.string,
        shrink: pt.bool,
        ariaDescribedBy: pt.string,
        testId: pt.string,
    };

    static defaultProps = {
        showLabel: false,
        small: false,
        checked: false,
        disabled: false,
        inactive: false,
        parent: false,
        value: 'checked',
        passback: {},
        tabindex: '0',
        shrink: false,
        testId: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { checked } = this.state;

        if (checked !== nextProps.checked) {
            this.setState({ checked: nextProps.checked });
        }
    }

    handleKeyPress = e => {
        const key = e && e.key;
        if (key === ENTER || key === SPACEBAR) {
            e.preventDefault();
            return this.handleChange();
        }
        return false;
    };

    handleChange = () => {
        const checked = !this.state.checked,
            value = checked ? this.props.value : '';

        if (!this.props.external) this.setState({ checked });
        this.props.onChange && this.props.onChange(value, this.props.passback, checked);
    };

    render() {
        const {
            disabled,
            inactive,
            small,
            tabindex,
            shrink,
            testId,
            ariaDescribedBy,
            title,
        } = this.props;
        const describedBy = {
            ...(ariaDescribedBy && {
                'aria-describedby': ariaDescribedBy,
            }),
        };

        return (
            <div
                className={cx(s.root, this.props.className, {
                    [s.disabled]: disabled,
                    [s.inactive]: inactive,
                    [s.small]: small,
                })}
                onClick={!disabled ? this.handleChange : null}
                data-testid={testId}
                data-checked={this.state.checked ? 'true' : 'false'}
                tabIndex={tabindex}
                onKeyDown={!disabled ? this.handleKeyPress : null}
                role="checkbox"
                aria-checked={this.state.checked}
                {...describedBy}
            >
                <div className={cx(s.inputContainer, s.srOnly)}>
                    <input
                        type="checkbox"
                        id={testId}
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        tabIndex="-1"
                        disabled
                    />
                </div>
                <div>
                    <div
                        data-testid={`${title &&
                            title instanceof String &&
                            title.toLowerCase()}Checkbox`}
                        className={cx(s.icon, this.props.iconClassName)}
                    >
                        <div
                            className={cx(
                                s.square,
                                this.state.checked && s.checked,
                                shrink && s.shrink,
                            )}
                        />
                    </div>
                </div>
                <div className={cx(this.props.labelClassName, s.title, shrink && s.titleShrink)}>
                    <label htmlFor={testId}>{title}</label>
                </div>
            </div>
        );
    }
}

export default Checkbox;
