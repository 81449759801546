import { nogento, sailthru } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';
import { getSailthru } from '@btc-frontend/middleware/api/sailthru';

// TODO remove
async function getSubscriptionStatus(email, listName) {
    const endpoint = `${nogento.customer}/getSubscriptionStatus`;

    const options = {
        method: 'POST',
        body: JSON.stringify({
            env: sailthru.env,
            query: {
                id: email,
            },
        }),
    };

    try {
        const response = await fetch(endpoint, options);
        const data = await response.json();

        if (data.success) {
            return {
                success: true,
                status: data.lists.hasOwnProperty(listName),
            };
        } else throw data.error;
    } catch (e) {
        console.log(e);
        return false;
    }
}

async function toggleSubscription(email, listName, status, source = 'webs') {
    // prevent blank calls
    if (!email && !listName && !status && !source) return false;

    const endpoint = `${nogento.customer}/subscribeUser`;

    const options = {
        method: 'POST',
        body: JSON.stringify({
            env: sailthru.env,
            query: {
                id: email,
                keys: {
                    email: email,
                },
                lists: { [listName]: status },
                source: source,
                onSuccess: res => {
                    resolve(true);
                },
                onError: e => {
                    reject(false);
                },
            },
        }),
    };

    try {
        const response = await fetch(endpoint, options);
        const data = await response.json();

        if (data.success) {
            return {
                success: true,
            };
        } else throw data.error;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export { getSubscriptionStatus, toggleSubscription };
