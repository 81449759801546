// MUI's typography variants were restrictive and built around Roboto font family.
// This allows for more flexibility.

function round(value) {
    return Math.round(value * 1e5) / 1e5;
}

const applyLetterSpacing = rule => (rule ? { letterSpacing: rule } : {});
const applyFontFamily = rule => (rule ? { fontFamily: rule } : {});
const applytextTransform = rule => (rule ? { textTransform: rule } : {});

// 16 = HTML default size, 14 = our size
const pxToRem = size => `${((size / 14) * 16) / 14}rem`;

export const buildVariant = (
    fontFamily,
    fontWeight,
    size,
    lineHeight,
    letterSpacing,
    textTransform,
) => ({
    // TODO pull from palette
    color: '#000',
    fontFamily,
    fontWeight,
    fontSize: pxToRem(size),
    lineHeight,
    padding: '0',
    ...applyFontFamily(fontFamily),
    ...applyLetterSpacing(letterSpacing),
    ...applytextTransform(textTransform),
    // ...allVariants,
});
