import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Icon.scss';
import cx from 'classnames';
import {
    SIZE_MEDIUM as DEFAULT_SIZE,
    THICKNESS_THIN as DEFAULT_THICKNESS,
} from '@beautycounter/constants/icon';
import theme from '@beautycounter/counter-ui/src/theme';

@withStyles(s)
class Icon extends Component {
    static propTypes = {
        thickness: pt.number,
        color: pt.string,
        size: pt.shape({
            width: pt.number,
            height: pt.number,
        }),
        viewBox: pt.shape({
            x: pt.number,
            y: pt.number,
            width: pt.number,
            height: pt.number,
        }),
        children: pt.oneOfType([pt.array, pt.object]),
        disabled: pt.bool,
        noStroke: pt.bool,
        onClick: pt.func,
        href: pt.string,
        altText: pt.string, // This is for accessibility purposes
    };

    static defaultProps = {
        thickness: DEFAULT_THICKNESS,
        color: theme.palette.black,
        size: DEFAULT_SIZE,
        children: [],
        disabled: false,
        href: '',
        testId: null,
        noStroke: false,
        altText: '',
    };

    handleClick = event => {
        if (!this.props.disabled) {
            this.props.onClick && this.props.onClick(event, this.props.href);
        }
    };

    render() {
        const { x, y, width, height } = this.props.viewBox,
            { altText, thickness, className, testId, color, noStroke, ...props } = this.props;
        const strokeColor = !noStroke ? color : 'none';
        return (
            <>
                <span className={s.srOnly}>{altText}</span>
                <svg
                    {...props}
                    className={cx(className, {
                        [s.clickable]: !!this.props.onClick,
                    })}
                    data-testid={testId}
                    onClick={this.handleClick}
                    viewBox={`${x} ${y} ${width} ${height}`}
                    style={{
                        enableBackground: `new ${x} ${y} ${width} ${height}`,
                        width: this.props.size.width,
                        height: this.props.size.height,
                        ...this.props.style,
                    }}
                >
                    {this.props.defs}
                    <g stroke={strokeColor} fill={strokeColor} strokeWidth={thickness}>
                        {this.props.children}
                    </g>
                </svg>
            </>
        );
    }
}

export default Icon;
