const bcRed = {
    50: '',
    100: '#E5503F', // beautycounter red
    200: '',
    300: '#E5503F', // beautycounter red
    400: '',
    500: '#E5503F', // beautycounter red
    600: '',
    700: '#E5503F', // beautycounter red
    800: '',
    900: '#E5503F', // beautycounter red
    A100: '',
    A200: '',
    A400: '',
    A700: '',
};

export default bcRed;
