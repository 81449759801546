import { observable, computed, action, autorun, toJS } from 'mobx';
import { keyBy, Dictionary } from 'lodash';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { Error, messageChannel } from '@btc-frontend/middleware/constants/messageTypes';

class MessagingStore {
    @observable queue: IMessages.IMessage[];
    queueId: number;
    queueById: { [id: number]: IMessages.IMessage };
    timeout: number;

    constructor() {
        this.queue = [];
        this.queueById = {};
        this.timeout = 4000;
        __BROWSER__ &&
            requestAnimationFrame(() => {
                autorun(() => {
                    this.queueById = keyBy(this.queue, 'id');
                });
            });
    }

    @computed
    get activeMessage() {
        return this.getActiveChannelMessage();
    }

    @computed
    get activeBannerMessage() {
        return this.getActiveChannelMessage(messageChannel.banner);
    }

    @computed
    get activeSnackbarMessage() {
        return this.getActiveChannelMessage(messageChannel.snackbar);
    }

    getActiveChannelMessage(channelName: string = null) {
        let resp: IMessages.IMessage | boolean = false;
        if (this.unread.length) {
            if (channelName) {
                const channelMessages = this.unread.filter(x => x.channel === channelName);
                if (channelMessages.length) resp = channelMessages[0];
            } else {
                if (this.unread.length) resp = this.unread[0];
            }
        }
        return resp;
    }

    @action
    clear() {
        this.queue = [];
    }

    @action
    readMessage = (message: IMessages.IMessage) => {
        if (message && message.active && !message.read) {
            this.markRead(message);
        }
        return message || false;
    };

    @action
    markRead = (message: IMessages.IMessage) => {
        console.log('marking read: ', message);
        if (!message) return false;
        this.queueById[message.id].read = true;
        this.queue = this.queue.slice();

        const store = this;
        setTimeout(() => {
            if (getSafe(() => store.queueById[message.id].active) === true) {
                store.queueById[message.id].active = false;
            }
        }, message.timeout);
    };

    @computed
    get unread() {
        return this.queue.filter(message => message.active).reverse();
    }

    /**
     * @description handles adding system messages.
     * @param {String} message :
     * @param {Object} options : Can contain the key 'type', with the value 'success' or 'error'.
     */
    @action
    addMessage(
        message: string,
        options: { type?: string; timeout?: number; channel?: string; icon?: React.Component } = {},
    ) {
        if (message === (this.getActiveChannelMessage(options.channel) || {}).message) return false;

        this.queue.push({
            message,
            time: Date.now(),
            type: options.type || Error,
            id: Math.random(),
            active: true,
            timeout: options.timeout || this.timeout,
            read: false,
            channel: options.channel || messageChannel.banner,
            icon: options.icon,
        });
        this.queue = this.queue.slice();
    }

    @action
    clearErrors = () => {
        const newQueue = this.queue.map(item => {
            return {
                ...item,
                read: item.type === Error ? true : item.read,
                active: item.type === Error ? false : item.active,
            };
        });
        this.queue = newQueue;
    };

    @action
    closeMessage = (id: number | string) => {
        this.queueById[id].active = false;
    };
}

const messagingStore = new MessagingStore();

export default messagingStore;
export { MessagingStore };
