import GraphQLFetch, { EnumTypeString } from '@btc-frontend/middleware/api/graphql';
import { nogento } from '@btc-frontend/config';
import navigationStore from '@btc-frontend/stores/navigationStore';
import { navLinks } from '@btc-frontend/nav';
import { perPageDefault } from '@counter-ui/Core/DataTable/perPageSelect/perPageConstants';
import { toJS } from 'mobx';

class OrdersApi {
    globalSearch = async ({ searchText = '', region = null, searchType, page = 0 }) => {
        const graphql = new GraphQLFetch({ urlTag: 'globalSearch' });
        await graphql.useAuth();
        const args = {
            searchTerm: searchText,
            region: region ? new EnumTypeString(region) : new EnumTypeString('EnUS'),
            ...(searchType ? { searchType: new EnumTypeString(searchType) } : {}),
            page,
        };
        graphql.addType(
            'globalSearch',
            { ...args },
            `products{
                title
                sku
                image{
                    imageUrl
                }
            }
            orders {
                fullName
                orderId
                uid
            }
            customers{
                fullName
                fsDocId
            }
            `,
        );
        const {
            data: { globalSearch },
        } = await graphql.execute();
        return globalSearch;
    };

    fetchOrders = isExport => async ({
        orderBy = 'TIMESTAMP',
        sort = 'DESC',
        orderDateFrom = null,
        orderId,
        orderIdArray = [],
        orderTypes = [],
        orderStatus = [],
        orderDateTo = null,
        orderIdnGram = null,
        productSearch = '',
        productSearchArray = [],
        fullName = '',
        fullNameArray = [],
        uid = '',
        currentPage = 1,
        totalOrders = null,
    }) => {
        const graphql = new GraphQLFetch({ urlTag: 'fetchOrders' });
        await graphql.useAuth();
        const args = {};

        args.perPage = totalOrders || perPageDefault;

        args.pageNumber = currentPage;

        if (orderDateFrom !== null) {
            args.orderDateFrom = orderDateFrom;
        }

        if (orderDateTo !== null) {
            args.orderDateTo = orderDateTo;
        }
        args.orderBy =
            orderBy !== null
                ? new EnumTypeString(
                      `${orderBy.toUpperCase().replace('ORIGINAL', '')}_${sort.toUpperCase()}`,
                  )
                : new EnumTypeString(`TIMESTAMP_DESC`);
        args.productSearch = productSearch;
        args.productSearchArray = toJS(productSearchArray);
        args.fullName = fullName;
        args.fullNameArray = toJS(fullNameArray);
        args.orderIdnGram = orderIdnGram;
        args.orderId = orderId || null;
        args.orderIdArray = toJS(orderIdArray);
        args.uid = uid || '';
        args.orderTypes =
            (orderTypes.length && orderTypes.map(type => new EnumTypeString(`${type}`))) || null;
        args.orderStatus =
            (orderStatus.length && orderStatus.map(type => new EnumTypeString(`${type}`))) || null;
        args.useSql = true;

        graphql.addType(
            'orders',
            { ...args },
            `ordersMetadata {
                totalOrders
            }
            orderItems {
                orderId
                status
                timestamp
                firstName
                lastName
                email
                phone
                country
                phoenixId
                grandTotal
                commissionDateUTC
                shipments {
                    shipDate
                }
                cart {
                    subtotal
                    volumes {
                        PV
                        CV
                        QV
                        NV
                        SV
                    }
                    products {
                        sku
                        title
                        name
                        quantity
                        swatchlabel
                        image
                        price
                        discountPrice
                        totalPrice
                        CV
                        PV
                        QV
                        NV
                        SV
                    }
                },
                orderType
                compVersion
            }`,
        );
        if (!isExport) {
            const response = await graphql.execute();
            if (!response && orderId)
                navigationStore.to({
                    url: navLinks.notFound.to,
                });

            const { data: { orders: { orderItems, ordersMetadata } = {} } = {} } = response || {};
            return { orderItems, ordersMetadata };
        }
    };

    fetchAggregates = async ({
        orderBy = 'TIMESTAMP',
        sort = 'DESC',
        orderDateFrom = null,
        orderId,
        orderIdArray = [],
        orderTypes = [],
        orderStatus = [],
        orderDateTo = null,
        orderIdnGram = null,
        productSearch = '',
        productSearchArray = [],
        fullName = '',
        fullNameArray = [],
        uid = '',
        currentPage = 1,
        totalOrders = null,
    }) => {
        const args = {};
        if (orderDateFrom !== null) {
            args.orderDateFrom = orderDateFrom;
        }

        if (orderDateTo !== null) {
            args.orderDateTo = orderDateTo;
        }
        args.productSearch = productSearch;
        args.productSearchArray = productSearchArray;
        args.fullNameArray = fullNameArray;
        args.fullName = fullName;
        args.orderIdArray = orderIdArray;
        args.orderTypes =
            (orderTypes.length && orderTypes.map(type => new EnumTypeString(`${type}`))) || null;
        args.orderStatus =
            (orderStatus.length && orderStatus.map(type => new EnumTypeString(`${type}`))) || null;
        const graphql = new GraphQLFetch({ urlTag: 'ordersFetchAggregates' });
        await graphql.useAuth();

        graphql.addType(
            'orderAggregateStats',
            { ...args },
            `uniqueOrderTypeCounts {
              orderType
              orderTypeCount
            }
            uniqueStatusCounts {
              status
              statusCount
            }`,
        );
        const response = await graphql.execute();
        const { data: { orderAggregateStats = {} } = {} } = response || {};
        return orderAggregateStats;
    };

    downloadCSV = () => this.fetchOrders(true);

    fetchColumns = async () => {
        try {
            const graphql = new GraphQLFetch({ urlTag: 'ordersFetchColumns' });
            await graphql.useAuth();

            const args = {};

            args.tableName = new EnumTypeString(`OrderHistoryColumns`);

            graphql.addType(
                'userColumnsConfig',
                {
                    ...args,
                },
                `columns
                `,
            );

            const {
                data: { userColumnsConfig },
            } = await graphql.execute();

            // new consultant will have value null in columns since they have no preferences for columns yet
            if (
                userColumnsConfig === null ||
                userColumnsConfig.columns === null ||
                userColumnsConfig.columns[0] === null ||
                userColumnsConfig.columns.length === 0
            ) {
                return [
                    'orderId',
                    'fullName',
                    'status',
                    'channel',
                    'timestamp',
                    'country',
                    'subTotal',
                    'PV',
                    'QV',
                    'CV',
                    'NV',
                    'grandTotal',
                ];
            }

            return userColumnsConfig.columns;
        } catch (e) {
            return [];
        }
    };

    setColumns = async columns => {
        const graphql = new GraphQLFetch({ urlTag: 'ordersSetColumns' });
        await graphql.useAuth();
        const { Authorization } = graphql.options.headers;
        // TODO: refactor the current http request for mutation to a more reusable approach later
        try {
            const query = `mutation UpdateUserColumns($columns: [String]){
            updateUserColumns( tableName: OrderHistoryColumns, columns:$columns){
                columns
            }}`;

            return await fetch(`${nogento.graphql}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization,
                },
                body: JSON.stringify({
                    query,
                    variables: { columns },
                }),
            })
                .then(r => r.json())
                .then(results => results);
        } catch (e) {
            console.info(e);
            return e;
        }
    };
}

const ordersApi = new OrdersApi();

export default ordersApi;
