import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: 0,
    y: 0,
    width: 28,
    height: 24,
};

class Inventory extends Component {
    render() {
        return (
            <Icon {...this.props} altText='Inventory' thickness={0} viewBox={viewBox}>
                <path d="M23.7021277,10.8476596 L21.2198582,6.37078014 L12,1.3177305 L2.82439716,6.37078014 L0.297815035,10.8476596 L2.69143262,12.2217872 L2.69143262,17.5851915 L11.9998014,22.7270355 L21.3081702,17.5851915 L21.3081702,12.2217872 L23.7021277,10.8476596 Z M22.4609929,10.4930496 L14.7926241,14.9256738 L12.6206525,11.6899291 L20.6881702,7.25730496 L22.4609929,10.4930496 Z M12,2.29276596 L19.9344681,6.68113475 L12,11.0695035 L4.06553191,6.68113475 L12,2.29276596 Z M20.4221277,17.0533333 L12,21.7075177 L3.57787234,17.0533333 L3.57787234,12.7095035 L9.47319149,16.1225532 L11.4678582,13.1083688 L10.7143262,12.620766 L9.16289362,14.9257305 L1.49452482,10.4931064 L3.26757447,7.2573617 L11.5566525,11.8230355 L11.5566525,20.4221844 L12.4431773,20.4221844 L12.4431773,13.0196312 L14.5265248,16.1226099 L20.421844,12.7095603 L20.4221277,17.0533333 Z" id="Fill-2"></path>
                <polygon id="Fill-3" points="14.5265248 8.58695035 12.6204823 6.6809078 14.5265248 4.81920567 13.9059574 4.1986383 11.9999149 6.06034043 10.0938723 4.15429787 9.47330496 4.77486525 11.3793475 6.6809078 9.47330496 8.58695035 10.0938723 9.20751773 11.9999149 7.30147518 13.9059574 9.20751773"></polygon>
            </Icon>
        );
    }
}

export default Inventory;
