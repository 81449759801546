export const SPACEBAR = ' ',
    ENTER = 'Enter',
    DOWN_ARROW = 'ArrowDown',
    DOWN = 'Down', // IE/Edge Specific
    UP_ARROW = 'ArrowUp',
    UP = 'Up', // IE/Edge Specific
    LEFT_ARROW = 'ArrowLeft',
    LEFT = 'Left', // IE/Edge Specific
    RIGHT_ARROW = 'ArrowRight',
    RIGHT = 'Right', // IE/Edge Specific
    ESCAPE = 'Escape',
    ESC = 'Esc', // IE/Edge Specific
    TAB = 'Tab';
