import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import s from './Progress.scss';
import cx from 'classnames';

@withStyles(s)
class Progress extends Component {
    render() {
        const { show } = this.props;

        return (
            <div className={s.root}>
                <TransitionGroup>
                    {show && (
                        <CSSTransition
                            classNames={{
                                appear: s.enter,
                                appearActive: s.enterActive,
                                enter: s.enter,
                                enterActive: s.enterActive,
                                exit: s.exit,
                                exitActive: s.exitActive,
                            }}
                            timeout={50000}
                        >
                            <div className={s.determinate} />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        );
    }
}

export default Progress;
