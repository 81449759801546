export const masks = {
    phone,
    name,
    zip,
    ssn,
    sin,
    date,
    transitInstitutionNumber,
};

export function phone(value) {
    if (!value) value = '';
    if (typeof value != 'string') value = value.toString();
    let x = value.replace(/\D/g, '').match(/^(1|)?(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = !x[3] ? x[2] : `${x[1] ? '+1' : ''}(${x[2]}) ${x[3]}${x[4] ? `-${x[4]}` : ''}`;
    return x;
}

export function name(value) {
    return value.replace(/[0-9~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, '');
}

export function zip(value) {
    if (!value) return value;
    const normalized = value
        .toString()
        .toUpperCase()
        .replace(/['`\s~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, '');
    const hasLetters = /[a-zA-Z]+/;
    const isCanadian = hasLetters.test(normalized);
    if (isCanadian) {
        // format canadian codes to have a space after 3 characters
        return normalized.substring(0, 3) + ' ' + normalized.substring(3);
    }
    return normalized;
}

// US Social Security Number
export function ssn(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
    x = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    return x;
}

// US Social Security Number formatter
export function formatMaskedSsn(value) {
    const str = value.substring(value.length - 9);
    return `${str.substring(0, 3)}-${str.substring(3, 5)}-${str.substring(5, 9)}`;

    // return value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5);
}

// CAN Social Insurance Number
export function sin(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})/);
    x = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    return x;
}

// CA Social Insurance Number formatter
export function formatMaskedSin(value) {
    const str = value.substring(value.length - 9);
    return `${str.substring(0, 3)}-${str.substring(3, 6)}-${str.substring(6)}`;
}

export function date(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    x = !x[2] ? x[1] : `${x[1]}/${x[2]}${x[3] ? `/${x[3]}` : ''}`;
    return x;
}

export function formatUTCToDate(value = 0) {
    const dt = new Date(value);
    let month = dt.getMonth() + 1;
    let day = dt.getDate();

    if (month.toString().length === 1) month = `0${month}`;
    if (day.toString().length === 1) day = `0${day}`;

    return `${month}/${day}/${dt.getFullYear()}`;
}

// canadian routing number formatter
export function transitInstitutionNumber(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
    x = !x[2] ? x[1] : `${x[1]}-${x[2]}`;
    return x;
}

export default masks;
