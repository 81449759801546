import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -6,
    y: -7,
    width: 65,
    height: 65,
};

class Performance extends Component {
    render() {
        return (
            <Icon size={{ width: 53, height: 45 }} {...this.props} altText='Performance' viewBox={viewBox}>
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g
                        id="performance"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="2.5"
                    >
                        <g id="icon-btc-performance">
                            <path
                                d="M42.48,42.63 C42.48,42.63 53.98,28.57 47.59,14.52 C41.2,0.46 25.87,0.46 25.87,0.46 C25.87,0.46 9.26,-0.82 2.87,15.8 C-3.52,32.42 7.98,42.64 7.98,42.64"
                                id="Path"
                            />
                            <path d="M13.09,36.24 L7.98,41.36" id="Path" />
                            <path d="M9.26,24.74 L0.95,24.74" id="Path" />
                            <path d="M14.37,13.24 L8.62,7.33" id="Path" />
                            <path d="M25.87,9.41 L25.87,1.74" id="Path" />
                            <path d="M36.09,14.52 L42.89,7.67" id="Path" />
                            <path d="M41.2,24.74 L49.53,24.74" id="Path" />
                            <path d="M36.09,36.24 L42.48,41.36" id="Path" />
                            <g id="Group" transform="translate(23.000000, 18.000000)">
                                <circle id="Oval" cx="2.53" cy="11.13" r="2.5" />
                                <path d="M4.03,8.63 L10.03,0.63" id="Path" />
                            </g>
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Performance;
