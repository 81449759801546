import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: 0,
    y: 0,
    width: 29,
    height: 29,
};

class Ticket extends Component {
    render() {
        return (
            <Icon {...this.props} altText="Ticket" viewBox={viewBox}>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                        id="streamline-icon-ticket@140x140-copy"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#001D42"
                    >
                        <path
                            d="M25.9164238,17.221 C25.9236306,16.9764276 25.7503713,16.7635761 25.5094281,16.721 C24.021306,16.519986 22.9110411,15.2496527 22.9110411,13.748 C22.9110411,12.2463473 24.021306,10.976014 25.5094281,10.775 C25.7506037,10.7328199 25.9240655,10.5197196 25.9164238,10.275 L25.9164238,6.75 C25.9164238,6.47385763 25.6925685,6.25 25.416429,6.25 L3.41666667,6.25 C3.14052193,6.25 2.91666667,6.47385763 2.91666667,6.75 L2.91666667,10.286 C2.91713033,10.5343597 3.09982125,10.7447333 3.34566214,10.78 C4.82407147,10.9906641 5.92243284,12.2566415 5.92243284,13.75 C5.92243284,15.2433585 4.82407147,16.5093359 3.34566214,16.72 C3.09982125,16.7552667 2.91713033,16.9656403 2.91666667,17.214 L2.91666667,20.75 C2.91666667,21.0261424 3.14052193,21.25 3.41666667,21.25 L25.416429,21.25 C25.6925685,21.25 25.9164238,21.0261424 25.9164238,20.75 L25.9164238,17.221 Z"
                            id="Path"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            transform="translate(14.416667, 13.750000) rotate(-45.000000) translate(-14.416667, -13.750000) "
                        />
                        <polygon
                            id="Path"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            points="7.05270564 14.75 13.4166667 8.38603897 19.7806277 14.75 13.4166667 21.113961"
                        />
                        <circle id="Oval" fill="#001D42" cx="15.5" cy="6.5" r="0.5" />
                        <circle id="Oval-Copy-2" fill="#001D42" cx="19.5" cy="10.5" r="0.5" />
                        <circle id="Oval-Copy" fill="#001D42" cx="17.5" cy="8.5" r="0.5" />
                        <circle id="Oval-Copy-3" fill="#001D42" cx="21.5" cy="12.5" r="0.5" />
                        <line
                            x1="11.3560065"
                            y1="14.8106602"
                            x2="13.4773268"
                            y2="12.6893398"
                            id="Path"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <line
                            x1="13.3560065"
                            y1="16.8106602"
                            x2="15.4773268"
                            y2="14.6893398"
                            id="Path"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Ticket;
