import { locales } from '@btc-frontend/config';
import firebase from '@btc-frontend/middleware/firebase/init';

/**
 * Requests the catalog from Firestore
 * @param {string} code
 */

export async function fetchCatalog(code = locales[0].code) {
    const products = [];
    const categories = [];
    const filters = [];
    const db = await firebase.firestore();

    try {
        const [productsData, categoryData, filterData] = await Promise.all([
            db.collection(`products-${code.toLowerCase()}`).get(),
            // db.collection(`categories-${code.toLowerCase()}`).get(),
            // db.collection(`filters-${code.toLowerCase()}`).get(),
        ]);
        productsData.docs.forEach(doc => products.push(doc.data()));
        // categoryData.docs.forEach(doc => categories.push(doc.data()));
        // filterData.docs.forEach(doc => filters.push(doc.data()));
    } catch (e) {
        console.error(e);
    }

    return {
        Products: products,
        Categories: categories,
        Filters: filters,
    };
}
