import { formatNumBySeparators } from './amount';
import localeStore from '@btc-frontend/stores/localeStore';

const defaultCurrency = {
    name: 'USD',
    format: '$0',
    rounding: true,
};

export function formatPrice(price, currency = defaultCurrency) {
    let formattedPrice = parseFloat(price);
    if ((typeof currency.rounding != 'undefined' && !currency.rounding) || formattedPrice % 1 != 0)
        formattedPrice = formattedPrice.toFixed(2);

    let output = JSON.parse(JSON.stringify(currency.format));

    // format negatives
    if (price < 0) {
        output = `-${output}`;
        formattedPrice *= -1;
    }

    return output.replace('0', formattedPrice.toString());
}

export function formatPriceFull(price, currency) {
    const formattedPrice = formatNumBySeparators(parseFloat(price));
    const priceWithCurrency = currency.format.replace('0', formattedPrice.toString());

    return priceWithCurrency;
}

export function getCurrencySymbol(abbreviation) {
    return CURRENCY_SIGN[abbreviation];
}

export function calculatePrice(initialPrice, discount) {
    return initialPrice * (1 - discount);
}

export function formatCurrency(amount: number, currencyCode: string = 'USD') {
    // return currency ooutput based on user's locale
    const localeCode = localeStore.activeLocale.code;
    return amount.toLocaleString(localeCode, { style: 'currency', currency: currencyCode });
}
