const bcBlue = {
    50: '',
    100: '#ACCBD8', // sky blue
    200: '',
    300: '#7AA5BA', // cornflower blue
    400: '',
    500: '#007198', // peacock blue
    600: '',
    700: '#004875', // indigo blue
    800: '',
    900: '#001D42', // navy
    A100: '',
    A200: '',
    A400: '',
    A700: '',
};

export default bcBlue;
