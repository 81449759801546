import { specialCategories, verbose, flags, firebase } from '@btc-frontend/config';
import { processSrc } from '@btc-frontend/middleware/services/products';

const productFields = [
    'productId',
    'title',
    'handle',
    'categories',
    'parentCategoryIds',
    'positions',
    'description',
    'howToUse',
    'ingredients',
    'metaDescription',
    'inStock',
    'stockNotify',
    'sku',
    'image',
    'urlPath',
    'status',
    'productVolume',
    'bestSeller',
    'newTo',
    'newFrom',
    'isGWP',
    'upSellIds',
    'bundleIds',
    'bundledOptions',
    'altVideo',
    'video',
    'swatches',
    'price',
    'specialPrice',
    'maxQuantity',
    'preOrder',
    'preOrderConsultant',
    'flags',
    'information',
    'label',
    'shortDescription',

    // used by co-op clean slate type products
    'brandName',
    'brandPage',

    'whatsInside',
    'value',
    'productVariety',
    'bestFor',
    'simpleDescription',
];

const parentFields = [
    ...productFields,
    'variants',
    'altPromotion',
    'promoName',
    'widgets',
    'visibility',
];

/**
 * Processes the API product data by
 * injecting/renaming fields to match the internal
 * front end schema
 * @param {array} data
 */

export default function processProductData(data, drafts) {
    try {
        for (let i = data.length - 1; i >= 0; i--) {
            try {
                if ((data[i].draft && !drafts) || data[i].mobileOnly || !data[i].productId)
                    data.splice(i, 1);
                else {
                    let product = sanitizeFields(data[i], parentFields);
                    data[i] = product;
                    product.tags = [];
                    product.id = product.productId;

                    processCategories(product);
                    processImages(product);
                    processPrice(product);

                    product.inStock = parseInt(product.inStock);

                    if (product.positions) {
                        try {
                            const positions = product.positions.reduce(
                                (value, accumulator) => `${accumulator},${value}`,
                            );

                            product.positions = JSON.parse(`{${positions}}`);
                        } catch (e) {
                            product.positions = {};
                        }
                    } else product.positions = {};

                    createFallbackTags(product);
                    if (!product.maxQuantity) product.maxQuantity = 10;
                    if (!product.bundleIds) product.bundleIds = [];

                    // Fallback for bundledOptions titles
                    if (product.bundledOptions) {
                        product.bundledOptions.forEach((option, key) => {
                            if (!option.storeViewTitle)
                                product.bundledOptions[key].storeViewTitle =
                                    option.defaultTitle || '';
                            if (option.defaultTitle)
                                delete product.bundledOptions[key].defaultTitle;
                        });
                    }

                    if (product.ingredients && !product.ingredients.fullIngredients)
                        product.ingredients = null;

                    if (product.variants && Array.isArray(product.variants)) {
                        for (let x = product.variants.length - 1; x >= 0; x--) {
                            if (
                                typeof product.variants[x] === 'object' &&
                                product.variants[x].productId
                            ) {
                                if (product.variants[x].draft && !drafts)
                                    product.variants.splice(x, 1);
                                else {
                                    const variant = sanitizeFields(
                                        product.variants[x],
                                        productFields,
                                    );
                                    product.variants[x] = variant;

                                    variant.parentId = product.id;
                                    variant.tags = [];
                                    variant.inStock = parseInt(variant.inStock);
                                    variant.variantTitle = product.productId;
                                    variant.id = variant.productId;

                                    processImages(variant, product);
                                    processPrice(variant);
                                }
                            } else {
                                product.variants = placeholderVariant(product);
                            }
                        }

                        try {
                            product.variants.sort(
                                (a, b) => a.swatches.position - b.swatches.position,
                            );
                        } catch (e) {}
                    } else {
                        product.variants = placeholderVariant(product);
                    }
                }
            } catch (e) {
                console.log(e);
                return data[i];
            }
        }
        return { data };
    } catch (e) {
        console.error(e);
    }
}

function processImages(product, parent = product, parentGallery = []) {
    let productGallery = [];
    const lowestSortNumber = (a, b) => a.sortOrder - b.sortOrder;
    parentGallery = parentGallery.sort(lowestSortNumber);

    if (!product.video) product.video = [];

    const variantId = parent
        ? product.id
        : product.variants && product.variants.length > 1
        ? product.variants[0].productId
        : 0;

    if (product.image && product.image.imageUrl) {
        let image = product.image.imageUrl || null;
        product.images = image
            ? [
                  {
                      src: processSrc(image),
                      variantId,
                  },
              ]
            : [];

        if (product.image.gallery && Array.isArray(product.image.gallery)) {
            product.image.gallery.forEach(item => {
                !item.disabled &&
                    productGallery.push({
                        src: processSrc(item.url),
                        variantId,
                        sortOrder: item.sortOrder,
                    });
            });
        }
        if (product.altVideo) {
            productGallery.push({
                src: product.altVideo,
                variantId: product.id,
                video: true,
                sortOrder: 99,
            });
        }
        product.images.push(...productGallery.sort(lowestSortNumber), ...parentGallery);
    }
    delete product.image;
    if (!product.images || !product.images.length) product.images = [{ src: '' }];

    return product;
}

function placeholderVariant(product) {
    return [
        {
            productId: product.productId,
            id: product.productId,
            variantTitle: product.productId,
            price: parseFloat(product.price),
        },
    ];
}

function processPrice(product) {
    if (product.price) {
        product.price = parseFloat(product.price);
        if (product.specialPrice) {
            product.compareAtPrice = product.price;
            product.price = parseFloat(product.specialPrice);
        }
    } else product.price = 0;
}

function processCategories(product) {
    if (!product.parentCategoryIds || !Array.isArray(product.parentCategoryIds))
        product.parentCategoryIds = [];
    if (!product.categories || !Array.isArray(product.categories)) product.categories = [];
    product.categoryList = [...product.categories, ...product.parentCategoryIds];
}

function createFallbackTags(product) {
    /**
     * Inject "virtual" new and best seller categories
     */

    let isNew = false;
    if (product.newTo && product.newFrom) isNew = dateCheck(product.newFrom, product.newTo);

    if (isNew || (product.promoName && product.promoName.toUpperCase() == 'NEW')) {
        product.categoryList.push(specialCategories.newProducts);
        if (!product.parentCategoryIds) product.parentCategoryIds = [];
        product.tags.push('tagNew');
    }
    if (
        product.bestSeller ||
        (product.promoName && product.promoName.toUpperCase() == 'BEST SELLER')
    ) {
        product.categoryList.push(specialCategories.bestSellers);
        product.tags.push('tagBestSeller');
    }
    if (product.draft) {
        product.tags.push('tagDraft');
    }
}

function sanitizeFields(data, allowedFields) {
    const sanitized = {};
    allowedFields.forEach(key => {
        if (data[key]) sanitized[key] = data[key];
    });
    return sanitized;
}

function dateCheck(from, to) {
    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = new Date();

    if (cDate <= lDate && cDate >= fDate) {
        return true;
    }
    return false;
}
