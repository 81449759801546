exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2SHSl{position:fixed;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);z-index:500;-webkit-transition:opacity .7s ease-in-out;-o-transition:opacity .7s ease-in-out;transition:opacity .7s ease-in-out;-ms-touch-action:none;touch-action:none;pointer-events:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center}._2SHSl,.p_92O{-webkit-animation-duration:.6s;animation-duration:.6s}.p_92O{margin-top:25px;text-align:center}", ""]);

// exports
exports.locals = {
	"root": "_2SHSl fadein",
	"notice": "p_92O fadein"
};