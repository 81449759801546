import { observable, computed, action, autorun, toJS } from 'mobx';
import { flags, analytics, environmentName } from '@btc-frontend/config';
import { flushStorage } from '@beautycounter/bucketing/src/utils/optimizely';
import navigationStore from '@btc-frontend/stores/navigationStore';

const ENABLE_LOCALLY = false;
class FlagStore {
    @observable bucketing;

    constructor() {
        this.bucketing = {};
        this.forcedBucketing = {
            flags: {},
            experiments: {},
        };
        this.engine;
        this.summary = {};
        this.client = null;
        this.queue = [];

        flushStorage();
        this.hydrateBucketing(__BROWSER__ && window.BUCKETING);
    }

    @computed
    get hasExperiment() {
        this.bucketing;
        return experiment => {
            const key = this.experimentKey(experiment);
            this.addTask(() => this.triggerImpression(key));
            const ref = this.bucketing.experiments[experiment];
            return !!ref;
        };
    }

    @computed
    get isFeatureEnabled() {
        this.bucketing;
        return flag => {
            return !!toJS(this.bucketing.flags)[flag];
        };
    }

    @computed
    get hasExperimentVariation() {
        this.bucketing;
        return (experiment, variation) => {
            const key = this.experimentKey(experiment, variation);
            this.addTask(() => this.triggerImpression(key));
            const ref = this.bucketing.experiments[experiment];
            return !!(ref && ref.activeVariation === variation);
        };
    }

    @action
    clearForced() {
        this.forcedBucketing.flags = {};
        this.forcedBucketing.experiments = {};
    }

    @action
    forceFlag(flag, toggle) {
        this.forcedBucketing.flags[flag] = toggle;
        this.hydrateBucketing(this.bucketing);
    }

    @action
    forceExperimentVariation(experiment, variation) {
        this.forcedBucketing.experiments[experiment] = {
            ...this.bucketing.experiments[experiment],
            activeVariation: variation || null,
        };
        this.hydrateBucketing(this.bucketing);
    }

    @action
    hydrateBucketing(bucketing = { flags: {}, experiments: {} }) {
        if (bucketing.forcedBucketing) this.forcedBucketing = bucketing.forcedBucketing;
        this.bucketing = {
            experiments: {
                ...bucketing.experiments,
                ...this.forcedBucketing.experiments,
            },
            flags: {
                ...bucketing.flags,
                ...this.forcedBucketing.flags,
            },
        };
        this.bucketing = toJS(this.bucketing);
        this.generateSummary();
    }

    @action
    async rebucket() {
        if (ENABLE_LOCALLY || (flags.experiments && __BROWSER__ && !__TEST__))
            await this.startClient();
    }

    @action
    async startClient() {
        if (!this.client) {
            const asyncImport = await import('@beautycounter/bucketing');
            this.client = asyncImport.default;
        }
        const Bucketing = this.client;
        const instance = new Bucketing();

        const { disableTracking, datastore: datastoreLocation } = analytics.optimizely;
        const systemAttributes = {
            environmentName,
        };

        await instance.create({
            contextType: 'BROWSER',
            datastoreLocation,
            disableTracking,
            forcedBucketing: toJS(this.forcedBucketing),
            query: navigationStore.startingQuery,
            systemAttributes,
        });
        const bucketing = instance.getBucketing();
        this.hydrateBucketing(bucketing);
        this.engine = instance;
        this.runQueue();
    }

    experimentKey(experiment, variation = null) {
        if (!variation) return experiment;
        return `${experiment}-${variation}`;
    }

    generateSummary() {
        /*
         * Used only for reporting.
         * Do not use for app rendering. Prefer
         * isFeatureEnabled() and experimentVariation() methods
         * so that flag reads can be tracked.
         */

        const flags = { ...this.bucketing.flags, ...this.forcedBucketing.flags };
        const fullExperiments = {
            ...this.bucketing.experiments,
            ...this.forcedBucketing.experiments,
        };
        const experiments = {};
        Object.keys(fullExperiments).forEach(experiment => {
            experiments[experiment] = fullExperiments[experiment].activeVariation;
        });
        this.summary = {
            flags,
            experiments,
        };
    }

    addTask(callback) {
        if (this.engine) callback();
        else this.queue.push(callback);
    }

    runQueue() {
        this.queue.forEach((event, i) => {
            event();
            this.queue.splice(i, 1);
        });
    }

    triggerImpression = name => {
        if (this.engine) this.engine.triggerImpression(name);
    };
}

const flagStore = new FlagStore();

export default flagStore;
export { FlagStore };
