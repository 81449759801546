import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './MenuLeft.scss';
import cx from 'classnames';

import { navLinks, localizedNavLinks, linkRedirect, navTitles } from '@btc-frontend/nav';
import { assetPath, bccomDomain, developerTools } from '@btc-frontend/config';

import { SIZE_TINY } from '@btc-frontend/middleware/constants/icon';
import { parseBTCNav } from '@btc-frontend/middleware/services/navigation/navigation';
import { withTranslation } from 'react-i18next';
import { SIDE_NAV_COMPLETE } from '@btc-frontend/constants/eventNames.js';

import SideDrawer from '@counter-ui/Core/SideDrawer';
import List from '@counter-ui/Core/List';
import ListItem from '@counter-ui/Core/ListItem';
import Collapsible from '@counter-ui/Core/Collapsible';
import FlagDashboard from '@btc-frontend/components/FlagDashboard';
import Link from '@btc-frontend/components/Common/Link';

/* Icons */
import IconErrorBoundary from './MissingIcon';
import IconHome from '@counter-ui/Icon/Icons/Home.jsx';
import IconPerformance from '@counter-ui/Icon/Icons/Performance.jsx';
import IconOrdering from '@counter-ui/Icon/Icons/Ordering.jsx';
import IconClienteling from '@counter-ui/Icon/Icons/Clienteling.jsx';
import IconProspects from '@counter-ui/Icon/Icons/Prospects.jsx';
import IconTeam from '@counter-ui/Icon/Icons/Team.jsx';
import IconSocials from '@counter-ui/Icon/Icons/Socials.jsx';
import Newspaper from '@counter-ui/Icon/Icons/Newspaper2';
import CounterComms from '@counter-ui/Icon/Icons/CounterComms';
import CounterSupport from '@counter-ui/Icon/Icons/CounterSupport';
import GraduationCap from '@counter-ui/Icon/Icons/GraduationCap';
import TurnBackTime from '@counter-ui/Icon/Icons/TurnBackTime';
import AngleDown from '@counter-ui/Icon/Icons/Controls/AngleDown';
import AngleUp from '@counter-ui/Icon/Icons/Controls/AngleUp.jsx';
import WrenchIcon from '@counter-ui/Icon/Icons/Wrench.jsx';
import CounterTraining from '@counter-ui/Icon/Icons/CounterTraining';
import Inventory from '@counter-ui/Icon/Icons/Inventory.jsx';

import autoCommonTranslationsProvider from '@counter-ui/Extensions/AutoCommonTranslationsProvider';

@withStyles(s)
@autoCommonTranslationsProvider
@inject(
    'contentStore',
    'navigationStore',
    'userStore',
    'teamStore',
    'localeStore',
    'analyticsStore',
    'flagStore',
    'interfaceStore',
)
@observer
class MenuLeft extends Component {
    state = {
        open: false,
        active: false,
    };

    componentDidMount() {}

    handleSideNavOpen = () => {
        this.setState({ open: true });
    };

    handleSideNavClose = () => {
        this.setState({ open: false });
    };

    handleClickUtilityLink = event => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ active: true });
    };

    renderTriggerClose(heading) {
        return (
            <div data-testid={`sideNav-${heading.toLowerCase()}`}>
                {heading}
                <AngleDown className={cx(s.arrow)} size={SIZE_TINY} />
            </div>
        );
    }

    renderTriggerOpen(heading) {
        return (
            <div data-testid={`sideNav-${heading.toLowerCase()}`}>
                {heading}
                <AngleUp className={cx(s.arrow)} size={SIZE_TINY} />
            </div>
        );
    }

    renderMenuItem(navigationLinks, path, navStructure) {
        return Object.keys(navigationLinks).map((heading, key) => {
            const { name: title, icon } = navStructure.find(item => item.key === heading);

            const items = {
                home: {
                    icon: <IconHome size={{ width: 30, height: 21 }} />,
                    analytics: {
                        name: 'Home Page',
                    },
                },
                performance: {
                    icon: <IconPerformance size={{ width: 31, height: 19 }} />,
                },
                orders: {
                    icon: <IconOrdering size={{ width: 31, height: 21 }} />,
                },
                customers: {
                    icon: <IconClienteling size={{ width: 31, height: 18 }} />,
                },
                prospects: {
                    icon: <IconProspects size={{ width: 31, height: 25 }} />,
                },
                team: {
                    icon: <IconTeam size={{ width: 31, height: 19 }} />,
                },
                ['pop-ups']: {
                    icon: <IconSocials size={{ width: 31, height: 23 }} />,
                    analytics: {
                        name: 'Pop ups',
                    },
                },
                newsfeed: {
                    icon: <Newspaper size={{ width: 31, height: 22 }} />,
                    analytics: {
                        name: 'Newsfeed',
                    },
                },
                inventory: {
                    icon: <Inventory size={{ width: 31, height: 22 }} />,
                    analytics: {
                        name: 'Inventory Updates',
                    },
                },
            };

            return (
                <ListItem
                    className={cx(s.menuItem, heading && path.includes(heading) && s.selected)}
                    divider
                    key={heading}
                >
                    <Link
                        {...(() =>
                            items[heading] && items[heading].analytics
                                ? items[heading].analytics
                                : {})()}
                        eventName={SIDE_NAV_COMPLETE}
                        testId={`sideNav-${heading.toLowerCase().replace(' ', '')}`}
                        onClick={this.handleSideNavClose}
                        to={(() => {
                            const route = navStructure.find(r => r.key === heading);
                            const hasSubItems = navigationLinks[heading].length !== 0;
                            return !hasSubItems && (route.redirectTo || route.to);
                        })()}
                    >
                        {items[heading] && items[heading].icon ? (
                            items[heading].icon
                        ) : (
                            <IconErrorBoundary>
                                {icon && (
                                    <icon.component
                                        size={{ height: icon.height, width: icon.width }}
                                    />
                                )}
                            </IconErrorBoundary>
                        )}
                        <span className={s.itemLabel}>
                            {navigationLinks[heading].length === 0 && title}
                        </span>
                    </Link>
                    {navigationLinks[heading].length !== 0 && (
                        <Collapsible
                            key={heading}
                            trigger={this.renderTriggerClose(title)}
                            triggerWhenOpen={this.renderTriggerOpen(title)}
                            triggerClassName={s.collapsible}
                            triggerOpenedClassName={s.collapsibleExpanded}
                        >
                            <div className={cx(s.secondaryLinksWrapper, s.menuLink)}>
                                {navigationLinks[heading].map(
                                    subheading =>
                                        subheading[0] && (
                                            <div
                                                key={subheading[0].toLowerCase().replace(' ', '_')}
                                                role="presentation"
                                            >
                                                <Link
                                                    key={subheading[0]
                                                        .toLowerCase()
                                                        .replace(' ', '_')}
                                                    testId={`sideNav-${subheading[0]
                                                        .toLowerCase()
                                                        .replace(' ', '')}`}
                                                    eventName={SIDE_NAV_COMPLETE}
                                                    onClick={this.handleSideNavClose}
                                                    className={s.secondaryLink}
                                                    wrapperClassName={s.linkWrapper}
                                                    to={subheading[1]}
                                                >
                                                    {subheading[0]}
                                                </Link>
                                            </div>
                                        ),
                                )}
                            </div>
                        </Collapsible>
                    )}
                </ListItem>
            );
        });
    }

    renderSupportItem() {
        const { t } = this.props;
        return (
            <>
                <ListItem className={s.supportTitle}>
                    <div className={s.itemLabel}>{t('Support')}</div>
                </ListItem>

                <ListItem className={cx(s.menuItem, s.itemPadding)}>
                    <div
                        eventName={SIDE_NAV_COMPLETE}
                        onClick={() => {
                            linkRedirect(navLinks.counterCommsZenDesk);
                            this.props.analyticsStore.customTracked(SIDE_NAV_COMPLETE, {
                                navButtonSelected: 'Counter Comms',
                            });
                        }}
                        name="Counter Comms"
                        className={s.pointer}
                        to={navLinks.counterCommsZenDesk}
                    >
                        <CounterComms />
                    </div>
                    <div
                        eventname={SIDE_NAV_COMPLETE}
                        name="Counter Comms"
                        className={cx(s.itemLabel, s.linkWrapper, s.pointer)}
                        onClick={() => {
                            linkRedirect(navLinks.counterCommsZenDesk);
                            this.props.analyticsStore.customTracked(SIDE_NAV_COMPLETE, {
                                navButtonSelected: 'Counter Comms',
                            });
                        }}
                        to={navLinks.counterCommsZenDesk}
                    >
                        {t('counterComms', { defaultValue: `Counter Comms` })}
                    </div>
                </ListItem>

                <ListItem className={cx(s.menuItem, s.itemPadding)}>
                    <div
                        eventname={SIDE_NAV_COMPLETE}
                        onClick={() => {
                            linkRedirect(navLinks.counterSupportZenDesk);
                            this.props.analyticsStore.customTracked(SIDE_NAV_COMPLETE, {
                                navButtonSelected: 'Counter Support',
                            });
                        }}
                        name="Counter Support"
                        className={s.pointer}
                        to={navLinks.counterSupportZenDesk}
                    >
                        <CounterSupport size={{ width: 31, height: 21 }} />
                    </div>
                    <div
                        eventName={SIDE_NAV_COMPLETE}
                        name="Counter Support"
                        className={cx(s.itemLabel, s.linkWrapper, s.pointer)}
                        onClick={() => {
                            linkRedirect(navLinks.counterSupportZenDesk);
                            this.props.analyticsStore.customTracked(SIDE_NAV_COMPLETE, {
                                navButtonSelected: 'Counter Support',
                            });
                        }}
                        to={navLinks.counterSupportZenDesk}
                    >
                        {t('counterSupport', { defaultValue: `Counter Support` })}
                    </div>
                </ListItem>

                <ListItem className={cx(s.menuItem, s.itemPadding)}>
                    <div
                        name="Counter University"
                        eventName={SIDE_NAV_COMPLETE}
                        onClick={() => {
                            linkRedirect(navLinks.counterUniversity);
                            this.props.analyticsStore.customTracked(SIDE_NAV_COMPLETE, {
                                navButtonSelected: 'Counter University',
                            });
                        }}
                    >
                        <GraduationCap size={{ width: 31, height: 21 }} />
                    </div>
                    <div
                        name="Counter University"
                        eventName={SIDE_NAV_COMPLETE}
                        onClick={() => {
                            linkRedirect(navLinks.counterUniversity);
                            this.props.analyticsStore.customTracked(SIDE_NAV_COMPLETE, {
                                navButtonSelected: 'Counter University',
                            });
                        }}
                        className={cx(s.itemLabel, s.linkWrapper, s.pointer)}
                        testId="sideNav-counterUniversity"
                    >
                        {t('counterUniversity', { defaultValue: `Counter University` })}
                    </div>
                </ListItem>
            </>
        );
    }

    renderVersionsItem() {
        const { t } = this.props;
        return (
            <>
                <ListItem className={s.supportTitle}>
                    <div className={s.itemLabel}>{t('Versions')}</div>
                </ListItem>

                <ListItem className={cx(s.menuItem, s.itemPadding)}>
                    <Link name="Legacy BtC" to={navLinks.legacyBtc}>
                        <TurnBackTime size={{ width: 28, height: 18 }} />
                    </Link>
                    <Link
                        name="Legacy BtC"
                        to={navLinks.legacyBtc}
                        className={cx(s.itemLabel, s.linkWrapper)}
                        testId="sideNav-legacyBtc"
                    >
                        BtC <span className={s.versionTag}>{t('Original')}</span>
                    </Link>
                </ListItem>
            </>
        );
    }

    renderTestingUtility() {
        const { t } = this.props;
        return (
            <>
                <ListItem className={s.supportTitle}>
                    <div className={s.itemLabel}>{t('utility', { defaultValue: `Utility` })}</div>
                </ListItem>

                <ListItem className={cx(s.menuItem, s.itemPadding)}>
                    <div name="Testing Utility" onClick={this.handleClickUtilityLink}>
                        <WrenchIcon
                            color={'#001d42'}
                            size={{
                                width: 35,
                                height: 24,
                            }}
                        />
                        <FlagDashboard
                            visible={this.state.active}
                            onClose={() => this.setState({ active: false })}
                        />
                    </div>
                    <div
                        name="Testing Utility"
                        onClick={this.handleClickUtilityLink}
                        className={cx(s.itemLabel, s.linkWrapper, s.pointer)}
                    >
                        Testing Utility
                    </div>
                </ListItem>
            </>
        );
    }

    render() {
        const logoImage = `${assetPath}/b-logo.svg`;
        const {
            path,
            teamStore,
            userStore,
            navigationStore,
            localeStore: { activeLocale },
            flagStore,
        } = this.props;
        const navStructure = navTitles(activeLocale.lang, activeLocale.code);
        const navigationLinks = parseBTCNav(
            navStructure,
            teamStore,
            navigationStore,
            flagStore,
            userStore,
        );
        const showVersion = flagStore.isFeatureEnabled('btc_old_to_new_transition');

        return (
            <div className={s.menuLeft}>
                <div className={s.logoContainer}>
                    <div className={s.logoBackground}>
                        <img src={logoImage} alt="logo" className={s.logoImage} />
                    </div>
                </div>

                <SideDrawer
                    onMouseOver={this.handleSideNavOpen}
                    onMouseLeave={this.handleSideNavClose}
                    onFocus={this.handleSideNavOpen}
                    onBlur={this.handleSideNavClose}
                    open={this.state.open}
                >
                    <List disablePadding>
                        {this.renderMenuItem(navigationLinks, path, navStructure)}
                        {this.renderSupportItem()}
                        {showVersion && this.renderVersionsItem()}
                        {developerTools && this.renderTestingUtility()}
                    </List>
                </SideDrawer>
            </div>
        );
    }
}

export default withTranslation('navigation')(MenuLeft);
