import React, { Component } from 'react';
import PropTypes from 'prop-types';
import emptyFunction from 'node_modules/fbjs/lib/emptyFunction.js';
import DefaultLayout from '@btc-frontend/containers/LayoutContainer/DefaultLayout';
import EmptyLayout from '@btc-frontend/containers/LayoutContainer/EmptyLayout';
import ErrorPage from '@btc-frontend/containers/PageContainer/ErrorPage';

import FontPrefetch from '@btc-frontend/components/Extensions/FontPrefetch';
import Segment from '@btc-frontend/components/Extensions/Segment';

import s from '@beautycounter/counter-ui/src/theme/styles/app.scss';
import { observer, inject } from 'mobx-react';
import { includes } from 'lodash';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import {
    mobilePagesWithFooter,
    mobilePagesWithBackIcon,
    emptyLayoutPages,
} from '@btc-frontend/config.js';
import Progress from '@btc-frontend/components/Common/Progress';
import Notifications from '@btc-frontend/components/Notifications';
import StartupLoader from '@btc-frontend/components/Common/StartupLoader';
import { withTranslation } from 'react-i18next';

@inject(
    'navigationStore',
    'sessionStore',
    'interfaceStore',
    'messagingStore',
    'userStore',
    'accountStore',
)
@observer
class AppContainer extends Component {
    static propTypes = {
        context: PropTypes.shape({
            getCookie: PropTypes.func,
            setCookie: PropTypes.func,
            insertCss: PropTypes.func,
            insertPaginationInfo: PropTypes.func,
            setTitle: PropTypes.func,
            onSetMeta: PropTypes.func,
            onPageNotFound: PropTypes.func,
        }),
        path: PropTypes.string,
        children: PropTypes.node.isRequired,
        error: PropTypes.object,
        useLayout: PropTypes.bool,
    };

    static childContextTypes = {
        getCookie: PropTypes.func.isRequired,
        setCookie: PropTypes.func.isRequired,
        insertCss: PropTypes.func.isRequired,
        insertPaginationInfo: PropTypes.func.isRequired,
        setTitle: PropTypes.func,
        onSetMeta: PropTypes.func.isRequired,
        onPageNotFound: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        const { insertCss } = this.props.context;
        this.removeCss = insertCss(s);

        this.state = {
            error: false,
        };
    }

    componentDidMount = () => {
        const { context, path } = this.props;

        // Delay the session initation a moment to prevent the DOM
        // from being re-rendered after the session new state has been pushed
        requestAnimationFrame(async () => {
            this.props.sessionStore.restoreSessions();
            this.props.interfaceStore.addMountState();
        });
    };

    componentDidCatch(error, info) {
        this.setState({ error: true });
    }

    getChildContext() {
        const context = this.props.context;

        return {
            insertCss: context.insertCss || emptyFunction,
            insertPaginationInfo: context.insertPaginationInfo || emptyFunction,
            getCookie: context.getCookie || emptyFunction,
            setCookie: context.setCookie || emptyFunction,
            onSetMeta: context.onSetMeta || emptyFunction,
            onPageNotFound: context.onPageNotFound || emptyFunction,
        };
    }

    getCurrentPageName(component) {
        if (typeof component.type != 'undefined')
            return (
                component.type.pageName ||
                getSafe(() => component.props.children.type.pageName) ||
                (component.type.ComposedComponent && component.type.ComposedComponent.pageName)
            );
        return '';
    }

    renderExtensions() {
        return (
            <div>
                <Segment />
                <Notifications />
                <FontPrefetch />
            </div>
        );
    }

    render() {
        const { error } = this.state;
        const { children, path, useLayout, t } = this.props,
            currentPage = this.getCurrentPageName(children),
            showFooter = includes(mobilePagesWithFooter, currentPage),
            showBackIcon = includes(mobilePagesWithBackIcon, currentPage),
            isEmptyLayout = includes(emptyLayoutPages, this.getCurrentPageName(children));

        if (error && __PRODUCTION__)
            return (
                <ErrorPage
                    error={{
                        name: 'Whoops!',
                        stack: t('btcerrormessage', {
                            defaultValue: `There was an error loading Behind the Counter, please refresh the page. If you continue to run into this issue, contact Counter Support?`,
                        }),
                    }}
                />
            );

        let body = false;
        const notFound = this.getCurrentPageName(children) === 'notFound';

        if (isEmptyLayout || (__SERVER__ && notFound))
            body = <EmptyLayout>{this.props.children}</EmptyLayout>;
        else if (__BROWSER__)
            body = useLayout ? (
                <DefaultLayout
                    key="layout"
                    hasFooter={showFooter}
                    showBackIcon={showBackIcon}
                    path={path}
                >
                    {this.props.children}
                </DefaultLayout>
            ) : (
                this.props.children
            );

        return [
            <div key="default-body">
                <div>
                    {notFound ? <StartupLoader /> : <div />}
                    {body}
                </div>
                {this.renderExtensions()}
            </div>,
            <Progress key="progress" show={this.props.interfaceStore.inProgress} />,
        ];
    }
}

export default withTranslation('app')(AppContainer);
