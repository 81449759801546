import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Button.scss';
import cx from 'classnames';
import theme from '@beautycounter/counter-ui/src/theme';
import { ENTER, SPACEBAR } from '@beautycounter/constants/keyvalues';
import Ink from 'react-ink';

@withStyles(s)
class Button extends Component {
    static propTypes = {
        style: pt.object,
        onClick: pt.func,
        wide: pt.bool,
        to: pt.string,
        small: pt.bool,
        mobile: pt.bool,
        regular: pt.bool,
        fat: pt.bool,
        fullWidth: pt.bool,
        linkType: pt.bool,
        cat: pt.bool,
        noBorder: pt.bool,
        frame: pt.bool,
        thin: pt.bool,
        wrapText: pt.bool,
        error: pt.bool,
        color: pt.oneOf([
            theme.palette.white,
            theme.palette.black,
            'black',
            'white',
            'deepWhite',
            'transparent',
            'ghost',
            'ghostLight',
            'ghostDark',
            'icon',
        ]),
        icon: pt.element,
        disabled: pt.bool,
        disableStyleOnly: pt.bool,
        form: pt.string,
        className: pt.string,
        isSubmit: pt.bool,
        children: pt.node,
        tabindex: pt.string,
        id: pt.string,
        ariaProps: pt.object,
        setRef: pt.object,
    };

    static defaultProps = {
        wide: false,
        noBorder: false,
        frame: true,
        color: theme.palette.black,
        disabled: false,
        small: false,
        error: false,
        fat: false,
        cat: false,
        fullWidth: false,
        thin: false,
        isSubmit: false,
        regular: false,
        wrapText: false,
        tabindex: '',
        analyticsData: {},
        testId: null,
        id: null,
        setRef: null,
        ariaProps: {},
    };

    handleClick = event => {
        this.props.onClick && this.props.onClick(event);
    };

    handleKeyDown = event => {
        if (!event) return;

        if (event.key === ENTER || event.key === SPACEBAR) {
            this.handleClick(event);
        }
    };

    renderIcon() {
        return <span className={s.icon}>{this.props.icon}</span>;
    }

    render() {
        const { iconPosition, ariaProps = {}, color } = this.props;
        const isBlackColor = color === theme.palette.black || color === 'black';
        const isWhiteColor = color === theme.palette.white || color === 'white';

        return (
            <button
                id={this.props.id}
                type={this.props.isSubmit ? 'submit' : 'button'}
                form={this.props.form}
                onClick={this.handleClick}
                style={typeof this.props.style !== undefined ? this.props.style : {}}
                className={cx(
                    s.root,
                    {
                        [s.black]: isBlackColor,
                        [s.white]: isWhiteColor,
                        [s.ghostLight]: color === 'ghostLight',
                        [s.ghostDark]: color === 'ghostDark',
                        [s.iconType]: color === 'icon',
                        [s.ghost]: color === 'ghost',
                        [s.transparent]: color === 'transparent',
                        [s.noBorder]: this.props.noBorder,
                        [s.frame]: this.props.frame,
                        [s.regular]: this.props.regular,
                        [s.fat]: this.props.fat,
                        [s.thin]: this.props.thin,
                        [s.small]: this.props.small,
                        [s.mobile]: this.props.mobile,
                        [s.cat]: this.props.cat,
                        [s.wide]: this.props.wide,
                        [s.error]: this.props.error,
                        [s.disabled]: this.props.disabled || this.props.disableStyleOnly,
                        [s.wrapText]: this.props.wrapText,
                        [s.fullWidth]: this.props.fullWidth,
                        [s.link]: this.props.linkType,
                    },
                    this.props.className,
                )}
                disabled={this.props.disabled}
                tabIndex={this.props.tabindex}
                data-testid={this.props.testId}
                ref={typeof this.props.setRef === 'object' && this.props.setRef}
                onKeyDown={this.handleKeyDown}
                {...ariaProps}
            >
                {!s.link && (
                    <div className={s.ripple}>
                        <Ink duration="600" opacity={0.1} />
                    </div>
                )}
                <div className={cx(s.buttonContent, iconPosition && s.reversed)}>
                    <div className={s.text}>{this.props.children}</div>
                    {this.props.icon && this.renderIcon()}
                </div>
            </button>
        );
    }
}

export default Button;
