import React from 'react';
import s from './Breadcrumb.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

interface IProps {
    routes: IRoute.IBreadcrumb[];
    link(className: string, to: string): React.FC;
    className: string;
    addBottomMargin: boolean;
}

const Breadcrumb = withStyles(s)((props: IProps) => {
    const { routes, link: Link, className: injectedClass, addBottomMargin = true } = props;
    if (!(routes && routes.length)) return <div />;
    const lastIndex = routes.length - 1;
    const parentRoutes = routes.slice(0, lastIndex);
    const currentRoute = routes[lastIndex];
    return (
        <nav
            className={cx(s.root, injectedClass, addBottomMargin && s.addBottomMargin)}
            aria-label="Breadcrumb"
            data-testid="breadcrumbs"
        >
            <ol className={s.orderedList}>
                {parentRoutes.map((route, index) => (
                    <li key={index} className={s.crumb}>
                        <Link className={s.link} to={route.to}>
                            {route.name}
                        </Link>
                    </li>
                ))}
                <li key="current" className={cx(s.crumb, s.unclickable)}>
                    <Link className={s.currentLink} to={currentRoute.to} aria-current="page">
                        {currentRoute.name}
                    </Link>
                </li>
            </ol>
        </nav>
    );
});

export default withStyles(s)(Breadcrumb);
