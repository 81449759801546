/* translationGroup: myCustomers */
import { observable, action, toJS } from 'mobx';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import sessionStore from '../sessionStore/sessionStore';
import graphqlFetch from '@btc-frontend/middleware/api/graphql';
import navigationStore from '@btc-frontend/stores/navigationStore';
import analyticsStore from '@btc-frontend/stores/analyticsStore';
import flagStore from '@btc-frontend/stores/flagStore';
import { routeKeys } from '@btc-frontend/middleware/constants/routes';
import { SEARCH_QUERY_TYPES } from '@btc-frontend/constants/searchQueryTypes';
import { formatToTimeZone } from 'date-fns-timezone';
import { CUSTOMERS_FETCH } from '@btc-frontend/constants';
import localeStore from '@btc-frontend/stores/localeStore';
import { format } from 'date-fns';
import { PC_RANGE_8_TO_30_DAYS } from '@btc-frontend/constants/queryFilters';
import { getFilter } from '@btc-frontend/utils/filters';

class MyCustomersStore {
    @observable customers;
    @observable linkedColumns;
    @observable ignoreFields;
    @observable columns;
    @observable filters;
    @observable totalCustomers;
    @observable selectedColumns;
    @observable params;
    @observable searchText;

    constructor() {
        this.customers = [];
        this.linkedColumns = [];
        this.ignoreFields = [];
        this.selectedColumns = [];
        this.columns = [];
        this.params = {};
        this.filters = [];
        this.totalCustomers = 0;
        this.searchText = '';
        this.latencyInMs = 0;
        this.fetchMyCustomers = this.fetchMyCustomers.bind(this);
    }

    get selectedColumnDefs() {
        const allColumns = Object.values(this.columnDefinitions);
        const selected = allColumns.filter(x => this.selectedColumns.includes(x.field));
        const fixedColumns = allColumns.filter(x => x.fixed);

        const columnDefs = selected.length > 0 ? [...fixedColumns, ...selected] : allColumns;
        return columnDefs;
    }

    querySearch(filters) {
        if (!filters.length) return false;
        const querySearchFilterObject = filters.find(filter =>
            Object.keys(SEARCH_QUERY_TYPES).includes(filter.key),
        );
        return querySearchFilterObject;
    }

    get getParams() {
        return this.params;
    }

    @action
    loadCustomers = async ({
        filters = [],
        sortBy,
        customerIds = [],
        currentPage = 1,
        perPage = 0,
    }: ICounterUIDataTable.IDataCallback) => {
        const columnDefs = Object.values(this.columnDefinitions);
        let response;
        interfaceStore.showProgress(CUSTOMERS_FETCH);
        try {
            this.columns = this.selectedColumnDefs;
            // some fields require special sorting considerations
            const sortBySelection = () => {
                if (Object.entries(sortBy).length === 0)
                    sortBy = {
                        direction: 'asc',
                        column: 'firstName',
                    };
                if (
                    this.columnDefinitions[sortBy.column] &&
                    this.columnDefinitions[sortBy.column].sortingMapping
                )
                    return this.columnDefinitions[sortBy.column].sortingMapping();
                return sortBy.column;
            };

            this.params = {
                sort: {
                    sortBy: sortBySelection(),
                    sortMode: sortBy.direction,
                },
                filters: filters.map(({ key, value }) => ({ key, value })),
                page: currentPage,
                perPage,
                customerIds,
            };

            // handle search box query and capture analytics
            if (this.searchText && (filters.length || customerIds.length)) {
                const filterType = !filters.length
                    ? customerIds.map(id => ({ key: 'orderId', value: id }))
                    : filters;
                const searchFilterObject = this.querySearch(filterType);
                if (searchFilterObject) {
                    analyticsStore.customTracked('Customer BTC Customer Table Search Complete', {
                        searchQueryType: SEARCH_QUERY_TYPES[searchFilterObject.key],
                        searchQueryAmount: Array.isArray(searchFilterObject.value)
                            ? searchFilterObject.value.length
                            : 1,
                    });
                }
            }
            // handle filter query and capture analytics
            if (!this.searchText && filters.length) {
                analyticsStore.customTracked('Consultant BTC Customer Table Filter Complete', {
                    filterCustomerType: getFilter(filters, this.columnDefinitions.userType.field)
                        .value,
                    filterPCExpiring: getFilter(
                        filters,
                        this.columnDefinitions.productCreditExpiration.field,
                    ).value,
                    filterCity: getFilter(filters, this.columnDefinitions.city.field).value,
                    filterState: getFilter(filters, this.columnDefinitions.region.field).value,
                    filterCountry: getFilter(filters, this.columnDefinitions.countryId.field).value,
                    filterMemberExpiration: getFilter(
                        filters,
                        this.columnDefinitions.memberExpiration.field,
                    ).value,
                    filterIncentives: getFilter(filters, this.columnDefinitions.incentives.field)
                        .value,
                    filterOther: getFilter(filters, this.columnDefinitions.other.field).value,
                });
            }

            response = await this.fetchMyCustomers(this.params);
            if (!response) throw 'server error';

            const {
                myCustomers: { customersMetadata = {}, customers = [] },
            } = response || { myCustomers: {} };

            this.customers = this.flattenData(columnDefs, customers) || [];
            this.totalCustomers = customersMetadata ? customersMetadata.totalCustomers : 0;
            interfaceStore.hideProgress(CUSTOMERS_FETCH);
            return { rows: this.customers, total: this.totalCustomers };
        } catch (e) {
            console.log(e);
        }
    };

    loadAllCustomers = async ({ selectedColumns }) => {
        const exportParams = {
            ...this.params,
            totalCustomers: this.totalCustomers,
            isExport: true,
        };
        const allData = await this.fetchMyCustomers(exportParams);
        const flatten = this.flattenData(
            this.selectedColumnDefs.filter(({ field }) => selectedColumns.indexOf(field) > -1),
            allData.myCustomers.customers,
        );

        return flatten;
    };

    async loadDashboardQuery(query) {
        const { userType } = query;
        const customerUserType = userType === 'clients' ? 'client' : 'member';
        const filters = [{ key: 'userType', value: [customerUserType] }];
        this.filters = filters;
        this.customers = await this.fetchMyCustomers(filters);
    }

    flattenData(columns, data) {
        const flattenedData = [];
        data &&
            data.forEach(cust => {
                const {
                    address: addressObj,
                    countryId,
                    consultantPreferences,
                    productCredit,
                    pcBalance,
                    productCreditExpiration,
                    nextRenewalDateUTC,
                    lastPurchaseDate,
                    customerSinceDate,
                    bobExpiration,
                } = cust;
                const { address, region, city, postalCode } = addressObj || {};
                const { paymentAccess = false, creditAccess = false } = consultantPreferences || {};
                const { us, ca } = productCredit || {};
                const { balance } = (countryId == 2 ? ca : us) || {};
                const flattened = {
                    ...cust,
                    region,
                    address,
                    city,
                    postalCode,
                    paymentAccess,
                    creditAccess,
                    productCreditAmount: pcBalance || 0,
                    customerSinceDate: customerSinceDate
                        ? format(customerSinceDate, 'MM/DD/YYYY')
                        : null,
                    lastPurchaseDate: lastPurchaseDate
                        ? format(lastPurchaseDate, 'MM/DD/YYYY')
                        : null,
                    nextRenewalDateUTC: nextRenewalDateUTC
                        ? formatToTimeZone(nextRenewalDateUTC, 'MM/DD/YYYY', {
                              timeZone: 'America/Los_Angeles',
                          })
                        : null,
                    productCreditExpiration: productCreditExpiration
                        ? formatToTimeZone(productCreditExpiration, 'MM/DD/YYYY', {
                              timeZone: 'UTC',
                          })
                        : null,
                    memberExpiration: bobExpiration
                        ? formatToTimeZone(bobExpiration, 'MM/DD/YYYY', {
                              timeZone: 'America/Los_Angeles',
                          })
                        : null,
                };
                flattenedData.push(
                    columns.reduce((obj, column) => {
                        if (flattened[column.field] !== undefined) {
                            obj[column.field] =
                                column.field === 'timestamp'
                                    ? format(flattened[column.field], 'MM/DD/YYYY')
                                    : flattened[column.field];
                        } else {
                            obj[column.field] = '';
                        }
                        return obj;
                    }, {}),
                );
            });
        return flattenedData;
    }

    async fetchMyCustomers({
        page = 1,
        perPage,
        sort = {},
        totalCustomers = null,
        isExport = false,
        ...args
    }) {
        if (!perPage) return null;
        const graphql = new graphqlFetch();
        await graphql.useAuth();
        if (totalCustomers && totalCustomers > perPage) perPage = totalCustomers;
        let filters = (toJS(args.filters) || []).map(kvp => {
            let value;
            if (!Array.isArray(kvp.value)) value = [kvp.value];
            else value = kvp.value;
            value = value.map(val => `${val}`);
            return { key: kvp.key, value };
        });

        // consolidate 'sku' and 'products' filter types for backwards compatability
        const { reducedFilters, productValues } = filters.reduce(
            (acc, kvp) => {
                if (['products', 'sku'].includes(kvp.key)) {
                    acc.productValues = [...acc.productValues, ...kvp.value];
                } else {
                    acc.reducedFilters.push(kvp);
                }
                return acc;
            },
            { reducedFilters: [], productValues: [] },
        );
        reducedFilters.push({ key: 'products', value: productValues });
        filters = reducedFilters;

        const customerIds = toJS(args.customerIds) || [];
        graphql.addType(
            'myCustomers',
            { filters, perPage, page, sort, customerIds, isExport },
            `
            customersMetadata {
                totalCustomers
            }
            customers {
                fsDocId
                id
                uid
                firstName
                lastName
                fullName
                email
                birthDateUTC
                enrollmentDateUTC
                nextRenewalDateUTC
                groupId
                phoenixId
                phone
                customerSinceDate
                lastPurchaseDate
                lifetimeSpent
                pastYearSpent
                consultantPreferences {
                    paymentAccess
                    creditAccess
                }
                sponsorId
                enroller
                userType
                countryId
                pcBalance
                productCreditExpiration 
                bobExpiration
                subtotalExcludingEnrollmentProduct
                isFirstOrderAboveThreshold
                productCredit {
                    ca {
                        balance
                        currency
                        expirationDate
                    }
                    us {
                        balance
                        currency
                        expirationDate
                    }
                }
                address {
                    address
                    address2
                    city
                    state
                    region
                    country
                    countryId
                    postalCode
                }
            }
            `,
        );
        let response;

        if (sessionStore.getKey('tableLoadingWidget')) {
            response = await new Promise((resolve, reject) => {
                setTimeout(async () => resolve(await graphql.execute()), this.latencyInMs);
            });
        } else {
            response = await graphql.execute();
        }
        if (!(response && response.data)) return {};
        return response.data || {};
    }
    // This method is used for testing utility for DevToolDialog (see StickyButton module):
    setDevLatency = latencyInMs => {
        this.latencyInMs = latencyInMs;
    };

    get filterOptions() {
        const { t = t => t } = this;
        const options = {
            userType: {
                name: 'userType',
                key: 'userType',
                options: [
                    {
                        name: t('member', { defaultValue: 'MEMBER' }),
                        key: 'Member',
                        value: '2',
                    },
                    {
                        name: t('client', { defaultValue: 'CLIENT' }),
                        key: 'Client',
                        value: '3',
                    },
                ],
            },

            incentives: {
                name: 'incentives',
                key: 'incentives',
                options: [
                    {
                        name: t('followtheleaderchallenge', {
                            defaultValue: 'Follow the Leader Challenge',
                        }),
                        key: t('followtheleaderchallenge', {
                            defaultValue: 'Follow the Leader Challenge',
                        }),
                    },
                ],
            },
            productCreditExpiring: {
                name: 'productCreditExpiring',
                key: 'prdouctCreditExpiring',
                options: [
                    {
                        name: t('a7Days', { defaultValue: `<7 Days` }),
                        key: '7Days',
                    },
                    {
                        name: t('a30Days', { defaultValue: `<30 Days` }),
                        key: '30Days',
                    },
                ],
            },
            customerCountry: {
                name: 'customerCountry',
                key: 'customerCountry',
                options: [
                    {
                        name: t('us', { defaultValue: `US` }),
                        key: 'US',
                    },
                    {
                        name: t('canada', { defaultValue: `Canada` }),
                        key: 'CA',
                    },
                ],
            },
            other: {
                name: 'other',
                key: 'other',
                options: [
                    {
                        name: t('newcustomers', {
                            defaultValue: `New customers due to activity requirements`,
                        }),
                        key: `newCustomers`,
                    },
                ],
            },
        };

        if (flagStore.isFeatureEnabled('athenaExpiringCredit')) {
            options.productCreditExpiring.options.push({
                name: t('a8to30days', { defaultValue: '8 - 30 Days' }),
                key: PC_RANGE_8_TO_30_DAYS,
            });
        }

        if (flagStore.isFeatureEnabled('athenaClientsAndMembers')) {
            options.memberExpiration = {
                name: 'memberExpiration',
                key: 'memberExpiration',
                options: [
                    {
                        name: t('memberExpiration30Days', { defaultValue: '<30 Days' }),
                        key: '30',
                    },
                ],
            };
        }

        return options;
    }

    get columnDefinitions() {
        const { t = t => t } = this;
        const { customer } = routeKeys.clientele;
        const baseUrl = navigationStore.getPathFromRouteKey(customer);
        const columns = {
            phoenixId: {
                id: 0,
                title: 'CUSTOMER ID',
                filterPinTitle: 'Order #',
                field: 'phoenixId',
                orderBy: 'ID',
                canHide: true,
                sortable: true,
                sortMode: 'desc',
                searchBy: { orders: 'Orders' },
            },
            firstName: {
                id: 23,
                title: t('firstname', { defaultValue: `FIRST NAME` }),
                field: 'firstName',
                isDefaultSortBy: true,
                fixed: true,
                displayRefField: 'phoenixId',
                type: 'link',
                getUrl: data => `${baseUrl}/${data}`,
                sortable: true,
                sortMode: 'desc',
            },
            lastName: {
                id: 23,
                title: t('lastname', { defaultValue: `LAST NAME` }),
                field: 'lastName',
                isDefaultSortBy: true,
                fixed: true,
                displayRefField: 'phoenixId',
                type: 'link',
                getUrl: data => `${baseUrl}/${data}`,
                sortable: true,
                sortMode: 'desc',
            },
            email: {
                id: 2,
                title: t('email', { defaultValue: `EMAIL` }),
                field: 'email',
                type: 'email',
                sortable: true,
                isDefaultSortBy: true,
                sortMode: 'desc',
            },
            phone: {
                id: 3,
                title: t('phone', { defaultValue: `PHONE` }),
                field: 'phone',
                type: 'phone',
                sortable: true,
                isDefaultSortBy: true,
                sortMode: 'desc',
            },
            userType: {
                id: 4,
                title: t('customerType', { defaultValue: `CUSTOMER TYPE` }),
                field: 'userType',
                displayValue: values =>
                    values.map(value => {
                        if (value === '2') return 'Member';
                        if (value === '3') return 'Client';
                    }),
                isDefaultSortBy: true,
                filterable: true,
                sortable: true,
                type: 'multiple',
                sortMode: 'desc',
                options: this.filterOptions.userType.options,
                multipleSelection: true,
            },
            customerSinceDate: {
                id: 25,
                title: t('customersince', { defaultValue: `CUSTOMER SINCE` }),
                field: 'customerSinceDate',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
                type: 'date',
            },
            lastPurchaseDate: {
                id: 5,
                title: t('lastPurchase', { defaultValue: `LAST PURCHASE` }),
                field: 'lastPurchaseDate',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
                type: 'date',
            },
            lifetimeSpent: {
                id: 6,
                title: t('lifetimeSpent', { defaultValue: `LIFETIME SPENT` }),
                field: 'lifetimeSpent',
                type: 'currency',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
            },
            pastYearSpent: {
                id: 26,
                title: t('pastYearSpent', { defaultValue: `PAST YEAR SPENT` }),
                field: 'pastYearSpent',
                type: 'currency',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
            },
            productCreditAmount: {
                id: 7,
                title: t('pcAmount', { defaultValue: `PC BALANCE` }),
                field: 'productCreditAmount',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
                type: 'currency',
                sortingMapping: () => {
                    switch (localeStore.activeLocale.code) {
                        case 'en-CA':
                        case 'fr-CA':
                            return 'product_credit.ca.balance';
                        default:
                            return 'product_credit.us.balance';
                    }
                },
            },
            productCreditExpiration: {
                id: 8,
                title: t('productCreditExpiration', { defaultValue: `PRODUCT CREDIT EXPIRATION` }),
                field: 'productCreditExpiration',
                filterable: true,
                type: 'date',
                options: this.filterOptions.productCreditExpiring.options,
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
                multipleSelection: false,
                sortingMapping: () => {
                    switch (localeStore.activeLocale.code) {
                        case 'en-CA':
                        case 'fr-CA':
                            return 'product_credit.ca.expirationDate';
                        default:
                            return 'product_credit.us.expirationDate';
                    }
                },
            },
            bobExpiration: {
                id: 9,
                title: t('bobExpiration', { defaultValue: `BOB EXPIRATION` }),
                field: 'bobExpiration',
                isDefaultSortBy: true,
                sortMode: 'desc',
                type: 'date',
            },
            enroller: {
                id: 10,
                title: t('enroller', { defaultValue: `ENROLLER` }),
                field: 'enroller',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
            },
            nextRenewalDateUTC: {
                id: 11,
                title: t('renewalDate', { defaultValue: `RENEWAL DATE` }),
                field: 'nextRenewalDateUTC',
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
                type: 'date',
            },
            address: {
                id: 12,
                title: t('address', { defaultValue: `ADDRESS` }),
                field: 'address',
                isDefaultSortBy: true,
                filterable: true,
                sortable: true,
                type: 'multipleTextSearch',
                sortMode: 'desc',
                sortingMapping: () => 'address.address',
            },
            city: {
                id: 13,
                title: t('city', { defaultValue: `CITY` }),
                field: 'city',
                isDefaultSortBy: true,
                filterable: true,
                sortable: true,
                type: 'multipleTextSearch',
                sortMode: 'desc',
                sortingMapping: () => 'address.city',
            },
            region: {
                id: 14,
                title: t('state', { defaultValue: `STATE` }),
                field: 'region',
                isDefaultSortBy: true,
                filterable: true,
                type: 'multipleTextSearch',
                sortMode: 'desc',
                sortable: true,
                sortingMapping: () => 'address.region',
            },
            postalCode: {
                id: 15,
                title: t('zip', { defaultValue: `ZIP` }),
                field: 'postalCode',
                isDefaultSortBy: true,
                sortMode: 'desc',
                sortable: true,
                sortingMapping: () => 'address.postalCode',
            },
            birthDateUTC: {
                id: 16,
                title: t('birthday', { defaultValue: `BIRTHDAY` }),
                field: 'birthDateUTC',
                isDefaultSortBy: true,
                sortMode: 'desc',
                sortable: true,
                type: 'date',
            },
            countryId: {
                id: 17,
                title: t('country', { defaultValue: `COUNTRY` }),
                field: 'countryId',
                isDefaultSortBy: true,
                sortMode: 'desc',
                filterable: true,
                type: 'countryFlag',
                options: this.filterOptions.customerCountry.options,
                sortable: true,
                multipleSelection: true,
            },
            paymentAccess: {
                id: 18,
                title: t('paymentAccess', { defaultValue: `PAYMENT ACCESS` }),
                field: 'paymentAccess',
                isDefaultSortBy: false,
                sortable: true,
                sortMode: 'desc',
                type: 'boolean',
                sortingMapping: () => 'paymentAccess',
            },
            creditAccess: {
                id: 19,
                title: t('productCreditAccess', { defaultValue: `PRODUCT CREDIT ACCESS` }),
                field: 'creditAccess',
                isDefaultSortBy: false,
                sortable: true,
                sortMode: 'desc',
                type: 'boolean',
                sortingMapping: () => 'creditAccess',
            },
            incentives: {
                id: 20,
                title: t('incentives', { defaultValue: `incentives` }),
                field: 'incentives',
                isDefaultSortBy: false,
                filterable: true,
                sortMode: 'desc',
                type: 'multiple',
                options: this.filterOptions.incentives.options,
                canHide: true,
                multipleSelection: true,
            },
            subtotalExcludingEnrollmentProduct: {
                id: 21,
                title: t('subtotalexcludingenrollmentproduct', {
                    defaultValue: `First Product Order Value`,
                }),
                field: 'subtotalExcludingEnrollmentProduct',
                isDefaultSortBy: false,
                sortable: true,
                sortMode: 'desc',
                type: 'currency',
                isNotSelectable: true,

                sortingMapping: () => 'subtotalExcludingEnrollmentProduct',
            },
            isFirstOrderAboveThreshold: {
                id: 22,
                title: t('isfirstorderabovethreshold', {
                    defaultValue: `Order Above $100 US/ $125 CAN`,
                }),
                field: 'isFirstOrderAboveThreshold',
                sortable: true,
                sortMode: 'desc',
                type: 'boolean',
                isNotSelectable: true,
                sortingMapping: () => 'isFirstOrderAboveThreshold',
            },
            fullName: {
                id: 24,
                title: t('name', { defaultValue: `Name` }),
                field: 'fullName',
                canHide: true,
                searchBy: { customers: 'Clients' },
            },
            other: {
                id: 23,
                title: t('other', { defaultValue: `Other` }),
                field: 'other',
                isDefaultSortBy: false,
                filterable: true,
                sortMode: 'desc',
                type: 'multiple',
                options: this.filterOptions.other.options,
                canHide: true,
                multipleSelection: true,
            },
        };

        if (flagStore.isFeatureEnabled('athenaClientsAndMembers')) {
            columns.memberExpiration = {
                id: 27,
                title: t('memberExpiration', { defaultValue: `MEMBER EXPIRATION` }),
                field: 'memberExpiration',
                filterable: true,
                type: 'date',
                options: this.filterOptions.memberExpiration.options,
                isDefaultSortBy: true,
                sortable: true,
                sortMode: 'desc',
                multipleSelection: false,
                sortingMapping: () => 'nextRenewalDateUTC',
            };
        }

        const result = {};

        // TODO: update optimizely to 4.1.x+ to access the features of BTC-3080
        for (const key in columns) {
            if (columns.hasOwnProperty(key) && key !== 'bobExpiration') {
                result[key] = columns[key];
            }
        }
        return result;
    }

    get initialColumns() {
        return [
            'fullName',
            'address',
            'email',
            'phone',
            'userType',
            'customerSinceDate',
            'lastPurchaseDate',
            'lifetimeSpent',
            'pastYearSpent',
            'productCreditAmount',
            'productCreditExpiration',
            'memberExpiration',
            'enroller',
            'nextRenewalDateUTC',
            'paymentAccess',
            'creditAccess',
            'credit',
        ];
    }

    @action
    updateCustomerFilters = filters => (this.filters = filters);
}

const myCustomersStore = new MyCustomersStore();
export default myCustomersStore;
