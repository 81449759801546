import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import GraphQLFetch from '@btc-frontend/middleware/api/graphql';

export async function getNudgeData() {
    const endpoint = `${nogento.nudges}`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };
        const response = await fetch(endpoint, options);
        const { feed } = await response.json();
        return {
            success: true,
            feed,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getNudgeDataV2({
    phoenixId,
    status,
}: {
    phoenixId: string,
    status?: Array<string>,
}) {
    try {
        const graphql = new GraphQLFetch();
        await graphql.useAuth();
        graphql.addType(
            'feed',
            { phoenixId, status },
            `
                nudges {
                    id
                    idempotencyKey
                    category
                    subCategory
                    status
                    eventUserId
                    eventUser {
                        uid
                        countryId
                        country
                        email
                        firstName
                        lastName
                        phoenixId
                    } 
                    notifyUserId
                    notifyUser {
                        uid
                        countryId
                        country
                        email
                        firstName
                        lastName
                        phoenixId
                    }
                    dueDate
                    meta {
                        qvNeeded
                        activityEndPeriod
                        balance
                        expirationDate
                        orderId
                        message
                        milestone
                        ssoUrl
                        name
                        closingDate
                        id
                        countryId
                    }
                    lastModified
                    subCategory
                    eventDate
                    completedDate
                    sentScript
                    notes
                }
                lastSeen
                snoozedUsers
            `,
        );
        const { data } = await graphql.execute();
        if (data && data.feed) return { success: true, feed: data.feed };
        return { success: false, feed: [] };
    } catch (e) {
        console.error(e);
        return { success: false, feed: [] };
    }
}

export async function createFollowUp(body) {
    const endpoint = `${nogento.nudges}`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(body),
        };
        const response = await fetch(endpoint, options);
        const { feed } = await response.json();
        return {
            success: true,
            feed,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function markComplete(oneNudge, form) {
    const endpoint = `${nogento.nudges}/${oneNudge.id}/markComplete`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                script: (form && form.message) || '',
                completedDate: form && form.date,
                contactMethod: form && form.method,
            }),
        };
        const response = await fetch(endpoint, options);
        const { nudge } = await response.json();
        return {
            success: true,
            nudge,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function markDelete(oneNudge) {
    const endpoint = `${nogento.nudges}/${oneNudge.id}/markDeleted`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };
        const response = await fetch(endpoint, options);
        const { nudge } = await response.json();
        return {
            success: true,
            nudge,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function addNudgeNote(nudgeData, note) {
    const endpoint = `${nogento.nudges}/${nudgeData.id}/addNote`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                note: note.notes,
            }),
        };
        const response = await fetch(endpoint, options);
        const { nudge } = await response.json();
        return {
            success: true,
            nudge,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getAllUsersNotes() {
    const endpoint = `${nogento.btc}/rest/notes/`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };
        const response = await fetch(endpoint, options);
        const { data } = await response.json();
        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getUserNotes(id) {
    const endpoint = `${nogento.btc}/rest/notes/${id}`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };
        const response = await fetch(endpoint, options);
        const { data } = await response.json();
        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function createUserNotes(form) {
    const endpoint = `${nogento.btc}/rest/notes/`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                authorId: form.authorId,
                subjectId: form.subjectId,
                text: form.notes,
            }),
        };
        const response = await fetch(endpoint, options);
        const { data } = await response.json();
        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function updateUserNotes(form) {
    const endpoint = `${nogento.btc}/rest/notes/${form.noteId}`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    try {
        const options = {
            method: 'PUT',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                authorId: form.authorId,
                subjectId: form.subjectId,
                text: form.notes,
            }),
        };
        const response = await fetch(endpoint, options);
        const { data } = await response.json();
        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function snooze(param, userId) {
    const endpoint = `${nogento.nudges}/snooze/${userId}`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                snoozeTillDate: param.snoozeTillDate,
            }),
        };
        const response = await fetch(endpoint, options);
        const { user } = await response.json();

        return {
            success: true,
            user,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function unsnooze(userId) {
    const endpoint = `${nogento.nudges}/unsnooze/${userId}`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };
        const response = await fetch(endpoint, options);

        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}
