import React from 'react';
import { default as ListItemSource } from '@material-ui/core/ListItem';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '@beautycounter/counter-ui/src/theme';

function ListItem(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <ListItemSource {...props} />
        </MuiThemeProvider>
    );
}

export default ListItem;
