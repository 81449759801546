import { createMuiTheme } from '@material-ui/core/styles';
import typography from './typography';
import breakpoints from './breakpoints';
import palette from './palette';

const theme = {
    palette,
    typography,
    breakpoints,
    variants: {
        tags: 'pill',
    },
};

export default createMuiTheme(theme);
