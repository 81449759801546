import { observable, computed, action, autorun } from 'mobx';
import { track, identify, reset, page } from '@btc-frontend/middleware/api/analytics';
import { routes } from '@btc-frontend/config';
import { uniqBy } from 'lodash';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { CLIENT, MEMBER, CONSULTANT } from '@btc-frontend/middleware/constants/userStates';
import uuidv4 from 'uuid/v4';
import { userTitlesByIds } from '@btc-frontend/middleware/constants/userCodes';
import { navLinks, navigationAnalyticKey } from '@btc-frontend/nav';
import productsStore from '@btc-frontend/stores/productsStore';
import userStore from '@btc-frontend/stores/userStore';
import sessionStore from '@btc-frontend/stores/sessionStore';
import localeStore from '@btc-frontend/stores/localeStore';
import accountStore from '@btc-frontend/stores/accountStore';
import {
    LOG_IN_EVENT,
    LOG_OUT_EVENT,
    UPDATE_ACCOUNT_EVENT,
    UPDATE_PASSWORD_EVENT,
} from '@beautycounter/constants/siftNogento';
import sendSiftEventFromNogento from '@btc-frontend/middleware/services/sendSiftEventFromNogento/sendSiftEventFromNogento';

class AnalyticsStore {
    @observable currency;
    @observable title;
    @observable _orderProperties;
    @observable lastCheckoutStep;
    @observable inCheckout;

    constructor() {
        this.currency = null;
        this.title = null;
        this.renderCount = 0;
        this.skuSets = [];
        this.lastCheckoutStep = null;
        this.inCheckout = false;

        __BROWSER__ &&
            requestAnimationFrame(() => {
                this.updateUser();
                autorun(() => {
                    userStore.user;
                    userStore.guest;
                    sessionStore.anonymousId;
                    this.updateUser();
                });

                autorun(() => {
                    localeStore.activeLocale;
                    if (localeStore.activeLocale.code === 'en-US') this.currency = 'USD';
                    else this.currency = 'CAD';
                    this.updateUser();
                });
            });
    }

    @computed
    get orderProperties() {
        if (this._orderProperties) {
            return this._orderProperties;
        }
        return {};
    }

    @action
    setTitle(value) {
        if (this.title && value !== this.title) this.pageViewed(value);
        this.title = value;
    }

    @computed
    get category() {
        this.title;

        if (__BROWSER__ && window.location.pathname === '/') return 'Home';
        return this.title;
    }

    @computed
    get properties() {
        this.currency;
        localeStore.activeLocale;

        return {
            category: this.category,
            currency: this.currency,
            locale: getSafe(() => localeStore.activeLocale.code),
            time: Date.now(),
            chnl: 'online',
            application: 'tiamat',
            recognitionTitle: userTitlesByIds[accountStore.titleId],
        };
    }

    @action
    updateUser(attribution) {
        const { consultant_id, social_id } = this.properties;
        // Needed in cases where the user overrides consultant default attribution
        // to be HQ from a consultant
        if (attribution === false) {
            attribution = {
                consultantId: 1,
                socialId: 0,
            };
        }
        if (userStore.user && userStore.user.hasOwnProperty('email')) {
            const { magentoid, email, encoreid, firstName, lastName, uid } = userStore.user;
            const { groupId } = userStore;
            const id = uid || encoreid;
            identify(id, {
                email,
                username: email,
                user_id: uid,
                magento_id: magentoid,
                encore_id: encoreid,
                group_id: groupId || CLIENT,
                firstName,
                lastName,
                name: firstName || lastName ? `${firstName} ${lastName}` : 'Guest Shopper',
                ...this.properties,
                consultant_id:
                    (groupId != CONSULTANT && attribution && attribution.consultantId) ||
                    consultant_id ||
                    1,
                social_id:
                    (groupId != CONSULTANT && attribution && attribution.socialId) ||
                    social_id ||
                    0,
            });
        }
    }

    @action
    productAdded(product, incrementButton = false, props) {
        const { action, category, label } = props || {};
        const { quantity } = product;
        let trackProps = {
            label: incrementButton ? 'Increment Button' : 'Add To Bag',
            ...productsStore.segmentObject[product.id],
            quantity,
            ...this.properties,
        };
        if (label) trackProps = { ...trackProps, label };
        if (action) trackProps = { ...trackProps, action };
        if (category) trackProps = { ...trackProps, category };
        track('Product Added', trackProps);
    }

    @action
    productRemoved(product, decrementButton = false) {
        const { quantity } = product;
        track('Product Removed', {
            label: decrementButton ? 'Decrement Button' : 'Remove All',
            ...productsStore.segmentObject[product.id],
            quantity,
            ...this.properties,
        });
    }

    @action
    trackTopProducts(productSkus) {
        this.renderCount++;
        if (
            getSafe(() => dataLayer.length) &&
            this.renderCount % 2 !== 0 &&
            categoryStore.currentCategory
        ) {
            dataLayer.push({
                PageType: 'ListingPage',
                email: userStore.email || '',
                event: 'onListing',
                ProductIDList: productSkus,
            });
        }
    }

    @action
    customTracked(title, props = {}) {
        track(title, {
            ...this.properties,
            ...props,
        });
    }

    @action
    linkClicked(destinationType, props) {
        const { eventName, to, name, children, navButtonSelected, category, customerId } = props;
        const navigationTitle = navigationAnalyticKey(to);
        const eventTitle = eventName || 'Link Clicked';
        let trackProps = {
            label: name || (typeof children === 'string' ? children : null),
            destination: to,
            destinationType,
            ...this.properties,
            ...(navigationTitle && { navButtonSelected: navigationTitle }),
        };
        if (category) trackProps = { ...trackProps, category };
        if (customerId) trackProps = { ...trackProps, customerId };
        track(eventTitle, trackProps);
    }

    @action
    buttonClicked({ to, children, label, action, category }) {
        let trackProps = {
            label: label || getSafe(() => this.getChildString(children)),
            destination: to,
            ...this.properties,
        };
        if (action) trackProps = { ...trackProps, action };
        if (category) trackProps = { ...trackProps, category };
        track('Button Clicked', trackProps);
    }

    @action
    productsSearched(searchResults, params) {
        const results = getSafe(() => searchResults.map(obj => ({ id, sku, handle })));
        track('Products Searched', {
            results,
            numberOfResults: results && results.length,
            query: params,
            ...this.properties,
        });
    }

    @action
    consultantsSearched(result, query) {
        const results = result && result.items ? uniqBy(result.items, 'accountID') : [];
        track('Consultants Searched', {
            results,
            numberOfResults: results && results.length,
            query,
            ...this.properties,
        });
    }

    @action
    quickViewToggled(url, variant = {}) {
        const { id } = productsStore.productsBySlug[url];
        track('Quick View Toggled', {
            label: variant.name,
            ...productsStore.segmentObject[id],
            ...this.properties,
        });
    }

    @action
    promoItemRemoved(id, promoPack = null) {
        const product = productsStore.segmentObject[id];
        track('Promo Item Removed', {
            label: getSafe(() => product.name),
            products: [product],
            skus: [product.sku],
            promoPack,
            ...this.properties,
        });
    }

    @action
    pageViewed(title) {
        if (!(__BROWSER__ && window.location && title)) return false;
        const path = window.location.pathname;
        const label = this.getPageDetails(path);
        const newTitle = window.location.pathname === '/' ? 'Home' : title;
        const productsIndex = getSafe(() => path.split('/').indexOf(routes.PRODUCTS.substring(1)));

        if (productsIndex && productsIndex !== -1) {
            const slug = getSafe(() => path.split('/')[productsIndex + 1]);
            const category = slug && getSafe(() => categoryStore.categoriesBySlug[slug]);

            if (category) this.productListViewed(category, newTitle);
        }

        // If a user views a PWS we want to force update analytics with attribution to the PWS
        // Similar if a user was shopping on a PWS and goes to the Home page, their attribution needs to
        // be cleared
        if (this.category === 'PWS' || newTitle === 'Home') this.updateUser();

        page(undefined, {
            label,
            ...this.properties,
            category: this.category === 'PWS' ? this.category : newTitle,
        });

        // identify user if possible on new page
        this.updateUser();
    }

    @action
    signUp(email, source) {
        const { code, name, magentoStoreView } = localeStore.activeLocale;
        track('Button Click', {
            ...this.properties,
            label: source,
            Language: code.toUpperCase(),
            countryId: name === 'FR' ? 'CA' : name,
            accountType: 'Prospect',
            source,
            storeId: magentoStoreView,
            category: 'Email Sign-up',
        });
    }

    failedSignIn = props => sendSiftEventFromNogento(props, LOG_IN_EVENT);

    sendSiftLogoutFromNogento = props => sendSiftEventFromNogento(props, LOG_OUT_EVENT);

    sendSiftUpdateAccountFromNogento = props =>
        sendSiftEventFromNogento(props, UPDATE_ACCOUNT_EVENT);

    sendSiftUpdatePasswordFromNogento = props =>
        sendSiftEventFromNogento(props, UPDATE_PASSWORD_EVENT);

    @action
    enrollmentStarted() {
        track('Enrollment Started', {
            label: 'Join Now',
            ...this.properties,
        });

        this.enrollmentStepViewed(1);
    }

    @action
    enrollmentStepViewed = step => {
        track('Enrollment Step Viewed', { step, label: step, ...this.properties });
    };

    @action
    enrollmentIncentive(qualifies) {
        track('Enrollment Incentive', { label: qualifies, ...this.properties });
        this.enrollmentStepViewed(7);
    }

    @action
    reset() {
        reset();
    }

    @action
    getChildString(children) {
        if (!children) return null;
        if (typeof children === 'string') return children;
        if (Array.isArray(children)) {
            for (let i = 0; i < children.length; i++) {
                if (
                    children[i] &&
                    children[i].props &&
                    children[i].props.children &&
                    typeof children[i].props.children === 'string'
                ) {
                    return children[i].props.children;
                }
            }
        }
        return null;
    }

    @action
    getSkusQty(products) {
        const skus = getSafe(() => products.map(product => product.sku));
        const quantity = getSafe(() =>
            products.reduce((accum, product) => (accum += product.quantity), 0),
        );
        return { skus, quantity };
    }

    @action
    getPageDetails(path) {
        if (!path) return;
        let label;
        const params = path.split('/');

        params.shift();
        params[0] = `/${params[0]}`;
        if (params[params.length - 1] === '') params.length--;

        if (params[0] === routes.PRODUCTS) {
            label = getSafe(() => categoryStore.categorySlugs[params[1]].name);
        } else if (params[0] === routes.PRODUCT) {
            const product = getSafe(() => productsStore.productsBySlug[params[1]]);

            if (product && product.parentCategoryIds) {
                for (let i = 0; i < product.parentCategoryIds.length; i++) {
                    if (categoryStore.categoryIds[product.parentCategoryIds[i]]) {
                        label = getSafe(
                            () => categoryStore.categoryIds[product.parentCategoryIds[i]].name,
                        );
                        break;
                    }
                }
            }
        }

        return label;
    }

    // Live Connect
    async pushLiveConnectTag({ products = [], email, conversionId, amount, currency }) {
        if (__BROWSER__) {
            window.liQ = window.liQ || [];

            const items = products.map(product => ({
                currency, // String
                id: `${product.sku}`, // String
                price: product.price, // Double
                quantity: product.quantity, // Int
            }));

            window.liQ.push({
                items,
                email,
                currency,
                amount,
                conversionId,
                event: 'conversion',
                name: 'product_purchase',
            });
        }
    }
    @action
    chameleonTracking() {
        if (
            userStore.isConsultant &&
            userStore.user &&
            Object.prototype.hasOwnProperty.call(userStore.user, 'email')
        ) {
            const { email, uid } = userStore.user;

            if (__BROWSER__ && typeof dataLayer !== 'undefined' && dataLayer) {
                dataLayer.push({
                    event: 'socialsPageView',
                    email: email || '',
                    userId: uid || '',
                });
            }
        }
    }
}

const analyticsStore = new AnalyticsStore();

export default analyticsStore;
export { AnalyticsStore };
