import React from 'react';
import PropTypes from 'prop-types';
import { ENTER, SPACEBAR } from '@beautycounter/constants/keyvalues';

const createReactClass = require('create-react-class');

const Collapsible = createReactClass({
    // Set validation for prop types
    propTypes: {
        transitionTime: PropTypes.number,
        easing: PropTypes.string,
        open: PropTypes.bool,
        classParentString: PropTypes.string,
        openedClassName: PropTypes.string,
        triggerClassName: PropTypes.string,
        triggerOpenedClassName: PropTypes.string,
        contentOuterClassName: PropTypes.string,
        contentInnerClassName: PropTypes.string,
        accordionPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handleTriggerClick: PropTypes.func,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        triggerWhenOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        triggerDisabled: PropTypes.bool,
        lazyRender: PropTypes.bool,
        overflowWhenOpen: PropTypes.oneOf([
            'hidden',
            'visible',
            'auto',
            'scroll',
            'inherit',
            'initial',
            'unset',
        ]),
        triggerSibling: PropTypes.element,
        testId: PropTypes.string,
    },

    // If no transition time or easing is passed then default to this
    getDefaultProps() {
        return {
            transitionTime: 160,
            easing: 'ease-out',
            open: false,
            classParentString: 'Collapsible',
            triggerDisabled: false,
            lazyRender: false,
            overflowWhenOpen: 'hidden',
            openedClassName: '',
            triggerClassName: '',
            triggerOpenedClassName: '',
            contentOuterClassName: '',
            contentInnerClassName: '',
            className: '',
            triggerSibling: null,
            testId: '',
            onOpen: () => {},
            onClose: () => {},
        };
    },

    // Defaults the dropdown to be closed
    getInitialState() {
        if (this.props.open) {
            return {
                isClosed: false,
                shouldSwitchAutoOnNextCycle: false,
                height: 'auto',
                opacity: '1',
                transition: 'none',
                hasBeenOpened: true,
                overflow: this.props.overflowWhenOpen,
            };
        }
        return {
            isClosed: true,
            shouldSwitchAutoOnNextCycle: false,
            height: 0,
            opacity: 0,
            transition: `all ${this.props.transitionTime}ms ${this.props.easing}`,
            hasBeenOpened: false,
            overflow: 'hidden',
        };
    },

    // Taken from https://github.com/EvandroLG/transitionEnd/
    // Determines which prefixed event to listen for
    whichTransitionEnd(element) {
        const transitions = {
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'oTransitionEnd otransitionend',
            transition: 'transitionend',
        };

        for (const t in transitions) {
            if (element.style[t] !== undefined) {
                return transitions[t];
            }
        }
    },

    componentDidMount() {
        // Set up event listener to listen to transitionend so we can switch the height from fixed pixel to auto for much responsiveness;
        // TODO:  Once Synthetic transitionend events have been exposed in the next release of React move this funciton to a function handed to the onTransitionEnd prop

        this.refs.outer.addEventListener(this.whichTransitionEnd(this.refs.outer), event => {
            if (this.state.isClosed === false) {
                this.setState({
                    shouldSwitchAutoOnNextCycle: true,
                });
            }
        });
    },

    componentDidUpdate(prevProps) {
        if (this.state.shouldSwitchAutoOnNextCycle === true && this.state.isClosed === false) {
            // Set the height to auto to make compoenent re-render with the height set to auto.
            // This way the dropdown will be responsive and also change height if there is another dropdown within it.
            this.makeResponsive();
        }

        if (this.state.shouldSwitchAutoOnNextCycle === true && this.state.isClosed === true) {
            this.prepareToOpen();
        }

        // If there has been a change in the open prop (controlled by accordion)
        if (prevProps.open != this.props.open) {
            if (this.props.open === true && this.state.isClosed) {
                this.openCollapsible();
            } else {
                this.closeCollapsible();
            }
        }
    },

    handleTriggerClick(event) {
        event.preventDefault();

        if (this.props.triggerDisabled) {
            return;
        }

        if (this.props.handleTriggerClick) {
            this.props.handleTriggerClick(this.props.accordionPosition);
        } else if (this.state.isClosed === true) {
            this.openCollapsible();
        } else {
            this.closeCollapsible();
        }
    },

    handleKeyDown(event) {
        const key = event && event.key;
        if (key === ENTER || key === SPACEBAR) {
            event.preventDefault();
            this.handleTriggerClick(event);
        }
        return false;
    },

    closeCollapsible() {
        this.setState(
            {
                isClosed: true,
                shouldSwitchAutoOnNextCycle: true,
                height: this.refs.inner.offsetHeight,
                opacity: '1',
                overflow: 'hidden',
            },
            this.props.onClose,
        );
    },

    openCollapsible() {
        this.setState(
            {
                height: this.refs.inner.offsetHeight,
                opacity: '1',
                transition: `all ${this.props.transitionTime}ms ${this.props.easing}`,
                isClosed: false,
                hasBeenOpened: true,
            },
            this.props.onOpen,
        );
    },

    makeResponsive() {
        this.setState({
            height: 'auto',
            opacity: '1',
            transition: 'none',
            shouldSwitchAutoOnNextCycle: false,
            overflow: this.props.overflowWhenOpen,
        });
    },

    prepareToOpen() {
        // The height has been changes back to fixed pixel, we set a small timeout to force the CSS transition back to 0 on the next tick.
        window.setTimeout(() => {
            this.setState({
                height: 0,
                opacity: 0,
                shouldSwitchAutoOnNextCycle: false,
                transition: `all ${this.props.transitionTime}ms ${this.props.easing}`,
            });
        }, 50);
    },

    renderNonClickableTriggerElement() {
        if (this.props.triggerSibling) {
            return (
                <div className={`${this.props.classParentString}__trigger-sibling`}>
                    {this.props.triggerSibling}
                </div>
            );
        }

        return null;
    },

    render() {
        const dropdownStyle = {
            height: this.state.height,
            opacity: this.state.opacity,
            WebkitTransition: this.state.transition,
            msTransition: this.state.transition,
            transition: this.state.transition,
            overflow: this.state.overflow,
        };
        const contentInnerStyle = { ...this.props.contentInnerStyle };

        const openClass = this.state.isClosed ? 'is-closed' : 'is-open';
        const disabledClass = this.props.triggerDisabled ? 'is-disabled' : '';

        // If user wants different text when tray is open
        const trigger =
            this.state.isClosed === false && this.props.triggerWhenOpen !== undefined
                ? this.props.triggerWhenOpen
                : this.props.trigger;

        // Don't render children until the first opening of the Collapsible if lazy rendering is enabled
        let children = this.props.children;
        if (this.props.lazyRender) if (!this.state.hasBeenOpened) children = null;

        let triggerClassName =
            `${this.props.classParentString}__trigger` + ` ${openClass} ${disabledClass}`;

        if (this.state.isClosed) {
            triggerClassName = `${triggerClassName} ${this.props.triggerClassName}`;
        } else {
            triggerClassName = `${triggerClassName} ${this.props.triggerOpenedClassName}`;
        }

        const ariaConfig = {
            ...(this.props.ariaControls && {
                'aria-controls': this.props.ariaControls,
            }),
        };

        return (
            <div
                className={`${this.props.classParentString} ${
                    this.state.isClosed ? this.props.className : this.props.openedClassName
                }`}
                data-testid={this.props.testId}
            >
                {trigger && (
                    <div
                        className={triggerClassName.trim()}
                        onClick={this.handleTriggerClick}
                        onKeyDown={event => this.handleKeyDown(event)}
                        aria-expanded={!this.state.isClosed}
                        tabIndex="0"
                        role="button"
                        {...ariaConfig}
                    >
                        {trigger}
                    </div>
                )}

                {this.renderNonClickableTriggerElement()}

                <div
                    className={
                        `${this.props.classParentString}__contentOuter` +
                        ` ${this.props.contentOuterClassName}`
                    }
                    ref="outer"
                    style={dropdownStyle}
                >
                    <div
                        className={
                            `${this.props.classParentString}__contentInner` +
                            ` ${this.props.contentInnerClassName}`
                        }
                        ref="inner"
                        style={contentInnerStyle}
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    },
});

export default Collapsible;
