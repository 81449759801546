import { observable, computed, action, autorun, set } from 'mobx';
import { isEmpty, unset } from 'lodash';
import throttleBreakpoints from '@btc-frontend/middleware/utils/media';
import {
    MEDIA_PHONE,
    MEDIA_MOBILE,
    MEDIA_LAPTOP,
    MEDIA_DESKTOP,
} from '@btc-frontend/middleware/constants/media';
import { throttle } from 'lodash';
import analyticsStore from '@btc-frontend/stores/analyticsStore';
import { pathMatch } from 'tough-cookie';

export interface InterfaceStoreInterface {
    isMobile: Boolean;
}

class InterfaceStore<InterfaceStoreInterface> {
    @observable progress;
    @observable completedProgresses; // keeps a list of previous progresses.

    @observable stickyHeader;
    @observable headerStyle;
    @observable searchOpen;
    @observable viewportMode;
    @observable viewportWidth;
    @observable sharingOpen;
    @observable sharingOpen;
    @observable bottomPanelContent;
    @observable bottomPanelOptions;
    @observable appIsMounted;
    @observable appIsLoaded;
    @observable enrollmentInProgress;

    constructor() {
        const { box } = observable;

        this.initialRender = true;

        this.progress = {};
        this.completedProgresses = {};

        this.stickyHeader = false;
        this.headerStyle = {
            bordered: false,
        };
        this.searchOpen = false;
        this.sharingOpen = false;
        this.bottomPanelContent = null;
        this.bottomPanelOptions = null;
        this.appIsMounted = false;
        this.appIsLoaded = false;
        this.enrollmentInProgress = false;

        this.viewportWidth = __BROWSER__ ? window.innerWidth : 1280;
        this.viewportMode = this.viewportWidth;
        this.addResizeListener();
    }

    @computed
    get inProgress() {
        return !isEmpty(this.progress);
    }

    @action
    showProgress = index => {
        this.progress[index] = true;
        this.progress = JSON.parse(JSON.stringify(this.progress));
    };

    @action
    clearAllProgress = () => {
        this.progress = {};
    };

    withProgress = async (payload, callback, progressType) => {
        this.showProgress(progressType);
        const results = await callback(payload);
        this.hideProgress(progressType);
        return results;
    };

    @action
    hideProgress = index => {
        if (__BROWSER__) {
            unset(this.progress, index);
            this.progress = JSON.parse(JSON.stringify(this.progress));
            set(this.completedProgresses, index, true);
        }
    };

    @action
    openBottomPanel = (open = true) => {
        if (this.sharingOpen !== open) this.sharingOpen = open;
    };

    @action
    setBottomPanel = (param, options) => {
        this.bottomPanelContent = param;
        if (options) this.bottomPanelOptions = options;
        else this.bottomPanelOptions = null;
    };

    @action
    closeBottomPanel = param => {
        if (this.sharingOpen != false) this.sharingOpen = false;
    };

    @action
    stickyScrollHeader = () => {
        this.stickyHeader = true;
    };

    @action
    setHeaderStyle = params => {
        this.headerStyle = {
            ...this.headerStyle,
            ...params,
        };

        this.headerStyle = JSON.parse(JSON.stringify(this.headerStyle));
        return true;
    };

    @action
    fixedScrollHeader = () => {
        this.stickyHeader = false;
    };

    @action
    searchToggle = param => {
        this.searchOpen = param;
    };

    @action
    searchClose = () => {
        this.searchOpen = false;
    };

    @action
    updateRenderState = () => {
        if (this.initialRender) this.initialRender = false;
    };

    @action
    updateViewport = () => {
        const prevWidth = this.viewportWidth;
        this.viewportWidth = window.innerWidth;

        const newSize = throttleBreakpoints(window.innerWidth, prevWidth);
        if (newSize) this.viewportMode = newSize;
    };

    @action
    openStickyModal = (open = true) => {
        if (this.enrollmentInProgress !== open) this.enrollmentInProgress = open;
    };

    @action
    closeStickyModal = () => {
        if (this.enrollmentInProgress != false) this.enrollmentInProgress = false;
    };

    @action
    fadeOutAll = callback => {
        const body = document.getElementsByTagName('body')[0];
        body.style.transition = 'opacity .3s ease';
        return new Promise(resolve =>
            setTimeout(() => {
                body.style.opacity = 0;
                body.style.cursor = 'default';
                setTimeout(() => {
                    callback && callback();
                    resolve();
                }, 380);
            }, 30),
        );
    };

    @action
    fadeInAll = () => {
        const body = document.getElementsByTagName('body')[0];
        body.style.transition = 'opacity .3s ease';
        body.style.opacity = 1;
    };

    addResizeListener = () => {
        if (!__BROWSER__) return false;

        const resizeListener = throttle(this.updateViewport, 100);
        window.addEventListener('resize', resizeListener);
        this.updateViewport();
    };

    addMountState = () => {
        if (__BROWSER__) this.appIsMounted = true;
    };

    addLoadListener = () => {
        if (!__BROWSER__) return false;

        const loaded = () => {
            this.appIsLoaded = true;
        };

        if (document.readyState === 'complete') this.loadHandler(loaded);

        if (window.addEventListener) {
            window.addEventListener('load', () => loaded());
        } else if (window.attachEvent) {
            window.attachEvent('onload', () => loaded());
        }
    };

    @action
    asyncLoad = (call, timeout) => {
        if (!__BROWSER__) return false;

        if (this.appIsLoaded) {
            if (timeout) {
                setTimeout(() => call(), timeout);
            } else {
                requestAnimationFrame(() => call());
            }
        } else {
            const load = () => this.loadHandler(call);
            try {
                if (document.readyState === 'complete') load();
                else if (window.addEventListener) window.addEventListener('load', load);
                else if (window.attachEvent) window.attachEvent('onload', load);
            } catch (e) {
                console.log(e);
            }
        }
    };

    loadHandler(callback) {
        const handler = () =>
            requestAnimationFrame(() => {
                callback();
                window.removeEventListener('mousemove', handler);
                window.removeEventListener('touchmove', handler);
            });
        window.addEventListener('mousemove', handler);
        window.addEventListener('touchmove', handler);
    }

    @computed
    get isPhone() {
        return this.viewportMode <= MEDIA_PHONE;
    }

    @computed
    get isMobile() {
        return this.viewportMode < MEDIA_LAPTOP;
    }

    @computed
    get isTabletPortrait() {
        return this.viewportMode < MEDIA_LAPTOP && this.viewportMode > MEDIA_PHONE;
    }

    @computed
    get isComputer() {
        return this.viewportMode >= MEDIA_LAPTOP;
    }

    @computed
    get isSafari() {
        return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    }

    @computed
    get isAppleDevice() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
}

const interfaceStore = new InterfaceStore();

export default interfaceStore;
export { InterfaceStore };
