import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';

const addressEndpoint = `${nogento.btc}/rest/accounts/addresses`;

export async function getAddressData() {
    const endpoint = addressEndpoint;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                [`Content-Type`]: `application/json`,
                Authorization: `Bearer ${token}`,
            }),
        };

        const response = await fetch(endpoint, options);
        const {
            data: { addresses },
        } = await response.json();

        return {
            success: true,
            addresses: sanitizeAddressData(addresses),
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Writes specified address to user entry
 * @param {object} address
 */

export async function writeAddressData(address) {
    const endpoint = addressEndpoint;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                [`Content-Type`]: `application/json`,
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                ...address,
            }),
        };

        const response = await fetch(endpoint, options);
        const { data } = await response.json();

        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Deletes specified address to user entry
 * @param {string} address
 */

export async function deleteAddressData(address) {
    const endpoint = addressEndpoint;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'DELETE',
            headers: new Headers({
                [`Content-Type`]: `application/json`,
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                ...address,
            }),
        };

        const response = await fetch(endpoint, options);

        if (response.status === 200) {
            return {
                success: true,
            };
        }
        return {
            success: false,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export function sanitizeAddressData(addresses) {
    if (!addresses) return null;

    const updatedAddresses = [];
    if (addresses.length) {
        addresses.forEach(address => {
            const updatedAddress = {
                addressName: address.addressName,
                id: address.id,
                address: address.address1 ? address.address1 : address.address,
                address2: address.address2,
                city: address.city,
                country: address.country,
                countryId: address.countryId,
                isDefaultBilling: address.isDefaultBilling,
                isDefaultShipping: address.isDefaultShipping,
                firstName: address.firstName,
                lastName: address.lastName,
                companyName: address.name ? address.name : address.companyName,
                telephone: address.phone ? address.phone : address.telephone,
                region: address.state ? address.state : address.region,
                postalCode: address.zip ? address.zip : address.postalCode,
            };
            updatedAddresses.push(updatedAddress);
        });
    }
    return updatedAddresses;
}
