import { validUsZipcode, validCaZipcode } from '@btc-frontend/middleware/utils/validators';

export function zipcode(field = {}) {
    const { value, locale } = field;
    // TODO: enum. This is not the best place to handle locale logic, but it'll do
    return {
        value,
        isValid: locale.countryId === 1 ? validUsZipcode(value) : validCaZipcode(value),
    };
}
