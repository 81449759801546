import { fetchCatalog } from './fetch';
import getProducts from '@btc-frontend/middleware/services/products';
import { processCategories } from '@btc-frontend/middleware/services/categories';

/**
 * Handles processing of products from the database.
 * Should not be loaded in production to avoid additional
 * bandwidth and processing penalities that would affect
 * performance.
 */

export default async function productParser(locale, drafts) {
    const { Categories, Filters, Products } = await fetchCatalog(locale.code);

    const { data } = await getProducts(Products, drafts);

    return {
        data: {
            Categories: processCategories(Categories),
            Filters: processCategories(Filters),
            Products: data,
        },
    };
}
