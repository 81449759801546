import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

@inject('interfaceStore')
@observer
class ImagePrefetch extends Component {
    render() {
        const { images } = this.props;

        const Container = ({ children }) => <div style={{ display: 'none' }}>{children}</div>;

        if (!images || !this.props.interfaceStore.appIsLoaded) return <Container />;

        return (
            <Container>
                {images.map((image, key) => (
                    <img key={`prefetch-${image + key}`} src={image} alt="" />
                ))}
            </Container>
        );
    }
}

export default ImagePrefetch;
