import firebase from '@btc-frontend/middleware/firebase/init';

/**
 * Returns a store credit information snapshot
 * for the current authenticated user
 */

async function getCreditHistory() {
    const [database, auth] = await Promise.all([firebase.firestore(), firebase.auth()]);

    try {
        if (!auth.currentUser) return false;
        const uid = auth.currentUser.uid;

        const snapshot = await database
            .collection('productCredit')
            .doc(uid)
            .get();

        return snapshot.data();
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Allows for store credit
 * subscriptions and realtime updates of
 * product credit data.
 */

class Subscriber {
    constructor() {
        this.subscriptions = {};
    }

    init = async () => {
        const [database, auth] = await Promise.all([firebase.firestore(), firebase.auth()]);

        this.database = database;
        this.auth = auth;
    };

    addListener = async (callback, locale) => {
        if (!this.database || !this.auth) await this.init();
        if (!this.auth.currentUser) return;
        const uid = this.auth.currentUser.uid;
        const code = uid + locale;
        try {
            Object.keys(this.subscriptions).forEach(code => this.removeSubscriber(code));

            return this.addSubscriber(callback, uid, code, locale);
        } catch (e) {
            console.error(e);
        }
    };

    addSubscriber = async (callback, uid, code, locale) => {
        if (!this.database || !this.auth) this.init();

        const response = doc => {
            const data = doc.data();
            if (data) callback(data);
            else callback(null);
        };

        if (!this.subscriptions[code]) {
            this.subscriptions[code] = this.database
                .collection('productCredit')
                .doc(uid)
                .onSnapshot(response);
        }
    };

    removeSubscriber(code) {
        if (this.subscriptions[code]) {
            this.subscriptions[code]();
            delete this.subscriptions[code];
        }
    }
}

const creditSubscriber = new Subscriber();

export { creditSubscriber, getCreditHistory };
