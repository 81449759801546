export function region(field: { value?: string } = {}) {
    const value = field.value || '';

    let isValid = true;
    const quebecCodes = ['quebec', 'qc'];
    if (quebecCodes.includes(value.toLowerCase())) isValid = false;
    return {
        value,
        isValid,
    };
}
