import format from 'date-fns/format';
import { nogento } from '@btc-frontend/config';
import GraphQLFetch from '@btc-frontend/middleware/api/graphql';

import { querySet } from './queries';
import { IStartCounting } from './startCountingTypes';

export const fetchStartCountingStatus = async (phoenixId = null): Promise<String> => {
    const graphql = new GraphQLFetch({ url: `${nogento.startCountingGraphQL}/query` });
    await graphql.useAuth();
    graphql.addType('startCountingStatus', { participantAccountId: phoenixId });
    const { data = {} } = await graphql.execute();
    return data.startCountingStatus;
};

export const fetchStartCounting = async (
    phoenixId = null,
    { pageSize, ...rest } = {},
): Promise<IStartCounting> => {
    try {
        const graphql = new GraphQLFetch({ url: `${nogento.startCountingGraphQL}/query` });
        await graphql.useAuth();
        querySet.forEach(({ name, args, variables }) => {
            args = { participantAccountId: phoenixId };
            if (name == 'teamProgress')
                args = { enrollerAccountId: phoenixId, pageSize: pageSize || 20, ...rest };
            graphql.addType(name, args, variables);
        });

        const response: { data: IStartCounting; errors } = await graphql.execute();
        if (response.data) return response.data;
        if (response.errors) return {};
    } catch (error) {
        console.log('Error fetching start counting: ', error);
        return {};
    }
};

export const fetchTeamProgress = async (
    phoenixId = null,
    { pageSize, isExport, ...rest } = {},
): Promise<IStartCounting> => {
    try {
        const graphql = new GraphQLFetch({
            url: `${nogento.startCountingGraphQL}/${isExport ? 'export' : 'query'}`,
        });
        await graphql.useAuth();
        querySet
            .filter(f => f.name === 'teamProgress')
            .forEach(({ name, args, variables }) => {
                args = {
                    enrollerAccountId: phoenixId,
                    pageSize: pageSize || 20,
                    ...rest,
                };
                graphql.addType(name, args, variables);
            });

        const response = await graphql.execute({ isExport });
        if (isExport) return response;
        if (response.data) return response.data;
        if (response.errors) return {};
    } catch (error) {
        console.log('Error fetching team progress: ', error);
        return {};
    }
};

export const fetchEnrollerProgress = async (
    phoenixId = null,
    { pageSize, filters = [], ...rest } = {},
): Promise<IStartCounting> => {
    try {
        const graphql = new GraphQLFetch({ url: `${nogento.startCountingGraphQL}/query` });
        await graphql.useAuth();

        querySet
            .filter(f => f.name === 'teamProgress')
            .forEach(({ name, args, variables }) => {
                args = {
                    enrollerAccountId: phoenixId,
                    pageSize: pageSize || 20,
                    filters: [
                        {
                            key: 'level',
                            value: '1',
                        },
                        ...filters,
                    ],
                    ...rest,
                };
                graphql.addType(name, args, variables);
            });

        const response = await graphql.execute();
        if (response.data) return response.data;
        if (response.errors) return [];
    } catch (error) {
        console.log('Error fetching team progress: ', error);
        return [];
    }
};

export const mapEnrollerDataToCsvExportData = (data = [], selectedColumns = []) => {
    const { teamProgressData = [], totalRecords = null } = data;

    const csvModel = teamProgressData.map(data => {
        return {
            firstName: data.firstName,
            lastName: data.lastName,
            country: data.countryId == 2 ? 'CA' : 'US',
            email: data.email,
            milestone: data.milestone,
            milestoneEndDate: format(Number(data.milestoneEndDate), 'MM/DD/YYYY'),
            achievedMilestone: data.milestoneComplete ? 'Yes' : 'No',
            enrollerBonus: data.enrollerMilestoneComplete ? 'Yes' : 'No',
        };
    });

    // change internal column names to user-facing column names
    const columnsToExport = selectedColumns.map(col => {
        switch (col) {
            case 'countryId':
                return 'country';
            case 'milestoneComplete':
                return 'achievedMilestone';
            case 'enrollerMilestoneComplete':
                return 'enrollerBonus';
            default:
                return col;
        }
    });

    return csvModel.map(row => ({
        ...Object.keys(row)
            .filter(key => columnsToExport.includes(key))
            .reduce((output, key) => {
                output[key] = row[key];
                return output;
            }, {}),
    }));
};
