export const userQuery = `
countryId
email
enrollmentDateUTC
dateCreatedUTC
firstName
language
languageId
lastName
birthDateUTC
nextRenewalDateUTC
phoenixId
sponsorId
userType
telephone
image
pwsKey
pwsStatus
vtexId
flags
mainAddress {
    address
    address2
    city
    region
    postalCode
    telephone
    countryId
}
shippingAddresses {
    address
    address2
    city
    region
    postalCode
    telephone
}
leads {
    firstName
    lastName
    address {
        city
        state
    }
    phoenixId
    assignedDate
}
managingDirector {
    firstName
    lastName
    phoenixId
}
director {
    firstName
    lastName
    phoenixId
}
demographics {
  userAnswers
}
uplineCount
titles {
    paidAsTitleId
    recognitionTitleId
}
uid
`;

export const coreBusinessQuery = `
  flatCount
  absoluteRootLevel
  downgradeRisk{
    activityEndDate
    activityEndMonth
    activityEndPeriod
    activityStartPeriod
    country
    email
    enrollmentDateUTC
    firstName
    lastName
    level
    metricName
    phoenixId
    qvNeeded
    qvPerformanceGoal
    qvProgress
    sponsorId
    sponsorName
    telephone
  }
  firstPromotion{
    phoenixId
    firstName
    lastName
    level
    recognition
    score
  }
  hotlist{
    bv
    bvCap
    firstName
    lastName
    phoenixId
    remainingBV
  }
  newRecruits{
    birthDateUTC
    bv
    bobRecruitmentCount
    newBobRecruitmentCount
    bobRenewalCount
    cv
    directorLegs
    email
    enrollmentDateUTC
    fiftyPercentRule
    firstName
    firstPromotion
    fullName
    lastName
    level
    levelIncrement
    nextRenewalDateUTC
    nv
    paidAs
    phoenixId
    pv
    qv
    recognition
    recruitmentCount
    sponsorId
    sponsorName
    uid
    uplineCount
  }topBobRecruiters {
    phoenixId
    firstName
    lastName
    level
    recognition
    score
  }
  topNV {
    phoenixId
    firstName
    lastName
    level
    recognition
    score
  }
  topQV {
    phoenixId
    firstName
    lastName
    level
    recognition
    score
  }
  topPV {
    phoenixId
    firstName
    lastName
    level
    recognition
    score
  }
  topRecruiters {
    phoenixId
    firstName
    lastName
    level
    recognition
    score
  }`;
