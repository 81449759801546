import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import { observer, inject } from 'mobx-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CountrySelector.scss';
import cx from 'classnames';

import { assetPath, locales } from '@btc-frontend/config';
import { SIZE_TINY, SIZE_SMALLER_SMALL } from '@btc-frontend/middleware/constants/icon';
import ClickOutHandler from 'react-onclickout';
import ImagePrefetch from '@btc-frontend/components/Extensions/ImagePrefetch';

import Image from '@btc-frontend/components/Common/Image';
import AngleLeft from '@counter-ui/Icon/Icons/Controls/LeftAngle.jsx';
import Lock from '@counter-ui/Icon/Icons/Lock.jsx';
import Checked from '@counter-ui/Icon/Icons/Checked.jsx';

import Flag from '@btc-frontend/components/Common/FlagMaker';

@withStyles(s)
@inject('localeStore')
@observer
class CountrySelector extends Component {
    constructor(props) {
        super(props);

        this.flags = [];
        locales.map(country => this.flags.push(`${assetPath}/${country.image}`));

        this.state = {
            showDropdown: false,
        };
    }

    setLocale = country => {
        this.props.onSelect(country);
        this.setState({ showDropdown: false });
    };

    handleClickOutside = event => {
        this.setState({ showDropdown: false });
    };

    toggleDropdown() {
        this.setState({ showDropdown: !this.state.showDropdown });
    }

    render() {
        const { activeLocale } = this.props.localeStore;
        const { showDropdown } = this.state;
        const disabled = activeLocale.countryId === 1;
        const iconProps = {
            size: SIZE_TINY,
            className: cx(s.arrow, showDropdown && s.arrowDown)
        }
        return (
            <div className={s.root} >
                <ClickOutHandler onClickOut={this.handleClickOutside}>
                    <div className={cx(s.open, showDropdown, disabled && s.disabled)}>
                        <div className={cx(s.selected)} onClick={this.toggleDropdown.bind(this)}>
                            <Flag locale={activeLocale.code} className={s.flag} />
                            <span>{activeLocale.name}</span>
                            {disabled ?
                                <Lock size={SIZE_SMALLER_SMALL} />
                                :
                                <AngleLeft {...iconProps} />
                            }
                        </div>
                        {showDropdown && (
                            <CountryDropdownPanel
                                locales={locales}
                                activeCode={activeLocale.code}
                                selectLocale={this.setLocale}
                            />
                        )}
                    </div>
                </ClickOutHandler>
                <ImagePrefetch images={this.flags} />
            </div>
        );
    }
}

const CountryDropdownPanel = ({ locales, activeCode, selectLocale }) => (
    <div className={s.dropdown}>
        {locales.filter(({ code }) => code !== 'en-US').map((country, key) => (
            <div
                className={cx(s.dropdownItem, country.code === activeCode && s.selected)}
                onClick={() => selectLocale(country)}
                key={key}
            >
                <Flag locale={country.code} className={s.flag} />
                <div className={s.name}>{country.longName}</div>
                <div className={s.checked}>
                    {country.code === activeCode && <Checked size={{ width: 15, height: 15 }} />}
                </div>
            </div>
        ))}
    </div>
);

export default CountrySelector;
