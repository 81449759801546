import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: 0,
    y: 0,
    width: 90,
    height: 90,
};

class Wrench extends Component {
    render() {
        const def = <defs />;

        return (
            <Icon {...this.props} defs={def} altText='Wrench' viewBox={viewBox}>
                <path
                    id="svg_1"
                    strokeWidth=".1px"
                    d="m45.03405,18.018c0.495,0 0.99,0.025 1.485,0.076l-7.633,7.633l2.611,12.545l12.545,2.611l7.623,-7.623c0.38,3.847 -0.758,7.563 -3.348,10.153c-2.211,2.21 -5.29,3.428 -8.672,3.428c-1.558,0 -3.136,-0.252 -4.688,-0.749l-2.904,-0.93l-2.156,2.156l-13.031,13.03c-7.46051,7.69146 -8.78251,8.23946 -10.18951,8.23946c-1.406,0 -2.728,-0.548 -3.723,-1.542c-0.994,-0.994 -1.542,-2.316 -1.542,-3.723c0,-1.406 0.548,-2.728 1.542,-3.723l19.49751,-19.72846l2.156,-2.156l-0.93,-2.904c-1.622,-5.066 -0.621,-10.061 2.679,-13.361c2.214,-2.213 5.296,-3.432 8.678,-3.432m0,-5c-4.551,0 -8.928,1.613 -12.213,4.898c-4.773,4.773 -6.008,11.854 -3.905,18.421l-12.91552,13.031c-12.09215,11.51478 -10.59099,17.20546 -6.58199,21.21346c2.004,2.004 4.631,3.006 7.258,3.006c2.627,0 5.254,-1.002 7.258,-3.006l19.49751,-19.72846c2.049,0.656 4.149,0.987 6.213,0.987c4.549,0 8.924,-1.609 12.208,-4.892c5.878,-5.878 6.403,-15.254 1.912,-22.859l-11.349,11.349l-6.693,-1.393l-1.393,-6.692l11.349,-11.349c-3.354,-1.981 -7.053,-2.986 -10.645,-2.986l0,0z"
                />
            </Icon>
        );
    }
}
export default Wrench;
