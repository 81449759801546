import { flags } from '@btc-frontend/config';
import { autorun } from 'mobx';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import sessionStore from '@btc-frontend/stores/sessionStore';

/**
 * A utility can have these properties:
 * label, category, enabled, onClick and visible
 * A utility with an enabled property will become a flag
 * with a checkbox and can then be accessible with this.flags
 * because some stores may not be accessible during the retrieval,
 * wrap any values reading from a store in a function
 */

const utilities = () => ({
    contentDrafts: {
        label: 'Enable draft content (slow)',
        category: 'apis',
        enabled: flags.contentDrafts,
        onClick: () => window.location.reload(),
    },
    tableLoadingWidget: {
        label: 'Enable table loading widget',
        category: 'apis',
        enabled: sessionStore.getKey('tableLoadingWidget'),
        onClick: () => {
            const istableLoadingWidget = sessionStore.getKey('tableLoadingWidget');
            if (istableLoadingWidget) {
                sessionStore.saveKey('tableLoadingWidget', false);
            } else {
                sessionStore.saveKey('tableLoadingWidget', true);
            }
            window.location.reload();
        },
    },
    clearLocal: {
        label: 'Clear all Local Storage',
        category: 'other',
        onClick: () => {
            localStorage.clear();
            window.location.reload();
        },
    },
});

function initUtilities() {
    this.utilities = utilities();

    const utilitiesStorage =
        __BROWSER__ && getSafe(() => JSON.parse(window.localStorage.getItem('utilities')));
    if (utilitiesStorage)
        Object.keys(utilitiesStorage).forEach(key => {
            if (this.utilities[key]) {
                this.utilities[key].enabled = !getSafe(() => this.utilities[key].noPersist)
                    ? utilitiesStorage[key]
                    : flags[key];
            }
        });

    autorun(() => {
        this.utilities;

        const obj = {};
        Object.keys(this.utilities)
            .filter(key => this.utilities[key].hasOwnProperty('enabled'))
            .forEach(
                key =>
                    (obj[key] =
                        typeof this.utilities[key].enabled === 'function'
                            ? !!this.utilities[key].enabled()
                            : !!this.utilities[key].enabled),
            );

        __BROWSER__ && window.localStorage.setItem('utilities', JSON.stringify(obj));
    });
}

function handleUtilClick(key) {
    if (this.utilities[key]) {
        if (this.utilities[key].hasOwnProperty('enabled')) {
            this.utilities[key].enabled =
                typeof this.utilities[key].enabled === 'function'
                    ? !this.utilities[key].enabled()
                    : !this.utilities[key].enabled;
        }
        if (this.utilities[key].hasOwnProperty('onClick')) this.utilities[key].onClick();
    }
}

export { utilities, initUtilities, handleUtilClick };
