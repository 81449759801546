import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import { IServiceResponse } from '../../../../../types/IServiceResponse';
import userStore from '@btc-frontend/stores/userStore';
import flagStore from '@btc-frontend/stores/flagStore';

const btcUserEndpoint = `${nogento.btc}/rest/accounts`;

// CURRENTLY NOT BEING USED
export async function getUserData() {
    const endpoint = btcUserEndpoint;
    const token = await firebase.getToken();
    if (!token) return { success: false };

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };

        const response = await fetch(endpoint, options);
        const { data } = await response.json();

        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

// CURRENTLY NOT BEING USED
export async function getUplineDirectors() {
    const endpoint = `${btcUserEndpoint}/upline-directors`;
    const token = await firebase.getToken();
    if (!token) return { success: false };

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };

        const response = await fetch(endpoint, options);
        const { data } = await response.json();

        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function writeUserData(
    contact: IUserProfileUpdate,
): Promise<IServiceResponse<IUserAccount>> {
    const endpoint = btcUserEndpoint;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                ...contact,
            }),
        };

        const response = await fetch(endpoint, options);
        const { data } = await response.json();

        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Updates the user's image
 * @param {object} image
 */

export async function uploadUserPhoto(form) {
    const endpoint = `${btcUserEndpoint}/profile-image`;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
            body: form,
        };

        const response = await fetch(endpoint, options);
        const json = await response.json();

        return {
            success: true,
            data: {
                image: json.data,
            },
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Returns a one time snapshot of the user's
 * informtion from the user table
 * @param {string} uid
 */

// WILL BE DEPRECIATED FOR VTEX
export async function getUserDataSnapshot(uid, meta = {}) {
    try {
        const database = await firebase.firestore();

        const snapshot = await database
            .collection('users')
            .doc(uid)
            .get();

        const data = snapshot.data();

        return { ...data, ...meta, uid };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Invokes a callback with the user's data
 * each time it is modified in realtime
 * @param {function} callback
 */

// CURRENTLY NOT BEING USED
export async function subscribeToUserData(callback) {
    const database = await firebase.firestore();
    const auth = await firebase.auth();
    if (auth && auth.currentUser) {
        const uid = auth.currentUser.uid;

        database
            .collection('users')
            .doc(uid)
            .onSnapshot(data => callback(() => ({ ...data.data(), uid })), e => null);
    } else callback();
}

// FOR CS TOKEN
export async function loginWithToken(token) {
    const auth = await firebase.auth();
    const vtexFlag = flagStore.isFeatureEnabled('useVtexLogin');
    try {
        const session = await auth.signInWithCustomToken(token);
        if (!session.user) throw session;
        let response;
        if (vtexFlag) {
            response = await userStore.fetchNogentoUserData();
            if (response.success) return response.data.userAccount;
        }
        return await getUserDataSnapshot(session.user.uid);
    } catch (e) {
        console.error('failed sigin with custom token.', token, e);
        return e;
    }
}

/**
 * Logs user into Firebase Auth.
 * If it fails, will return an error.
 * @param {string} email
 * @param {string} password
 */

export async function login(email, password) {
    let session, meta;
    const auth = await firebase.auth();
    const vtexFlag = flagStore.isFeatureEnabled('useVtexLogin');
    try {
        session = await auth.signInWithEmailAndPassword(email, password);
        if (!session.user) throw session;
        let response;
        if (vtexFlag) {
            response = await userStore.fetchNogentoUserData();
            if (response.success) return response.data.userAccount;
        }
        return await getUserDataSnapshot(session.user.uid, meta);
    } catch (e) {
        console.info(e);
        return e;
    }
}

// Admin sdk doesn't support firebase login.
// verify the user login attempt in nogento
export async function userlogin(email, password) {
    let session, meta;
    const auth = await firebase.auth();
    const token = await getUserToken();
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email, password }),
    };
    const vtexFlag = flagStore.isFeatureEnabled('useVtexLogin');
    try {
        const serverSideVerification = await fetch(`${nogento.base}/auth/login`, options);
        const { success, ...rest } = await serverSideVerification.json();
        if (!success) throw rest;
        session = await auth.signInWithEmailAndPassword(email, password);
        if (!session.user) throw session;
        let response;
        if (vtexFlag) {
            response = await userStore.fetchNogentoUserData();
            if (response.success) return response.data.userAccount;
        }
        return await getUserDataSnapshot(session.user.uid, meta);
    } catch (e) {
        return e;
    }
}

/**
 * Checks Phoenix for a BTC login
 * @param {string} email
 * @param {string} password
 */

// CURRENTLY NOT BEING USED
async function checkForPhoenixLogin(email, password) {
    try {
        const options = {
            body: JSON.stringify({
                username: email,
                password,
            }),
            method: 'POST',
        };

        const request = await fetch(`${nogento.base}/accounts/phoenix/signin`, options);
        const session = await request.json();

        if (session.success && session.data && session.data.token) {
            const auth = await firebase.auth();
            return auth.signInWithCustomToken(session.data.token);
        }
        throw 'Login was not found in Firebase or Phoenix';
    } catch (e) {
        console.info(e);
        return {
            code: 'auth/wrong-password',
            message: 'Invalid credentials.',
        };
    }
}

/**
 * Logs user out and then invokes specified
 * callback on success
 * @param {function} callback
 */

export async function logout(callback = () => null) {
    const auth = await firebase.auth();

    return auth.signOut().then(() => callback());
}

/**
 * Checks whether user is logged in and invokes
 * callback if true
 * @param {function} callback
 */

export async function subscribeIsLogged(callback) {
    const auth = await firebase.auth();
    auth.onAuthStateChanged(session => {
        if (session && session.uid) {
            callback(() => ({ success: true, uid: session.uid }));
        } else callback(() => false);
    });
}

/**
 * Creates a Firebase Auth instance
 * as well as a user entry in the user table
 * @param {object} params
 */

// CURRENTLY NOT BEING USED
export async function createAccount(params) {
    const auth = await firebase.auth();
    try {
        const [account, newsletter] = await Promise.all([
            auth.createUserWithEmailAndPassword(params.email, params.password),
            require('./newsletter'),
        ]);
        const session = auth.currentUser;

        if (session && session.uid) {
            const data = {
                firstName: params.firstname || 'Guest',
                lastName: params.lastname || 'Shopper',
                userType: 3,
                email: params.email,
                language: params.language || 'en',
            };

            const writeData = await Promise.all([
                writeUserData(data),
                params.is_subscribed && newsletter.toggleSubscription(params.email, 'Master', true),
            ]);

            const userData = await getUserDataSnapshot(session.uid);
            if (!userData.error && !writeData.error)
                return {
                    success: true,
                    data: userData,
                };
            return {
                success: false,
                error: userData.error || writeData.error,
            };
        }
        return {
            success: false,
            error: account.error,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Checks whether email exists in the Auth table
 * @param {string} input
 */

// CURRENTLY NOT BEING USED
export async function checkEmailExists(input) {
    const auth = await firebase.auth();

    try {
        const email = await auth.fetchSignInMethodsForEmail(input);
        if (email.length)
            return {
                success: true,
            };
        throw false;
    } catch (e) {
        return {
            success: false,
        };
    }
}

/**
 * Applies email address change to Firebase Auth
 * if it is different
 * @param {object} data
 */

// CURRENTLY NOT BEING USED
export async function checkProfileChange(data) {
    const auth = await firebase.auth();

    const session = auth.currentUser;
    if (data.email && data.email !== session.email) {
        try {
            await session.updateEmail(data.email);
            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    } else
        return {
            success: true,
        };
}

export async function resetPassword(data) {
    const auth = await firebase.auth();

    try {
        await auth.sendPasswordResetEmail(data.email);

        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function confirmPasswordReset(query: any = {}) {
    const auth = await firebase.auth();

    try {
        await auth.confirmPasswordReset(query.oobCode, query.password);
        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getUserToken() {
    const auth = await firebase.auth();

    return auth.currentUser && auth.currentUser.getIdToken();
}

export function withTimeout(req, timeout = 8000) {
    return Promise.race([req(), new Promise((_, reject) => setTimeout(() => reject(), timeout))]);
}
export async function circuitBreaker(req) {
    try {
        const res = await withTimeout(req, 5000);
        return res;
    } catch (e) {
        e && console.error(e);
        return withTimeout(req, 10000).catch(e => console.error(e));
    }
}
