import firebase from '@btc-frontend/middleware/firebase/init';

const FIRESTORE_COLLECTION = 'client-logs';

/**
 * Singleton logging util to save arbitrary client data to server.
 */
class Logger {
    /**
     * Create a new document for each submission
     * Saves payload and API response
     * @param { String } key
     * @param { Object } data
     */
    async save({ key, data }) {
        try {
            const database = await firebase.firestore();

            database
                .collection(FIRESTORE_COLLECTION)
                .doc()
                .set({ key, data });
        } catch (e) {
            console.info(`Client failed to log\n`, e);
        }
    }
}

export default new Logger();
