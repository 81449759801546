export const AUTHORIZATION_FAILED = 3,
    PAYMENT_BILLING_INFO_ISSUES = 'PAYMENT_BILLING_INFO_ISSUES',
    PAYMENT_CARD_DECLINE = 'PAYMENT_CARD_DECLINE',
    PAYMENT_SYSTEMS_ISSUES = 'PAYMENT_SYSTEMS_ISSUES',
    CARD_EXPIRED_SQUARE = 'CARD_EXPIRED_SQUARE',
    CREATE_CUSTOMER_FAILED = 8,
    CREATE_CARD_FAILED = 9,
    AUTHORIZATION_AMOUNT_INVALID = 'AUTHORIZATION_AMOUNT_INVALID',
    INVALID_CARDID = 'INVALID_CARDID',
    INVALID_SQUARE_NONCE = 'INVALID_SQUARE_NONCE',
    SDK_GENERATE_CARD_TOKEN_ERROR = 'SDK_GENERATE_CARD_TOKEN_ERROR',
    SDK_PAYMENT_NOT_INITIALIZED = 'SDK_PAYMENT_NOT_INITIALIZED',
    APPLE_PAY_REQUEST_ERROR = 'APPLE_PAY_REQUEST_ERROR',
    APPLE_PAY_INIT_FAILURE = 'APPLE_PAY_INIT_FAILURE';

const SQUARE_PAYMENT_ERRORS = [
    AUTHORIZATION_FAILED,
    PAYMENT_BILLING_INFO_ISSUES,
    PAYMENT_CARD_DECLINE,
    PAYMENT_SYSTEMS_ISSUES,
    CARD_EXPIRED_SQUARE,
    CREATE_CUSTOMER_FAILED,
    CREATE_CARD_FAILED,
    AUTHORIZATION_AMOUNT_INVALID,
    INVALID_CARDID,
    INVALID_SQUARE_NONCE,
];

export default SQUARE_PAYMENT_ERRORS;
