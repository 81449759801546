const sienna = {
    sienna100: '#7E392C',
    sienna80: '#986156',
    sienna60: '#B28880',
    sienna40: '#CBB0AB',
    sienna20: '#E5D7D5',
    sienna10: '#F2EBE9',
    sienna1: '#FBF9F8',
};

module.exports = sienna;
