export const COLOR_BLACK = '#231F20',
    COLOR_WHITE = '#FEFEFE',
    COLOR_RED = '#d62d20',
    COLOR_BLUE = '#3b589b',
    COLOR_NAVY_BLUE = '#001D42',
    COLOR_NAVY = '#334A68',
    COLOR_MID_NAVY = '#66778E',
    COLOR_BLUE_GRAY = '#FBFBFC',
    COLOR_BLUE_LINE = '#3b589b',
    COLOR_CAT = '#c95b1f',
    COLOR_LIGHT_GRAY = '#ededed',
    COLOR_GRAY = 'gray',
    COLOR_MID_GRAY = '#eeeeee',
    COLOR_BONE = '#faf9f8',
    COLOR_GREEN = '#007566',
    COLOR_WARNING = '#856404',
    COLOR_SUCCESS = '#6AE368',
    COLOR_LINK = '#285591';
