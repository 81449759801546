import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { observer, inject, autorun } from 'mobx-react';
import s from './StickyButtons.scss';
import cx from 'classnames';
import { developerTools, devToolConfigs } from '@btc-frontend/config';
import ScrollToTop from '@btc-frontend/components/Common/ScrollToTop';
import Notices from '@btc-frontend/components/Common/Notices';
import DevToolDialog from '@btc-frontend/components/Common/DevToolDialog/DevToolDialog';
import DiscreteSlider from '@btc-frontend/components/DiscreteSlider/DiscreteSlider';
import IOIndicator from '@btc-frontend/components/IOIndicator/IOIndicator';

const StickyButtons = inject('interfaceStore', 'userStore', 'sessionStore', 'navigationStore')(
    observer(({ interfaceStore, userStore, sessionStore, navigationStore }) => {
        const TestingUtils =
            developerTools && interfaceStore.appIsMounted
                ? require('@btc-frontend/components/Common/TestingUtilitiesLink').default
                : null;

        const getCurrentStore = devToolConfigs => {
            const allDevToolConfigStores = Object.keys(devToolConfigs);
            const currentStore = allDevToolConfigStores.filter(
                store => devToolConfigs[store].navigationPath === navigationStore.path,
            );
            const isMatchedNavigationPath = devToolConfigs[currentStore[0]]?.navigationPath;

            return developerTools &&
                interfaceStore.appIsMounted &&
                isMatchedNavigationPath &&
                sessionStore.getKey('tableLoadingWidget')
                ? devToolConfigs[currentStore[0]]?.getStorePath()
                : null;
        };

        const getCurrentStoreName = devToolConfigs =>
            Object.keys(devToolConfigs).filter(
                store => devToolConfigs[store].navigationPath == navigationStore.path,
            );

        const myCurrentStore = developerTools && navigationStore && getCurrentStore(devToolConfigs);

        const { isEnrolling } = userStore;
        const { enrollmentInProgress } = interfaceStore;

        return (
            <>
                {sessionStore.getKey('tableLoadingWidget') && (
                    <div className={s.top}>
                        <DevToolDialog
                            overrides={{
                                Top: {
                                    component: DiscreteSlider,
                                    props: {
                                        label: 'Latency Dial (ms):',
                                        handleDialClick:
                                            myCurrentStore && myCurrentStore.setDevLatency,
                                    },
                                },
                                Optional: {
                                    component: IOIndicator,
                                    props: {
                                        label: `Loading ${getCurrentStoreName(
                                            devToolConfigs,
                                        )}... : `,
                                        currentLoadingParams:
                                            interfaceStore.inProgress &&
                                            myCurrentStore &&
                                            myCurrentStore.getParams,
                                    },
                                },
                            }}
                        />
                    </div>
                )}
                <div
                    className={cx(s.root, {
                        [s.activeTray]: enrollmentInProgress,
                    })}
                >
                    {developerTools && (
                        <div className={s.left}>
                            <Notices />
                        </div>
                    )}
                    <div className={s.right}>
                        <ScrollToTop />
                        {TestingUtils && <TestingUtils />}
                    </div>
                </div>
            </>
        );
    }),
);

export default withStyles(s)(StickyButtons);
