import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { observer, inject } from 'mobx-react';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import s from './Header.scss';
import cx from 'classnames';

/* For Desktop */
import SearchBar from '@btc-frontend/components/SearchBar';
import AccountLink from './AccountLink';

/* For Mobile */
import MobileHeader from '@btc-frontend/components/Header/Mobile/MobileHeader.jsx';

@withStyles(s)
@inject('interfaceStore', 'productsStore', 'analyticsStore', 'contentStore')
@observer
class Header extends Component {
    render() {
        const { searchOpen } = this.props.interfaceStore;
        const { isMobile, onMenuClick, onCloseSearch } = this.props;

        return !isMobile ? (
            <div className={s.root}>
                {/* <div className={s.searchContainer} /> */}
                
                <div className={s.accountContainer}>
                    <AccountLink />
                </div>
            </div>
        ) : (
            <MobileHeader onMenuClick={onMenuClick} onSearchClick={searchOpen} />
        );
    }
}

export default Header;
