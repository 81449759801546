import dateFormat from 'dateformat';
import { Parser } from 'json2csv';

export function exportJsonAsCsv<T>(request: IBctExportCsv.ICsvExportRequest<T>) {
    // prepare document
    const data = convertToCSVExport(request);
    const dataString = data.serializedResults;
    const csvBlob = new Blob([dataString], { type: 'text/csv' });

    /*
     * not a hack, actually SUPER clean and legit
     * (•_•)
     * ( •_•)> ⌐■-■
     * (⌐■_■)
     */
    const a = document.createElement('a');
    a.setAttribute('href', URL.createObjectURL(csvBlob));
    a.setAttribute('download', data.header.fileName);
    a.click();
}

function convertToCSVExport<T>(request: IBctExportCsv.ICsvExportRequest<T>) {
    const { records, fileName, appendTimestampToFileName, headers } = request;
    const serializedResults = serializeRecords(records, headers);
    const header = createHeaders(fileName, appendTimestampToFileName);
    return <IBctExportCsv.ICsvExportResponse>{
        header,
        serializedResults,
    };
}

function createHeaders(
    fileName: string,
    appendTimestampToFileName: boolean = false,
): IBctExportCsv.ICsvExportHeader {
    if (appendTimestampToFileName) {
        const formattedNow = dateFormat(new Date(), 'yyyy_mm_dd');
        fileName = `${fileName}_${formattedNow}`;
    }
    fileName = `${fileName}.csv`;
    return {
        metaString: `data:text/csv;charset=utf-8,`,
        fileName,
    };
}

function serializeRecords<T>(records: T[], headers): string {
    let fields = [];
    if (!(records && records.length)) return null;
    if (headers) {
        fields = Object.keys(records[0]).map((v, i) => ({
            label: headers[i],
            value: v,
        }));
    } else fields = Object.keys(records[0]);

    const parser = new Parser({ fields });
    return parser.parse(records);
}
