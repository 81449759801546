import { getCookieClient, setCookieClient } from '@btc-frontend/middleware/utils/cookie';
import { siteName } from '@btc-frontend/config';
import { canUseDOM } from '@btc-frontend/middleware/utils/env';
import analyticsStore from '@btc-frontend/stores/analyticsStore';

class Context {
    constructor() {
        this.initialize();
        this.bind();
    }

    initialize() {
        this.styles = {};
        this.css = [];
        this.data = {
            title: '',
            description: '',
            meta: [],
        };
    }

    bind() {
        this.insertCss = this.insertCss.bind(this);
        this.onSetMeta = this.onSetMeta.bind(this);
    }

    getUserAgent() {
        return __BROWSER__ ? navigator.userAgent : false;
    }

    getCookie(name) {
        return getCookieClient(name);
    }

    setCookie(name, value) {
        return setCookieClient(name, value);
    }

    getCss() {
        return this.css;
    }

    insertCss(...styles) {
        // eslint-disable-next-line no-underscore-dangle
        if (typeof styles == 'array' || Object.keys(styles[0])[1]) {
            if (canUseDOM) {
                const removeCss = styles.map(x => x._insertCss());
                return () => {
                    removeCss.forEach(f => f());
                };
            }
            styles.forEach(style => this.css.push(style._getCss()));
            return this.css;
        }
    }

    // Remove and create a new <meta /> tag in order to make it work
    // with bookmarks in Safari
    onSetMeta(name, content, key = 'name') {
        if (canUseDOM) {
            const meta = document.createElement('meta'),
                elements = document.getElementsByTagName('meta');

            Array.from(elements).forEach(element => {
                if (element.getAttribute(key) === name) {
                    element.parentNode.removeChild(element);
                }
            });

            meta.setAttribute(key, name);
            meta.setAttribute('content', content);
            document.getElementsByTagName('head')[0].appendChild(meta);
        } else {
            const meta = {
                content,
            };
            meta[key] = name;

            this.data.meta.push(meta);
        }
    }
}

export default Context;
