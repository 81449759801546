import React from 'react';
import LazyLoad from '@btc-frontend/components/Common/LazyLoad';

function FontPrefetch() {
    const critical = (
        <React.Fragment>
            <LazyLoad>
                <span style={{ fontFamily: 'star-font' }} />
            </LazyLoad>
        </React.Fragment>
    );

    const nonCritical = (
        <div>
            <span style={{ fontFamily: 'Chronicle' }} />
            <span style={{ fontFamily: 'Chronicle', fontStyle: 'italic' }} />
            <span style={{ fontFamily: 'Chronicle', fontStyle: 'bold' }} />
            <span style={{ fontFamily: 'Mark' }} />
            <span style={{ fontFamily: 'Mark', fontWeight: 300 }} />
            <span style={{ fontFamily: 'Mark', fontWeight: 400 }} />
            <span style={{ fontFamily: 'Mark', fontWeight: 600 }} />
        </div>
    );

    return (
        <div style={{ opacity: 0 }}>
            {critical}
            {nonCritical}
        </div>
    );
}

export default FontPrefetch;
