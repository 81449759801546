import { filter } from 'lodash';
import { mergeObjects } from '@btc-frontend/middleware/utils/filter';
import { keyBy } from 'lodash';
import { createCategoryUrl } from '@btc-frontend/middleware/utils/url';

/**
 * Rearranges the Magento subcategory schema into
 * "filters" that can be used in conjunction with eachother
 * @param {Object} categories
 * @param {Object} content
 */

export function sortFilters({ categories, content, definedFilters }) {
    let filters = {},
        allFilters = [],
        specialCategories = [];

    content = keyBy(content, 'magentoId');

    // Inject subcategories into the filter object
    categories.map(category => {
        if (typeof content[category.categoryId] != 'undefined') {
            if (
                !content[category.categoryId].special &&
                typeof (category.children != 'undefined')
            ) {
                // Subcategories
                category.children &&
                    category.children.map(subcategory => {
                        if (subcategory.isActive != '0') {
                            if (typeof filters[category.categoryId] != 'undefined') {
                                filters[category.categoryId]['Category'].push({
                                    ...subcategory,
                                    parent: 'Category',
                                });
                            } else {
                                filters[category.categoryId] = {};
                                filters[category.categoryId]['Category'] = [
                                    {
                                        ...subcategory,
                                        parent: 'Category',
                                    },
                                ];
                            }
                        }
                    });

                // Filters (repeated across each category)
                definedFilters &&
                    definedFilters.length &&
                    definedFilters.map(
                        section =>
                            section.children &&
                            section.children.length &&
                            (!section.showOnlyOnParent ||
                                section.parentId === category.categoryId) &&
                            section.children.map(filter => {
                                try {
                                    !filters[category.categoryId][section.name] &&
                                        (filters[category.categoryId][section.name] = []);
                                    filters[category.categoryId][section.name].push({
                                        ...filter,
                                        parent: section.name,
                                    });
                                } catch (e) {
                                    return false;
                                }
                            }),
                    );
            } else {
                specialCategories.push(category.categoryId);
            }
        }
    });

    // Inject a virtual category for "new" products
    // which do not have an actual category defined in the CMS
    specialCategories.push(10999);

    // 1 for all
    specialCategories.push(1);

    // Inject all filters into the special categories that don't have their own filters but instead pick up from other categories
    specialCategories.forEach(special => {
        filters[special] = {};
        categories.forEach(category => {
            if (filters[category.categoryId] && !content[category.categoryId].special) {
                Object.keys(filters[category.categoryId]).forEach(section => {
                    if (typeof filters[special][section] == 'undefined')
                        filters[special][section] = [];
                    filters[special][section] = [
                        ...filters[category.categoryId][section],
                        ...filters[special][section],
                    ];
                });
            }
        });
        filters[special] = removeDuplicates(filters[special]);
    });

    function removeDuplicates(item) {
        Object.keys(item).forEach(array => {
            for (let y = 0; y < item[array].length; y++) {
                for (let x = 0; x < item[array].length; x++) {
                    if (item[array][y].categoryId != item[array][x].categoryId) {
                        if (item[array][x].name == item[array][y].name) {
                            item[array].splice(y, 1);
                        }
                    }
                }
            }
        });
        return item;
    }

    return filters;
}

/**
 * Gets the hard URL for subcategories that are
 * actually "filters"
 * @param {Object} categoryFilters
 * @param {Object} categoriesById
 */

export function getCategoryLinks(categoryFilters, categoriesById) {
    let categoryLinks = [];

    Object.keys(categoryFilters).forEach((category, key) => {
        if (categoriesById[category]) {
            categoryLinks.push({
                id: category,
                deepId: category,
                name: categoriesById[category].name,
                type: 'parent',
                to: createCategoryUrl({
                    slug: categoriesById[category].slug,
                    id: category,
                }),
            });

            Object.keys(categoryFilters[category]).forEach((filterContainer, key) => {
                categoryFilters[category][filterContainer].length &&
                    categoryFilters[category][filterContainer].forEach((filter, key) => {
                        categoryLinks.push({
                            id: filter.categoryId,
                            deepId: category + (filter.categoryId && filter.categoryId.toString()),
                            name: filter.name,
                            type: 'filter',
                            parent: category,
                            to: createCategoryUrl({
                                slug: categoriesById[category].slug,
                                isFilter: true,
                                id: filter.categoryId,
                            }),
                        });
                    });
            });
        }
    });
    return categoryLinks;
}

/**
 * Pre-processes categories
 * @param {Object} categories
 */

export function processCategories(categories) {
    let processed = [];

    try {
        const _filterCategory = category => {
            const children =
                category.children &&
                category.children.length &&
                category.children.filter(
                    child =>
                        child.categoryId &&
                        child.isActive !== 0 &&
                        !child.draft &&
                        child.categoryId !== 193 &&
                        _filterCategory(child),
                );
            category.children = children;

            return category;
        };

        return (processed = categories.filter(
            category => category.categoryId && _filterCategory(category),
        ));
    } catch (e) {
        console.error(e);
    }

    return processed;
}
