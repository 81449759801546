/**
 * TODO:
 * Set specfic overwrites for each font face
 * create a markdown component with variants defined by these standards
 */
// const {
//     display4,
//     display3,
//     display2,
//     display1,
//     headline,
//     title,
//     subheading,
//     h1,
//     h2,
//     h3,
//     h4,
//     h5,
//     h6,
//     subtitle1,
//     subtitle2,
// }

// const {
//     color: '',
//     fontFamily: '',
//     fontWeight: 500,
//     letterSpacing: '',
//     lineHeight: '',
// }

//
const fontFamilies = ['"mark", "sans-serif"', '"Chronicle Display", "serif"'];

const typography = {
    fontFamilies,
    fontFamily: fontFamilies[0],
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
        fontFamily: 'Mark, Helvetica Neue, Helvetica, sans-serif',
        fontWeight: 600,
        '&::after': {
            display: 'none',
        },
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
    },
    title: 'mark',
    useNextVariants: true,
};

export default typography;
