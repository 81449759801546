/**
 * Default developer environment
 * Values defined here will be overwritten by those declared in specific environments
 * such as "qa" or prod.
 */

const environment = {
    // base URL that the site will appear on
    liveUrl: 'https://behindthecounter.beautycounter.dev',
    cdnUrl: '',
    bccomUrl: 'https://drafts.beautycountertest.com', // TODO: swap out after vtex launch for new BccomUrl
    newBccomUrl: 'https://qa.beautycountertest.com', // temp variable for vtex launch
    environmentName: 'local',
    vtexScope: 'beautycounterqa',

    firebaseProject: '',

    // whether to use shortened CSS class names
    cssShortening: false,

    // whether to use JS minification and ahead-of-time compilation
    codeOptimization: false,

    // whether to enable detailed console messaging
    verbose: true,

    // enable the hidden developer tools menu
    developerTools: true,

    // DEPRECATED Please use flagstore
    // See https://notes.beautycounter.com/bccom/feature-flags
    flags: {
        contentDrafts: false,
        ordersCollection: false,
        teamTree: false,
        inventoryPage: true,
        renewalStatusOverride: false,
        renewalFeature: true,
        ssoLogin: false,
        passwordStrength: true,
        rallyware: true,
        experiments: true,
        useVtexUrls: true,
        newCounterULink: true,
    },

    firebase: {
        key: 'Drafts',
        apiKey: 'AIzaSyCTrd9CdKtrknuTRW5JakRagRhJ2Vj4Ct8',
        projectId: 'beautycounter-drafts',
        assetURL: '',
        assetBucket: 'beautycounter-draft-assets',
        apiBase: 'https://us-central1-beautycounter-drafts.cloudfunctions.net',
        image: [
            'https://www.googleapis.com/storage/v1/b/beautycounter-drafts.appspot.com/o',
            'https://firebasestorage.googleapis.com/v0/b/beautycounter-drafts.appspot.com/o',
        ],
    },

    square: {
        US: {
            url: 'https://js.squareupsandbox.com/v2/paymentform',
            appId: 'sandbox-sq0idb-qVIqJGwZKMrL16f7_kzZKg',
            locationId: 'CJY833967SKSF',
            sandboxAppId: 'sandbox-sq0idb-qVIqJGwZKMrL16f7_kzZKg',
            sandboxLocationId: 'CJY833967SKSF',
            SDKUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
        },
        CA: {
            url: 'https://js.squareupsandbox.com/v2/paymentform',
            appId: 'sandbox-sq0idb-mOTJnRgdwW3fw7eOFu3OaA',
            locationId: 'YQBMZ1KQCXVY6',
            sandboxAppId: 'sandbox-sq0idb-mOTJnRgdwW3fw7eOFu3OaA',
            sandboxLocationId: 'YQBMZ1KQCXVY6',
            SDKUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
        },
    },

    // setting 'preview' to true will tell Contentful to pull drafts
    contentfulbtc: {
        internalName: 'btc',
        accessToken: '91717e13c1502e38b7938fe9baae3936c76b520e9360977dc05933204485fd9b',
        space: 'fde98eeiqfg3',
        previewAccessToken: '71ba3c0be65dcd59f733210d240269ce54f866900260a79aa71d2324b5f1aa15',
    },

    // the testing environment for btc
    // contentfulbtc: {
    //     internalName: 'btc-test',
    //     accessToken: '015b989874008d03964a5c5a18df6ac67ce684f6bbc39eea1f670905dcad56ce',
    //     space: '2vh8xrkslzfa',
    //     previewAccessToken: 'a6f1947927b2ab0cb985e0743a219672e2b22060b8061f6eb841f48e078f84ff',
    //     managementToken: 'CFPAT-bbffd9966ed2a0c29c4ef97548e57acf36db9020be808c166a183fe670e5463f',
    // },

    contentfulbc: {
        internalName: 'bc',
        accessToken: '241923aebd05f59685c26adcd08490d8945c9b983f9ae90348f4a6d09953ba40',
        space: 'ylsk4zpp53wa',
        previewAccessToken: '5f091a0b95046c4053ca35d8a32a3dfcd8168685037a5af8e1b0527631abdde3',
    },

    phoenix: {
        pws: 'https://v2phoenix.api.beautycountertest.com/pws',
        search: 'https://v1phoenix.api.beautycountertest.com',
        prospect:
            'https://nxiy7lbifa.execute-api.us-east-1.amazonaws.com/stg/account/addprospectaccount',
        leads: 'https://nxiy7lbifa.execute-api.us-east-1.amazonaws.com/stg/leads',
        geoSearch: 'https://nxiy7lbifa.execute-api.us-east-1.amazonaws.com/stg/account/geosearch',
        btcLink: 'https://behindthecounter.beautycounter.com.staging.beautycounter.com',
    },

    recaptcha: {
        sitekey: '6LdgfEkUAAAAANjymviQqNHj9YpxNIvGXtcQaBiC',
    },

    sailthru: {
        // Customer ID key for BeautyCounter - Dev
        customerId: '3b5ad8b4e0e0534c8e3d44a0261a0127',
        env: 'dev',
    },

    googleMaps: {
        key: 'AIzaSyAuqVGoDI8XkUWZyiwhMtBNwLh9qv6xFJU',
    },

    // hide will remove the review area from that locale
    yotpoReviews: {
        US: {
            key: 'qBrMzNvjMrV8YKWaIiUFHw8MSdYIjPumYtPpiWzF',
        },
        CA: {
            hide: true,
            key: 'vtpuOZEjkXcG0ut3RF9H3dfHL1k7S6skpwiLDQ7q',
        },
        FR: {
            hide: true,
            key: '47jUMdw3L7Cgk2WVURSyQVQ4lXtDicGW2KifAkIF',
        },
    },

    bccomDomain: {
        base: 'https://drafts.beautycountertest.com', // TODO: swap out to new base value after vtex launch
        newBase: 'https://qa.beautycountertest.com', // temp
        get quickOrder() {
            return `/quick-order`;
        },
        get socials() {
            return '/pop-ups/dashboard';
        },
    },

    narvarUrl: {
        base: 'https://beautycounter.narvar.com/beautycounter/tracking/',
    },

    rallyware: {
        redirectURI: 'https://beautycounter-sandbox.rallyware.com/oauth/v1/auth',
        trainingPage: '/digital-library',
        homePage: '/',
        clientId: 'wb5m6BcQCkoWF7DKhFti',
    },

    klarna: {
        klarnaCanCutoverDateUTC: '2022-03-23T16:00:00.000Z',
    },

    hyperwallet: {
        url: 'https://beautycounter.hyperwallet.com/',
    },

    nogento: {
        base: 'https://nogento.beautycountertest.com',
        btc: 'https://api.btc.beautycountertest.com',
        get geoSearch() {
            return `${this.base}/account/geosearch`;
        },
        get incentives() {
            return `${this.base}/accounts/incentives/programEnrollments`;
        },
        get estimate() {
            return `${this.base}/checkout/estimate`;
        },
        get purchase() {
            return `${this.base}/checkout/purchase`;
        },
        get paypal() {
            return `${this.base}/checkout/paypal`;
        },
        get customer() {
            return `${this.base}/customer`;
        },
        get promorules() {
            return `${this.base}/promorules`;
        },
        get enrollment() {
            return `${this.base}/account/enrollment`;
        },
        get graphql() {
            return `${this.btc}/graphql/query`;
        },
        get startCountingGraphQL() {
            return `${this.base}/startCounting`;
        },
        get graphqlExport() {
            return `${this.btc}/graphql/export`;
        },
        get nudges() {
            return `${this.btc}/nudges`;
        },
        get socials() {
            return `${this.base}/consultant/socials`;
        },
        get pwsImage() {
            return `${this.base}/accounts/pws/image`;
        },
        get resetPassword() {
            return `${this.base}/accounts/resetPassword`;
        },
        get resetPasswordv2() {
            return `${this.base}/accounts/me`;
        },
        get vtex() {
            return `${this.btc}/vtex`;
        },
    },
    legacyBtc: {
        base: 'https://behindthecounter.beautycounter.com.staging.beautycounter.com/',
    },

    analytics: {
        google: {
            trackingId: null,
            merchantId: '122729573',
        },
        optimizely: {
            enabled: false,
            // disable if not being used locally to prevent being billed for impressions
            disableTracking: true,
            datastore: 'https://cdn.optimizely.com/datafiles/MGeUKFTcQNLoaNV4V6A8hm.json',
        },
        newRelic: {
            enabled: false,
            licenseKey: 'bca62b8798',
            sourceMaps: false,
        },
        segment: {
            enabled: false,
            key: 'zKK6N7N3vP4K6FeNzhrOolKWPBZZhtlM',
        },
    },

    search: {
        noIndex: true,
    },

    info: {
        startupNotice: 'You are in a testing environment that is not optimized.',
    },

    redirect: true,

    olapic: {
        version: 'v2.2',
        key: '8e795bb53fd76bec8b2a8f96ce27ba04cb3cec536277eb204e5e8aa8ed1d631e',
        baseURL: 'https://photorankapi-a.akamaihd.net',
    },

    paypal: {
        env: 'sandbox',
        script: 'https://www.paypalobjects.com/api/checkout.js',
        US: {
            client: {
                sandbox:
                    'AewD-eXgTPM9LSV5j8HvVTmJFcBhgk3hogvdSwGYhghr2rdAGFOJkrTldedwcXmk8FzcOmLGuxm2x87b',
            },
            sandboxClient: {
                sandbox:
                    'AewD-eXgTPM9LSV5j8HvVTmJFcBhgk3hogvdSwGYhghr2rdAGFOJkrTldedwcXmk8FzcOmLGuxm2x87b',
                production:
                    'AewD-eXgTPM9LSV5j8HvVTmJFcBhgk3hogvdSwGYhghr2rdAGFOJkrTldedwcXmk8FzcOmLGuxm2x87b',
            },
        },
        CA: {
            client: {
                sandbox:
                    'AfQVQk9TzEmLeaE4zVbOHvEYpUvRDME0hN13jxLTkJFmR90-EYjIxGwp2U0scynd1OH9qcCLg2Wz31iW',
            },
            sandboxClient: {
                sandbox:
                    'AfQVQk9TzEmLeaE4zVbOHvEYpUvRDME0hN13jxLTkJFmR90-EYjIxGwp2U0scynd1OH9qcCLg2Wz31iW',
                production:
                    'AfQVQk9TzEmLeaE4zVbOHvEYpUvRDME0hN13jxLTkJFmR90-EYjIxGwp2U0scynd1OH9qcCLg2Wz31iW',
            },
        },
    },
};

export { environment };
