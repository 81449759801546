import stringify from 'json-stringify-safe';
import { cloneDeep } from 'lodash';

/**
 * Safely attempts to save to LocalStorage and falls
 * back to the window object if unavailable. SessionStorage
 * can also be used instead of LocalStorage by setting it as a param.
 * @param {String} name
 * @param {Object} param
 * @param {Boolean} sessionStorage
 */

export function saveSessionItem(name, param, sessionStorage = false) {
    if (!__BROWSER__) return false;

    try {
        if (sessionStorage) window.sessionStorage.setItem(name, stringify(param));
        else localStorage.setItem(name, stringify(param));
        return true;
    } catch (e) {
        console.info(e);
        return createFallbackStorage(name, param);
    }
}

/**
 * Safely checks for the existence of an item in LocalStorage
 * or the window object (if localStorage is not available).
 * @param {String} name
 * @param {Boolean} sessionStorage
 */

export function getSessionItem(name, sessionStorage = false) {
    if (!__BROWSER__) return false;

    try {
        return JSON.parse(
            sessionStorage ? window.sessionStorage.getItem(name) : localStorage.getItem(name),
        );
    } catch (e) {
        console.info(e);
        return getFallbackStorage(name);
    }
}

/**
 * Clear the storage, defaults to session
 * @param {Boolean} sessionStorage
 */

export function clearStorage(sessionStorage = true) {
    if (!__BROWSER__) return false;

    try {
        if (sessionStorage) {
            // persist the locale for unexpected logout redirects to bccom.
            const locale = cloneDeep(window.sessionStorage.selectedLocale);
            window.sessionStorage.clear();
            window.sessionStorage.selectedLocale = locale;
        } else {
            localStorage.clear();
        }
    } catch (e) {
        console.error(e);
    }
}

/**
 * If LocalStorage is unavailable (e.g. Safari private mode)
 * use the window object as temporary storage
 * @param {String} name
 * @param {Object} type
 */

function createFallbackStorage(name, param) {
    try {
        if (typeof window !== 'undefined' && name) {
            if (!window.fallbackStorage) window.fallbackStorage = {};
            window.fallbackStorage[name] = stringify(param);
            return true;
        }
    } catch (e) {
        console.info(e);
        return false;
    }
}

/**
 * If LocalStorage is unavailable (e.g. Safari private mode)
 * attempt to check the window object for temporary storage
 * @param {String} name
 * @param {Object} type
 */

function getFallbackStorage(name) {
    try {
        if (typeof window !== 'undefined' && name) {
            if (!window.fallbackStorage) window.fallbackStorage = {};
            if (window.fallbackStorage[name]) return JSON.parse(window.fallbackStorage[name]);
        }
    } catch (e) {
        console.info(e);
        return false;
    }
}
