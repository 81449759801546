// Navigation specific configuration.
import { routeConfig } from '@btc-frontend/router/routes';
import { routeKeys } from '@btc-frontend/middleware/constants/routes';
import firebase from '@btc-frontend/middleware/firebase/init';
import { getEnvironmentConfig } from '@btc-frontend/middleware/utils/env';
import { cloneDeep } from 'lodash';
import i18n from './config/i18n';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import userStore from '@btc-frontend/stores/userStore';
import { bccomDomain, flags, newBccomUrl } from '@btc-frontend/config';
import { bccomLocalizedRedirector } from '@btc-frontend/middleware/utils/url';
import { generateRallywareSsoLink } from '@btc-frontend/middleware/services/rallyware/sso';

const {
    nogento: { btc: nogentoBTC },
    legacyBtc: { base: legacyBtc },
} = getEnvironmentConfig();

const itemTemplate = config => ({
    ...config,
    type: 'item',
});

const menuItemList = (routeConfig, parentUrl = '') => {
    let list = [];
    if (parentUrl === '/') parentUrl = '';
    for (let i = 0; i < routeConfig.length; i += 1) {
        const config = routeConfig[i];
        const { menuParent, to, children, redirectTo } = config;
        if (config.to === '/') {
            list.push({ to: config.to, name: config.name, key: 'home', sidebar: config.sidebar });
        }
        if (Array.isArray(to) && menuParent) {
            list = [
                ...list,
                ...config.to.map(to => {
                    const configPart = config;
                    configPart.to = `${parentUrl}${to}`;
                    return itemTemplate(configPart);
                }),
            ];
        } else if (menuParent) {
            if (!config.redirectTo) config.to = `${parentUrl}${to}`;
            list.push(itemTemplate(config));
        }
        if (children) {
            list.unshift(...menuItemList(children, `${parentUrl}${to}`));
        }
    }
    return list;
};

const navListGenerator = (routeConfig, parentUrl = '') => {
    const list = [];

    const items = menuItemList(routeConfig);

    list.push(...items.filter(item => item.to === '/'));
    list.push(...items.filter(item => item.menuParent === routeKeys.root));
    return list;
};

function customerRouteGenerator(routeConfig) {
    const list = [];
    for (let i = 0; i < routeConfig.length; i++) {
        const config = routeConfig[i];
        const { children } = config;
        if (config.customerRoute) {
            list.push(config);
        }
        if (children) {
            list.unshift(...customerRouteGenerator(children));
        }
    }
    return list;
}

// get a list of all the routes flatly with no hiearchy for keying purposes.
function flatList(routes) {
    const list = [];
    // list.push(route);
    routes.forEach(route => {
        list.push(route);
        if (route.children) list.push(...flatList(route.children));
    });
    return list;
}

function flatRoutes(routes, UrlAppend): any {
    let routeObject = {};

    routes.forEach(route => {
        routeObject = {
            ...routeObject,
            [route.key]: {
                ...route,
                to: `${(UrlAppend && UrlAppend != '/' && UrlAppend) || ''}${route.to}`,
            },
        };
        if (route.children) {
            routeObject = {
                ...routeObject,
                ...flatRoutes(route.children, route.to),
            };
        }
    });
    return routeObject;
}

// redirection handler for navigation
async function linkRedirect(src) {
    try {
        const auth = await firebase.auth();
        const token = await auth.currentUser.getIdToken(true);
        const link = document.createElement('a');

        if (src === routeKeys.support.counterUniversity && flags.rallyware) {
            link.href = await generateRallywareSsoLink(
                flags.newCounterULink,
                userStore.enrolled90DaysOrMore,
            );
        } else if (src === routeKeys.support.counterTraining) {
            const base = flags.useVtexUrls ? bccomDomain.newBase : bccomDomain.base;
            const url = `${base}/counter-training`;
            link.href = url;
        } else {
            const options = {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                }),
            };
            const response = await fetch(src, options);
            const url = await response.text();
            link.href = url;
        }
        // by dynamically creating a <a> html tag in this function, safari browser does not allow javascript to programmatically open link in a new tab
        if (!interfaceStore.isSafari && !interfaceStore.isAppleDevice) {
            link.target = '_blank';
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        console.error(e);
    }
}

// returns structured nav links.
const navList = navListGenerator(cloneDeep(routeConfig()));

const renderedTitles = (lang, localeCode = null) => {
    const t = (key, options) => {
        const render = i18n.t(key, { ns: 'common', lang, ...options });
        // console.info(`${'common'}?`, i18n.hasResourceBundle(lang, 'common'), key, render);
        return render;
    };
    return routeConfig(t, { localeCode });
};

// use nav titles to resolve the name field in routes.
const navTitles = (lang, localeCode = null) =>
    navListGenerator(cloneDeep(renderedTitles(lang, localeCode)));
// like flatRoutes but with translator support
const flatTitles = lang => flatRoutes(renderedTitles(lang));

const customerRouteList = customerRouteGenerator(cloneDeep(routeConfig()));
// todo: BTC-1142 - create full route list dynamically using existing routes.
const navLinks = {
    ...flatRoutes(routeConfig()),
    startCounting: '/performance/counting',
    counterUniversity: 'counterUniversity',
    counterTraining: `counterTraining`,

    legacyBtc,

    renewalOrder: code => bccomLocalizedRedirector('/quick-order?sku=9990', code),
    counterCommsZenDesk: `${nogentoBTC}/zendesk/`,
    counterSupportZenDesk: `${nogentoBTC}/zendesk/requests/new`,
    contactCard: id => `/consultant/${id}`,
    tools: '/tools/digital-assets',
};

// the same as navlinks but with locale code injected for localized routes (bccom, popups)
const localizedNavLinks = localeCode => ({
    ...navLinks,
    ...flatRoutes(routeConfig(t => t, { localeCode })),
});

export const navigationPathKeys = {
    '/': 'Home',
    '/performance/dashboard': 'Performance Dashboard',
    '/performance/incentives': 'Incentives',
    '/performance/growthtracker': 'Growth Tracker',
    '/performance/counting': 'Start Counting',
    '/performance/earnings': 'Earnings',
    [`${flags.useVtexUrls ? newBccomUrl : bccomDomain.base}/quick-order`]: 'Quick Order',
    '/orders/orderHistory': 'Order History',
    '/customers/top-customers': 'Customer Dashboard',
    '/customers/my-customers': 'My Customers',
    '/prospects/my-prospects': 'My Prospects',
    '/prospects/add': 'Add Prospects',
    '/team/recruitment': 'Team Dashboard',
    '/team/downline-report': 'My Downline',
    '/team/watchlist': 'Watchlist',
    [`${bccomDomain.socials}`]: 'Pop Ups',
    [`${flags.useVtexUrls ? newBccomUrl : bccomDomain.base}${bccomDomain.socials}`]: 'Pop Ups',
    '/newsfeed': 'Newsfeed',
    '/inventory/inventory-updates': 'Inventory Updates',
    '/tools/digital-assets': 'Tools Digital Assets',
    'https://beautycounter.go.customprintcenter.ca': 'Tools Printed Materials',
    [navLinks.counterCommsZenDesk]: 'Counter Comms',
    [navLinks.counterSupportZenDesk]: 'Counter Support',
    'https://counteru.beautycounter.com/': 'Counter University',
    'https://counteru.beautycounter.com/beautycounter/user_activity_info.aspx?id=320':
        'Counter University',
};

export const navigationAnalyticKey: (path: string) => string | null = (path: string) => {
    if (!path) return null;
    const analyticTitleKey = navigationPathKeys[path];
    if (!analyticTitleKey) return null;
    return analyticTitleKey;
};

export {
    navList,
    customerRouteList,
    navLinks,
    localizedNavLinks,
    linkRedirect,
    navTitles,
    flatTitles,
    renderedTitles,
};
