exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".menuContainer{width:200px;padding:0}html{font-size:14px}", ""]);

// exports
exports.locals = {
	"menuContainer": "menuContainer"
};