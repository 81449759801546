import { toJS, computed, action, autorun } from 'mobx';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { keyBy, cloneDeep } from 'lodash';
import { locales } from '@btc-frontend/config';
import validations from './validations';
import Form from './form';

import contentStore from '@btc-frontend/stores/contentStore';
import localeStore from '@btc-frontend/stores/localeStore';
import sessionStore from '@btc-frontend/stores/sessionStore';

class FormStore {
    formsByLocale = keyBy(cloneDeep(locales), 'code');

    ready() {
        this.applySessionForms();
        __BROWSER__ &&
            autorun(() => {
                localeStore.activeLocale;
                this.applySessionForms();
            });
    }

    @computed
    get validations(): IFormStore.IFormCollection {
        return validations(this.errorMessages || {});
    }

    @computed
    get forms(): IFormStore.IFormCollection {
        return this.formsByLocale[localeStore ? localeStore.activeLocale.code : locales[0].code];
    }

    @action
    form(key: string) {
        return this.forms[key] || {};
    }

    @action
    create(options: string | IFormStore.ICreateFormOptions): IFormStore.IForm {
        const { key = '', persist = false, locale = localeStore.activeLocale, config } =
            typeof options === 'string' ? { key: options } : options;
        if (!key) {
            throw new Error('Error in Formstore: Must provide a key when creating a form');
        }
        if (!this.forms[key]) {
            this.forms[key] = new Form({
                key,
                persist,
                locale,
                save: this.save,
                labels: this.formLabels,
                validations: this.validations,
                config,
            });
        }
        return this.forms[key];
    }

    @action
    createReactiveForm(name: string, modelLocation: string, action: () => void) {
        const reaction = () => {
            this.clearForm(name);
            action();
        };
        action();
        contentStore.addExtensionSubscription(modelLocation, reaction);
    }

    @action
    clearForm(key: string) {
        if (this.forms[key]) delete this.forms[key];
    }

    @action
    resetForm(key: string) {
        const formToReset = this.forms[key];
        formToReset && formToReset.clearFields();
    }

    @computed
    get sessionString(): IFormStore.IFormCollection {
        return `formStore-${localeStore.activeLocale.code}`;
    }

    @computed
    get sessionForms(): IFormStore.IFormCollection {
        return getSafe(() =>
            JSON.parse(sessionStore.getKey(this.sessionString, { localized: false }, true)),
        );
    }

    @action
    saveSessionForms(forms: IFormStore.IFormCollection) {
        try {
            sessionStore.saveKey(this.sessionString, toJS(forms), { localized: false }, true);
        } catch (error) {
            console.log('Error saving forms to session storage: ', error);
        }
    }

    @action
    applySessionForms() {
        const { activeLocale } = localeStore;
        if (this.sessionForms) {
            Object.keys(this.sessionForms).forEach(key => {
                const { fields = {}, locale = activeLocale } = this.sessionForms[key] || {};
                this.forms[key] = <IFormStore.IForm>new Form({
                    key,
                    locale,
                    fields,
                    persists: true,
                    save: this.save,
                    labels: this.formLabels,
                    validations: this.validations,
                });
            });
        }
    }

    @action
    save = (key: string) => {
        const form = this.forms[key];
        if (form instanceof Form) {
            const storedForms = this.sessionForms;
            this.saveSessionForms({
                ...storedForms,
                [key]: toJS({
                    key: form.key,
                    locale: form.locale,
                    fields: form.fields,
                }),
            });
        }
    };

    @action
    clear(...keys: string[]) {
        if (keys.length > 0) {
            const storedForms = this.sessionForms;
            keys.forEach(key => {
                if (storedForms && storedForms[key]) delete storedForms[key];
                if (this.forms[key]) this.forms[key] = null;
            });
            if (storedForms) this.saveSessionForms(storedForms);
        } else {
            this.formsByLocale[localeStore.activeLocale.code] = {};
            __BROWSER__ && window.sessionStorage.removeItem(this.sessionString);
        }
    }

    @computed
    get formLabels(): IFormStore.IFormLabelCollection {
        return getSafe(() => contentStore._extensions.forms.data.configForms[0].formLabels) || {};
    }

    @computed
    get errorMessages(): IFormStore.IErrorCollection {
        return (
            getSafe(() => contentStore._extensions.forms.data.configForms[0].errorMessages) || {}
        );
    }
}

const formStore = new FormStore();

export default formStore;
export { FormStore };
