exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1gIoz{width:300;-webkit-box-sizing:\"border-box\";box-sizing:\"border-box\"}", ""]);

// exports
exports.locals = {
	"root": "_1gIoz"
};