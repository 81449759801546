/**
 * When defining environment specific rules such as API URLS,
 * it is recommended to put that in the environment configs
 * and to then reference the environment middleware here
 */

import { getEnvironmentConfig } from '@beautycounter/btc-frontend/src/middleware/utils/env';

export const render = typeof process.env.NODE_SPECIAL != 'undefined',
    production = process.env.NODE_ENV === 'production',
    PORT = process.env.PORT || 3000,
    devUrl = `http://localhost:${PORT}`,
    prefix = '/btc',
    // Configurable variables e.g. dev, QA, prod
    environment = getEnvironmentConfig(process.env.CONFIG),
    liveUrl = environment.liveUrl,
    redirectUrl = environment.redirectUrl,
    bccomUrl = environment.bccomUrl,
    newBccomUrl = environment.newBccomUrl,
    firebaseProject = environment.firebaseProject,
    cdnUrl = environment.cdnUrl,
    env = environment.name,
    verbose = environment.verbose,
    info = environment.info,
    developerTools = environment.developerTools,
    contentfulbc = environment.contentfulbc,
    contentfulbtc = {
        ...environment.contentfulbtc,
        managementToken: process.env.contentfulBTCManagementToken,
    },
    magento = environment.magento,
    phoenix = environment.phoenix,
    klarnaCanCutoverDateUTC = environment.klarna.klarnaCanCutoverDateUTC,
    puff = environment.puff,
    analytics = environment.analytics,
    nogento = environment.nogento,
    recaptcha = environment.recaptcha,
    googleMaps = environment.googleMaps,
    sailthru = environment.sailthru,
    yotpoReviews = environment.yotpoReviews,
    square = environment.square,
    vimeo = environment.vimeo,
    redirect = environment.redirect,
    olapic = environment.olapic,
    search = environment.search,
    firebase = environment.firebase,
    paypal = environment.paypal,
    flags = environment.flags,
    environmentName = environment.environmentName,
    bccomDomain = environment.bccomDomain,
    narvarUrl = environment.narvarUrl,
    rallyware = environment.rallyware,
    hyperwallet = environment.hyperwallet,
    vtexScope = environment.vtexScope,
    // Routes are declared here to help with rendering
    routes = {
        HOME: '/',
        INDEX: '/index.html',
        LOGIN: '/login',
        LOGOUT: '/logout',
        ACCOUNT: '/account',
        FORGOT_PASSWORD: '/customer/account/forgotpassword',
        ORDER_CONFIRM: '/order-confirmation',
    },
    // Do not render these pages server-side
    clientOnlyPages = [
        'ORDER_CONFIRM',
        'LOGOUT',
        'ACCOUNT',
        'ACCOUNTDASHBOARD',
        'BLOG',
        'PWS',
        'CHECKOUT',
        'FORGOT_PASSWORD',
        'CHANGE_FORGOTTEN_PASSWORD',
        'RESET_PASSWORD',
    ],
    // Provides a list of rewrites for urls which originate on the api, and for mapping urls to the api.
    legacyPageMapping = {
        '/account': '/customer/account',
    },
    emptyLayoutPages = [
        'error',
        'checkout',
        'login',
        'checkoutInterstitial',
        'siteDown',
        'zendeskPage',
    ],
    mobilePagesWithFooter = ['home', 'category', 'product'],
    mobilePagesWithBackIcon = [],
    specialFilters = ['New', 'Best Sellers', 'Sale'],
    // Core data models that must be fetched from Contentful prior to render
    coreModels = [
        'configGeneral',
        'cart',
        'category',
        'footer',
        'hamburgerNavigation',
        'header',
        'shop',
        'configProductInfo',
        'links',
        'pdpMiniWidget',
        'configSharingPage',
        'configShareWithSocial',
        'configEmailSharing',
        'configAccount',
        'promoBanner',
    ],
    asyncModels = {
        counterUI: {
            id: 'counterUI',
            models: ['translationsDataTable'],
            contentfulSpace: 'bccom',
        },
        navigation: {
            id: 'navigation',
            models: ['translationsNavigation'],
        },
        login: {
            id: 'login',
            route: routes.LOGIN,
            models: ['configLoginCreateAccount'],
        },
        checkoutShippingPayment: {
            id: 'checkoutShippingPayment',
            route: routes.CHECKOUT,
            models: ['configCheckoutPaymentShipping', 'configCheckoutErrorMessages'],
        },
        checkoutInterstitial: {
            id: 'checkout',
            route: routes.CHECKOUT,
            models: ['configCheckout'],
        },
        forms: {
            id: 'forms',
            models: ['configForms'],
            contentfulSpace: 'bccom',
        },
        advocacy: {
            id: 'advocacy',
            route: routes.ADVOCACY,
            models: ['configAdvocacy'],
        },
        help: {
            id: 'help',
            route: routes.HELP,
            models: ['configHelp'],
        },
        orderConfirmation: {
            id: 'orderConfirmation',
            route: routes.ORDER_CONFIRM,
            models: ['configOrderConfirmation'],
        },
        becomeMember: {
            id: 'becomeMember',
            route: [routes.BECOME_MEMBER, routes.MEMBER_RENEWAL],
            models: ['configBecomeMember'],
        },
        becomeConsultant: {
            id: 'becomeConsultant',
            route: routes.BECOME_CONSULTANT,
            models: ['configBecomeAConsultant'],
        },
        flawlessInFive: {
            id: 'flawlessInFive',
            route: routes.FLAWLESS_IN_FIVE,
            models: ['configFlawlessInFive', 'widgetVideoSelector'],
        },
        PWS: {
            id: 'PWS',
            models: ['configPws'],
        },
        neverList: {
            id: 'neverList',
            route: routes.NEVER_LIST,
            models: ['configNeverList'],
        },
        hostSocial: {
            id: 'hostSocial',
            route: routes.HOST_SOCIAL,
            models: ['configHostASocial'],
        },
        transparency: {
            id: 'transparency',
            route: routes.TRANSPARENCY,
            models: ['configTransparency'],
        },
        findConsultant: {
            id: 'findConsultant',
            route: routes.FIND_CONSULTANT,
            models: ['configFindConsultant'],
        },
        memberPromotion: {
            id: 'memberPromotion',
            models: ['configMemberPromotion'],
        },
        connectConsultant: {
            id: 'connectConsultant',
            route: routes.CONNECT_CONSULTANT,
            models: ['configConnectWithAConsultant'],
        },
        ingredientsGlossary: {
            id: 'ingredientsGlossary',
            route: routes.INGREDIENT_GLOSSARY,
            models: ['configIngredientGlossary'],
        },
        enrollment: {
            id: 'enrollment',
            route: routes.ENROLLMENT,
            models: ['configEnrollment'],
        },
        quiz: {
            id: 'quiz',
            route: routes.QUIZ,
            models: ['configQuiz'],
        },
        theCleanSlate: {
            id: 'theCleanSlate',
            route: routes.CLEAN_SLATE,
            models: ['configTheCleanSlate'],
        },
        holiday: {
            id: 'holiday',
            route: routes.HOLIDAY,
            models: ['configHoliday'],
        },
        filters: {
            id: 'filters',
            models: ['filters'],
        },
        account: {
            id: 'account',
            route: routes.ACCOUNT,
            models: [
                'configAccount',
                'translationsMyAccount',
                'translationsMyAccountAboutMe',
                'translationsMyAccountAddressBook',
                'translationsMyAccountDashboard',
                'translationsMyAccountHeader',
                'translationsMyAccountDisbursement',
                'translationsMyAccountPaymentProfile',
                'translationsMyAccountPhotoProfile',
            ],
        },
        commissions: {
            id: 'commissions',
            models: ['configCommissions'],
            contentfulSpace: 'btc',
        },
        countries: {
            id: 'countries',
            models: ['transalationsCountries'],
            contentfulSpace: 'btc',
        },
        nudges: {
            id: 'nudges',
            models: [
                'configNudges',
                'translationsNudgeList',
                'translationsNudges',
                'translationsNudgesFollowUp',
                'translationsNudgesLogs',
                'translationsNudgesCompletion',
                'translationsNudgesNotes',
                'translationsNudgesSnooze',
                'translationsNudgesMethods',
            ],
            contentfulSpace: 'btc',
        },
        earnings: {
            id: 'earnings',
            models: [
                'configEarnings',
                'translationsEarnings',
                'translationsEarningsDirOverride',
                'translationsEarningsLevelOverride',
                'translationsDates',
                'translationsEarningsRetail',
            ],
            contentfulSpace: 'btc',
        },
        performance: {
            id: 'performanceMeta',
            models: ['configPerformanceDashboard'],
            contentfulSpace: 'btc',
        },
        dates: {
            id: 'date',
            models: ['translationsDates'],
            contentfulSpace: 'btc',
        },
        startCountingV2: {
            id: 'startCountingV2',
            models: ['translationsStartCounting'],
            contentfulSpace: 'btc',
        },
        homePage: {
            id: 'homePage',
            models: ['translationsHomePage'],
            contentfulSpace: 'btc',
        },
        customerDashboard: {
            id: 'customerDashboard',
            models: ['translationsCustomerDashboard'],
            contentfulSpace: 'btc',
        },
        policiesProcedures: {
            id: 'policiesProcedures',
            models: ['configPoliciesAndProcedures'],
            contentfulSpace: 'bccom',
        },
    },
    // @method is used to return matching callback from the sorting utility
    sortTypes = [
        { id: 'sortRecommended', method: 'byPosition' },
        { id: 'sortByPrice', method: 'byPrice', order: 'asc' },
        { id: 'sortByPriceDescending', method: 'byPrice', order: 'desc' },
    ],
    locales = [
        {
            code: 'en-US',
            longName: 'US',
            lang: 'en',
            name: 'US',
            country: 'United States',
            countryShort: 'US',
            countryId: 1,
            languageId: 1,
            image: 'images/us-flag.svg',
            flagImage: 'images/icon-us-location.svg',
            default: true,
            currency: {
                name: 'USD',
                format: '$0',
                rounding: true,
            },
            magentoURL: '',
            magentoStoreView: 1,
            phoenixCountry: 1,
            preRender: true,
        },
        {
            code: 'en-CA',
            longName: 'CA',
            lang: 'en',
            name: 'CA',
            country: 'Canada',
            countryShort: 'CA',
            countryId: 2,
            languageId: 1,
            image: 'images/ca-flag.svg',
            flagImage: 'images/icon-ca-location.svg',
            currency: {
                name: 'CAD',
                format: '$0',
                rounding: true,
            },
            magentoURL: '/en-ca',
            magentoStoreView: 2,
            phoenixCountry: 2,
        },
        {
            code: 'fr-CA',
            longName: 'FR',
            lang: 'fr',
            name: 'FR',
            country: 'Canada',
            countryShort: 'CA',
            countryId: 2,
            languageId: 2,
            image: 'images/ca-flag.svg',
            flagImage: 'images/icon-ca-location.svg',
            currency: {
                name: 'CAD',
                format: '0 $',
                rounding: true,
            },
            magentoURL: '/fr-ca',
            magentoStoreView: 3,
            phoenixLanguageId: 3,
            phoenixCountry: 2,
        },
    ],
    specialCategories = {
        bestSellers: 24,
        newProducts: 10999,
    },
    // Static pages are declared here to help with caching
    pages = [],
    cachePaths = {
        products: {
            public: '/content/products-array.json',
            local: 'scripts/cache/products-array.json',
        },
        categories: {
            public: '/content/categories.json',
            local: 'scripts/cache/categories.json',
        },
        ingredients: {
            public: '/content/ingredients.json',
            local: 'scripts/cache/ingredients.json',
        },
    },
    siteName = 'Behind the Counter',
    mail = 'info@beautycounter.com',
    facebook = {
        APP_ID: '',
    },
    HOST = production ? liveUrl : devUrl,
    baseUrl = HOST,
    useCDN = true,
    cacheAPIData = environment.cache,
    assetPath = useCDN && production ? cdnUrl : '',
    pixel = production,
    devToolConfigs = {
        myCustomersStore: {
            name: 'myCustomersStore',
            navigationPath: '/customers/my-customers',
            getStorePath: () =>
                require('@btc-frontend/stores/myCustomersStore/myCustomersStore').default,
        },
    };
