import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';

export async function getCustomerCards(params) {
    const endpoint = `${nogento.customer}/getCustomerCards`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    const options = {
        method: 'POST',
        body: JSON.stringify({
            ...params,
            user: { token },
        }),
    };

    try {
        const response = await fetch(endpoint, options);
        const { cards } = await response.json();
        return {
            success: true,
            cards,
        };
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}

export async function createCustomerCard(params) {
    const endpoint = `${nogento.customer}/createCustomerCard`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    const options = {
        method: 'POST',
        body: JSON.stringify({
            ...params,
            user: { token },
        }),
    };

    try {
        const response = await fetch(endpoint, options);
        const { card } = await response.json();
        if (card) {
            return {
                success: true,
                card,
            };
        }
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function updateCustomerCard(params) {
    const endpoint = `${nogento.customer}/updateCustomerCard`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    const options = {
        method: 'POST',
        body: JSON.stringify({
            ...params,
            user: { token },
        }),
    };

    try {
        const response = await fetch(endpoint, options);
        const data = await response.json();
        if (data.success) {
            return {
                success: true,
                data,
            };
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function deleteCustomerCard(params) {
    const endpoint = `${nogento.customer}/deleteCustomerCard`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    const options = {
        method: 'POST',
        body: JSON.stringify({
            ...params,
            user: { token },
        }),
    };

    try {
        const response = await fetch(endpoint, options);
        const data = await response.json();
        return {
            success: true,
            data,
        };
    } catch (e) {
        console.log(e);
        return false;
    }
}
