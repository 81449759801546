import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';

// style related
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import global from './DefaultLayout.acss';
import s from './DefaultLayout.scss';
import cx from 'classnames';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from '@btc-frontend/theme';

import { observer, inject } from 'mobx-react';
import { throttle } from 'lodash';

/* For Header */
import Header from '@btc-frontend/components/Header/Desktop';
import Breadcrumb from '@counter-ui/Core/Breadcrumb';
import Link from '@btc-frontend/components/Common/Link';

/* For Sidebars */
import MenuLeft from './MenuLeft';
import Sidebar from '@btc-frontend/components/Common/Sidebar';
import Navigation from '@btc-frontend/components/Navigation';

/* For Footer */
import Snackbar from '@btc-frontend/components/Common/SnackbarWrapper/SnackbarWrapper';
import StickyButtons from '@btc-frontend/components/Common/StickyButtons';

/* For Policy Procedure Pop Up */
import PoliciesProceduresContainer from '@btc-frontend/containers/PoliciesProceduresContainer/PoliciesProceduresContainer';

@withStyles(s)
@withStyles(global)
@inject(
    'interfaceStore',
    'productsStore',
    'analyticsStore',
    'contentStore',
    'navigationStore',
    'flagStore',
)
@observer
class DefaultLayout extends Component {
    static contextTypes = {
        getCookie: pt.func.isRequired,
        setCookie: pt.func.isRequired,
    };

    static propTypes = {
        hasFooter: pt.bool,
        children: pt.node,
        path: pt.string,
    };

    static defaultProps = {
        hasFooter: true,
        path: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 1280,
            menuOpen: false,
        };
        this.onSetMenuOpen = this.onSetMenuOpen.bind(this);
    }

    componentDidMount() {
        if (typeof __BROWSER__ !== 'undefined') {
            this.resizeListener = throttle(this.updateDimensions, 250);
            window.addEventListener('resize', this.resizeListener);
            this.updateDimensions();
        }
    }

    handleNavigationClick = () => {
        this.setState({ menuOpen: false });
    };

    onSetMenuOpen = open => {
        this.setState({ menuOpen: open });
    };

    handleMenuClick = () => {
        this.setState({ menuOpen: true });
    };

    handleCloseSearch = () => {
        this.props.interfaceStore.searchClose();
    };

    updateDimensions = () => {
        let windowWidth = 1280;
        if (typeof __BROWSER__ !== 'undefined') {
            windowWidth = window.innerWidth;
        }
        if (windowWidth !== this.state.viewportWidth) {
            this.setState({ viewportWidth: windowWidth });
        }
    };

    getBreadcrumbs = () => {
        const {
            routeConfig,
        }: { routeConfig: IRoute.IRouteConfig } = this.props.children.props.children.props;
        const variables = this.props.children.props.children.props.variables; //if route has a variable
        return this.props.navigationStore.getBreadCrumbs(routeConfig.key, variables);
    };

    render() {
        const { appIsMounted, isMobile } = this.props.interfaceStore;
        const policiesAndProceduresEnabled = this.props.flagStore.isFeatureEnabled(
            'policiesAndProcedures',
        );

        const menuStyles = {
            sidebar: {
                zIndex: 9999,
                backgroundColor: 'white',
            },
        };

        /* Mobile Nav */
        const menuContent = appIsMounted && (
            <Navigation
                className={cx(s.navigation, this.state.menuOpen && s.active)}
                onClick={this.handleNavigationClick}
                onBlur={this.handleNavigationClick}
            />
        );

        /* Desktop Nav */
        const sideMenu = (
            <MenuLeft
                fixed
                display
                className={s.sidebar}
                path={this.props.path}
                isMobile={this.state.viewportWidth < 1000}
            />
        );

        const breadcrumbs = this.getBreadcrumbs();

        return (
            <MuiThemeProvider theme={theme}>
                <div id="page" className={s.root}>
                    <div className={s.sidebar}>{sideMenu}</div>
                    <Sidebar
                        key="sidebar-left"
                        sidebar={menuContent}
                        open={this.state.menuOpen}
                        onSetOpen={this.onSetMenuOpen}
                        styles={menuStyles}
                    />
                    <div className={s.appBody}>
                        {policiesAndProceduresEnabled && <PoliciesProceduresContainer />}
                        <div className={s.header}>
                            <Header
                                isMobile={this.state.viewportWidth < 1000}
                                onMenuClick={this.handleMenuClick}
                                onCloseSearch={this.handleCloseSearch}
                            />
                        </div>
                        <div className={s.main}>
                            <Breadcrumb
                                className={s.breadcrumbs}
                                routes={breadcrumbs}
                                link={Link}
                            />
                            {this.props.children}
                        </div>
                    </div>
                    <Snackbar />
                    <StickyButtons />
                </div>
            </MuiThemeProvider>
        );
    }
}

export default DefaultLayout;
