import { observable, computed, action, autorun, toJS } from 'mobx';
import { findLast, last } from 'lodash';
import QueryString from 'query-string';
import {
    isBackPointPath,
    parseNavigation,
} from '@btc-frontend/middleware/services/navigation/navigation';
import {
    setURLQuery,
    removeURLQueries,
    unlocalizeURL,
    getAbsoluteBaseUrl,
    getLocaleURL,
} from '@btc-frontend/middleware/utils/url';
import { prefix, routes, analytics, liveUrl, accountLinks, locales } from '@btc-frontend/config';
import Location from '@btc-frontend/middleware/utils/Location';
import { routeConfig } from '@btc-frontend/router/routes';
import localeStore from '@btc-frontend/stores/localeStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import userStore from '@btc-frontend/stores/userStore';
import { routeKeys } from '@btc-frontend/middleware/constants/routes';
import i18n from '../../config/i18n';

const DEFAULT_BACK_PATH = '/';

class NavigationStore {
    @observable history;
    @observable navigation;
    @observable hash;

    constructor() {
        this.history = [
            {
                path: DEFAULT_BACK_PATH,
                isBackPoint: true,
            },
        ];
        this.navigation = [];
        this.getPathFromRouteKey = this.getPathFromRouteKey.bind(this);
        this.hash = this.updatedHash;
        window.onpopstate = event => {
            this.history.pop();
        };
    }

    @action
    load = data => {
        this.navigation = parseNavigation(data);
    };

    @action
    to = ({ url = null, key = null, reload = false, assign = false, newWindow = false } = {}) => {
        if (key) {
            url = this.getPathFromRouteKey(key);
        }

        // relative paths that don't have the site prefix should have it appended.
        // '/orders` becomes '/btc/orders'
        url = /^(\/(?!btc).*)/.test(url) ? `${prefix}${url}` : url;

        if (reload && __BROWSER__) {
            window.location.replace(url);
            return true;
        }
        if (assign && newWindow && __BROWSER__) {
            window.open(url);
            return true;
        }

        if (assign && __BROWSER__) {
            window.location.assign(url);
            return true;
        }

        Location.push(url);
        this.clearPanels();

        const path = typeof url === 'string' ? url : url.pathname;

        this.history.push({
            path,
            isBackPoint: interfaceStore.cartOpen ? false : isBackPointPath(path),
        });
    };

    @action
    clearPanels() {
        interfaceStore.searchClose();
        interfaceStore.closeBottomPanel();
    }

    @action
    goBack = data => {
        const backPath = this.previous.path != this.path ? this.previous.path : null;
        this.to({ url: backPath || routes.HOME });
    };

    @action
    addQuery = ({ queries = [], isBackPoint = false }) => {
        if (__BROWSER__) {
            window.history.replaceState('', '', setURLQuery(queries));

            this.history.push({
                path: window.location.pathname + window.location.search,
                isBackPoint,
            });
        }
    };

    @action
    changePath = ({ path = '', isBackPoint = false }) => {
        this.history.push({
            path,
            isBackPoint,
        });
    };

    @action
    setPath = ({ path = '' }) => {
        window.history.pushState('', '', path);
        this.history.push({ path });
    };

    @action
    cleanPath = ({ route = '' }) => {
        const query = window.location.search;
        const url = getLocaleURL(localeStore.activeLocale.code, route);
        window.history.replaceState('', '', url + query);
    };

    @computed
    get queryStringMap() {
        return QueryString.parse(this.queryString) || {};
    }

    @action
    addPathArgument = ({ route = '', arg = '' }) => {
        const query = window.location.search;
        const url = getLocaleURL(localeStore.activeLocale.code, route);
        window.history.replaceState('', '', `${url}/${arg}${query}`);
    };

    // similar to addPathArgument, except it adds to the history.
    @action
    setArg = ({ route = '', arg = '' }) => {
        const query = window.location.search;
        const url = getLocaleURL(localeStore.activeLocale.code, route);
        window.history.pushState('', '', `${url}/${arg}${query}`);
    };

    @action
    localizeURL = url => getLocaleURL(localeStore.activeLocale.code, url);

    @action
    setStartPage = (path = '') => {
        this.history.push({
            path,
            isBackPoint: isBackPointPath(path),
        });
    };

    @action
    newPath = (path = '') => {
        this.history.push({
            path,
            isBackPoint: isBackPointPath(path),
        });
        this.postNavigationEvents();
    };

    @action
    postNavigationEvents = () => {
        __BROWSER__ &&
            requestAnimationFrame(() => {
                if (analytics.optimizely.enabled) {
                    window.optimizely = window.optimizely || [];
                    window.optimizely.push({ type: 'activate' });
                }
            });
    };

    @computed
    get backPath() {
        return findLast(this.history, { isBackPoint: true }).path;
    }

    @computed
    get previous() {
        return this.history[this.history.length - 3] || { isBackpoint: false };
    }

    @computed
    get path() {
        return last(this.history).path.replace('btc/', '');
    }

    @computed
    get queryString() {
        return QueryString.extract(this.path);
    }

    @computed
    get absolutePath() {
        return liveUrl + last(this.history).path;
    }

    @action
    unlocalizedPath = (path = this.path) => unlocalizeURL(path);

    @action
    unlocalizedBasePath = (path = this.path) => removeURLQueries(false, unlocalizeURL(path));

    @action
    parseNavigation = links =>
        parseNavigation(links.filter(link => link && this.isLinkVisible(link) && link));

    @action
    isLinkVisible = link => true;

    // Update subscriber components when link visibility changes
    @computed
    get observeLinkChanges() {
        return [interfaceStore.appIsMounted, userStore.isConsultant];
    }

    get updatedHash() {
        if (__BROWSER__) {
            return '' || window.location.hash.substr(1);
        }
        return '';
    }

    @action
    updateHash = newHash => {
        window.history.replaceState(null, null, newHash ? `#${newHash}` : '');
        this.hash = this.updatedHash;
    };

    // create a key:route index object for all routes on BTC
    getIndexedRoutes = (routeCollection: IRoute.IRouteConfig[] = null): IRoute.IRouteIndex => {
        const t = key => {
            const render = i18n.t(key, {
                ns: 'common',
                lang: localeStore.activeLocale.lang,
            });
            // console.info(`${'common'}?`, i18n.hasResourceBundle(lang, 'common'), key, render);
            return render;
        };
        const routes: IRoute.IRouteConfig[] = routeCollection || routeConfig(t);
        return routes.reduce((index: IRoute.IRouteIndex, route: IRoute.IRouteConfig) => {
            const { children, key } = route;
            index[key] = route;
            if (children && children.length)
                index = { ...index, ...this.getIndexedRoutes(children) };
            return index;
        }, {});
    };

    @computed
    get routeIndex(): IRoute.IRouteIndex {
        return this.getIndexedRoutes();
    }

    // return appropriate breadcrumb set for a given route
    @action
    getBreadCrumbs(routeKey: string, variables): IRoute.IBreadcrumb[] {
        const currentRoute = this.routeIndex[routeKey];
        const { disableBreadcrumbs } = currentRoute || { disableBreadcrumbs: false };
        if (!currentRoute || disableBreadcrumbs) return [];

        const routeList: IRoute.IRouteConfig[] = [];
        let currentKey = routeKey;
        while (currentKey) {
            const currentRoute = this.routeIndex[currentKey];
            if (currentRoute) routeList.unshift(currentRoute);
            let parentKey = currentRoute.menuParent;
            if (!parentKey && currentRoute.key !== routeKeys.root) parentKey = routeKeys.root;
            currentKey = parentKey;
        }
        let fullRoute = `${prefix}`;
        return routeList.reduce((breadcrumbs: IRoute.IBreadcrumb[], route) => {
            // we don't add the root to the fullRoute string because
            // we'd end up with a double-slash '//my-page' situation
            if (route.key !== routeKeys.root) {
                if (route.redirectTo) {
                    // we dont want to add a redirectTo into fullRoute, or there will be word repeats in the breadcrumb
                    fullRoute = `${fullRoute}${route.to}`;
                    breadcrumbs.push({
                        name: route.name,
                        to: `${prefix}${route.redirectTo}`,
                        key: route.key,
                    });
                } else {
                    fullRoute = `${fullRoute}${
                        Array.isArray(route.to)
                            ? route.to.find(element => element.indexOf(':') == -1) // uses route that doesn't have parameter
                            : route.to
                    }`;
                    breadcrumbs.push({
                        name: route.name,
                        to: fullRoute,
                        key: route.key,
                    });
                    if (variables && Array.isArray(route.to)) {
                        if (variables.hasOwnProperty('uid') && variables.uid) {
                            breadcrumbs.push({
                                name: `Team Member Progress`,
                                to: `${fullRoute}/${variables.uid}`,
                                key: route.key,
                            });
                        }
                    }
                }
            } else {
                breadcrumbs.push({
                    name: route.name,
                    to: String(route.to),
                    key: route.key,
                });
            }

            return breadcrumbs;
        }, []);
    }

    @action
    getPathFromRouteKey(routeKey: string, isTabLink): string {
        const route = this.routeIndex[routeKey];
        if (!route || routeKey == routeKeys.root) return String(this.routeIndex[routeKeys.root].to);

        const routeList: IRoute.IRouteConfig[] = [];
        let currentKey = routeKey;
        while (currentKey) {
            const currentRoute = this.routeIndex[currentKey];
            if (currentRoute) routeList.unshift(currentRoute);
            let parentKey = currentRoute.menuParent;
            if (parentKey !== routeKeys.root) {
                currentKey = parentKey;
            } else currentKey = null;
        }
        const addPrefix = isTabLink ? `${prefix}` : '';
        return routeList.reduce((fullPath, route) => {
            let currentRoute;
            if (Array.isArray(toJS(route.to))) currentRoute = route.to[0];
            else currentRoute = String(route.to);

            // we need to remove any `/:variable`
            // tags from the registered route path
            if (route.variables) {
                route.variables.forEach(variable => {
                    try {
                        currentRoute = currentRoute.replace(`/:${variable}`, '');
                    } catch (e) {
                        console.error(e);
                    }
                });
            }

            fullPath = `${fullPath}${currentRoute}`;
            return fullPath;
        }, addPrefix);
    }

    @action
    getRouteGroup = (groupName: string) => {
        return Object.keys(this.routeIndex)
            .map(key => this.routeIndex[key])
            .filter(route => (route.routeGroup || []).includes(groupName));
    };
}

const navigationStore = new NavigationStore();

export default navigationStore;
export { NavigationStore };
