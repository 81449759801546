import FetchQL from 'fetchql';

export default class FetchQLExtension extends FetchQL {
    /**
     * operate a query
     * @param {Object} options
     * @param {String} options.query
     * @param {Object=} options.variables
     * @param {Boolean} options.isExport
     * @param {Object=} options.opts - addition options(will not be passed to server)
     * @param {Boolean=} options.opts.omitEmptyVariables - remove null props(null or '') from the variables
     * @param {Object=} options.requestOptions - addition options to fetch request(refer to fetch api)
     * @returns {Promise}
     * @memberOf FetchQL
     */

    query({ operationName, query, variables, opts = {}, requestOptions = {}, isExport }) {
        const options = Object.assign({}, this.requestObject, requestOptions);
        let vars;
        if (this.omitEmptyVariables || opts.omitEmptyVariables) {
            vars = this.doOmitEmptyVariables(variables);
        } else {
            vars = variables;
        }
        const body = {
            operationName,
            query,
            variables: vars,
        };
        options.body = JSON.stringify(body);

        this.onStart();

        return this.fetch(this.url, options)
            .then(async res => {
                if (res.ok && isExport) {
                    const text = await res.text();
                    return text;
                } else if (res.ok) {
                    return res.json();
                }
                // return an custom error stack if request error
                return {
                    errors: [
                        {
                            message: res.statusText,
                            stack: res,
                        },
                    ],
                };
            })
            .then(
                chain =>
                    new Promise((resolve, reject) => {
                        const { data, errors } = chain;
                        this.onEnd();

                        if (isExport) return resolve(chain);

                        // if data in response is 'null'
                        if (!data) {
                            return reject(errors || [{}]);
                        }
                        // if all properties of data is 'null'
                        const allDataKeyEmpty = Object.keys(data).every(key => !data[key]);
                        if (allDataKeyEmpty) {
                            return reject(errors);
                        }
                        return resolve({ data, errors });
                    }),
            );
    }
}
