const indigo = {
    indigo100: '#004875',
    indigo80: '#336D91',
    indigo60: '#6691AC',
    indigo40: '#99B6C8',
    indigo20: '#CCDAE3',
    indigo10: '#E5ECF1',
    indigo1: '#F7F9FA',
};

module.exports = indigo;
