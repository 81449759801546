const navy = {
    navy100: '#001D42',
    navy80: '#334A68',
    navy60: '#66778E',
    navy40: '#99A5B3',
    navy20: '#CCD2D9',
    navy10: '#E5E8EC',
    navy1: '#F7F8F9',
};

module.exports = navy;
