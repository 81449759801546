import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './FlagDashboard.scss';
import cx from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import ClickOutHandler from 'react-onclickout';

import Portal from '@btc-frontend/components/Common/Portal';
import Button from '@btc-frontend/components/Common/Button';
import Checkbox from '@beautycounter/counter-ui/src/Inputs/Checkbox';

import { menu } from './config';

@withStyles(s)
@inject('contentStore', 'userStore', 'flagStore')
@observer
class FlagDashboard extends Component {
    state = {
        activeTab: 0,
    };

    handleClick(e, key) {
        this.props.contentStore.handleUtilClick(key);
    }

    handleClickOutside = event => {
        event.stopPropagation();
        event.preventDefault();
        this.props.onClose && this.props.onClose(event);
    };

    setTab(tab) {
        this.setState({ activeTab: tab });
    }

    renderFlags(category) {
        const { flags = {} } = this.props.flagStore.bucketing;
        const merged = [];

        merged.push(
            Object.keys(flags)
                .sort((a, b) => a > b)
                .map(key => {
                    const label = key;
                    const hasEnabled = !!flags[key];
                    return (
                        <div
                            key={key}
                            data-testid={key}
                            className={s.listItem}
                            onClick={e => this.props.flagStore.forceFlag(key, !hasEnabled)}
                        >
                            <Checkbox title={label} checked={hasEnabled} shrink />
                        </div>
                    );
                }),
        );

        return (
            <React.Fragment>
                {this.renderList('features')}
                {merged}
            </React.Fragment>
        );
    }

    renderList(category) {
        const { utilities = {} } = this.props.contentStore;

        return Object.keys(utilities)
            .filter(
                key =>
                    utilities[key].category === category &&
                    (utilities[key].hasOwnProperty('visible')
                        ? typeof utilities[key].visible === 'function'
                            ? !!utilities[key].visible()
                            : !!utilities[key].visible
                        : true),
            )
            .map(key => {
                const label =
                    typeof utilities[key].label === 'function'
                        ? utilities[key].label()
                        : utilities[key].label;
                const hasEnabled = utilities[key].hasOwnProperty('enabled');
                return (
                    <div
                        key={label}
                        data-testid={key}
                        className={s.listItem}
                        onClick={e => this.handleClick(e, key)}
                    >
                        {hasEnabled ? (
                            <Checkbox
                                title={label}
                                checked={
                                    typeof utilities[key].enabled === 'function'
                                        ? !!utilities[key].enabled()
                                        : !!utilities[key].enabled
                                }
                                shrink
                            />
                        ) : (
                            label
                        )}
                    </div>
                );
            });
    }

    renderConfig() {
        return (
            <div>
                {this.renderList('apis')}
                <br />
                {this.renderList('other')}
            </div>
        );
    }

    renderContentArea() {
        const { activeTab } = this.state;

        return (
            <>
                <div className={s.menu}>
                    {menu.tabs.map((tab, index) => (
                        <div
                            key={tab.id}
                            data-testid={`tab-${tab.id}`}
                            className={cx(s.tab, activeTab === index && s.active)}
                            onClick={() => this.setTab(index)}
                        >
                            <div
                                className={s.icon}
                                style={{
                                    backgroundImage: `url(${tab.icon})`,
                                }}
                            />
                            <div className={s.label}>{tab.name}</div>
                        </div>
                    ))}
                </div>
                <div className={s.options}>
                    <div className={s.heading}>{menu.tabs[activeTab].title}</div>
                    <div className={s.optionsContent}>
                        {this[menu.tabs[activeTab].content](menu.tabs[activeTab].argument)}
                    </div>
                    <div className={s.buttons}>
                        {menu.tabs[activeTab].actions &&
                            menu.tabs[activeTab].actions.map((action, index) => (
                                <Button
                                    key={action.method}
                                    thin
                                    color={index > 0 ? 'white' : 'black'}
                                    onClick={() => this[action.method](action.argument)}
                                >
                                    {action.label}
                                </Button>
                            ))}
                    </div>
                </div>
            </>
        );
    }

    renderDialog() {
        return (
            <div className={cx(s.root)}>
                <div className={s.container}>
                    <div className={s.dialog}>
                        <ClickOutHandler onClickOut={this.handleClickOutside}>
                            <div className={cx(s.content)}>{this.renderContentArea()}</div>
                        </ClickOutHandler>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Portal visibility>
                <TransitionGroup>
                    {this.props.visible && (
                        <CSSTransition
                            classNames={{
                                enter: s.fullscreenEnter,
                                enterActive: s.fullscreenEnterActive,
                                exit: s.fullscreenExit,
                                exitActive: s.fullscreenExitActive,
                            }}
                            timeout={{ enter: 400, appear: 0, exit: 400 }}
                        >
                            {this.renderDialog()}
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </Portal>
        );
    }
}

export default FlagDashboard;
