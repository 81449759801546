import { nogento, rallyware } from '@btc-frontend/config';
import firebase from '@btc-frontend/middleware/firebase/init';

export async function generateRallywareSsoLink(newLinks, enrolled90DaysOrMore): Promise<string> {
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);
    const endpoint = `${nogento.base}/auth/third-party-api-codes`;
    const clientBody = {
        clientId: `${rallyware.clientId}`,
        redirectURI: `${rallyware.redirectURI}`,
    };

    const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(clientBody),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    const json = await response.json();
    if (!newLinks) return `${rallyware.redirectURI}?code=${json.data.code}`;

    let counterUPage = '';
    if (enrolled90DaysOrMore) {
        counterUPage = rallyware.trainingPage;
    } else {
        counterUPage = rallyware.homePage;
    }

    return `${rallyware.redirectURI}?code=${json.data.code}&returnUrl=${counterUPage}`;
}
