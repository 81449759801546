import { createTypography } from '@material-ui/core/styles/createTypography';
import { buildVariant } from './buildVariant.js';

const fontFaces = {
    regular: ' Mark, Helvetica, sans-serif',
    serif: ' Chronicle Display, Chronicle, serif',
};

export const typographyVariants = {
    // Regular
    h1: buildVariant(fontFaces.regular, 400, 60, 1, 0),
    h2: buildVariant(fontFaces.regular, 400, 44, 1, 0),
    h3: buildVariant(fontFaces.regular, 400, 32, 1, 0),
    h4: buildVariant(fontFaces.regular, 400, 22, 1, 0),
    h5: buildVariant(fontFaces.regular, 400, 18, 1, 0),
    h6: buildVariant(fontFaces.regular, 400, 13, 1, 3, 'upperCase'),
    body1: buildVariant(fontFaces.regular, 400, 11, 1, 0),
    body2: buildVariant(fontFaces.regular, 400, 13, 1, 0),

    // Bold
    h1_bold: buildVariant(fontFaces.regular, 700, 60, 1, 0),
    h2_bold: buildVariant(fontFaces.regular, 700, 44, 1, 0),
    h3_bold: buildVariant(fontFaces.regular, 700, 32, 1, 0),
    h4_bold: buildVariant(fontFaces.regular, 700, 22, 1, 0),
    h5_bold: buildVariant(fontFaces.regular, 700, 18, 1, 0),
    h6_bold: buildVariant(fontFaces.regular, 700, 13, 1, 3, 'upperCase'),

    // Serif
    h1_serif: buildVariant(fontFaces.serif, 400, 72, 1, 0),
    h2_serif: buildVariant(fontFaces.serif, 400, 52, 1, 0),
    h3_serif: buildVariant(fontFaces.serif, 400, 36, 1, 0),
    h4_serif: buildVariant(fontFaces.serif, 400, 24, 1, 0),
    h5_serif: buildVariant(fontFaces.serif, 400, 20, 1, 0),
    body1_serif: buildVariant(fontFaces.serif, 400, 18, 1, 0),
    body2_serif: buildVariant(fontFaces.serif, 400, 22, 1, 0),
};
