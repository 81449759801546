import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -9,
    y: -8,
    width: 70,
    height: 70,
};

class Socials extends Component {
    render() {
        return (
            <Icon size={{ width: 51, height: 51 }} {...this.props} altText='Socials' viewBox={viewBox}>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="pop-ups" stroke="#000000">
                        <g id="icon-btc-pop-ups">
                            <path
                                d="M25.8934673,3.98466052 C30.1155952,9.70594981 36.7698055,15.604056 46.3110203,20.6572144 L46.73873,20.8837353 L46.7085394,21.3667837 C46.1494267,30.3125875 46.5268856,40.416487 48.6857056,48.6906555 L48.9307908,49.63 L1.75139566,49.63 L1.99388287,48.6922397 C3.80493649,41.6884306 4.80768465,32.5200938 4.17123048,21.4229441 L4.14285349,20.9281658 L4.58687603,20.7080414 C11.8030421,17.1306219 19.2857661,11.5864018 24.6785911,3.99562782 L25.2784563,3.1512761 L25.8934673,3.98466052 Z M25.2738879,2.2257504 C23.0420711,5.67947807 20.2388138,8.83987054 16.9923218,11.7119635 C13.9635369,14.3914563 10.721151,16.6734498 7.47827604,18.5647505 C6.34243954,19.2271899 5.28688232,19.7938542 4.33821173,20.2654254 C4.07267353,20.3974208 3.83045873,20.5144683 3.61324953,20.616608 C4.22168734,29.9227803 3.64491827,38.0948568 2.30653032,44.9811283 C1.96777761,46.7240783 1.60431226,48.2461265 1.24011424,49.5387398 C1.18093441,49.7487811 1.12363379,49.9459028 1.06850756,50.13 L49.6167465,50.13 C48.8296635,47.5186144 48.2335569,44.5876909 47.8094495,41.3915293 C46.9752324,35.1046967 46.8586885,28.4439079 47.1588328,22.382293 C47.1901547,21.7497274 47.2237385,21.1849562 47.2573602,20.6952855 C47.2598632,20.6588314 47.2622989,20.6237465 47.2646631,20.5900444 C44.8481972,19.3408193 42.5808495,18.0121571 40.4581989,16.6141584 C35.3545791,13.2528639 31.29844,9.64706521 28.1836274,6.03606214 C27.0893824,4.76750366 26.1903257,3.58717935 25.4730465,2.5249042 C25.4036263,2.42209438 25.3372516,2.3223508 25.2738879,2.2257504 Z"
                                id="Shape"
                                strokeWidth="2"
                                fillRule="nonzero"
                            />
                            <path
                                d="M25.3,3.22 C25.3,3.22 25.09,15.41 15.16,20.36"
                                id="Path"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M25.29,3.35 C25.29,3.35 25.51,15.38 35.44,20.35"
                                id="Path"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M4.28,20.83 L46.4,20.83"
                                id="Path"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M14.56,20.83 C14.56,20.83 15.59,35.32 11.71,49.76"
                                id="Path"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M36.62,20.83 C36.62,20.83 35.79,35.32 39.67,49.76"
                                id="Path"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <polyline
                                id="Path"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                points="20.91 49.88 21.33 34.1 29.76 34.1 29.76 49.88"
                            />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Socials;
