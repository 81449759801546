const passwordStrictLength = 10;

export function password(field = {}) {
    const { value } = field;
    return {
        value,
        isValid: value.length > 5,
    };
}

// Requires an array from Contentful of symbols that are allowed &&
// an array of words that cannot be used.
export function passwordStrict(field = {}, form = {}) {
    const { value = '' } = field;
    return {
        value,
        isValid:
            containsUpperLowerLetter(value) &&
            containsNumber(value) &&
            containsSymbol(value, form) &&
            validLength(value) &&
            hasNoFirstLastName(value, form) &&
            hasNoForbiddenWords(value, form),
        criteria: {
            containsUpperLowerLetter: containsUpperLowerLetter(value),
            containsNumber: containsNumber(value),
            containsSymbol: containsSymbol(value, form),
            validLength: validLength(value),
            hasNoFirstLastName: hasNoFirstLastName(value, form),
            hasNoForbiddenWords: hasNoForbiddenWords(value, form),
        },
    };
}

// Password must contain an uppercase and a lowercase character.
export function containsUpperLowerLetter(value) {
    return /[a-z]/.test(value) && /[A-Z]/.test(value);
}

// Password must contain at least 1 number.
export function containsNumber(value) {
    return /\d/.test(value);
}

// Password must contain at least 1 symbol (specified in Contentful).
export function containsSymbol(value, form = {}) {
    const symbols = (form.config && form.config.symbols) || [];
    if (!symbols.length) return true;
    let checkForSymbols = [];
    symbols.forEach(symbol => checkForSymbols.push(value.includes(symbol)));
    return checkForSymbols.includes(true);
}

// Password must have a length greater than or equal to 10.
export function validLength(value) {
    return value.length >= passwordStrictLength;
}

// Password does not include first or last name of user.
export function hasNoFirstLastName(value, form = {}) {
    const firstName =
        form.firstName || (form.fields && form.fields.firstName && form.fields.firstName.value);
    const lastName =
        form.lastName || (form.fields && form.fields.lastName && form.fields.lastName.value);
    if (!firstName || !lastName) return true;
    const firstNameLower = firstName.toLowerCase();
    const lastNameLower = lastName.toLowerCase();
    const valueLower = value.toLowerCase();
    return !valueLower.includes(firstNameLower) && !valueLower.includes(lastNameLower);
}

export function nameValidation(value, form = {}) {
    const passwordValue =
        (form.fields && form.fields.passwordStrict && form.fields.passwordStrict.value) || '';
    return {
        value,
        isValid: hasNoFirstLastName(passwordValue, form),
    };
}

// Password does not include certain words (specified in Contentful).
export function hasNoForbiddenWords(value, form = {}) {
    const words = (form.config && form.config.forbiddenWords) || [];
    const valueLower = value.toLowerCase();
    if (!words.length) return true;
    let checkForWords = [];
    words.forEach(word => checkForWords.push(valueLower.includes(word.toLowerCase())));
    return !checkForWords.includes(true);
}

export function forbiddenWordValidation(value, form = {}) {
    const passwordValue =
        (form.fields && form.fields.passwordStrict && form.fields.passwordStrict.value) || '';
    return {
        value,
        isValid: hasNoForbiddenWords(passwordValue, form),
    };
}

export function passwordConfirm(field = {}, form = {}) {
    const { value } = field;
    const password =
        form.fields &&
        ((form.fields.password && form.fields.password.value) ||
            (form.fields.passwordStrict && form.fields.passwordStrict.value));

    return {
        value,
        isValid: value === password,
    };
}
