import React, { Component } from 'react';
import { observer } from 'mobx-react';

const IOIndicator = observer(props => {
    const { label, currentLoadingParams } = props;
    const isLoadingDone = !currentLoadingParams;
    return (
        <div>
            {label}
            {!isLoadingDone ? JSON.stringify(currentLoadingParams) : 'done'}
        </div>
    );
});

export default IOIndicator;
