/* translationGroup: navigation */
import React from 'react';
import { isEmpty, omit, camelCase } from 'lodash';
import { routes, asyncModels, locales, flags, bccomDomain } from '@btc-frontend/config';
import {
    parseContentURL,
    hostnameStrip,
    safelyUnnestQueryString,
    bccomLocalizedRedirector,
} from '@btc-frontend/middleware/utils/url';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { timeOut } from '@btc-frontend/middleware/utils/time';

import { map301s } from './customActions/map301s';
import { rootAction } from './customActions/rootAction';
import {
    routeGroups,
    routeKeys as keys,
    teamDashboardRoutes,
    customerDashboardRoutes,
} from '@btc-frontend/middleware/constants/routes';

import IconInventory from '@counter-ui/Icon/Icons/Inventory.jsx';
import IconTools from '@counter-ui/Icon/Icons/Tools.jsx';
import IconTicket from '@counter-ui/Icon/Icons/Ticket.jsx';

// nested configuration for all standardized routes.
const routeConfig: (
    t: (text: string, options) => string,
    options: {
        localeCode: string;
    },
) => IRoute.IRouteConfig[] = (
    t = (k, { defaultValue } = {}) => defaultValue || k,
    { localeCode } = {
        localeCode: 'en-US',
    },
) => [
        /*
         * SCHEMA:
         *  to: URI path piece
         *  preLoadCallBack: custom processing at the beginnning of the route. Good place to set dynamic route template props.
         *  dependencies: any containers or stores needed to load.
         *  key: used to get the translated name of the path via contentful.
         *  component: the container for the path.
         *  redirectTo: instead of rendering a component, redirects to another route.
         *  preRenderCallback: optional route specific logic before rendering.
         *  children: additional nested routes.
         *  props: custom props for the rendered component.
         *  navLink: options for adding this link to the nav bar.
         *    {
         *       icon: reference the icon for this link
         *       position: Tools/ *or* 5
         *    }
         *  requireAuth: must be logged in.(bool)
         *  menuParent: the key for the parent item to nest within.
         *  icon: the icon to be displayed in menu.
         *  variables: named variables path pieces to extract after the current piece.
         *  contentfulSpace (DEPRECATED): set the space to connect to in contentful. Also currently hints if i18n-contentful translations are to be loaded.
         */

        {
            to: '/',
            preLoadCallBack: rootAction,
            component: 'HomePageContainer',
            name: t('Home'),
            sidebar: true,
            key: keys.hompage,
            dependencies: {
                contentful: [
                    asyncModels.homePage,
                    'translationsNewsfeed',
                    'translationsTeam',
                    'feedAlert',
                    'feedItemTag',
                    'translationsNudgeList',
                    'translationsMyAccount',
                ],
                stores: ['NewsfeedStore', 'AccountStore', 'ToolsStore', 'PerformanceStore'],
            },
            disableBreadcrumbs: true,
            contentfulSpace: 'btc',
            preRenderCallback: ({ context }) => context.onSetMeta('robots', 'noindex'),
            children: [
                {
                    key: 'notFound',
                    to: '/404',
                    component: 'NotFoundPage',
                    disableAuth: true,
                    disableLayout: false,
                    sidebar: false,
                },

                    {
                        to: '/account',
                        menuParent: keys.root,
                        dependencies: {
                            contentful: [
                                asyncModels.account,
                                'configForms',
                                'translationsMyAccount',
                                'translationsMyAccountDashboard',
                                'translationsMyAccountHeader',
                            ],
                            stores: ['AccountStore'],
                        },
                        routeGroup: [routeGroups.accountLinks],
                        key: keys.account.root,
                        component: 'AccountContainer',
                        contentfulSpace: 'btc',
                        name: t('myAccount', { defaultValue: `My Account` }),
                        navName: t('accountOverview', { defaultValue: `Account Overview` }),
                        children: [
                            {
                                to: '/aboutme',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccountAboutMe',
                                        'translationsMyAccount',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account, routeGroups.accountLinks],
                                key: keys.account.about,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('aboutMe', { defaultValue: `About Me` }),
                            },
                            {
                                to: '/contactinfo',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccount',
                                        'translationsContactInfo',
                                        'translationsErrors',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account, routeGroups.accountLinks],
                                key: keys.account.contactInfo,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('myContactInfo', { defaultValue: `My Contact Info` }),
                            },
                            {
                                to: '/contactinfo?changePassword',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccount',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account],
                                key: keys.account.contactInfoPassword,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('myContactInfo', { defaultValue: `My Contact Info` }),
                            },
                            {
                                to: '/addresses',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccount',
                                        'translationsMyAccountAddressBook',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account, routeGroups.accountLinks],
                                key: keys.account.addresses,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('myAddresses', { defaultValue: `My Addresses` }),
                            },
                            {
                                to: '/disbursementprofile',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccount',
                                        'translationsMyAccountDisbursement',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account, routeGroups.accountLinks],
                                key: keys.account.disbursement,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('directDeposit', { defaultValue: `Direct Deposit` }),
                            },
                            {
                                to: '/paymentprofile',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccount',
                                        'translationsMyAccountPaymentProfile',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account, routeGroups.accountLinks],
                                key: keys.account.payment,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('paymentProfiles', { defaultValue: `Payment Profiles` }),
                            },
                            {
                                to: '/photoupload',
                                dependencies: {
                                    contentful: [
                                        asyncModels.account,
                                        'configForms',
                                        'translationsMyAccount',
                                        'translationsMyAccountPhotoProfile',
                                    ],
                                    stores: ['AccountStore'],
                                },
                                routeGroup: [routeGroups.account],
                                key: keys.account.photoUpload,
                                component: 'AccountContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.account.root,
                                name: t('uploadPhoto', { defaultValue: `Upload Photo` }),
                            },
                        ],
                    },
                    {
                        to: '/performance',
                        key: keys.performance.root,
                        menuParent: keys.root,
                        contentfulSpace: 'btc',
                        component: 'PerformanceDashboardContainer',
                        name: t('Performance'),
                        sidebar: true,
                        dependencies: {
                            contentful: [
                                'translationsPerformanceDashboard',
                                'translationsPerformanceBusinessBuilder',
                                'translationsDates',
                            ],
                            stores: ['PerformanceStore'],
                        },
                        children: [
                            {
                                to: '/dashboard',
                                key: keys.performance.dashboard,
                                menuParent: keys.performance.root,
                                contentfulSpace: 'btc',
                                component: 'PerformanceDashboardContainer',
                                name: t('Dashboard'),
                                sidebar: true,
                                dependencies: {
                                    contentful: [
                                        'translationsPerformanceDashboard',
                                        'translationsPerformanceBusinessBuilder',
                                        'translationsDates',
                                    ],
                                    stores: ['PerformanceStore'],
                                },
                            },
                            {
                                to: '/incentives',
                                key: keys.performance.incentives,
                                menuParent: keys.performance.root,
                                contentfulSpace: 'btc',
                                component: 'IncentivesContainer',
                                name: t('incentives', { defaultValue: `Incentives` }),
                                sidebar: true,
                                dependencies: {
                                    contentful: ['translationsIncentives'],
                                    stores: [],
                                },
                            },
                            {
                                to: '/growthtracker',
                                key: keys.performance.growthTracker,
                                menuParent: keys.performance.root,
                                contentfulSpace: 'btc',
                                component: 'GrowthTrackerContainer',
                                name: 'Growth Tracker',
                                sidebar: true,
                                dependencies: {
                                    contentful: ['translationsGrowthTracker'],
                                    stores: ['PerformanceStore'],
                                },
                            },
                            {
                                to: ['/counting/:uid', '/counting'],
                                variables: ['uid'],
                                name: t('startCounting', { defaultValue: `Start Counting` }),
                                key: keys.performance.startCounting,
                                menuParent: keys.performance.root,
                                sidebar: true,
                                component: 'StartCountingContainer',
                                dependencies: {
                                    stores: ['TeamStore', 'PerformanceStore'],
                                    contentful: [
                                        'translationsStartCountingBase',
                                        'translationsStartCountingMSOptions',
                                        'translationsStartCountingMilestones',
                                        'links',
                                        'translationsTeamTable',
                                        'translationsStartCountingTable',
                                    ],
                                },
                            },
                            {
                                to: '/earnings',
                                name: t('Earnings'),
                                key: keys.performance.earnings,
                                menuParent: keys.performance.root,
                                sidebar: true,
                                component: 'EarningsContainer',
                                contentfulSpace: 'btc',
                                dependencies: {
                                    contentful: [
                                        asyncModels.earnings,
                                        'translationsEarnings',
                                        'translationsEarningsDirOverride',
                                        'translationsEarningsLevelOverride',
                                        'translationsEarnings',
                                        'translationsDates',
                                        'translationsEarningsRetail',
                                    ],
                                    stores: ['EarningsStore'],
                                },
                            },
                        ],
                    },
                    {
                        to: '/orders',
                        key: keys.orders.root,
                        menuParent: keys.root,
                        contentfulSpace: 'btc',
                        component: '',
                        sidebar: true,
                        name: t('Orders'),
                        redirectTo: '/orders/orderHistory',
                        children: [
                            {
                                key: keys.orders.quickOrder,
                                name: t('quickOrder', { defaultValue: `Quick Order` }),
                                menuParent: keys.orders.root,
                                sidebar: true,
                                redirectTo: (() => bccomLocalizedRedirector('/quick-order', localeCode))(),
                            },
                            {
                                to: '/orderHistory',
                                name: t('orderHistory', { defaultValue: `Order History` }),
                                key: keys.orders.orderHistory,
                                menuParent: keys.orders.root,
                                contentfulSpace: 'btc',
                                dependencies: {
                                    stores: ['OrderHistoryStore'],
                                    contentful: ['translationsOrderHistory'],
                                },
                                component: 'OrderHistoryContainer',
                                sidebar: true,
                            },
                            {
                                to: '/order/:id',
                                variables: ['id'],
                                key: keys.clientele.orderDetail,
                                component: 'OrderDetailPage',
                                name: t('orderDetail', { defaultValue: `Order Detail` }),
                                menuParent: keys.orders.root,
                                dependencies: {
                                    stores: ['OrderDetailStore'],
                                    contentful: [
                                        'translationsOrderDetail',
                                        'translationsOrderDetailLineItem',
                                    ],
                                },
                                contentfulSpace: 'btc',
                                sidebar: false,
                            },
                        ],
                    },
                    {
                        to: '/customers',
                        dependencies: {
                            contentful: [
                                asyncModels.nudges,
                                'translationsNudgeList',
                                'translationsCustomer',
                                asyncModels.customerDashboard,
                            ],
                            stores: ['MyCustomersStore', 'CustomerDashboardStore'],
                        },
                        key: keys.clientele.root,
                        menuParent: keys.root,
                        component: 'CustomerDashboardContainer',
                        contentfulSpace: 'btc',
                        name: t('Customers'),
                        sidebar: true,
                        redirectTo: '/customers/my-customers',
                        children: [
                            {
                                to: Object.values(customerDashboardRoutes),
                                dependencies: {
                                    contentful: [
                                        'translationsNudgeList',
                                        'translationsCustomer',
                                        asyncModels.customerDashboard,
                                    ],
                                    stores: ['MyCustomersStore', 'CustomerDashboardStore'],
                                },
                                routeGroup: [routeGroups.clientele],
                                key: keys.clientele.dashboard,
                                component: 'CustomerDashboardContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.clientele.root,
                                name: t('topCustomers', { defaultValue: `Top Customers` }),
                                sidebar: true,
                            },
                            {
                                to: '/member-breakdown',
                                dependencies: {
                                    contentful: [
                                        'translationsNudgeList',
                                        'translationsCustomer',
                                        asyncModels.customerDashboard,
                                    ],
                                    stores: ['MyCustomersStore', 'CustomerDashboardStore'],
                                },
                                routeGroup: [routeGroups.clientele],
                                key: keys.clientele.clientBreakdown,
                                component: 'CustomerDashboardContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.clientele.root,
                                name: t('clientBreakdown', { defaultValue: `Client Breakdown` }),
                                sidebar: false,
                            },
                            {
                                to: '/reorder-rate',
                                dependencies: {
                                    contentful: [
                                        'translationsNudgeList',
                                        'translationsCustomer',
                                        asyncModels.customerDashboard,
                                    ],
                                    stores: ['MyCustomersStore', 'CustomerDashboardStore'],
                                },
                                routeGroup: [routeGroups.clientele],
                                key: keys.clientele.reorderRate,
                                component: 'CustomerDashboardContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.clientele.root,
                                name: t('reorderRate', { defaultValue: `Reorder Rate` }),
                                sidebar: false,
                            },
                            {
                                to: '/expiring-credit',
                                dependencies: {
                                    contentful: [
                                        'translationsNudgeList',
                                        'translationsCustomer',
                                        asyncModels.customerDashboard,
                                    ],
                                    stores: ['MyCustomersStore', 'CustomerDashboardStore'],
                                },
                                routeGroup: [routeGroups.clientele],
                                key: keys.clientele.expiringCredit,
                                component: 'CustomerDashboardContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.clientele.root,
                                name: 'Expiring Credit',
                                sidebar: false,
                            },
                            {
                                to: '/my-customers',
                                dependencies: {
                                    contentful: ['translationsMyCustomers'],
                                    stores: ['MyCustomersStore'],
                                },
                                key: keys.clientele.myCustomers,
                                component: 'MyCustomersContainer',
                                menuParent: keys.clientele.root,
                                name: t('myCustomers', { defaultValue: `My Customers` }),
                                sidebar: true,
                            },

                            {
                                to: '/customer/:id',
                                dependencies: {
                                    contentful: [
                                        ...asyncModels.nudges.models,
                                        'translationsCustomerDetail',
                                        'translationsCustomerDetailPage',
                                    ],
                                    stores: ['CustomerDetailStore', 'OrderHistoryStore'],
                                },
                                variables: ['id'],
                                key: keys.clientele.customer,
                                component: 'CustomerDetailPage',
                                contentfulSpace: 'btc',
                                menuParent: keys.clientele.root,
                                name: t('customerDetail', { defaultValue: `Customer Detail` }),
                                sidebar: false,
                            },
                        ],
                    },
                    {
                        to: '/prospects',
                        key: keys.prospects.root,
                        dependencies: {
                            contentful: ['translationsProspects'],
                            stores: ['ProspectStore'],
                        },
                        menuParent: keys.root,
                        component: 'ProspectContainer',
                        contentfulSpace: 'btc',
                        name: t('Prospects'),
                        children: [
                            {
                                dependencies: {
                                    contentful: ['translationsProspects'],
                                    stores: ['ProspectStore', 'GeneralStore'],
                                },
                                to: '/my-prospects',
                                routeGroup: [routeGroups.prospectTabs],
                                key: keys.prospects.table,
                                component: 'ProspectContainer',
                                menuParent: keys.prospects.root,
                                name: t('myProspects', { defaultValue: `My Prospects` }),
                                sidebar: true,
                            },
                            {
                                dependencies: {
                                    contentful: ['translationsProspects'],
                                    stores: ['ProspectStore', 'GeneralStore'],
                                },
                                to: '/add',
                                routeGroup: [routeGroups.prospectTabs],
                                key: keys.prospects.add,
                                component: 'ProspectContainer',
                                menuParent: keys.prospects.root,
                                name: t('addProspect', { defaultValue: `Add Prospect` }),
                                sidebar: true,
                            },
                            {
                                dependencies: {
                                    contentful: ['translationsProspects'],
                                    stores: ['ProspectStore', 'GeneralStore'],
                                },
                                to: '/edit/:id',
                                variables: ['id'],
                                key: keys.prospects.edit,
                                component: 'ProspectContainer',
                                menuParent: keys.prospects.root,
                                name: t('editProspect', { defaultValue: `Edit Prospect` }),
                            },
                            {
                                dependencies: {
                                    contentful: ['translationsProspects'],
                                    stores: ['ProspectStore'],
                                },
                                to: '/detail/:id',
                                variables: ['id'],
                                key: keys.prospects.detail,
                                component: 'ProspectContainer',
                                menuParent: keys.prospects.root,
                                name: t('prospectDetail', { defaultValue: `Prospect Detail` }),
                            },
                        ],
                    },
                    {
                        to: '/team',
                        dependencies: {
                            contentful: [
                                asyncModels.nudges,
                                'translationsTeam',
                                'translationsTeamRetention',
                                'translationsTeamRecognition',
                            ],
                            stores: ['TeamStore'],
                        },
                        key: keys.team.root,
                        menuParent: keys.root,
                        component: 'TeamContainer',
                        contentfulSpace: 'btc',
                        name: t('Team'),
                        redirectTo: '/team/recruitment',
                        sidebar: true,
                        children: [
                            {
                                to: Object.values(teamDashboardRoutes),
                                dependencies: {
                                    contentful: [
                                        'translationsNudgeList',
                                        'translationsTeam',
                                        'translationsTeamRetention',
                                        'translationsTeamRecognition',
                                    ],
                                    stores: ['TeamStore'],
                                },
                                routeGroup: [routeGroups.team],
                                key: keys.team.dashboard,
                                component: 'TeamContainer',
                                contentfulSpace: 'btc',
                                name: t('Dashboard'),
                                menuParent: keys.team.root,
                                sidebar: true,
                            },
                            {
                                to: '/downline-report',
                                dependencies: {
                                    contentful: [
                                        'translationsTeamTable',
                                        'translationsTeam',
                                        'translationsTeamRetention',
                                        'translationsTeamRecognition',
                                        'translationsDates',
                                    ],
                                    stores: ['TeamStore', 'GeneralStore'],
                                },
                                routeGroup: [routeGroups.team],
                                key: keys.team.downlineReport,
                                component: 'TeamContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.team.root,
                                name: t('myDownline', { defaultValue: 'My Downline' }),
                                sidebar: true,
                            },
                            {
                                to: '/watchlist',
                                dependencies: {
                                    contentful: [
                                        'translationsTeamTable',
                                        'translationsTeam',
                                        'translationsTeamRetention',
                                        'translationsTeamRecognition',
                                    ],
                                    stores: ['TeamStore'],
                                },
                                routeGroup: [routeGroups.team],
                                key: keys.team.watchlist,
                                component: 'TeamContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.team.root,
                                name: t('Watchlist'),
                                sidebar: true,
                            },
                            {
                                to: '/activity-requirements',
                                dependencies: {
                                    contentful: [],
                                    stores: ['TeamStore'],
                                },
                                routeGroup: [routeGroups.team],
                                key: keys.team.activity,
                                component: 'TeamContainer',
                                contentfulSpace: 'btc',
                                menuParent: keys.team.root,
                                name: 'Activity Requirements',
                                sidebar: true,
                            },
                        ],
                    },
                    {
                        name: t('popups', { defaultValue: `Pop-ups` }),
                        menuParent: keys.root,
                        key: keys.socials.root,
                        sidebar: true,
                        children: [
                            {
                                menuParent: keys.socials.root,
                                key: keys.socials.dashboard,
                                name: t('Dashboard'),
                                sidebar: true,
                                redirectTo: (() =>
                                    bccomLocalizedRedirector('/pop-ups/dashboard', localeCode))(),
                            },
                            {
                                menuParent: keys.socials.root,
                                key: keys.socials.details,
                                name: t('details', { defaultValue: `Details` }),
                                sidebar: false,
                                redirectTo: (() =>
                                    bccomLocalizedRedirector('/pop-ups/details', localeCode))(),
                            },
                            {
                                menuParent: keys.socials.root,
                                key: keys.socials.other,
                                name: t('bookAnInstoreEvent', { defaultValue: `Book an in-store event` }),
                                sidebar: false,
                                redirectTo: 'https://beautycounterconsultanthostedeventspage.as.me/',
                            },
                        ],
                    },
                    {
                        to: '/newsfeed',
                        dependencies: {
                            contentful: ['feedItemTag', 'translationsNewsfeed'],
                            stores: ['NewsfeedStore'],
                        },
                        menuParent: keys.root,
                        key: keys.newsFeed,
                        component: 'NewsfeedContainer',
                        contentfulSpace: 'btc',
                        disableBreadcrumbs: true,
                        sidebar: true,
                        name: t('Newsfeed'),
                    },
                    {
                        to: '/tools',
                        menuParent: keys.root,
                        key: keys.tools.root,
                        component: '',
                        contentfulSpace: 'btc',
                        name: t('Tools'),
                        redirectTo: '/tools/digital-assets',
                        sidebar: true,
                        icon: {
                            component: IconTools,
                            height: 22,
                            width: 31,
                        },
                        children: [
                            {
                                to: [
                                    '/digital-assets',
                                    '/digital-assets/category/:catId',
                                    '/digital-assets/:id',
                                ],
                                variables: ['catId', 'id'],
                                name: t('digitalAssets', { defaultValue: `Digital Assets` }),
                                key: keys.tools.digitalAssets,
                                menuParent: keys.tools.root,
                                contentfulSpace: 'btc',
                                dependencies: {
                                    contentful: ['translationsTools'],
                                    stores: ['ToolsStore'],
                                },
                                component: 'ToolsContainer',
                                sidebar: true,
                            },
                            {
                                key: keys.tools.printedAssets,
                                name: t('printedMaterials', { defaultValue: `Printed Materials` }),
                                menuParent: keys.tools.root,
                                sidebar: true,
                                redirectTo: t('printedmaterialslink', {
                                    defaultValue: 'https://beautycounter.go.customprintcenter.com',
                                }),
                            },
                        ],
                    },
                    {
                        to: '/inventory/inventory-updates',
                        menuParent: keys.root,
                        key: keys.inventory.root,
                        component: 'InventoryContainer',
                        name: t('inventoryUpdates', { defaultValue: `Inventory Updates` }),
                        dependencies: {
                            contentful: ['productCategory', 'translationsInventory'],
                        },
                        sidebar: flags.inventoryPage,
                        icon: {
                            component: IconInventory,
                            height: 20,
                            width: 33,
                        },
                    },
                    {
                        to: '/lead',
                        menuParent: keys.root,
                        key: keys.lead.root,
                        component: '',
                        contentfulSpace: 'btc',
                        name: t('lead2021', { defaultValue: `LEAD 2021` }),
                        redirectTo: '/lead',
                        sidebar: true,
                        icon: {
                            component: IconTicket,
                            height: 22,
                            width: 31,
                        },
                        children: [
                            {
                                key: keys.lead.tickets,
                                name: t('buyTickets', { defaultValue: `Buy Tickets` }),
                                menuParent: keys.tools.root,
                                sidebar: true,
                                redirectTo: t('httpbclead21com', { defaultValue: `http://bclead21.com` }),
                            },
                        ],
                    },
                    {
                        to: '/consultant/:id',
                        dependencies: {
                            contentful: [
                                ...asyncModels.nudges.models,
                                'translationsConsultantDetail',
                                'translationsTableView',
                                'translationsTeamTable',
                                'translationsTeam',
                            ],
                            stores: ['TeamStore'],
                        },
                        variables: ['id'],
                        component: 'ConsultantDetailPage',
                        contentfulSpace: 'btc',
                        key: keys.consultant,
                        menuParent: keys.root,
                        name: t('consultantDetail', { defaultValue: `Consultant Details` }),
                        sidebar: false,
                    },
                    {
                        to: '/login',
                        dependencies: {
                            contentful: ['translationsLoginPage'],
                        },
                        component: 'LoginPage',
                        key: keys.login,
                        disableAuth: !flags.ssoLogin,
                        disableLayout: true,
                        contentfulSpace: 'btc',
                        disableOverride: ['RenewalOverrideContainer'],
                    },
                    {
                        to: '/logout',
                        redirectTo: routes.LOGIN,
                        preRenderCallback: ({ stores }) => stores.userStore.logout(),
                        component: 'LoginPage',
                        key: keys.logout,
                        routeGroup: [routeGroups.accountLinks],
                        name: t('Logout'),
                        disableAuth: true,
                        disableLayout: true,
                        disableOverride: ['RenewalOverrideContainer'],
                    },
                    {
                        key: keys.redirectAction,
                        to: '/redirect',
                        dependencies: {
                            contentful: ['translationsErrors'],
                        },
                        component: 'RedirectPage',
                        disableAuth: true,
                        disableLayout: false,
                        sidebar: false,
                    },
                    {
                        key: keys.zendesk,
                        to: '/zendesk',
                        component: 'ZendeskPage',
                        disableAuth: false,
                        disableLayout: true,
                        sidebar: false,
                    },
                ],
            },
        ];

function appendLocale(path) {
    try {
        const pathCode = path.split('/')[1].split('/')[0];
        let found;
        locales.map(locale => {
            if (locale.code.toLowerCase().includes(pathCode)) found = locale.code.toLowerCase();
        });

        if (found) return `/${found}`;
    } catch (e) {
        console.error(e);
    }
    return '';
}

function validatePage(page, stores) {
    const config = getSafe(() => stores.contentStore.extensions[page.id].data[page.models[0]][0]);

    if (config && config.hide) return false;
    return true;
}

export { routeConfig };
