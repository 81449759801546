import { observable, computed, action, set } from 'mobx';
const { box } = observable;
import { TEAM_FETCH } from '@btc-frontend/constants';
import teamService from '@btc-frontend/middleware/services/user/team';
import {
    getCurrentPeriod,
    getPeriodOffset,
    getPeriodsInPeriodRange,
} from '@btc-frontend/middleware/utils/period';
import { fetchGraphQLFields, ITypeOptions } from '@btc-frontend/middleware/api/graphql';
import { userTitleIds } from '@btc-frontend/middleware/constants/userCodes';
import userStore from '@btc-frontend/stores/userStore';
import flagStore from '@btc-frontend/stores/flagStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import messagingStore from '@btc-frontend/stores/messagingStore';
import accountStore from '@btc-frontend/stores/accountStore';
import navigationStore from '@btc-frontend/stores/navigationStore';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { navLinks } from '@btc-frontend/nav';
import downlineService from './downlines';
import { cloneDeep } from 'lodash';

const teamConfig = {
    isUnitTest: false,
    /* Consultants whose enrollment date falls within this window will be highlighted as new */
    daysAsANewRecruit: 30,
    /* Consultants whose BV is at least this % of their max BV (50% rule) will be highlighted */
    hotListBVPercentile: 0.5,
    /* Consultants all leaderboards will highlight the 10 n consultants in a given category */
    leaderboardSize: 10,
    /* Team Data for the current period will be sorred up to a given number of minutes before being marked as stale. */
    minutesToCacheLiveData: 15,

    bvCap: 2500,
};

class TeamStore {
    private static instance: TeamStore;
    private static exists: boolean = false;
    /*
     * new recruits for the currentPeriod in the USER's downline report
     */
    @observable recruits;

    /*
     * top Recruiters of consultants for the currentPeriod in the USER's downline report
     */
    @observable topRecruiters;

    /*
     * top Recruiters of BoB members for the currentPeriod in the USER's downline report
     */
    @observable topBobRecruiters;

    /*
     * those users celebrating their first ever promotion this currentPeriod in the USER's downline report
     */
    @observable firstPromotion;

    /*
     * top performing consultants who have not maxed out thier earning potential for the currentPeriod in the USER's downline report
     */
    @observable hotList;
    @observable topNV;
    @observable topQV;

    /*
     * users at risk of being downgraded in the near future for the currentPeriod in the USER's downline report
     */
    @observable retention;

    /*
     * all users for the currentPeriod in the USER's downline report
     */
    @observable downline;

    /*
     * a chosen period (format: YYYYMM ) from which to base downline, tree view, and watchlist results
     */
    @observable currentPeriod;

    /*
     * boolean. TeamStore has finished initializing
     */
    @observable finishedLoading;
    @observable downlineReady;

    /*
     * boolean. some error occurred while pulling teamStore data
     */
    @observable failedToLoad;

    /*
     * returns the downline data for a consultant (used in the Downline Table)
     */
    @observable teamDownlineData;

    /*
     * an object detailing commission, recruitment, and title history for users by period
     *
     * eg. to select Carol White's (phoenixId 2) recruitment performance for period Feb 2018 (201802):
     *
     * teamStore.historicPerformance[2][201802].commission
     */
    @observable historicPerformance;
    @observable currentTreeRoot;
    @observable periods;
    @observable context;

    @observable myDownlineIds: number[];
    @observable consultantTreeStructure: any;
    @observable directorTreeStructure: any;
    @observable directorPerformance: any;
    @observable mdTreeStructure: any;
    @observable mdPerformance: any;
    @observable accountDetails: any;
    @observable performance: any;
    @observable watchlist: any;
    @observable accountLevel: { [phoenixId: number]: number };
    @observable absoluteRootLevel: number;
    @observable viewInTreeConsultant: number;
    @observable treeLoaded;
    @observable isInitial;
    @observable consultantDetail;
    @observable consultantDownline;
    @observable showConsultantDownlineTab;
    @observable consultantDetailLoaded;
    @observable consultantUpline: number;
    @observable topPromotions: any;

    constructor(context = null) {
        this.context = context || teamConfig;
        if (TeamStore.exists && !(context && context.isUnitTest)) return TeamStore.instance;

        TeamStore.instance = this;
        TeamStore.exists = true;

        this.recruits = [];
        this.topRecruiters = [];
        this.topBobRecruiters = [];
        this.firstPromotion = [];
        this.hotList = [];
        this.topNV = [];
        this.topQV = [];
        this.retention = [];
        this.periods = {};
        this.currentTreeRoot = null;
        this.finishedLoading = box(false);
        this.downlineReady = box(false);
        this.historicPerformance = {};
        this.failedToLoad = false;
        this.teamDownlineData = null;
        this.currentPeriod = getCurrentPeriod();
        this.downline = [];
        this.watchlist = [];
        this.accountDetails = {};
        this.performance = {};
        this.accountLevel = {};
        this.saveUserToWatchlist = this.saveUserToWatchlist.bind(this);
        this.removeUserFromWatchlist = this.removeUserFromWatchlist.bind(this);
        this.absoluteRootLevel = null;
        this.viewInTreeConsultant = null;
        this.treeLoaded = box(false);
        this.mdTreeStructure = {};
        this.directorTreeStructure = {};
        this.consultantTreeStructure = {};
        this.isInitial = false;
        this.consultantDetail = {};
        this.consultantDownline = [];
        this.showConsultantDownlineTab = false;
        this.consultantDetailLoaded = box(false);
        this.consultantUpline = 0;
        this.topPromotions = [];
    }

    @action
    async init() {
        if (!this.isInitial && userStore.isLogged) {
            set(this, 'isInitial', true);
            this.finishedLoading.set(true);
        }
    }

    @computed
    get accountTitle() {
        return accountStore.accountTitle;
    }

    @computed
    get activityRequirements() {
        return flagStore.isFeatureEnabled('activityRequirements');
    }

    @computed
    get splitBobColumns() {
        return flagStore.isFeatureEnabled('splitBobColumns');
    }

    @computed
    get showBVYTD() {
        return flagStore.isFeatureEnabled('showbvytd');
    }

    @computed
    get downlineTopPromotions() {
        return flagStore.isFeatureEnabled('downlineTopPromotions');
    }

    get initialColumns() {
        return [
            'level',
            'firstName',
            'lastName',
            'countryId',
            'qv',
            'bv',
            'pv',
            'cv',
            'nv',
            'fiftyPercentRule',
            this.showBVYTD ? 'bvytd' : '',
            'directorLegs',
            'email',
            'enrollDate',
            'nextRenewalDateUTC',
            'sponsorName',
            'uplineDirectorName',
            'uplineMDName',
            'recognition',
            'paidAs',
            'recruitmentCount',
            this.splitBobColumns ? 'newBobRecruitmentCount' : 'bobRecruitmentCount',
            this.splitBobColumns ? 'bobRenewalCount' : '',
            'flatCount',
            'birthDateUTC',
            'address',
            'city',
            'postalCode',
            'telephone',
            'region',
        ];
    }

    /*
     * See InitializeOnceReady Note:
     * select a new node to be the Current Tree Root of the downline tree
     */
    @action
    updateTreeRoot = (phoenixId: number) => {
        this.currentTreeRoot = phoenixId;
    };

    @action
    updateCurrentPeriod = async (period: number) => {
        await this.treeLoaded.set(false);
        set(this, 'currentPeriod', period);
        await this.treeLoaded.set(true);
    };

    /*
     * get account details and performance by phoenixId
     */
    @action
    findConsultant = (phoenixId: number) => {
        if (!this.accountDetails[phoenixId]) return null;
        return {
            ...this.accountDetails[phoenixId],
            performance: { ...this.performance[phoenixId] },
            level: this.accountLevel[phoenixId],
            isOnWatchlist: !!this.watchlist.find(x => x.phoenixId === phoenixId),
        };
    };

    /*
     * remove a downline consultant from the watchlist
     */
    @action
    async removeUserFromWatchlist(userId: string) {
        await teamService.removeUserIdFromWatchList(Number(userId));
        await this.getWatchlist(true);
    }

    /*
     * save a downline consultant to the watchlist
     */
    @action
    async saveUserToWatchlist(phoenixId: number) {
        await teamService.saveUserIdToWatchList(phoenixId);
        await this.getWatchlist(true);
    }
    /*
     * get watchlist (use `force` to ignore chached result)
     */
    @action
    async getWatchlist(force: boolean = false) {
        const response = await teamService.getWatchList();
        if (!(response && response.success)) {
            // This needs a contentful message
            messagingStore.addMessage('Could not load watchlist data. Retry?');
            console.error('could not load watchlist');
            return false;
        }

        this.watchlist = observable(response.data.watchList);
    }

    // Internal methods
    @action
    fetchDownlineTable = async (
        searchArgs: IBtcTeamService.ITeamSearchArgs,
        consultantDetail = null,
        useConsultantUpline = false,
        country = null,
    ) => {
        // this function is used up update the teamTable values on filter chage.
        interfaceStore.showProgress(TEAM_FETCH);
        let teamDownlineResponse = await teamService.fetchDownlineTable(searchArgs, country);

        if (consultantDetail && useConsultantUpline) {
            teamDownlineResponse = this.buildConsultantDownline(
                teamDownlineResponse,
                this.consultantDetail,
            );
        }

        this.teamDownlineData = teamDownlineResponse;
        interfaceStore.hideProgress(TEAM_FETCH);
        return teamDownlineResponse;
    };

    @action
    fetchDownlineTableWithoutSideEffect = async (
        searchArgs: IBtcTeamService.ITeamSearchArgs,
        country: string = null,
    ) => {
        let teamDownlineResponse = await teamService.fetchDownlineTable(searchArgs, country);
        this.teamDownlineData = teamDownlineResponse;
        return teamDownlineResponse;
    };

    @action
    SetTeamDownlineData(data) {
        this.teamDownlineData = data;
    }

    /**
     * uses search parameters to query nogento and export teamDownline results
     * @param searchArgs
     * @param baseLevel
     */
    @action
    fetchAndExportDownlineTable = async (
        searchArgs: IBtcTeamService.ITeamSearchArgs,
        baseLevel: number = null,
    ) => teamService.fetchDownlineTableExport(searchArgs, baseLevel);

    /**
     * uses teamDownline results from nogento to export csv
     * @param data
     * @param selectedColumns
     * @param baseLevel
     */
    @action
    exportDownlineTable = async (data, selectedColumns, baseLevel: number = null) =>
        teamService.exportDownlineData(data, selectedColumns, baseLevel);

    @action
    searchConsultantTree = async (searchName: string) => {
        return await this.fetchDownlineTable({
            pageNumber: 1,
            perPage: 25,
            textSearch: searchName,
            period: this.currentPeriod,
        });
    };

    @action
    getConsultantDetail = async (id: string) => {
        this.consultantDetailLoaded.set(false);

        // Called on customer detail page. fetch performace data from past year
        const currentPeriod = getCurrentPeriod();
        const periods = getPeriodsInPeriodRange(getPeriodOffset(currentPeriod, -11), currentPeriod);
        const [users, countryVolumes] = await Promise.all([
            this.fetchDownlineUsers([Number(id)], periods),
            this.fetchCountryPerformance(Number(id)),
        ]);

        const { performance, accounts = [{}], accountLevel = {} } = users;
        const account = getSafe(() =>
            accounts[0] && accounts[0].phoenixId ? accounts[0] : accounts[0][id],
        );
        const level = accountLevel[id];
        const incomingPerformance = performance[id] || {};
        if (!account) {
            navigationStore.to({
                url: navLinks.notFound.to,
            });
        } else {
            const titles = getSafe(() => incomingPerformance[getCurrentPeriod()].title) || {};
            this.consultantDetail = {
                ...account,
                ...titles,
                level,
                performance: {
                    countryVolumes,
                    ...(this.consultantDetail.performance || {}),
                    ...cloneDeep(incomingPerformance),
                },
                profileImage: (account || {}).image,
            };

            const searchParams = {
                pageNumber: 1,
                perPage: 13,
                period: this.currentPeriod,
                consultantId: this.consultantDetail.phoenixId,
            };
            const { country } = accountStore;

            const consultantDownline = await this.fetchDownlineTable(
                searchParams,
                this.consultantDetail,
                country,
            );

            this.consultantDownline = this.buildConsultantDownline(
                consultantDownline,
                this.consultantDetail,
            );

            const showTab =
                this.consultantDownline.results &&
                this.consultantDownline.results.length > 1 &&
                this.consultantDownline.results.find(
                    c => c.phoenixId !== this.consultantDetail.phoenixId,
                );

            this.getWatchlist(true);
            this.showConsultantDownlineTab = showTab ? true : false;
        }
        this.consultantDetailLoaded.set(true);
    };

    @action
    buildConsultantDownline = (response, user) => {
        const data = response || {};
        let upline;
        if (data && data.results && Array.isArray(data.results)) {
            upline = data.results.find(c => c.phoenixId === user.phoenixId);
        }
        this.consultantUpline = upline && upline.uplineCount;
        return {
            ...data,
        };
    };

    @action
    fetchDownlineUsers = async (phoenixIds: number[], periods?: number[]) => {
        const {
            accountDetails,
            performance,
            accountLevel,
            accounts,
        } = await downlineService.fetchDownlineUsers({
            phoenixIds,
            periods,
            accountDetails: this.accountDetails,
            performance: this.performance,
            accountLevel: this.accountLevel,
        });
        accountDetails && set(this, 'accountDetails', accountDetails);
        performance && set(this, 'performance', performance);
        accountLevel && set(this, 'accountLevel', accountLevel);

        return {
            accountDetails,
            performance,
            accountLevel,
            accounts,
        };
    };

    getDefaultMinTitleId = titleId => {
        if (titleId >= userTitleIds.managingDirector) return userTitleIds.managingDirector;
        if (titleId >= userTitleIds.director) return userTitleIds.director;
        return userTitleIds.consultant;
    };

    /**
     * returns object containing volume sets for each country for a given consultant.
     * ie. { 1: { 202001: { qv, cv, bv, nv, ... }, ... }, 2: { ... } }
     *
     * sets contain available data drom each period of the
     *   past year ordered olderst to newest
     * @param consultantId - consultan't phoenixId
     */
    fetchCountryPerformance = async (consultantId: number) => {
        const queryList: ITypeOptions = [
            {
                name: 'downlineAccount',
                args: { id: consultantId, periods: [] },
                variables: `performance {
                    volumes{
                      period
                      countryId
                      phoenixId
                      qv
                      cv
                      bv
                      nv
                      pv
                      fiftyPercentRule
                    }
                  }`,
            },
        ];
        const response = await fetchGraphQLFields(queryList, 'consultant-detail-volumes');
        const { success, data } = response;
        if (!(success && data && data.downlineAccount && data.downlineAccount.performance))
            return {};
        const volumes = data.downlineAccount.performance.reduce((index, next) => {
            for (let i = 0; i < next.volumes.length; i++) {
                const countryVolume = next.volumes[i];
                if (!index[countryVolume.countryId]) index[countryVolume.countryId] = {};
                index[countryVolume.countryId][countryVolume.period] = countryVolume;
            }

            return index;
        }, {});
        return volumes;
    };

    fetchTopPromotions = async () => {
        const { userInfo } = userStore;
        // Currently, these are the default arguments for fetching top promotions.
        // This can be later built out where user can select how many percentages, change periods and levels.
        const size = 10;
        const period = getCurrentPeriod();
        const minFactor = 0.75;
        const levels = 3;

        const response = await teamService.fetchTopPromotions(size, period, minFactor, levels);
        if (response.success) {
            // remove user from list and set to topPromotions
            // query response sends back user if they meet top promotions
            this.topPromotions = response.data.filter(
                data => data.phoenixId !== userInfo.phoenixId,
            );
            return true;
        } else {
            return false;
        }
    };

    // SELL-65: As of 11/2022, Consultants, Senior Consultants and Managing Directors are exempt from accumulating NV
    isNvQualified(titleId: number) {
        switch (titleId) {
            case userTitleIds.consultant:
                return false;
            case userTitleIds.srConsultant:
                return false;
            case userTitleIds.managingDirector:
                return false;
            default:
                return true;
        }
    }

    // SELL-65: As of 11/2022, Consultants are exempt from accumulating QV
    isQvQualified(titleId: number) {
        switch (titleId) {
            case userTitleIds.consultant:
                return false;
            default:
                return true;
        }
    }
}

const teamStore = new TeamStore();

export default teamStore;
export { TeamStore };
