import { flagIcon, profileIcon, configIcon } from './tabIcons';

export const menu = {
    tabs: [
        {
            name: 'Features',
            title: 'Feature Flags',
            id: 'features',
            content: 'renderFlags',
            actions: [
                {
                    label: 'Generate random bucketing',
                    method: 'rebucket',
                },
            ],
            icon: flagIcon,
        },
        {
            name: 'Config',
            title: 'Configuration',
            id: 'config',
            content: 'renderConfig',
            icon: configIcon,
        },
    ],
};
