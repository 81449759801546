import { createMuiTheme } from '@material-ui/core/styles';
import { typographyVariants } from './typography/typographyVariants.js';
import palette from './palette';
import zIndex from './zIndex';
import { themeBreakpoints } from './breakpoints';

export default createMuiTheme({
    breakpoints: themeBreakpoints,
    palette,
    typography: typographyVariants,
    zIndex,
});
