export const rootAction = async (
    { path, stores, localizedPath, isInitialRender },
    params,
    config,
) => {
    const pagePath = path
        .replace(/\\/g, '')
        .replace(/\//g, '')
        .toLowerCase();

    /**
     * Contentful custom page lookup
     * Searches through the local URL index
     * If there is a match, it fetches the dynamic page container and
     * the detailed information for that page.
     */

    const newestContent = stores.contentStore.content;
    const locale = stores.localeStore.activeLocale.code;
    let contentKey = false;
    let pageConfig = {};

    for (let page in newestContent.page) {
        if (parseContentURL(page) == path || parseContentURL(page) + '/' == path) {
            contentKey = page;
            pageConfig = newestContent.page[page];
        }
    }

    // Check to see if we have data locally for that page
    // If not, run an async request for that page
    if (
        pageConfig.url &&
        (typeof pageConfig.displayName === 'undefined' ||
            !pageConfig.displayName ||
            pageConfig.activeLocale !== locale)
    ) {
        await stores.contentStore.getPage({
            locale,
            page: pageConfig.url,
            firstLoad: isInitialRender,
        });
        pageConfig = stores.contentStore.content.page[contentKey];
    }

    if (contentKey && pageConfig && !pageConfig.disabled) {
        // Is it a CMS page?
        let widgetsToLoad = [];
        let widgetFetchers = [];
        let widgetPreload = [];

        if (pageConfig.heroWidget) widgetsToLoad = [pageConfig.heroWidget];

        if (pageConfig.widgets) {
            widgetsToLoad = [...widgetsToLoad, ...pageConfig.widgets];
        }

        if (widgetsToLoad && widgetsToLoad.length) {
            widgetFetchers = widgetsToLoad.map(
                widget =>
                    getSafe(() => widget.sys.contentType.sys.id) &&
                    getSafe(() => widgets[widget.sys.contentType.sys.id.replace('widget', '')]()),
            );
        }
        await Promise.all([...widgetFetchers]);

        widgetPreload = widgetsToLoad.map(
            widget =>
                getSafe(() => widget.sys.contentType.sys.id) &&
                widget.sys.contentType.sys.id.replace('widget', ''),
        );

        config = {
            ...config,
            headerConfig: pageConfig,
            component: 'DynamicPage',
            props: () => ({
                id: contentKey,
                firstLoad: __SERVER__ || isInitialRender,
            }),
        };
    }

    // Check for redirect mappings
    const redirect301 = map301s(path, stores);
    if (redirect301.redirect)
        return {
            redirect: redirect301.redirect,
            replace: appendLocale(localizedPath) + (redirect301.replace || ''),
        };
};
