import { synonymList } from './synonyms';

/**
 * Filters products based on a given search input
 */

export function searchForProducts(products, entry) {
    function isVisible(visibility) {
        return visibility === '4' || visibility === '3';
    }

    const hasSKU = products.filter(product =>
        product.variants.length > 1
            ? product.variants.filter(
                  variant =>
                      variant.sku &&
                      variant.sku &&
                      variant.sku.toString().toLowerCase() == entry &&
                      isVisible(variant.visibility),
              ).length
            : product.sku &&
              product.sku.toString().toLowerCase() == entry &&
              isVisible(product.visibility),
    );

    if (hasSKU.length) {
        return hasSKU;
    } else {
        return products.filter(
            product => isVisible(product.visibility) && searchProductNames(product, entry),
        );
    }
}

/**
 * Used by clean slate to set affiliate products to the end.
 */

export function deprioritizeAffiliateProducts(products) {
    try {
        const affiliateIndexes = [];
        const affiliateProducts = products.filter((v, i) => {
            if (typeof v.brandName !== 'undefined') {
                affiliateIndexes.push(i);
                return true;
            }
        });
        affiliateIndexes.forEach((i, j) => {
            products.splice(i - j, 1);
        });
        return [...products, ...affiliateProducts];
    } catch (e) {
        console.info('Failed to process affiliate products');
        return products;
    }
}

export function searchProductNames(product, search: string) {
    let result;

    if (search) {
        const results = searchForWords(search, product.title, product.tags);
        if (results) {
            result = results;
        } else return false;
    } else result = true;

    return result;
}

function searchForWords(actualEntry, title = false, tags = []) {
    let entry = actualEntry.toLowerCase().split(' ');
    if (typeof title == 'string') title = title.toLowerCase().split(' ');

    let results = [],
        safeToPush;

    if (entry[entry.length - 46] == '') entry.pop();

    const synonyms = {};
    title.forEach(item => {
        if (synonymList[item]) synonyms[item] = [...synonymList[item]];
    });

    entry = entry.filter(
        word =>
            word != 'for' &&
            word != 'with' &&
            word != 'and' &&
            word != 'that' &&
            word != 'is' &&
            word != 'people' &&
            word != 'products' &&
            word != '',
    );

    title.forEach(item => {
        entry.forEach(item2 => {
            if (item.includes(item2)) results.push(item);
            else if (synonyms[item] && synonyms[item].includes(item2)) {
                results.push(item);
            }
        });
    });

    // Experimental
    title.forEach(item => {
        Object.keys(entry).forEach(item2 => {
            if (item.includes(entry[item2])) {
                safeToPush = true;

                results.forEach(previousResult => {
                    if (previousResult == item) safeToPush = false;
                });
                if (safeToPush) results.push(item);
            }
        });
    });

    if (results.length >= entry.length) return results.length;
    return false;
}

export function includeSynonyms(tags) {
    let result = [],
        isSynonym = false,
        synonym = '',
        synonyms = [];
    tags.forEach(tag => {
        isSynonym = tag.includes('@');
        if (isSynonym) {
            synonym = tag.split('@')[1].toLowerCase();
            result.push(synonym);
        }
    });
    return result;
}

export function searchList(entry, keyword) {
    let result;

    if (entry) {
        if (searchForWords(entry, keyword)) {
            result = true;
        } else return false;
    }
    return result;
}
