import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { noop } from 'lodash';
import s from './Image.scss';
import cx from 'classnames';
import {
    getProductImageSize,
    getRawImage,
    placeholderImage,
} from '@btc-frontend/middleware/services/products';
import {
    MEDIA_PHONE,
    MEDIA_MOBILE,
    MEDIA_LAPTOP,
    MEDIA_DESKTOP,
    MEDIA_HUGE,
} from '@btc-frontend/middleware/constants/media';

@withStyles(s)
class Image extends Component {
    static propTypes = {
        className: pt.string,
        onLoad: pt.func,
        onClick: pt.func,
        onLoading: pt.func,
        src: pt.string.isRequired,
        noQueries: pt.bool,
    };

    static defaultProps = {
        onLoad: noop,
        onClick: noop,
        onLoading: noop,
        noQueries: false,
    };

    render() {
        const { src, className, small } = this.props;
        const classNames = cx(s.root, s[className], className, 'touch-priority');

        const props = {
            className: classNames,
            onClick: () => this.props.onClick && this.props.onClick(),
            ref: ref => (this.img = ref),
            alt: 'Product Image',
            'data-testid': 'image',
        };

        if (!src || src.includes('placeholder')) return <img src={placeholderImage()} {...props} />;

        if (small)
            return (
                <img
                    {...props}
                    src={`${src}&q=20&w=260`}
                    srcSet={`${src}&q=15&w=220 ${MEDIA_PHONE}w,
                        ${src}&q=40&w=500 2820w`}
                />
            );

        return (
            <img
                {...props}
                src={`${src}&q=60&w=900`}
                srcSet={`${src}&q=50&w=400 ${MEDIA_PHONE}w,
                        ${src}&q=50&w=600 ${MEDIA_MOBILE}w,
                        ${src}&q=60&w=700 ${MEDIA_DESKTOP}w,
                        ${src}&q=60&w=900 2820w`}
            />
        );
    }
}

export default Image;
