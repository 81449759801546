import { map, each, find, get, partial, memoize, cloneDeep, uniq, isPlainObject } from 'lodash';
import jsonStringifySafe from 'json-stringify-safe';

export default function wrapEntryCollection(
    data,
    resolveLinks = true,
    resolveForAllLocales = true,
) {
    const wrappedData = mixinStringifySafe(toPlainObject(cloneDeep(data)));
    if (resolveLinks) {
        const includes = prepareIncludes(wrappedData.includes, wrappedData.items);
        mixinLinkGetters(wrappedData.items, includes, resolveForAllLocales);
    }
    return freezeSys(wrappedData);
}

function mixinStringifySafe(data) {
    return Object.defineProperty(data, 'stringifySafe', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function(serializer = null, indent = '') {
            return jsonStringifySafe(this, serializer, indent, (key, value) => {
                return {
                    sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: value.sys.id,
                        circular: true,
                    },
                };
            });
        },
    });
}

function shouldLinksResolve(query) {
    return !!('resolveLinks' in query ? query.resolveLinks : globalSetting);
}

function prepareIncludes(includes = {}, items) {
    includes.Entry = includes.Entry || [];
    includes.Entry = uniq(includes.Entry.concat(cloneDeep(items)));
    return includes;
}
/**
 * Sets getters on links for a given response
 * @private
 * @param {Array<Entry|Asset|DeletedEntry|DeletedAsset>} items
 * @param {Object} includes - Object with lists of Entry, Asset, DeletedEntry and DeletedAsset
 */
function mixinLinkGetters(items, includes) {
    const linkGetter = memoize(getLinksFromIncludes, memoizationResolver);
    each(items, item => {
        if (item.fields) {
            setLocalizedFieldGetters(item.fields, !!item.sys.locale);
        }
    });

    /**
     * If a field does not have a locale defined in sys, the content of that field
     * is an object where the keys are each available locale, and we need to iterate
     * over each of those
     * @private
     * @param {Object} fields - Fields object
     * @param {boolean} hasLocale - If entry has been requested with a locale
     */
    function setLocalizedFieldGetters(fields, hasLocale) {
        if (hasLocale) {
            setFieldGettersForFields(fields);
        } else {
            each(fields, localizedField => setFieldGettersForFields(localizedField));
        }
    }

    /**
     * Sets getters on each link field or list of link fields for each item
     * @private
     * @param {Object} fields - Fields object
     */
    function setFieldGettersForFields(fields) {
        each(fields, (field, fieldKey) => {
            if (Array.isArray(field)) {
                addGetterForLinkArray(field, fieldKey, fields);
            } else {
                addGetterForLink(field, fieldKey, fields);
            }
        });
    }

    /**
     * Sets a getter which resolves the link of the given fieldKey in fields
     * @private
     * @param {Object} field - Field object
     * @param {string} fieldKey
     * @param {Object} fields - Fields object
     */
    function addGetterForLink(field, fieldKey, fields) {
        if (get(field, 'sys.type') === 'Link') {
            Object.defineProperty(fields, fieldKey, {
                get: partial(linkGetter, field),
            });
        }
    }

    /**
     * Sets a getter which resolves the array of links of the given fieldKey in fields
     * @private
     * @param {Array<Object>} field - List field array
     * @param {string} fieldKey
     * @param {Object} fields - Fields object
     */
    function addGetterForLinkArray(listField, fieldKey, fields) {
        if (get(listField[0], 'sys.type') === 'Link') {
            Object.defineProperty(fields, fieldKey, {
                get: function() {
                    return map(listField, partial(linkGetter));
                },
            });
        }
    }

    /**
     * Looks for given link field in includes.
     * If linked entity is not found, it returns the original link.
     * This method shouldn't be used directly, and it's memoized whenever this
     * module's main method is used.
     * This is done to prevent the same link being resolved multiple times.
     * @private
     * @param {Object} field - Field object
     * @return {Object} Field, or link if field not resolved
     */
    function getLinksFromIncludes(field) {
        var link = find(includes[field.sys.linkType], ['sys.id', field.sys.id]);
        if (link && link.fields) {
            setLocalizedFieldGetters(link.fields, !!link.sys.locale);
            return link;
        }
        return field;
    }

    function memoizationResolver(link) {
        return link.sys.id;
    }
}

function toPlainObject(data) {
    return Object.defineProperty(data, 'toPlainObject', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function() {
            return cloneDeep(this);
        },
    });
}

function freezeObjectDeep(obj) {
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (isPlainObject(value)) {
            freezeObjectDeep(value);
        }
    });
    return Object.freeze(obj);
}

function freezeSys(obj) {
    freezeObjectDeep(obj.sys || {});
    return obj;
}
