import { verbose, firebase } from '@btc-frontend/config';
import {
    MEDIA_PHONE,
    MEDIA_MOBILE,
    MEDIA_LAPTOP,
    MEDIA_DESKTOP,
    MEDIA_HUGE,
} from '@btc-frontend/middleware/constants/media';

/**
 * Wrapper getProducts function
 * to prepare API data for the app
 * @param {array} data
 */

export default async function getProducts(data = {}, drafts = false) {
    const processor = require('./productsProcess');
    return processor.default(data, drafts);
}

/**
 * Returns a normalized object of all products and variants by field
 * @param {Array} products
 * @param {String} string
 */

export function normalizeProducts(products, field) {
    let output = {};
    for (let i = 0, len = products.length; i < len; i++) {
        output[products[i][field]] = {
            id: products[i].id,
            selectedVariant: false,
        };
        if (products[i].variants && products[i].variants.length > 1) {
            for (let v = 0, vlen = products[i].variants.length; v < vlen; v++) {
                output[products[i].variants[v][field]] = {
                    id: products[i].id,
                    selectedVariant: products[i].variants[v].id,
                };
            }
        }
    }
    return output;
}

/**
 * Returns the variant ID of a selected product
 * @param {Number} variantId
 * @param {Object} product
 */

export function getVariantId(variantId, product) {
    let result = false;

    for (let i = 0; i < product.variants.length; i++) {
        if (product.variants[i].id == variantId) result = i;
    }
    return result;
}

/**
 * Returns the variant name of a product
 * from the variant id
 * @param {Object} product
 * @param {Number} variantId
 */

export function getVariantNameFromVariantId(product, variantId) {
    let result = false;

    try {
        for (let i = 0; i < product.variants.length; i++) {
            if (product.variants[i].id == variantId)
                result = product.variants[i].swatches.swatchlabel;
        }
    } catch (e) {
        console.log(e);
    }
    return result;
}

/**
 * Returns an array of available product images
 * for switching between variants
 * @param {Array} product
 * @param {String} variant
 */

export function getProductImages(product) {
    let imageOutput = [];

    try {
        if (typeof product.variants[0].swatches != 'undefined') {
            for (let i = 0; i < product.variants.length; i++) {
                imageOutput.push(product.variants[i].images[0]);
            }
        } else {
            return product.images;
        }
    } catch (e) {
        console.log(e);
        return [
            {
                src: '',
            },
        ];
    }

    return imageOutput;
}

/**
 * Returns the product image or images revelent for a variant
 * @param {Array} product
 * @param {Number} variant
 */

export function getThisVariantImage(product, variant = false) {
    let imageOutput = [];

    if (variant && typeof product.variants[0].swatches != 'undefined') {
        for (let i = 0; i < product.variants.length; i++) {
            if (product.variants[i].id == variant) {
                return product.variants[i].images;
            }
        }
    } else {
        return product.images;
    }
}

/**
 * Returns the product image ID
 * @param {Array} images
 * @param {String} variant
 */

export function getThisVariantImageId(images, variant = false) {
    let thisVariant,
        imageId = 0,
        imageOutputLast = [],
        image;

    for (let i = 0; i < images.length; i++) {
        image = images[i];
        if (variant && image.src.includes('variant-')) {
            // found a variant image
            thisVariant = image.src.split('variant-');
            thisVariant = thisVariant[1].split('-');
            if (
                thisVariant[0] ==
                variant
                    .toLowerCase()
                    .replace('.', '')
                    .replace('/', '')
            ) {
                imageId = i;
                break;
            }
        }
    }

    return imageId;
}

/**
 * Returns the variant attributes
 * based on the array key
 * @param {Number} selected
 * @param {Oject} product
 */

export function getVariantKey(selected, product) {
    for (let key = 0; key < product.variants.length; key++) {
        if (product.variants[key].id == selected) return product.variants[key];
    }

    return product;
}

export function preloadProductImages(product = {}, breakpoint) {
    const images = [];

    try {
        product.images.map(image => images.push(getProductImageSize(image.src, breakpoint)));
        if (product.variants && product.variants.length > 1) {
            product.variants.map(variant => {
                variant.images.map(image =>
                    images.push(getProductImageSize(image.src, breakpoint)),
                );
            });
        }
    } catch (e) {
        verbose && __BROWSER__ && console.info(e);
    }
    return images;
}

export function getProductImageSize(src, breakpoint) {
    const output = src;

    if (breakpoint < MEDIA_PHONE) return `${output}&q=40&w=400`;
    else if (breakpoint < MEDIA_MOBILE) return `${output}&q=50&w=600`;
    else if (breakpoint < MEDIA_DESKTOP) return `${output}&q=50&w=750`;
    return `${output}&q=60&w=900`;
}

export function processSrc(src) {
    for (let i = 0; i < firebase.image.length; i++) {
        if (src.includes(firebase.image[i]))
            return (
                '//images.beautycounter.com' +
                src.replace(firebase.image[i], '') +
                `&auto=format&lossless=false`
            );
    }
    return placeholderImage();
}

export function placeholderImage() {
    return '/images/placeholder.jpg';
}

export function getProductsByFlag(products = [], flag) {
    return products.filter(product => product.flags && product.flags.includes(flag));
}

export function separateFreeProducts(products = []) {
    const newProducts = [];
    products.forEach(product => {
        if (!product.discounts) return newProducts.push(product);
        let freeProductCount = 0;
        product.discounts.forEach(promo => promo.free && freeProductCount++);
        if (!freeProductCount) return newProducts.push(product);
        newProducts.push({ ...product, quantity: freeProductCount, totalPrice: 0, free: true });
        if (product.quantity - freeProductCount > 0) {
            newProducts.push({
                ...product,
                quantity: product.quantity - freeProductCount,
                totalPrice: product.totalPrice,
            });
        }
    });
    return newProducts;
}
