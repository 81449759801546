import fetch from '@btc-frontend/middleware/api/fetch';
import staticService from '@btc-frontend/middleware/api/static';
import { getAbsoluteBaseUrl } from '@btc-frontend/middleware/utils/url';
import { baseUrl, phoenix, googleMaps, nogento } from '@btc-frontend/config';

/**
 * Checks for a consultant personal web page
 * using the route name key or consultant ID
 * @param {object} params
 */

export default async function requestPWS({ key, id }) {
    try {
        const endpoint = key ? `${phoenix.pws}?key=${key}` : `${phoenix.pws}/${id}`;
        const response = await fetch(endpoint);
        const json = await response.json();
        if (json.success) return json;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Returns an array of socials for a consultant
 * @param {number} id
 */

export async function getSocials(id, country = 1) {
    const endpoint = `${phoenix.search}/Account/Open/GetOpenSocials`;

    const body = {
        accountid: id,
        countryid: country,
    };

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const json = await response.json();
        if (json.success) return json.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Returns an array of socials for a consultant
 * @param {object} param
 * param = {
        "consultantId":"1084939",
        "firstName":"rey",
        "lastName":"de las Papa Johns",
        "countryId":1,
        "emailAddress":"rey2@papajohns.com",
        "phone":"5551235455",
        "defaultLanguageId":"1"
    }
 *
 */

export async function addProspect(prospect) {
    const endpoint = phoenix.prospect;

    const body = JSON.stringify(prospect);

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            body,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const json = await response.json();
        if (json.success) return json.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Adds a prospect to the "lead wheel", resulting in the account getting created and assigned to
 * a consultant in BTC/Phoenix
 * @param {object} param
 * param = {
     "firstName": "MyLead",
     "lastname": "SampleLast",
     "emailAddress": "NeedToEat@Joes3.com",
     "phone": "8088088088",
     "address1": "12 Address1 No",
     "address2": "34 Address2 Yes",
     "city":"Los Angeles",
     "province": "CA",
     "countryId": "1",
     "defaultLanguageId": "1",
     "postalCode": "90010",
     "comment":"sick!",
     "disclaimerAccepted": true
 }
 *
 */

export async function addLead(lead) {
    const endpoint = phoenix.leads;

    const body = JSON.stringify(lead);

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            body,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        console.log(response);
        const json = await response.json();
        console.log(json);
        if (json.success) return json.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Searches consultant first and last names
 * and returns an array of results
 * @param {string} entry
 * @param {number} limit
 */

export async function searchConsultantNames(entry, limit = 10, country = 1) {
    const endpoint = `${phoenix.search}/Account/Open/QuickNameSearchConsultants`;

    try {
        const response = await fetch(`${endpoint}?q=${entry}&take=${limit}&countryid=${country}`);
        const json = await response.json();
        if (json.success) return json.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function searchConsultantById(entry) {
    const endpoint = `${phoenix.search}/Account/Open/QuickNameSearchConsultants`;
    try {
        const response = await fetch(`${endpoint}?account=${entry}`);
        const json = await response.json();
        if (json.success) return json.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Searches consultants by location using the
 * Google Maps API, then passes the GEO info to PHX
 * and returns an array of results
 * @param {string} entry
 * @param {number} limit
 */

// todo: feature to be removed after launch ds-1099
export async function searchConsultantsByLocation(entry, limit = 10) {
    try {
        const locationRequest = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${entry}&key=${
                googleMaps.key
            }`,
        );
        const location = await locationRequest.json();
        const zip = entry.match(/^(\d{5})?$/) ? entry.match(/^(\d{5})?$/)[0] : null;

        if (!location.results) throw 'no results';

        const radius = 15;
        const geo = location.results[0].geometry;

        const viewport = zip
            ? radius
            : ((geo.viewport.northeast.lat -
                  geo.viewport.southwest.lat +
                  (geo.viewport.northeast.lng - geo.viewport.southwest.lng)) /
                  2) *
              65;

        const body = {
            Latitude: geo.location.lat,
            Longitude: geo.location.lng,
            MaximumDistance: viewport,
            Skip: '0',
            Take: limit,
            PostalCode: zip,
        };

        const url = nogento.geoSearch;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        });

        const json = await response.json();
        if (json.success) return json.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}
