import { bcBlue, bcRed, bcGreen } from './colors';

const palette = {
    type: 'light',
    common: {
        black: '#000000',
        white: '#FFFFFF',
    },
    primaryColor: bcBlue,
    secondaryColor: bcBlue,
    error: bcRed,
    seccess: bcGreen,
    selected: '#000000',
    lightText: '#FFFFFF',
    grey: '#50555B',

    // custom ones used on newsfeed.
    selected: '#000000',
    lightText: '#FFFFFF',
    grey: '#50555B',
    lightGrey: '#D8D8D8',
};

export default palette;
