export function flushStorage() {
    /*
     * Optimizely often accumulates data in the
     * pending events log without pruning it.
     * When this grows, it starts to
     * become excessively slow to parse.
     *
     * We will clear
     * this on startup as we do not need this data to be persisted
     * in most cases due to SPA architecture and the performance
     * cost of the persistence is not worth it.
     */

    if (!__BROWSER__) return;
    try {
        const key = 'fs_optly_pending_events';
        localStorage.getItem(key);
        if (key) localStorage.removeItem(key);
    } catch (e) {
        console.info('LocalStorage not supported.');
    }
}
