import { observable, computed, action, autorun } from 'mobx';
import { partial } from 'lodash';
import Field from './field.js';

/**
 * Form
 * Handles updates, validation, of all fields. Tracked by formStore
 */
export default class Form {
    @observable key;
    @observable persist;
    @observable fields;
    @observable timestamp;
    @observable locale;

    constructor({
        key,
        save,
        locale,
        fields = {},
        persist = false,
        labels = {},
        validations = {},
        config = {},
    }) {
        this.key = key;
        this.persist = persist;
        this.fields = {};
        this.config = config;
        this.locale = locale;
        this.timestamp = Date.now();
        this.validations = validations;
        this.labels = labels;
        this.save = partial(save, key);

        this.add(Object.values(fields));
    }

    @action
    add(fields) {
        fields = Array.isArray(fields) ? fields : [fields];
        fields.forEach(options => {
            if (!(this.fields[options.key] instanceof Field)) {
                if (options.key)
                    this.fields[options.key] = new Field({
                        ...options,
                        label: this.labels[options.label] || options.label || '',
                        locale: this.locale,
                        validations: this.validations,
                        form: this,
                        criteria: {},
                    });
                else
                    throw new Error(
                        'Error in Formstore: Must provide a key when adding a field to a form',
                    );
            }
        });
    }

    @action
    removeFields(fields) {
        fields &&
            fields.length &&
            fields.forEach(key => {
                if (this.fields[key]) {
                    delete this.fields[key];
                }
            });
    }

    @action
    clearField(key) {
        if (this.fields[key]) this.fields[key].value = '';
    }

    @action
    value(key) {
        return this.fields[key].value;
    }

    @computed
    get values() {
        const obj = {};
        Object.keys(this.fields).forEach(key => (obj[key] = this.fields[key].value));
        return obj;
    }

    @action
    field(key) {
        return this.fields[key] || {};
    }

    @action
    clearFields() {
        Object.keys(this.fields).forEach(key => this.fields[key].clearValue());
    }

    @action
    validate() {
        Object.keys(this.fields).forEach(key => this.fields[key].validate());
    }

    @action
    refresh() {
        this.timestamp = Date.now();
    }

    @computed
    get valid() {
        this.timestamp;
        return Object.keys(this.fields).every(key => this.fields[key].valid);
    }
}
