export const SIGN_IN_EVENT_TITLE = 'Sign In';
export const SIGN_IN_OUT_CATEGORY = 'Sign In/Sign Out';
export const SIGN_IN_ACTION = 'Sign In';

export const SIGN_OUT_EVENT_TITLE = 'Sign Out';
export const SIGN_OUT_ACTION = 'Sign Out';

export const LOG_IN_ERROR_REASON = {
    'auth/invalid-email': '$account_unknown',
    'auth/wrong-password': '$wrong_password',
    'auth/user-not-found': '$account_unknown',
};

export const UNKNOWN_LOG_IN_ERROR = '$unknown_error';
export const LOGIN_STATUS_SUCCESS = '$success';
export const LOGIN_STATUS_FAILURE = '$failure';
export const OPERATION_STATUS = {
    SUCCESS: '$success',
    FAILED: '$failure',
    PENDING: '$pending',
};
export const CREDIT_CARD_PAYMENT_TYPE = '$credit_card';
export const SQUARE_PAYMENT_GATEWAY = '$square';

export const UPDATE_ACCOUNT_EVENT_TITLE = 'Update account';
export const UPDATE_PASSWORD_EVENT_TITLE = 'Update Password';
export const UPDATE_PASSWORD_ACTION = 'update password';
export const FORCED_UPDATE_PASSWORD_EVENT_ACTION = 'forced password update';

export const UPDATE_PASSWORD_REASON = {
    USER_UPDATE: '$user_update',
    FORGOT_PASSWORD: '$forgot_password',
    FORCED_RESET: '$forced_reset',
};

export const UPDATE_PASSWORD_CATEGORY = 'Account Information';
export const RESET_PASSWORD_CATEGORY = 'Forgot Password | Beautycounter';
export const ACCOUNT_CREATE_EVENT_TITLE = 'Account Create';
