import generalStore, { GeneralStore } from './generalStore';
import contentStore, { ContentStore } from './contentStore';
import interfaceStore, { InterfaceStore } from './interfaceStore';
import productsStore, { ProductsStore } from './productsStore';
import navigationStore, { NavigationStore } from './navigationStore/navigationStore';
import localeStore, { LocaleStore } from './localeStore';
import messagingStore, { MessagingStore } from './messagingStore';
import sessionStore, { SessionStore } from './sessionStore';
import userStore, { UserStore } from './userStore';
import analyticsStore, { AnalyticsStore } from './analyticsStore';
import formStore, { FormStore } from './formStore';
import searchStore, { SearchStore } from './searchStore';
import teamStore, { TeamStore } from './teamStore';
import nudgeStore, { NudgeStore } from './nudgeStore';
import accountStore, { AccountStore } from './accountStore';
import flagStore, { FlagStore } from './flagStore';

/**
 * Global stores
 *
 * Stores declared in this index are loaded on
 * all pages synchronously and will be
 * available to all components.
 *
 * For stores specific to
 * routes, prefer importing them asynchronously
 * in the router using a <Provider />. Loading too many
 * stores globally will result in too much data
 * being sent on the initial page load.
 */

const storeClasses = {
    GeneralStore,
    ContentStore,
    InterfaceStore,
    ProductsStore,
    NavigationStore,
    LocaleStore,
    MessagingStore,
    SessionStore,
    UserStore,
    AnalyticsStore,
    FormStore,
    SearchStore,
    TeamStore,
    NudgeStore,
    AccountStore,
    FlagStore,
};

export default {
    storeClasses,
    generalStore,
    searchStore,
    localeStore,
    contentStore,
    interfaceStore,
    productsStore,
    navigationStore,
    sessionStore,
    messagingStore,
    userStore,
    analyticsStore,
    formStore,
    teamStore,
    nudgeStore,
    accountStore,
    flagStore,
};

/**
 * Inititalizes base stores with access to other store instances.
 * Should only fire on app init (client) or route init (server).
 */

export function setupStores(inserts = {}) {
    const stores = {};
    stores.stores = stores;
    Object.keys(storeClasses).forEach(key => {
        const ReferenceClass = storeClasses[key];
        if (ReferenceClass)
            stores[key.charAt(0).toLowerCase() + key.slice(1)] = new ReferenceClass();
    });
    Object.keys(stores).forEach(key => {
        Object.keys(stores).forEach(key2 => {
            if (key !== key2) {
                const base = stores[key.charAt(0).toLowerCase() + key.slice(1)];
                base[key2] = stores[key2];
                base.stores = stores;
            }
        });
        if (__SERVER__ && inserts.server && stores[key].server) stores[key].server = inserts.server;
        if (stores[key].ready) stores[key].ready();
    });
    return stores;
}

/**
 * Inject base stores
 * into an async store that does not already have other stores, and
 * in turn expose that store to base stores.
 */

export function injectStores(stores, store, instanceName) {
    Object.keys(stores).forEach(key => {
        if (store[key] !== stores[key] && !store[key]) store[key] = stores[key];
    });
    Object.keys(stores).forEach(key => {
        const name = instanceName || camelCase(store.constructor.name);
        if (stores[key][name] !== store && !stores[key][name]) stores[key][name] = store;
        stores[key].stores && (stores[key].stores[name] = store);
    });
    store.stores = stores;
    if (store.ready) store.ready();
    return store;
}
