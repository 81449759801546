import { firebase as config } from '@btc-frontend/config';
import { firestore } from './settings';

export async function firebaseInit() {
    const [module] = await Promise.all([
        import(/* webpackChunkName: "FirebaseApp" */ 'firebase/app'),
    ]);
    const firebase = module.default;
    if (firebase.apps.length === 0) firebase.initializeApp(config);

    return firebase;
}

/**
 * Wrap Firebase into a singleton so that we only load one instance
 * of its services throughout the duration of the app. We will also
 * only load the module that is required for the service, and nothing more.
 */

class FirebaseApp {
    init = async () => {
        if (!this.app) this.app = await firebaseInit();
    };

    firestore = async () => {
        await Promise.all([
            this.init(),
            import(/* webpackChunkName: "FirebaseFirestore" */ 'firebase/firestore'),
        ]);

        this.db = this.app.firestore();
        this.db.settings(firestore);

        return this.db;
    };

    auth = async () => {
        await Promise.all([
            this.init(),
            import(/* webpackChunkName: "FirebaseAuth" */ 'firebase/auth'),
        ]);

        this.authentication = this.app.auth();

        return this.authentication;
    };

    getToken = async () => {
        return new Promise(async resolve => {
            try {
                const auth = this.authentication || (await this.auth());

                // the getIdToken is not available until firebase finished initializing
                if (!(auth.currentUser && typeof auth.currentUser.getIdToken == 'function')) {
                    auth.onAuthStateChanged(async user => {
                        let id = '';
                        if (user) id = await user.getIdToken();
                        resolve(id);
                    });
                } else {
                    const id = await auth.currentUser.getIdToken();
                    resolve(id);
                }
            } catch (err) {
                console.error(err);
                resolve('');
            }
        });
    };

    getFirebase = () => this.app;
}

const instance = new FirebaseApp();
export default instance;
