import fetch from '@btc-frontend/middleware/api/fetch';
import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';

export async function getPolicy({ phoenixId }) {
    try {
        const auth = await firebase.auth();
        if (!auth.currentUser) return { success: false };
        const token = await auth.currentUser.getIdToken(true);
        const url = `${nogento.btc}/rest/policies-procedures/${phoenixId}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.json();
    } catch (err) {
        return {
            success: false,
            error: err,
        };
    }
}

export async function updatePolicy({ phoenixId, version, accepted }) {
    try {
        const auth = await firebase.auth();
        if (!auth.currentUser) return { success: false };
        const token = await auth.currentUser.getIdToken(true);
        const url = `${nogento.btc}/rest/policies-procedures/${phoenixId}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                version,
                accepted,
            }),
        });

        return response.json();
    } catch (err) {
        return {
            success: false,
            error: err,
        };
    }
}
