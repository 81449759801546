import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';

export default async function getVtexProductCredit() {
    const endpoint = `${nogento.vtex}/accounts/productCredit`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}
