import { addressLength } from './address';
import { optional } from './optional';
import { email } from './email';
import { emailConfirm } from './emailConfirm';
import {
    password,
    passwordStrict,
    passwordConfirm,
    nameValidation,
    forbiddenWordValidation,
} from './password.js';
import { telephone } from './telephone.js';
import { required } from './required.js';
import { zipcode } from './zipcode.js';
import { humanName } from './humanName.js';
import { routingNumber } from './routingNumber.js';
import { numberOnly } from './numberOnly.js';
import { region } from './region';
import { countryId } from './countryId.js';

export const validations = {
    addressLength,
    optional,
    email,
    emailConfirm,
    password,
    passwordStrict,
    nameValidation,
    humanName,
    forbiddenWordValidation,
    passwordConfirm,
    telephone,
    required,
    zipcode,
    routingNumber,
    numberOnly,
    region,
    countryId,
};

const withError = errorMessage => validation => (field, form) => ({
    ...validation(field, form),
    errorMessage,
});

/**
 * Eliminates the need to inject formstore into child classes.
 * Allows the ability to compare validations to one another
 * since they are the same function, not a copy.
 */
export default errors => {
    const methods = {
        name: withError(errors.passwordContainsName)(nameValidation),
        forbiddenWord: withError(errors.passwordContainsForbiddenWord)(forbiddenWordValidation),
        addressLength: withError(errors.addressLength)(addressLength),
        optional: withError(errors.optional)(optional),
        email: withError(errors.email)(email),
        emailConfirm: withError(errors.emailConfirm)(emailConfirm),
        humanName: withError(errors.humanName)(humanName),
        password: withError(errors.passwordLength)(password),
        passwordStrict: withError(errors.passwordStrict)(passwordStrict),
        passwordConfirm: withError(errors.passwordConfirm)(passwordConfirm),
        telephone: withError(errors.telephone)(telephone),
        required: withError(errors.required)(required),
        zipcode: withError(errors.zipcode)(zipcode),
        routingNumber: withError(errors.isNotARoutingNumber)(routingNumber),
        numberRequired: withError(errors.isNotANumber)(numberOnly),
        countryId: withError(errors.required)(countryId),
        region: withError(errors.region)(region),
    };

    return {
        email: [methods.email, methods.required],
        emailConfirm: [methods.email, methods.emailConfirm, methods.required],
        zip: [methods.zipcode, methods.required],
        address: [methods.addressLength, methods.required],
        addressOptional: [methods.addressLength],
        required: [methods.required],
        requiredName: [methods.humanName, methods.required],
        default: [methods.optional],
        optional: [methods.optional],
        password: [methods.password, methods.required],
        passwordStrict: [
            methods.passwordStrict,
            methods.name,
            methods.forbiddenWord,
            methods.required,
        ],
        passwordConfirm: [methods.password, methods.passwordConfirm, methods.required],
        passwordOptional: [methods.optional],
        telephone: [methods.telephone, methods.required],
        phoneOptional: [methods.telephone],
        creditCardNumber: [methods.required],
        creditCardNumberOptional: [methods.optional], // my account hack type.
        creditCardVerification: [methods.required],
        routingNumber: [methods.routingNumber, methods.required],
        numberRequired: [methods.numberRequired, methods.required],
        countryId: [methods.countryId, methods.required],
        region: [methods.region, methods.required],
    };
};
