import { nogento } from '@btc-frontend/config';
import { NOGENTO_SIFT_ROUTES } from '@beautycounter/constants/siftNogento';
import { getPostRequestOptions } from '@beautycounter/utils/getPostRequestOptions';

// this function sends Sift events from nogento
// only when there's no UID available.
// To retrieve UID by Email, it was decided to
// trigger these events on Nogento, so that we
// do not expose UID to unauthenticated users
export default async function sendSiftEventFromNogento(context = {}, eventType) {
    try {
        const request = await fetch(
            `${nogento.base}/sift/${NOGENTO_SIFT_ROUTES[eventType]}`,
            getPostRequestOptions(context),
        );
        return await request.json();
    } catch (error) {
        console.error(error);
        return error;
    }
}
