const green = {
    green100: '#007566',
    green80: '#339185',
    green60: '#66ACA3',
    green40: '#99C8C2',
    green20: '#CCE3E0',
    green10: '#E5F1EF',
    green1: '#F7FAFA',
};

module.exports = green;
