import { ITypeOptions } from '@btc-frontend/middleware/api/graphql';
export const querySet: ITypeOptions = [
    {
        name: 'milestoneProgress',
        variables: `milestoneProgressId
            enrollerAccountId
            participantAccountId
            participantFirstName
            participantLastName
            enrollerFirstName
            enrollerLastName
            startDate
            endDate
            totalDays
            currentDay
            completed
            completionDate
            active
            milestone{
                milestoneId
                name
                description
                rewards {
                    rewardId
                    name
                    rewardTypeId
                    value
                    rewarded
                    payoutDate
                    earnedDate
                    expirationDate
                }
                requirements {
                    requirementId
                    name
                    requirementTypeId
                    value
                    earnedValue
                }
            }
            enrollerMilestone{
                milestoneId
                name
                description
                rewards{
                    name
                    rewardTypeId
                    value
                    rewarded
                    payoutDate
                    earnedDate
                    expirationDate
                }
                requirements{
                    name
                    requirementTypeId
                    value
                    earnedValue
                }
            }
        `,
    },
    {
        name: 'teamProgress',
        variables: `
            totalRecords
            teamProgressData {
                accountId
                sponsorId
                level
                countryId
                firstName
                lastName
                email
                enrollmentDate
                sponsorFirstName
                sponsorLastName
                milestone
                milestoneEndDate
                milestoneComplete
                enrollerMilestoneComplete
            }
        `,
    },
    {
        name: 'startCountingStatus',
    },
];
