import React from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ClickOutHandler from 'react-onclickout';
import Portal from '@btc-frontend/components/Common/Portal';

// component related
import s from './PopupComponent.scss';
import { Transition } from 'react-transition-group';

// children components
import IconClose from '@counter-ui/Icon/Icons/Controls/Close.jsx';

export interface Props {
    viewportWidth: number;
    handleClick: any;
    cta: React.Component;
    action: string;
    visibility: boolean;
    showCloseIcon: boolean;
    animationStyle?: 'appear' | null;
    canClickOut?: boolean;
}

export interface State {
    show: boolean;
}

@withStyles(s)
export default class PopupComponent extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount() {
        window.requestAnimationFrame(this.handleToggle);
    }

    handleToggle() {
        this.setState(({ show }) => ({
            show: !show,
        }), () => {
            if (!this.state.show)
                setTimeout(() => this.props.handleClick && this.props.handleClick(), 500);
        });
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.viewportMode !== nextProps.viewportMode) {
            this.forceUpdate();
        }
    }

    render() {
        const { show } = this.state;
        const { visibility, isMobile, showCloseIcon, children, animationStyle } = this.props;

        const iconPrefs = (() => {
            const color = isMobile ? '#000000' : '#1B2B45';
            return {
                color,
                thickness: 5,
            };
        })();
        const duration = 400;
        const defaultStyle = {
            transition: `opacity ${duration}ms , transform ${duration}ms `,
            opacity: '0',
        }
        const transitionStyles = {
            entered: { transform: 'translateX(0%)', opacity: '.6' },
        };
        let defaultContentStyle = {
            transition: `opacity ${duration}ms , transform ${duration}ms `,
            transform: 'scale(.8) translateX(-60%)',
            opacity: '0',
        };
        let contentTransitionStyles = {
            entered: { transform: 'scale(1) translateX(-50%)', opacity: '1' },
        };
        if (!isMobile) {
            defaultContentStyle = {
                transition: `opacity ${duration}ms , transform ${duration}ms `,
                transform: 'scale(.8) translateX(-60%) translateY(-60%)',
                opacity: '0',
            };
            contentTransitionStyles = {
                entered: { transform: 'scale(1) translateX(-50%) translateY(-50%)', opacity: '1' },
            };
        }

        return (
            <Portal visibility={visibility}>
                {animationStyle !== 'appear' && <div className={s.popupBG}>
                    <Transition in={!!show} timeout={duration}>
                        {state => (
                            <>
                                <div
                                    className={s.popupBG__triangle_bottom}
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                />
                                <div
                                    className={s.popupBG__triangle_top}
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                />
                            </>
                        )}
                    </Transition>
                </div>}
                <Transition in={!!show} timeout={duration}>
                    {state => (
                        <ClickOutHandler onClickOut={this.props.canClickOut && this.handleToggle}>
                            <div
                                style={{
                                    ...defaultContentStyle,
                                    ...contentTransitionStyles[state],
                                }}
                                className={s.popup}
                            >
                                <div className={s.popup__close}>
                                    {showCloseIcon && <IconClose
                                        className={s.popup__closeIcon}
                                        onClick={this.handleToggle}
                                        {...iconPrefs}
                                    />}
                                </div>
                                {/* Left columm */}
                                <div className={s.popup__bodyContainer}>
                                    {children}
                                </div>
                            </div>
                        </ClickOutHandler>
                    )}
                </Transition>
            </Portal>
        );
    }
}
