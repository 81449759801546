export const languages = {
    EN_US: 'en-us',
    EN_CA: 'en-ca',
    FR_CA: 'fr-ca',
};

export const languageIds = {
    EN: 1,
    FR: 2,
};
