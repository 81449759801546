import { observable, computed, action, toJS } from 'mobx';
import { normalizeProducts, getVariantKey } from '@btc-frontend/middleware/services/products';
import { keyBy, values, head, isEqual } from 'lodash';
import { filterCategories } from '@btc-frontend/middleware/services/filter';
import { searchForProducts } from '@btc-frontend/middleware/services/search';
import { analytics, specialCategories } from '@btc-frontend/config';
import { getNativeProductUrl } from '@btc-frontend/middleware/utils/url';
import { getSafe } from '@btc-frontend/middleware/utils/object';

import analyticsStore from '@btc-frontend/stores/analyticsStore';

class ProductsStore {
    @observable total;
    @observable page;
    @observable products;
    @observable productsById;
    @observable activeProduct;
    @observable searchProducts;
    @observable searchResults;
    @observable searchTotal;

    constructor() {
        this.total = 0;
        this.page = 0;
        this.products = []; // parents only, variants nested
        this.productsById = {}; // parents only, variants nested
        this.productsByCategoryId = {}; // parents only, variants nested
        this.normalizedBySKU = {}; // includes variants, limited attributes
        this.normalizedById = {}; // includes variants, limited attributes
        this.activeProduct = null;
        this.searchProducts = ''; // keywords
        this.searchResults = [];
        this.searchTotal = 0;
    }

    // Called in ContentStore ONLY
    @action
    load = catalogs => {
        this.products = [].concat.apply([], catalogs.map(({ data }) => data.Products));

        this.productsById = {
            ...keyBy(this.products, 'productId'),
        };
        this.normalizedById = normalizeProducts(this.products, 'id');
        this.normalizedBySKU = normalizeProducts(this.products, 'sku');
    };

    @action
    productBySKU = SKU => {
        if (typeof this.normalizedBySKU[SKU] != 'undefined') {
            return {
                ...this.productsById[this.normalizedBySKU[SKU].id],
                selectedVariant: this.normalizedBySKU[SKU].selectedVariant,
            };
        }
        return false;
    };

    @action
    productsBySKUS = SKUS => {
        if (SKUS && SKUS.length && Array.isArray(SKUS.slice())) {
            const lookups = [];
            return SKUS.filter(sku => {
                const product = this.productBySKU(sku);
                return product ? lookups.push(product) : false;
            }).map((sku, i) => lookups[i]);
        }
        return [];
    };

    @action
    productOrVariantById = id => {
        if (typeof this.normalizedById[id] != 'undefined') {
            return {
                ...this.productsById[this.normalizedById[id].id],
                selectedVariant: this.normalizedById[id].selectedVariant,
            };
        }
        return false;
    };

    @action
    variantById = id => {
        const product = getSafe(() => this.normalizedById[id]);
        const variant = getSafe(() =>
            product.selectedVariant
                ? getVariantKey(product.selectedVariant, this.productsById[product.id])
                : this.productsById[product.id],
        );
        return variant;
    };

    @computed
    get productsBySlug() {
        return keyBy(this.products, 'handle');
    }

    @computed
    get productsBySearch() {
        return this.searchTotal
            ? this.searchResults
            : this.productsByCategoryId[specialCategories.bestSellers];
    }

    @action
    setSearch = params => {
        this.searchProducts = params;
        this.searchResults = searchForProducts(this.products, params);
        this.searchTotal = this.searchResults.length;
        analyticsStore.productsSearched(this.searchResults, params);
    };

    @action
    setActiveProduct = params => {
        this.activeProduct = params.slug;
    };

    @action
    unsetActiveProduct = () => {
        this.activeProduct = null;
    };

    @action
    getPrimaryCategory = product => {
        if (
            categoryStore.lastCategory &&
            product.categoryList.includes(categoryStore.lastCategory)
        ) {
            return categoryStore.uniqueCategoryLinksById[categoryStore.lastCategory];
        }

        return (
            categoryStore.uniqueCategoryLinksById[
                product.parentCategoryIds.length > 1 && product.parentCategoryIds[1]
            ] || null
        );
    };

    @action
    getSecondaryCategory = product => {
        if (
            categoryStore.lastCategory &&
            categoryStore.lastFilter &&
            product.categories.includes(categoryStore.lastFilter)
        ) {
            return categoryStore.categoryLinksById[
                categoryStore.lastCategory + categoryStore.lastFilter.toString()
            ];
        }

        for (let i = 0; i < product.categories.length; i++) {
            const category = product.categories[i];
            const deepId =
                product.parentCategoryIds.length > 1 &&
                product.parentCategoryIds[1] + category.toString();
            if (categoryStore.categoryLinksById[deepId])
                return categoryStore.categoryLinksById[deepId];
        }
        return null;
    };

    @action
    getProductLink(id) {
        return getNativeProductUrl(this.productsById[id].handle);
    }

    @computed
    get anyById() {
        const any = {};
        this.products.forEach(product => {
            product.variants.forEach(variant => {
                any[variant.id] = variant;
            });
            any[product.id] = product;
        });
        return any;
    }

    @computed
    get segmentObject() {
        const segment = {};
        Object.keys(this.anyById).forEach(key => {
            const product = this.anyById[key];
            segment[product.id] = {
                product_id: product.sku,
                magentoId: product.id,
                sku: product.sku,
                name: product.title,
                price: product.price,
                value: product.price && product.price.toFixed(2),
                url: product.handle,
                tags: product.tags,
                product: product.swatches && product.swatches.swatchlabel,
                image_url: getSafe(() => product.images[0].src) || null,
            };
        });
        return segment;
    }

    @action
    bundledProducts(SKU, bundled) {
        const product = this.productBySKU(SKU);
        const bundledProducts = [];
        Object.values(bundled).forEach(selection => {
            product.bundledOptions.some(option =>
                option.bundledOptionValues.some(({ selectionId, sku, productId, name }) => {
                    if (selectionId === selection) {
                        return bundledProducts.push({ sku, id: Number(productId), name });
                    }
                }),
            );
        });
        return bundledProducts;
    }
}

const productsStore = new ProductsStore();

export default productsStore;
export { ProductsStore };
