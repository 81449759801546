export const // Sizes
    SIZE_TINY = {
        width: 9,
        height: 9,
    },
    SIZE_EXTRA_SMALL = {
        width: 12,
        height: 12,
    },
    SIZE_SMALLER_SMALL = {
        width: 15,
        height: 15,
    },
    SIZE_SMALL = {
        width: 20,
        height: 20,
    },
    SIZE_NAV = {
        width: 24,
        height: 24,
    },
    SIZE_MEDIUM = {
        width: 24,
        height: 24,
    },
    SIZE_LARGE = {
        width: 36,
        height: 36,
    },
    SIZE_EXTRA_LARGE = {
        width: 48,
        height: 48,
    },
    SIZE_MASSIVE = {
        width: 128,
        height: 128,
    },
    // Thickness
    THICKNESS_THIN = 1,
    THICKNESS_NORMAL = 5,
    THICKNESS_THICK = 10;
