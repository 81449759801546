import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './StartupLoader.scss';
import cx from 'classnames';
import { setTimeout } from 'timers';
import { info } from '@btc-frontend/config';

@withStyles(s)
class StartupLoader extends Component {
    componentDidMount() {
        requestAnimationFrame(() => {
            const loader = document.getElementById('page-loader');
            if (loader) loader.style.opacity = 0;
        });
    }

    render() {
        return (
            <div id={'page-loader'} className={s.root}> 
            <div className={s.loader}>     
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="54.000000pt" height="54.000000pt" viewBox="0 0 16.000000 16.000000"
                preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,16.000000) scale(0.001770,-0.001770)"
                    fill="#000000" stroke="none">
                    <path d="M1072 4523 l3 -4518 1685 0 c1502 1 1819 4 2060 20 121 8 225 15 265
                    20 22 2 69 7 105 10 36 4 79 8 95 10 17 2 55 7 85 10 30 3 66 7 80 10 14 2 43
                    6 65 10 42 6 196 32 230 39 113 23 187 39 220 47 22 6 47 12 55 14 80 16 341
                    101 475 155 90 36 302 138 325 156 8 6 31 20 50 31 34 18 177 113 209 138 163
                    132 306 272 389 384 15 20 30 38 33 41 11 9 130 190 136 208 4 9 10 19 13 22
                    8 5 96 179 123 240 54 124 114 295 132 376 23 105 36 167 40 200 4 21 8 50 11
                    64 26 146 26 517 0 701 -3 22 -14 76 -31 159 -34 170 -80 311 -154 480 -64
                    144 -110 231 -183 343 -21 32 -38 60 -38 62 0 5 -89 125 -100 135 -3 3 -18 23
                    -35 45 -86 115 -304 326 -445 431 -36 27 -67 51 -70 54 -13 14 -162 110 -170
                    110 -6 0 -10 4 -10 8 0 4 -12 13 -27 20 -16 6 -55 28 -88 47 -68 40 -250 129
                    -286 140 -13 4 -30 12 -38 18 -7 6 -18 12 -25 13 -6 1 -58 20 -116 42 -58 21
                    -139 49 -180 62 -41 12 -79 25 -84 28 -5 2 34 15 85 28 99 25 290 87 349 113
                    19 8 37 15 40 16 3 0 31 13 64 28 32 15 61 27 63 27 3 0 18 9 34 21 16 11 29
                    17 29 14 0 -3 13 3 29 14 16 12 31 21 34 21 9 0 164 105 182 123 9 10 20 17
                    24 17 8 0 162 149 208 201 133 154 275 453 308 649 3 19 8 42 10 50 8 30 22
                    135 31 230 8 97 9 343 1 440 -13 158 -72 408 -127 545 -7 17 -16 39 -20 50
                    -53 136 -160 327 -267 477 -80 111 -106 141 -243 278 -89 90 -126 123 -215
                    193 -51 40 -152 107 -162 107 -6 0 -16 6 -20 13 -23 36 -390 195 -488 212 -11
                    2 -45 8 -75 15 -88 18 -92 19 -145 26 -50 6 -354 8 -3083 18 l-1453 6 3 -4517z
                    m3353 4117 c11 0 44 2 73 3 28 1 52 0 52 -3 0 -3 143 -5 318 -6 341 -1 702
                    -10 767 -20 80 -11 204 -55 308 -108 59 -30 112 -60 119 -65 7 -6 42 -32 78
                    -59 101 -74 109 -81 212 -182 54 -52 121 -126 150 -163 29 -38 58 -75 65 -82
                    43 -46 203 -321 203 -349 0 -8 4 -16 8 -18 22 -8 99 -243 127 -383 30 -152 26
                    -406 -10 -605 -22 -125 -44 -217 -65 -265 -4 -11 -18 -47 -31 -80 -82 -219
                    -239 -426 -439 -582 -64 -49 -213 -143 -234 -147 -6 -1 -36 -15 -66 -30 -189
                    -93 -462 -153 -835 -182 -38 -2 -97 -8 -130 -11 -33 -3 -847 -8 -1810 -10
                    l-1750 -4 -3 1108 c-2 609 -5 1362 -6 1673 -2 311 -2 568 0 571 2 3 650 5
                    1441 3 791 -2 1447 -4 1458 -4z m885 -3824 c172 -5 245 -10 245 -16 0 -1 15
                    -4 32 -6 18 -3 57 -12 85 -20 29 -8 60 -16 68 -18 117 -30 445 -188 620 -299
                    73 -46 240 -166 255 -182 6 -6 35 -31 65 -56 51 -41 229 -216 275 -270 68 -80
                    116 -140 145 -184 19 -27 36 -52 40 -55 12 -10 110 -176 110 -187 0 -6 3 -13
                    8 -15 8 -3 92 -174 92 -187 0 -4 7 -23 16 -41 9 -18 20 -46 25 -64 4 -17 20
                    -69 34 -116 15 -47 28 -96 30 -110 2 -14 8 -45 14 -70 30 -132 34 -504 7 -637
                    -3 -11 -8 -43 -11 -70 -14 -94 -61 -282 -85 -338 -5 -11 -15 -38 -22 -60 -20
                    -63 -114 -249 -153 -304 -19 -28 -35 -53 -35 -57 0 -3 -17 -27 -37 -52 -21
                    -26 -45 -55 -53 -66 -29 -38 -176 -183 -235 -233 -57 -48 -214 -156 -286 -197
                    -51 -29 -249 -126 -258 -126 -4 0 -25 -8 -47 -19 -58 -28 -291 -106 -374 -126
                    -209 -50 -442 -93 -585 -109 -27 -4 -59 -8 -70 -10 -18 -3 -82 -9 -235 -21
                    -243 -19 -529 -23 -2160 -31 l-1285 -7 -2 2188 -1 2187 1786 -5 c983 -3 1875
                    -7 1982 -11z"/>
                    </g>
                </svg>
                </div>
                {info.startupNotice && <div className={s.notice}>
                    {info.startupNotice}
                </div>}
            </div>
        )
    };
}

export default StartupLoader;
