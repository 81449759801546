/**
 * Draft environment
 * This configuration defines special exceptions for the drafts environment
 * Run 'yarn start -- -environment=drafts' to use this config
 */

const environment = {
    // base URL that the site will appear on
    liveUrl: 'https://behindthecounter.beautycounter.dev',
    redirectUrl: 'https://drafts.beautycountertest.com',
    bccomUrl: 'https://drafts.beautycountertest.com', //TODO: swap out after vtex launch for new BccomUrl
    newBccomUrl: 'https://qa.beautycountertest.com', //temp variable for vtex launch
    firebaseProject: 'beautycounter-drafts',
    cdnUrl: 'https://draftassets.beautycounter.com',
    environmentName: 'drafts',

    // whether to use shortened CSS class names
    cssShortening: true,

    // whether to enable detailed console messaging
    verbose: true,

    // whether to use JS minification and ahead-of-time compilation
    codeOptimization: true,

    // enable the hidden developer tools menu
    developerTools: true,

    flags: {
        contentDrafts: false,
        ordersCollection: false,
        renewalStatusOverride: false,
        renewalFeature: true,
        ssoLogin: false,
        useVtexUrls: true,
        newCounterULink: true,
    },

    puff: {
        baseQuery: '',
    },

    analytics: {
        fullStory: {
            enabled: false,
        },
        optimizely: {
            enabled: true,
        },
        segment: {
            enabled: true,
        },
        newRelic: {
            enabled: true,
            sourceMaps: true,
            applicationId: '176352920',
        },
    },

    sailthru: {
        // Customer ID key for BeautyCounter - Internal
        customerId: '1b669ea68760541ca2f425b23a7d5e14',
        env: 'internal',
    },

    firebase: {
        assetURL: 'https://draftassets.beautycounter.com/btc',
    },
};

export { environment };
