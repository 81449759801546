import React, { Component } from 'react';
import IconClose from '@counter-ui/Icon/Icons/Controls/Close.jsx';

export default class IconErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error);
    }

    render() {
        if (this.state.hasError || (this.props.children && this.props.children.type === undefined)) {
            return <IconClose />;
        }

        return this.props.children || null;
    }
}