import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';

import userStore from '@btc-frontend/stores/userStore';

// NOT BEING USED
export default async function getIncentives(opid) {
    const { phoenixId, email } = userStore.userInfo;
    const endpoint = `${nogento.incentives}/${opid ||
        phoenixId}?email=${email}&showDataAfterEndDate=true`;
    const auth = await firebase.auth();
    if (!auth.currentUser) return { success: false };
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };

        const response = await fetch(endpoint, options);
        if (response.status < 200 || response.status >= 300) {
            const e = await response.text();
            throw `[${response.status}] ${e}`;
        }
        const { data } = await response.json();

        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}
