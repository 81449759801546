export function formatAmount(amount) {
    const string = `${amount}`,
        triads = [];

    for (let i = string.length - 1; i >= 0; i -= 3) {
        if (i > 2) {
            triads.push(string.substr(i - 2, 3));
        } else {
            triads.push(string.substr(0, i + 1));
        }
    }

    let result = `${triads[triads.length - 1]}`;

    for (let i = triads.length - 2; i > -1; i--) {
        result += ` ${triads[i]}`;
    }

    return result;
}

export function formatNumBySeparators(
    amount: number = 0,
    options: { decimals?: number; culture?: string } = {},
) {
    // formats number by thousands separators and two decimal places ex. 69123.420 => 69,123.42
    if (!amount) return 0;
    if (options.decimals === undefined) options.decimals = 2;
    if (!options.culture) options.culture = 'en';
    return amount.toLocaleString(options.culture, {
        minimumFractionDigits: options.decimals,
        maximumFractionDigits: options.decimals,
    });
}
