/* translationGroup: nudgesMethods */
export const methodConstants = ({ t }) => [
    {
        id: 'email',
        name: t('methodEmailName', { defaultValue: 'Email' }),
    },
    {
        id: 'phone',
        name: t('methodPhoneName', { defaultValue: 'Phone' }),
    },
    {
        id: 'text',
        name: t('methodTextName', { defaultValue: 'Text' }),
    },
    {
        id: 'note',
        name: t('methodNoteName', { defaultValue: 'Note' }),
    },
    {
        id: 'in-person',
        name: t('methodInPersonName', { defaultValue: 'In Person' }),
    },
    {
        id: 'other',
        name: t('methodOthereName', { defaultValue: 'Other' }),
    },
];

export const titleConstants = ({ t }) => [
    {
        name: t('titleProductCredit1', { defaultValue: 'Product Credit 1' }),
        category: 'member',
        id: 'expiringPC30',
    },
    {
        name: t('titleProductCredit12', { defaultValue: 'Product Credit 2' }),
        category: 'member',
        id: 'expiringPC7',
    },
    {
        name: t('titleMemberdPending', { defaultValue: 'Member Pending' }),
        category: 'member',
        id: 'memberPending',
    },
    {
        name: t('titleMemberRenewal1', { defaultValue: `Member Renewal 1` }),
        category: 'member',
        id: 'memberExpiring20',
    },
    {
        name: t('titleMemberRenewal2', { defaultValue: `Member Renewal 2` }),
        category: 'member',
        id: 'memberExpiring5',
    },
    {
        name: t('titleNewOrder', { defaultValue: 'New Order' }),
        category: 'clienteling',
        id: 'newOrder',
    },
    {
        name: t('titleCheckup', { defaultValue: 'Checkup' }),
        category: 'clienteling',
        id: 'week2',
    },
    {
        name: t('titleReplenish', { defaultValue: 'Replenish' }),
        category: 'clienteling',
        id: 'month2',
    },
    {
        name: t('titleReturns', { defaultValue: 'Returns' }),
        category: 'clienteling',
        id: 'return',
    },
    {
        name: t('titleBirthday', { defaultValue: 'Birthday' }),
        category: 'clienteling',
        id: 'birthday',
    },
    {
        name: t('titleCustomFollowUp', { defaultValue: 'Custom Follow Up' }),
        category: 'clienteling',
        id: 'customFollowUp',
    },
    {
        name: t('titleNewEnrollment', { defaultValue: 'New Enrollment' }),
        category: 'team',
        id: 'newEnrollment',
    },
    {
        name: t('titlePersonalSellingBonus1', { defaultValue: 'Personal Selling Bonus 1' }),
        category: 'team',
        id: 'psb10',
    },
    {
        name: t('titlePersonalSellingBonus2', { defaultValue: 'Personal Selling Bonus 2' }),
        category: 'team',
        id: 'psb7',
    },
    {
        name: t('titlePersonalSellingBonus3', { defaultValue: 'Personal Selling Bonus 3' }),
        category: 'team',
        id: 'psb5',
    },
    {
        name: t('titleActivityRequirement1', { defaultValue: 'Activity Requirement 1' }),
        category: 'team',
        id: 'activityReq90',
    },
    {
        name: t('titleActivityRequirement2', { defaultValue: 'Activity Requirement 2' }),
        category: 'team',
        id: 'activityReq60',
    },
    {
        name: t('titleActivityRequirement3', { defaultValue: 'Activity Requirement 3' }),
        category: 'team',
        id: 'activityReq30',
    },
    {
        name: t('titleStartMilestone1', { defaultValue: 'Milestone 1 Started' }),
        category: 'team',
        id: 'startCountingStartMilestone1',
    },
    {
        name: t('titleStartMilestone2', { defaultValue: 'Milestone 2 Started' }),
        category: 'team',
        id: 'startCountingStartMilestone2',
    },
    {
        name: t('titleStartMilestone3', { defaultValue: 'Milestone 3 Started' }),
        category: 'team',
        id: 'startCountingStartMilestone3',
    },
    {
        name: t('titleMilestone1', { defaultValue: 'Milestone 1 Completed' }),
        category: 'team',
        id: 'startCountingCompleteMilestone1',
    },
    {
        name: t('titleMilestone2', { defaultValue: 'Milestone 2 Completed' }),
        category: 'team',
        id: 'startCountingCompleteMilestone2',
    },
    {
        name: t('titleMilestone3', { defaultValue: 'Milestone 3 Completed' }),
        category: 'team',
        id: 'startCountingCompleteMilestone3',
    },
    {
        name: t('titleFirstTimePromo', { defaultValue: 'First Time Promo' }),
        category: 'team',
        id: 'firstTimePromo',
    },
];

export const CATEGORY_TEAM = 'team',
    CATEGORY_MEMBER = 'member',
    CATEGORY_CLIENTELING = 'clienteling',
    CATEGORY_PERSONAL = 'personal',
    STATUS_OPEN = 'open',
    STATUS_COMPLETE = 'complete',
    STATUS_DELETE = 'deleted',
    SNOOZE_ALWAYS = 'always',
    SNOOZE_NEVER = 'indefinite',
    SNOOZE_CUSTOM = 'custom';

export const SUBCAT_FOR_PERSONAL_SC = [
    `NewStartCountingStartMilestone1`,
    `NewStartCountingStartMilestone2`,
    `NewStartCountingStartMilestone3`,
    `NewStartCountingCompleteMilestone1`,
    `NewStartCountingCompleteMilestone2`,
    `NewStartCountingCompleteMilestone3`,
];

export const SUBCAT_FOR_DEGRADE = [
    'activityReq90',
    'activityReq60',
    'activityReq30',
    'activityReq15',
];

export const SUBCAT_FOR_RALLYWARE = ['rallywareEnrollment'];
export const SUBCAT_FOR_POPUPS = ['popupStarting', 'popupExpiring7'];
