exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NQ3US{display:inline-block;text-align:center;background-color:#e5503f;font-size:12.8px;font-size:.8rem;color:#fefefe;padding:6px 20px;border-radius:4px;margin:4px 10px 4px 0;-webkit-animation-duration:.3s;animation-duration:.3s}._15DcW{background-color:#50555b}._1lSbw{background-color:#e5503f}", ""]);

// exports
exports.locals = {
	"notice": "NQ3US fadein forward-fill",
	"gray": "_15DcW",
	"red": "_1lSbw"
};