import dateFormat from 'dateformat';
import { parseFromTimeZone } from 'date-fns-timezone';

const dateNameFormat = 'mmmm yyyy';

export function getCurrentPeriod() {
    return getPeriodForDate();
}

export function getPeriodForDate(date = null) {
    if (!date) date = new Date();
    return date.getFullYear() * 100 + (date.getMonth() + 1);
}

export function getPeriodDateComponents(period: number) {
    const year = Math.floor(period / 100);
    const month = period - year * 100;
    return {
        month,
        year,
    };
}

export function getNameOfPeriod(period: number) {
    const date = getEffectiveDateForPeriod(period);
    return dateFormat(date, dateNameFormat) as string;
}

export function getNamedPeriodRangeOfPastMonths(monthsToLookBack) {
    if (monthsToLookBack <= 0) return [];
    const end = getCurrentPeriod();
    const range = getPeriodsInPeriodRange(getPeriodOffset(end, -1 * (monthsToLookBack - 1)), end);
    return range.map(period => {
        const date = getEffectiveDateForPeriod(period);
        return {
            id: period,
            month: dateFormat(date, 'mmmm'),
            name: dateFormat(date, dateNameFormat),
        };
    });
}

export function getPreceedingPeriod(period = null) {
    if (!period) period = getCurrentPeriod();
    let lastPeriod = period - 1;
    if (lastPeriod % 100 === 0) lastPeriod = lastPeriod - 88;
    return lastPeriod;
}

export function getStartDateForPeriod(period: number) {
    if (!period) return new Date();
    const year = Math.floor(period / 100);
    const month = period % 100 < 10 ? `0${period % 100}` : `${period % 100}`;
    // const date =
    return parseFromTimeZone(`${year}-${month}-01T00:00:00.000`, {
        timeZone: 'America/Los_Angeles',
    });
}

export function getEndDateForPeriod(period: number): Date {
    // times are returned as last second in a period ... PST.
    return new Date(period / 100, period % 100, 0, 23, 59, 59);
}

export function getEffectiveDateForPeriod(period, getEndDate = false): Date {
    if (!period || (period === getCurrentPeriod() && !getEndDate)) return new Date();

    // times are returned as last second in a period ... PST.
    return getEndDateForPeriod(period);
}

export function getPeriodsInPeriodRange(start, end) {
    if (!start || !end) return [];
    if (start === end) return [start];
    if (start > end) throw 'starting period is greater than ending period';
    const periods = [];
    let current = end;
    while (current >= start) {
        periods.push(current);
        current = current - 1;
        if (current % 100 === 0) current = current - 88;
    }
    return periods;
}

/*
  Add or subtract a number of months/periods from a given period

  eg: getPeriodOffset(201807, -10) => 201709
*/
export function getPeriodOffset(period, offset) {
    const monthDiff = offset % 12;
    const yearDiff = (offset - monthDiff) / 12;
    const result = period + yearDiff * 100 + monthDiff;
    const month = result % 100;
    if (month > 12 && month < 50) return result + 88;
    if (month > 50 || month == 0) return result - 88;
    return result;
}

// we will assume periodA comes before periodB.
// getPeriodMonthDiff(201909, 202009) => 12
// getPeriodMonthDiff(201912, 201909) => -3
export function getPeriodMonthDiff(periodA: number, periodB: number) {
    const aMonths = periodA % 100;
    const bMonths = periodB % 100;
    const yearDiff = (periodB - bMonths) / 100 - (periodA - aMonths) / 100;
    const monthDiff = bMonths - aMonths;
    return yearDiff * 12 + monthDiff;
}

export function getMonthFromDate(dateString, isAbbreviated = true) {
    const date = new Date(dateString);
    let monthNames;
    if (isAbbreviated) {
        monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
        ];
    } else {
        monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
    }

    const monthIndex = date.getMonth();

    return `${monthNames[monthIndex]}`;
}

export function getMonthDayFromDate(dateString, isAbbreviated = true) {
    const date = new Date(dateString);
    let monthNames;
    if (isAbbreviated) {
        monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
        ];
    } else {
        monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
    }

    const day = date.getDate();
    const monthIndex = date.getMonth();

    return `${monthNames[monthIndex]} ${day}`;
}
