export const SAILTHRU_USER_COOKIE = 'sailthru_hid';

export function getCookieClient(name) {
    const matches = document.cookie.match(
        new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getCookieServer(name, req) {
    return req.cookies[name];
}

export function getCookieEdge(name, request = {}) {
    if (!request.headers) return;
    const cookies = request.headers.get('cookie');
    if (!cookies) return;
    const matches = Object.fromEntries(
        cookies.split(/; */).map(c => {
            const [key, ...v] = c.split('=');
            return [key, decodeURIComponent(v.join('='))];
        }),
    );

    return matches[name];
}

export function setCookieServer(name, value, res) {
    res.cookie(name, value, { maxAge: 31536000 });
}

export function setCookieEdge(name, value, res) {
    return makeCookieString(name, value);
}

/**
 * Options: expires is in seconds from current time
 * @param name
 * @param value
 * @param options
 */
export function setCookieClient(name, value, options, disableURLEncoding = false) {
    document.cookie = makeCookieString(name, value, options, disableURLEncoding);
}

export function makeCookieString(name, value, options = {}, disableURLEncoding = false) {
    if (!options.path) options.path = '/';

    const now = new Date();
    const expires = options.expires || now.setFullYear(now.getFullYear() + 10);
    options.expires = new Date(expires).toUTCString();

    if (!disableURLEncoding) {
        value = encodeURIComponent(value);
    }

    let updatedCookie = `${name}=${value}`;

    for (const propName in options) {
        updatedCookie += `; ${propName}`;

        const propValue = options[propName];

        if (propValue !== true) {
            updatedCookie += `=${propValue}`;
        }
    }
    return updatedCookie;
}

export function deleteCookie(name) {
    setCookieClient(name, '', {
        expires: -1,
    });
}
