export const routeGroups = {
    clientele: 'customers',
    team: 'team',
    account: 'account',
    accountLinks: 'account-links',
    prospectTabs: 'prospect-tabs',
};

export const routeKeys = {
    hompage: 'root',
    root: 'root',
    account: {
        root: 'account',
        about: 'account-about',
        contactInfo: 'account-contactinfo',
        contactInfoPassword: 'account-contactinfo-password',
        addresses: 'account-addresses',
        disbursement: 'account-disbursement',
        payment: 'account-payment',
        photoUpload: 'account-photoupload',
    },
    clientele: {
        root: 'customers',
        dashboard: 'dashboard',
        myCustomers: 'my-customers',
        topCustomers: 'top-customers',
        clientBreakdown: 'member-breakdown',
        reorderRate: 'reorder-rate',
        expiringCredit: 'expiring-credit',
        customer: 'customer-detail',
        orderDetail: 'customers-order-detail',
    },
    customer: {
        root: 'customer',
        account: 'customer-account',
        forgotPassword: 'forgotpassword',
        resetPassword: 'reset-password',
    },
    consultant: 'consultant',
    join: '/join',
    login: 'login',
    logout: 'logout',
    newsFeed: 'newsfeed',
    redirectAction: 'redirectAction',
    zendesk: 'zendesk',
    orders: {
        root: 'orders',
        order: 'order',
        orderHistory: 'orders-order-history',
        quickOrder: 'quick-order',
    },
    performance: {
        root: 'performance',
        dashboard: 'performance-dashboard',
        incentives: 'performance-incentives',
        growthTracker: 'performance-growth-tracker',
        startCounting: 'reports-counting',
        earnings: 'reports-earnings',
    },
    prospects: {
        root: 'prospects',
        table: 'prospects-table',
        add: 'prospects-add',
        edit: 'prospects-edit',
        detail: 'prospects-detail',
    },
    socials: {
        root: 'pop-ups',
        dashboard: 'pop-ups-dashboard',
        details: 'pop-ups-single',
        other: 'pop-ups-other',
    },
    team: {
        root: 'team',
        dashboard: 'team-dashboard',
        downlineReport: 'downline-report',
        watchlist: 'watchlist',
        activity: 'activity-requirements',
    },
    tools: {
        root: 'tools',
        digitalAssets: 'tools-digital-assets',
        printedAssets: 'tools-printed-assets',
    },
    inventory: { root: 'inventory' },
    support: {
        counterUniversity: 'counterUniversity',
        counterTraining: 'counterTraining',
    },
    lead: {
        root: 'lead',
        tickets: 'lead-tickets',
    },
};

export const teamDashboardRoutes = {
    recruitment: '/recruitment',
    hotList: '/hot-list',
    recognition: '/recognition',
    retention: '/retention',
};

export const customerDashboardRoutes = {
    topCustomers: '/top-customers',
    clientBreakdown: '/member-breakdown',
    reorderRate: '/reorder-rate',
    expiringCredit: '/expiring-credit',
};

export const customerBreakdownParams = {
    CLIENT_BREAKDOWN_FILTER_PATH: '/customers/customers/filter?type=breakdown&userType=clients',
    MEMBER_BREAKDOWN_FILTER_PATH: '/customers/customers/filter?type=breakdown&userType=members',
    ALL_CUSTOMERS_PATH: '/customers/customers',
};
