const orange = {
    orange100: '#F37D27',
    orange80: '#F59752',
    orange60: '#F8B17D',
    orange40: '#FACBA9',
    orange20: '#FDE5D4',
    orange10: '#FDF2E9',
    orange1: '#FEFBF8',
};

module.exports = orange;
