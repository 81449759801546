exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-DqTR{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%}@media screen and (max-width:667px){.-DqTR h1:after{margin-left:0}}._1cKS6{width:40px;border-top:3px solid #231f20;margin:0 auto}._1azcY{font-family:Chronicle,Times,serif;font-size:36px}._3wHb5{margin:1em 0}", ""]);

// exports
exports.locals = {
	"root": "-DqTR",
	"hr": "_1cKS6",
	"h2": "_1azcY",
	"p": "_3wHb5"
};