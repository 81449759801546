import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { observer, inject } from 'mobx-react';
import s from './MobileHeader.scss';
import cx from 'classnames';

import { SIZE_SMALL } from '@btc-frontend/middleware/constants/icon';
import { assetPath } from '@btc-frontend/config';

import IconMenu from '@counter-ui/Icon/Icons/Controls/Menu.jsx';
import IconBack from '@counter-ui/Icon/Icons/Controls/LeftAngle.jsx';
import Link from '@btc-frontend/components/Common/Link';

import { COLOR_WHITE, COLOR_BLUE } from '@btc-frontend/middleware/constants/colors';

@withStyles(s)
@inject('navigationStore', 'interfaceStore')
@observer
class MobileHeader extends Component {
    static propTypes = {
        className: pt.string,
        showBackIcon: pt.bool,
        onMenuClick: pt.func,
        onCartClick: pt.func,
        id: pt.string,
    };

    static defaultProps = {
        showBackIcon: false,
    };

    handleMenuIconClick = event => {
        this.props.onMenuClick && this.props.onMenuClick(event);
    };

    handleBackIconClick = event => {
        // this.props.interfaceStore.closeRightPanel();
    };

    renderMenuIcon() {
        return (
            <div className={s.hamburger} onClick={this.handleMenuIconClick}>
                <IconMenu size={SIZE_SMALL} color={COLOR_WHITE} />
            </div>
        );
    }

    renderBackIcon() {
        return (
            <Link to={this.props.navigationStore.backPath} localize={false}>
                <div className={s.backIcon}>
                    <IconBack size={SIZE_SMALL} />
                </div>
            </Link>
        );
    }

    render() {
        const showBackIcon =
            this.props.showBackIcon &&
            this.props.navigationStore.previous.isBackPoint &&
            this.props.navigationStore.history.length > 3;

        return (
            <>
                <div id={this.props.id} className={cx(s.rootMobile, this.props.className)}>
                    <div className={s.leftIconsBlock} onClick={this.handleBackIconClick}>
                        {showBackIcon ? this.renderBackIcon() : this.renderMenuIcon()}
                    </div>
                    <div className={s.middleBlock}>
                        <Link to="/" testId="BTCLogo-mobileHeader" className={s.logoMobile}>
                            <img
                                className={s.logoImage}
                                src={`${assetPath}/b-logo.svg`}
                                alt="Behind The Counter Logo"
                            />
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

export default MobileHeader;
