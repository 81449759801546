import React from 'react';
import { default as ListSource } from '@material-ui/core/List';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '@beautycounter/counter-ui/src/theme';

function List(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <ListSource {...props} />
        </MuiThemeProvider>
    );
}

export default List;
