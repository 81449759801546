import React from 'react';
import { Provider } from 'mobx-react';
import { asyncModels, redirectUrl, siteName } from '@btc-frontend/config';
import { containers } from '@btc-frontend/containers';
import { withTranslation } from 'react-i18next';
import i18n from '../config/i18n';
import { navLinks } from '@btc-frontend/nav';
import { omit } from 'lodash';
import { flags } from '@btc-frontend/config';
import { bccomLocalizedRedirector } from '@btc-frontend/middleware/utils/url';

const actionTemplate = (config: IRoute.IRouteConfig) => ({
    path: config.to,
    async action({ next }, params) {
        return await next();
    },
    children: [
        ...(() =>
            (config.variables &&
                config.variables.map(path => ({
                    path,
                    action: context => context.route.parent.page(context),
                }))) ||
            [])(),
        {
            path: `*`,
            action: context => context.route.parent.page(context),
        },
    ],
    page: async (context, params) => {
        // definitions
        const { stores, query } = context;
        const pagePath = context.path.replace(/\\/g, '').replace(/\//g, '');

        let {
            dependencies,
            to,
            component,
            key,
            headerConfig,
            preRenderCallback,
            preLoadCallBack,
            contentfulSpace,
            props,
            variables,
            name,
            namespaceOverride,
        } = config;
        stores.interfaceStore.showProgress('page-load');
        const isLogged = await stores.userStore.verifyLoggedIn();
        const empty = {
            config: {
                ...config,
                disableLayout: true,
            },
            component: <div />,
        };

        if (!config.disableAuth) {
            if (!isLogged) {
                window.location.replace(
                    flags.ssoLogin
                        ? bccomLocalizedRedirector(
                              `/login?requestedLocation=${encodeURIComponent(
                                  window.location.href.replace('/logout', ''),
                              )}`,
                              stores.localeStore.activeLocale.code,
                          )
                        : `/btc${navLinks.login.to}`,
                );
                return empty;
            }
            if (isLogged && !stores.userStore.isConsultant) {
                if (redirectUrl) window.location.assign(redirectUrl);
                else stores.userStore.logout();
                return empty;
            }
        }

        const storeDeps = (dependencies && dependencies.stores) || [];
        if (preLoadCallBack) await preLoadCallBack(context, params, config);

        // perform async calls
        const loadedStores = (await Promise.all([
            ...storeDeps.map(s => {
                return containers[s]();
            }),
        ]))
            .map((s, i) => ({ [storeDeps[i].replace(/^./, str => str.toLowerCase())]: s.default }))
            .reduce((acc, x) => {
                for (const key in x) acc[key] = x[key];
                return acc;
            }, {});
        // this injects a namespaced translation function into the explicitly loaded stores for this route.
        Object.values(loadedStores).forEach(store => {
            store.t = (translationKey, options = {}) => {
                // uncomment this if you want to see if a translations is bundle loaded
                // console.info(`${key}?`, i18n.hasResourceBundle(stores.localeStore.activeLocale.lang, key));
                return i18n.t(translationKey, {
                    ns: key,
                    lang: stores.localeStore.activeLocale.lang,
                    ...options,
                });
            };
        });

        // i18n and contentful loading.
        if (dependencies && dependencies.contentful) {
            await contentfulLoader(context, { dependencies, key, contentfulSpace });
            // since we awaiting, we can just fire of callback in sync
            contentfulLoaderCallback(context, { dependencies, key, namespaceOverride });
        }

        // set header config
        if (headerConfig) await stores.interfaceStore.setHeaderStyle(headerConfig);

        // optional route specific pre rendering.
        if (preRenderCallback) preRenderCallback(context, params);

        // creates an object of the route variables keys to received params
        let variablesFormatted = {};
        let currentRoute = String(to);
        variables &&
            variables.forEach(variable => {
                try {
                    variablesFormatted = {
                        ...variablesFormatted,
                        [variable]: context.params[variable],
                    };
                    currentRoute = currentRoute.replace(`/:${variable}`, '');
                } catch (e) {
                    console.error(e);
                }
            });

        stores.interfaceStore.hideProgress('page-load');

        if (config.redirectTo) stores.navigationStore.to({ url: config.redirectTo });
        else {
            if (!props) props = () => ({});

            // load page wrapper
            const Component = component && (await containers[component]()).default;
            const WrappedComponent = withTranslation(key)(Component);

            setTitle(name);

            const propsForComponent = {
                currentRoute,
                route: context.path,
                path: pagePath,
                routeConfig: config,
                query,
                variables: variablesFormatted,
                ...props(context, params),
                routerNamespace: key,
            };

            return {
                config,
                chunks: [storeDeps, Component],
                component: (
                    <Provider {...loadedStores}>
                        <WrappedComponent {...propsForComponent} />
                    </Provider>
                ),
            };
        }
    },
});

const setTitle = title => {
    document.title = title ? `${title} | ${siteName}` : siteName;
};

const contentfulLoader = async ({ path, stores }, { dependencies, key, contentfulSpace }) => {
    try {
        if (!dependencies) dependencies = {};
        if (!dependencies.contentful) dependencies.contentful = [];

        // load i18n translations dynamically
        // currently you manually load up the model name in the route config.
        await Promise.all([
            stores.contentStore.fetchContentfulConfig(asyncModels.forms),
            stores.contentStore.fetchContentfulConfig(asyncModels.navigation),
            stores.contentStore.fetchContentfulConfig(asyncModels.counterUI),
            stores.contentStore.fetchContentfulConfig(asyncModels.policiesProcedures),
        ]);
        const models = [];
        for (let i = 0; i < dependencies.contentful.length; i += 1) {
            // handles routes in the simple string model format.
            if (typeof dependencies.contentful[i] === 'string')
                models.push(dependencies.contentful[i]);
            // handles classic routes that have contentful async models.
            else if (dependencies.contentful[i].id)
                await stores.contentStore.fetchContentfulConfig({
                    ...dependencies.contentful[i],
                    contentfulSpace,
                });
        }

        if (models.length > 0) {
            const dynamicAsyncModel = {
                id: key,
                route: path,
                models,
                contentfulSpace,
            };
            await stores.contentStore.fetchContentfulConfig(dynamicAsyncModel);
        }
    } catch (e) {
        console.error(e);
        return [];
    }
};

const contentfulLoaderCallback = ({ stores }, { dependencies, key, namespaceOverride }) => {
    const translations = {
        ...getI18nTranslationFromContentful(dependencies, key),
    };
    i18n.addResourceBundle(
        stores.localeStore.activeLocale.lang,
        namespaceOverride || key,
        translations,
    );

    try {
        const navigation = stores.contentStore._extensions['navigation'];
        const counterUI = stores.contentStore._extensions['counterUI'];
        i18n.addResourceBundle(stores.localeStore.activeLocale.lang, 'common', {
            ...Object.keys(counterUI.data).reduce((acc, cur) => {
                return { ...acc, ...flattenContentfulTranslations(counterUI.data[cur][0]) };
            }, {}),
            ...(flattenContentfulTranslations(navigation.data['translationsNavigation'][0]) || {}),
        });
    } catch (e) {
        console.log('Error loading global namespace', e);
    }

    i18n.changeLanguage(stores.localeStore.activeLocale.lang);
};

const getI18nTranslationFromContentful = (dependencies, key) => {
    try {
        const stores = require('@btc-frontend/stores').default;
        const config = dependencies.contentful.filter(
            model => typeof model === 'string' && model.indexOf('translation') > -1,
        );
        const extension = stores.contentStore._extensions[key];

        return config.reduce((acc, it) => {
            return {
                ...acc,
                ...(extension && extension.data && extension.data[it]
                    ? flattenContentfulTranslations(extension.data[it][0])
                    : !!!console.error(
                          `i18n has the contentful dependency '${it}' which was unable to load.`,
                      ) && {}),
            };
        }, {});
    } catch (e) {
        console.error(e);
        return {};
    }
};

const flattenContentfulTranslations = labels => {
    return {
        ...(labels.jsonFields || {}),
        ...omit(labels, 'jsonFields'),
    };
};

export { actionTemplate };
