/**
 * BCCOM constatns for reference until full migration
 *   MEDIA_PHONE = 667,     // max width
 *   MEDIA_MOBILE = 999,    // max width
 *   MEDIA_LAPTOP = 1000,   // min width
 *   MEDIA_DESKTOP = 1170,  // min width
 *   MEDIA_HUGE = 1370;     // min width
 */

// width == keys[ keys.findIndex(key => values[key] =< window.width) - 1 ] || keys.length;
const breakpoints = {
    keys: ['phone', 'tablet', 'laptop', 'desktop', 'huge'],
    values: {
        phone: 0,
        tablet: 668,
        laptop: 1000,
        desktop: 1170,
        huge: 1370,

        mobile: 999,
    },
};

export default breakpoints;
