import { routes, bccomDomain } from '@btc-frontend/config';
import { forEach, filter, isNil, includes, value, map } from 'lodash';
import { getNavigationItemUrl } from '@btc-frontend/middleware/utils/url';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { routeKeys } from '@btc-frontend/middleware/constants/routes';
const BACK_POINT_PATHS = [routes.PRODUCTS, routes.CATEGORY];

/**
 * Returns whether we can use the path URL
 * as a back point
 * @param {String} path
 */

function isBackPointPath(path) {
    let isBackPoint = false;

    forEach(BACK_POINT_PATHS, subpath => {
        if (path.includes(subpath)) {
            isBackPoint = true;
        }
    });

    return isBackPoint;
}

/**
 * Refactor raw navigation JSON into
 * the eader navigation
 * @param {Array} data
 */

function parseNavigation(data) {
    const navigation =
        data &&
        data.map(link => {
            if (getSafe(() => link.fields.magentoId)) {
                // is it a category?
                const { svgDisplayLocations, svgIcon: svgIconRef } = link.fields;
                let svgIcon = null;
                getSafe(() => {
                    svgIcon = svgIconRef.fields.file.url;
                });
                return {
                    name: link.fields.name,
                    to: getNavigationItemUrl(link.fields.slug),
                    id: link.fields.magentoId,
                    isSpecial: link.fields.special ? true : false,
                    type: 'category',
                    svgDisplayLocations,
                    svgIcon,
                };
            } else if (getSafe(() => link.fields.name))
                return {
                    // is it a standard page link?
                    name: link.fields.name,
                    to: link.fields.url,
                    type: 'link',
                };
            else return null;
        });

    return navigation;
}

function parseBTCSubNav(data: IRoute.IRouteConfig[]) {
    const navigation =
        data &&
        data.map(children => {
            if (getSafe(() => children.sidebar === true))
                return {
                    type: 'submenu',
                    ...children,
                };
            return null;
        });
    return navigation;
}

function parseBTCNav(
    navList: IRoute.IRouteConfig[],
    teamStore: any,
    navigationStore: any,
    flagStore: any,
    userStore: any = {},
) {
    const { team, orders, tools, performance, lead } = routeKeys;
    let navigationLinks = {};
    const navigation = {};

    navList
        // persona logic injection
        .filter(menuItem => {
            if (menuItem.sidebar === false) return false;
            if (menuItem.key === lead.root && !flagStore.isFeatureEnabled('lead')) return false;
            if (menuItem.key === team.root) {
                const { hasTeam } = userStore;
                if (!hasTeam) return false;
            }
            return true;
        })
        .map(menuItem => {
            const children: IRoute.IRouteConfig[] = getSafe(() =>
                parseBTCSubNav(menuItem.children).filter(child => child),
            );
            if (menuItem.sidebar === true) navigation[menuItem.key] = [];
            if (children) {
                children
                    .filter(nav => {
                        if (nav.key === team.activity) {
                            try {
                                return teamStore.activityRequirements;
                            } catch (e) {
                                return false;
                            }
                        }
                        if (nav.key === performance.incentives) {
                            try {
                                return flagStore.isFeatureEnabled('incentivesDashboard');
                            } catch (e) {
                                console.error(e);
                                return false;
                            }
                        }
                        return true;
                    })
                    .map(nav => {
                        if (nav) {
                            navigation[menuItem.key] = navigation[menuItem.key] || [];
                            navigation[menuItem.key].push([
                                nav.name,
                                nav.redirectTo
                                    ? nav.redirectTo
                                    : navigationStore.getPathFromRouteKey(nav.key),
                            ]);
                        }
                    });
            }
        });

    navigationLinks = { ...navigationLinks, ...navigation };
    return navigationLinks;
}
export { isBackPointPath, parseNavigation, parseBTCNav, parseBTCSubNav, getNavigationItemUrl };
