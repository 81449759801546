export const synonymList = {
    facial: ['face'],
    face: ['facial'],
    tinted: ['tint', 'tinting'],
    moisturizer: ['moisturize', 'moisturizing', 'moisturizers'],
    moisturizing: [
        'moisturize',
        'moisturizer',
        'moisturizers',
        'moisturise',
        'moisturiser',
        'moisturisers',
    ],
    lotion: ['lotions'],
    serum: ['serums'],
    scrub: ['scrubs'],
    wash: ['washes'],
    cleansing: ['cleanser', 'cleansers'],
    cleanser: ['cleansing'],
    plumbing: ['plumper'],
    lip: ['lips'],
    mattifying: ['mattifying'],
    brow: ['eyebrow'],
    mask: ['masks'],
    brush: ['brushes'],
    oil: ['oils', 'oily'],
    concealer: ['concealers'],
    brightening: ['lightening', 'lightener', 'brightener'],
    shampoo: ['shampoos'],
    conditioner: ['conditioners'],
    protect: ['protective'],
    bronzer: ['bronzers'],
    volumizing: ['volumizer'],
    volume: ['volumizer', 'volumizing'],
    smooth: ['smoothening'],
    repair: ['repairing'],
    sunscreen: ['sunblocker', 'sunscren'],
    foundation: ['foundations'],
    hydrating: ['moisturizing', 'moisturizer', 'moisturize'],
    cloths: ['wipes'],
    baby: ['babies'],
    collection: ['collections'],
    bag: ['bags'],
    côte: ['cote'],
};
