import firebase from '@btc-frontend/middleware/firebase/init';
import { fetchWithTimeout } from '@beautycounter/utils/fetch';
import { nogento } from '@btc-frontend/config';
import { writeUserData } from './index';

/**
 * Updates user in Firebase Auth
 * @param {string} password
 */

export async function updateUser({ name, lastName, ...rest }) {
    const auth = await firebase.auth();
    const token = auth.currentUser && (await auth.currentUser.getIdToken());

    let response = {};
    try {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                channel: 2,
                firstName: name,
                lastName,
                ...rest,
            }),
        };
        response = await fetchWithTimeout(`${nogento.resetPasswordv2}`, options, 12500);

        const success = response.ok;
        const result = { success };
        if (!success) {
            const responseBody = await response.json();
            if (!!responseBody.error) result.error = responseBody.error;
        }

        return result;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Verifies whether a password is correct
 * in Firebase Auth
 * @param {string} password
 */

export async function verifyCurrentPassword(password) {
    const auth = await firebase.auth();

    try {
        const credential = auth.app.firebase_.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            password,
        );
        await auth.currentUser.reauthenticateWithCredential(credential);

        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Updates password in Firebase Auth
 * @param {string} password
 */
// may be deprecated
export async function updatePassword(password) {
    const auth = await firebase.auth();

    try {
        await auth.currentUser.updatePassword(password);
        await writeUserData({ lastPasswordUpdated: new Date().toISOString() });

        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}
