// not_started is something that hasn't begun yet
// started is something started but not yet met
// met is something started and met
// completed is something started, met, and onto the next month
// not_completed is something started, not met, and onto the next month

export const NotStarted = 'not_started',
    Started = 'started',
    Met = 'met',
    Completed = 'completed',
    NotCompleted = 'not_completed',
    // new start counting statuses

    NONE = 'NONE',
    OLD = 'OLD',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED';
