import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import messagingStore from '@btc-frontend/stores/messagingStore';
import FetchQLExtension from './fetchQLExtension.js';
import { FetchQLOptions } from 'fetchql';

export interface ITypeOption {
    name: string;
    args?: object;
    variables?: string;
}

export interface ITypeOptions extends Array<ITypeOption> {}

export interface GraphQLClassInterface {
    new (options: object);
    useAuth(): null;
    addType(name: string, args: object, variables: string | Array<string>): null;
    types: Array<ITypeOptions>;
    options: FetchQLOptions;
}

interface EnumTypeStringInterface {
    new (value: string);
    value: string;
}
export class EnumTypeString<EnumTypeStringInterface> {
    value: string;
    constructor(value) {
        const snakeToCamel = s => s.replace(/(\-\w)/g, m => m[1].toUpperCase());
        this.value = snakeToCamel(value);
    }

    get() {
        return this.value;
    }
}

export async function fetchGraphQLFields(types: ITypeOptions = [], tag: string = undefined) {
    try {
        const options: any = {};
        if (tag !== undefined) options.urlTag = tag;
        const graphql = new GraphQLClass(options);
        await graphql.useAuth();
        types.forEach(({ name, args, variables }) => graphql.addType(name, args, variables));
        const response = await graphql.execute();
        if (response && response.status) {
            return { success: false };
        }
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, error };
    }
}

class GraphQLClass<GraphQLClassInterface> {
    types: Array<ITypeOptions>;
    options: FetchQLOptions;
    mutations: ITypeOptions[];

    constructor(options = {}) {
        this.types = [];
        this.mutations = [];

        this.options = {
            url: `${nogento.graphql}`, // GraphQL server address
            ...options,
            interceptors: [],
            onStart(requestQueueLength) {}, // callback of a new request queue
            onEnd(requestQueueLength) {}, // callback of a request queue finished
            omitEmptyVariables: false, // remove null props(null or '') from the variables
        };
        // @ts-ignore
        if (options.urlTag) this.options.url = `${this.options.url}?${options.urlTag}`;
        this.execute = this.execute.bind(this);
        this.export = this.export.bind(this);
        this.getExecutionQuery = this.getExecutionQuery.bind(this);
    }

    async useAuth() {
        const auth = await firebase.auth();
        // @ts-ignore
        if (!auth.currentUser) return false;
        // @ts-ignore
        const token = await auth.currentUser.getIdToken(true);
        this.options = {
            ...this.options,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            //TODO: handling fetchQL error below
            interceptors: [
                {
                    response: function(response) {
                        return response;
                    },
                    responseError: function(error) {
                        console.log('response error:', error);
                        // return Promise.reject(error);
                    },
                },
            ],
        };
        return true;
    }

    addMutation(options: ITypeOptions) {
        if (!(options.name && options.variables)) return false;
        this.mutations = this.mutations.filter(type => type.name !== options.name);
        this.mutations = [...this.mutations, options];
    }

    addType(name: string, args: object = {}, variables: string | Array<string> = []) {
        if (!name || !variables) return false;
        this.types = this.types.filter(type => type.name !== name);
        this.types = [
            ...this.types,
            {
                name,
                args,
                variables,
            },
        ];
    }

    typesParse(value) {
        if (Array.isArray(value)) {
            let arrayStr = '[';
            value.forEach((item, index) => {
                arrayStr = `${arrayStr}${this.typesParse(item)}`;
                if (index < value.length - 1) arrayStr = `${arrayStr}, `;
            });
            arrayStr = `${arrayStr}]`;
            return arrayStr;
        }

        if (typeof value === 'string') return `"${value}"`;
        if (value === null) return `null`;

        if (typeof value === 'object') {
            if (typeof value.get === 'function') return value.get();
            return `{${Object.keys(value).reduce(
                (acc, key) => `${acc}${key}:${this.typesParse(value[key])},`,
                '',
            )}}`;
        }

        return value;
    }

    export() {
        try {
            const query = this.getExecutionQuery();
            const uri = `${nogento.graphqlExport}?query=${query}`;
            const a = document.createElement('a');
            a.href = uri;
            a.setAttribute('target', '_blank');
            a.click();
        } catch (e) {
            messagingStore.addMessage(`Unable to trigger export,${e}`);
        }
    }

    getExecutionQuery(typeOptions: ITypeOptions[] = this.types) {
        let query = `{${typeOptions.map(({ name, args, variables }) => {
            if (!name) return '';
            let argString = '';
            const argKeys = Object.keys(args);
            if (argKeys.length > 0) {
                argKeys.forEach(key => {
                    const value = this.typesParse(args[key]);
                    argString = `${argString}${argString.length > 0 ? ', ' : ''}${key}: ${value}`;
                });
                argString = `(${argString})`;
            }

            const variableQuery = this.getVariableString(variables);
            return `${name}${argString} ${variableQuery}`;
        })}}`;
        return query;
    }

    getVariableString(variables: string) {
        if (!variables) return '';
        if (Array.isArray(variables)) {
            if (!!variables.length) return `{${variables}}`;
            else return '';
        }
        return `{${variables}}`;
    }

    async mutate() {
        try {
            const mutation = `mutation${this.getExecutionQuery(this.mutations)}`;
            const fetch = new FetchQLExtension(this.options);
            const response = await fetch.query({
                query: mutation,
                variables: {},
                opts: {},
                isExport: false,
            });
            return response;
        } catch (e) {
            if (e == undefined) {
                console.error(
                    'fetchQL error: if data in response is "null" or if all properties of data is "null"',
                );
            } else {
                console.error('graphql error: ', e);
            }
        }
    }

    async execute({ isExport = false } = {}) {
        try {
            const query = this.getExecutionQuery();
            const fetch = new FetchQLExtension(this.options);
            const response = await fetch.query({
                query,
                variables: {},
                opts: {},
                isExport,
            });

            return response;
        } catch (e) {
            if (e == undefined) {
                console.error(
                    'fetchQL error: if data in response is "null" or if all properties of data is "null"',
                );
                // fetching library fails on 400 range errors.
            } else if (e[0] && e[0].stack && e[0].stack.status === 403) {
                return e[0].stack;
            } else {
                console.error('graphql error: ', e);
            }
        }
    }
}

export default GraphQLClass;
