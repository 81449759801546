import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Location from '@btc-frontend/middleware/utils/Location';
import { observer, inject } from 'mobx-react';
import { parseContentURL } from '@btc-frontend/middleware/utils/url';
import analyticsStore from '@btc-frontend/stores/analyticsStore';
import { bccomDomain } from '@btc-frontend/config';

import s from './Link.scss';
import cx from 'classnames';
import { locales, redirectUrl, prefix } from '@btc-frontend/config';
import { startsWith } from 'lodash';

function isLeftClickEvent(event) {
    return event.button === 0;
}

function isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

@withStyles(s)
@inject('navigationStore', 'localeStore', 'sessionStore')
@observer
class Link extends Component {
    static propTypes = {
        name: pt.string,
        to: pt.oneOfType([pt.string, pt.object, pt.bool]),
        onClick: pt.func,
        onMouseEnter: pt.func,
        className: pt.string,
        wrapperClassName: pt.string,
        underlined: pt.bool,
        custom: pt.bool,
        hasHoverAnimation: pt.bool,
        children: pt.node,
        icon: pt.node,
        localize: pt.bool,
        ariaLabel: pt.string,
    };

    static defaultProps = {
        hasHoverAnimation: true,
        underlined: false,
        custom: false,
        localize: true,
        testId: null,
    };

    handleNativeLinkClick = event => {
        analyticsStore.linkClicked('Internal', this.props);
        const { navigationStore } = this.props;
        let allowTransition = true,
            clickResult;

        const to = this.props && this.props.to;

        const newWindow = this.props && this.props.newWindow && { newWindow: true, assign: true };

        if (this.props && this.props.onClick) {
            clickResult = this.props.onClick(event);
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return;
        }

        if (clickResult === false || event.defaultPrevented === true) {
            allowTransition = false;
        }

        /* To avoid Uncaught SecurityError: Failed to execute 'pushState' on 'History':
                                            A history state object with URL 'mailto:*@*.**' cannot be created
         */
        if (!('' + to).toLowerCase().startsWith('mailto:')) {
            event.preventDefault();

            if (allowTransition) {
                const link = event.currentTarget;

                if (to) {
                    navigationStore.to({
                        url: this.props.to,
                        ...newWindow,
                    });
                } else {
                    navigationStore.to({
                        url: link.pathname,
                        search: link.search,
                    });
                }
            }
        }
    };

    handleCustomLinkClick = () => {
        analyticsStore.linkClicked('External', this.props);
        const assign = startsWith(this.props.to, 'http') || startsWith(this.props.to, 'mailto');
        const newWindow =
            !startsWith(this.props.to, 'mailto') && !startsWith(this.props.to, redirectUrl);

        if (this.props && this.props.onClick) {
            this.props.onClick(event);
        }

        const url = this.props.to;

        if (url.includes(bccomDomain.quickOrder) || url.includes(bccomDomain.socials)) {
            this.props.sessionStore.saveKey('isNewBtc', true);
        }

        this.props.navigationStore.to({
            url,
            assign,
            newWindow,
        });
    };

    createStaticLink = url => (url.startsWith(prefix) ? url : prefix + url);

    renderIcon() {
        return <span className={s.icon}>{this.props.icon}</span>;
    }

    renderNativeLink() {
        const {
            underlined,
            name,
            wrapperClassName,
            className,
            onMouseEnter,
            to,
            image,
            wrap,
            styles,
            navigationStore,
            ariaLabel,
            testId,
        } = this.props;
        const config = {
            key: `link-${name}`,
            className: cx(s.aTag, className, {
                [s.underlined]: underlined,
            }),
            'data-testid': testId,
            style: styles,
            href: this.createStaticLink(to),
            onClick: this.handleNativeLinkClick,
            onMouseEnter: onMouseEnter,
            'data-onclick': to,
        };

        if (!image) {
            if (!wrap)
                return (
                    <span className={wrapperClassName}>
                        <a {...config} aria-label={ariaLabel}>
                            {this.props.children ? this.props.children : name}
                        </a>
                    </span>
                );
            else
                return (
                    <a {...config} aria-label={ariaLabel} className={s.wrap}>
                        {this.props.children ? this.props.children : name}
                    </a>
                );
        } else {
            return (
                <img
                    className={wrapperClassName}
                    src={this.props.image}
                    alt={ariaLabel || ''}
                    {...config}
                />
            );
        }
    }

    renderCustomLink() {
        const { className, name, hasHoverAnimation, underlined, testId } = this.props;

        return (
            <span
                className={cx(s.custom, className, {
                    [s.hoverAnimation]: hasHoverAnimation,
                    [s.underlined]: underlined,
                })}
                onClick={this.handleCustomLinkClick}
                data-testid={testId}
            >
                {this.props.children ? this.props.children : name}
            </span>
        );
    }

    render() {
        let custom = this.props.custom;

        if (startsWith(this.props.to, 'http') || startsWith(this.props.to, 'mailto:'))
            custom = true;

        try {
            if (this.props.to) return !custom ? this.renderNativeLink() : this.renderCustomLink()
            else
                return (
                    <span className={this.props.className} data-testid={this.props.testId}>
                        {this.props.children}
                    </span >
                );
        } catch (e) {
            console.error('An error with this link:', this.props.to, e);
            return 'error';
        }
    }
}

export default Link;
