import i18n from 'i18next';
// import { reactI18nextModule } from 'react-i18next';

// import backend from 'i18next-xhr-backend';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            'Welcome to React': 'Welcome to React and react-i18next',
        },
    },
};

// const changeLanguage = (lng) => {
//   i18n.changeLanguage(lng);
// }

i18n
    // .use(detector)
    // .use(backend)
    // .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en', // use en if detected lng is not available

        // saveMissing: true, // send not translated keys to endpoint

        // keySeparator: false, // we do not use keys in form messages.welcome
        keySeparator: '>',
        nsSeparator: '|',

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
