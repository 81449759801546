import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

interface IProps {
    t();
    classes;
    messagingStore: {
        activeSnackbarMessage: IMessages.IMessage;
        unread: IMessages.IMessage[];
        markRead(message: IMessages.IMessage);
    };
}

const styles: any = theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        fontSize: '15px !important',
        margin: '16px !important',
        textAlign: 'center',
    },
});

@inject('messagingStore')
@observer
class SnackbarWrapper extends Component<IProps> {
    render() {
        const {
            t,
            classes,
            messagingStore: { activeSnackbarMessage, unread, markRead },
        } = this.props;

        const handleClose = () => markRead(activeSnackbarMessage);
        if (!activeSnackbarMessage) return <div />;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={unread && unread.length > 0}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    classes: { root: classes.message },
                }}
                message={<div>{activeSnackbarMessage.message}</div>}
            />
        );
    }
}

export default withStyles(styles)(SnackbarWrapper);
