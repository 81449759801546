/**
 * Sandbox environment
 * This configuration defines special overrides for your sandbox environment
 */

const sandbox = typeof __SANDBOX__ !== 'undefined' ? __SANDBOX__ : 'sandbox';

const environment = {
    // whether to use shortened CSS class names
    cssShortening: true,

    // whether to enable detailed console messaging
    verbose: true,

    // whether to use JS minification and ahead-of-time compilation
    codeOptimization: true,

    // enable the hidden developer tools menu
    developerTools: true,

    flags: {
        contentDrafts: false,
        renewalStatusOverride: false,
        renewalFeature: true,
    },

    puff: {
        baseQuery: '',
    },

    analytics: {
        fullStory: {
            enabled: false,
        },
        optimizely: {
            enabled: true,
        },
        segment: {
            enabled: false,
        },
    },

    liveUrl: `https://btc-${sandbox}.firebaseapp.com`,

    firebaseProject: `btc-${sandbox}`,
};

export { environment };
