import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -7,
    y: -7,
    width: 65,
    height: 50,
};

class Clienteling extends Component {
    render() {
        return (
            <Icon size={{ width: 52, height: 40 }} {...this.props} altText='Clienteling' viewBox={viewBox}>
                <defs>
                    <polygon id="path-1" points="0 0 55 0 55 57 0 57" />
                </defs>
                <g id="Symbols" stroke="#000000" strokeWidth="1" fillRule="evenodd">
                    <g id="clienteling" transform="translate(-1.000000, -8.000000)">
                        <g id="icon-btc-ordering">
                            <path
                                d="M10.8333333,11.5727273 C8.26452599,11.5727273 6.17737003,13.7161157 6.17737003,16.4090909 C6.17737003,19.0471074 8.26452599,21.2454545 10.8333333,21.2454545 C13.4021407,21.2454545 15.4892966,19.1020661 15.4892966,16.4090909 C15.4892966,13.7710744 13.4021407,11.5727273 10.8333333,11.5727273 M10.8333333,22.4545455 C7.62232416,22.4545455 5,19.7615702 5,16.4090909 C5,13.0566116 7.62232416,10.3636364 10.8333333,10.3636364 C14.0443425,10.3636364 16.6666667,13.0566116 16.6666667,16.4090909 C16.6666667,19.7615702 14.0443425,22.4545455 10.8333333,22.4545455"
                                id="Fill-1"
                                fillRule="evenodd"
                            />
                            <path
                                d="M3.21362799,29.8729604 L20.0644567,29.8729604 C18.8489871,26.2191142 15.53407,23.7278555 11.611418,23.7278555 C7.68876611,23.7278555 4.37384899,26.2191142 3.21362799,29.8729604 L3.21362799,29.8729604 Z M21.6666667,31.0909091 L1.66666667,31.0909091 L1.83241252,30.3158508 C2.88213628,25.7208625 6.97053407,22.4545455 11.6666667,22.4545455 C16.3627993,22.4545455 20.3959484,25.6655012 21.5009208,30.3158508 L21.6666667,31.0909091 Z"
                                id="Fill-3"
                                fillRule="evenodd"
                            />
                            <path
                                d="M40.8330657,23.6636364 C38.2641406,23.6636364 36.1768888,25.8070248 36.1768888,28.5 C36.1768888,31.1929752 38.2641406,33.3363636 40.8330657,33.3363636 C43.4019909,33.3363636 45.4892426,31.1929752 45.4892426,28.5 C45.4892426,25.8070248 43.4019909,23.6636364 40.8330657,23.6636364 M40.8330657,34.5454545 C37.6219093,34.5454545 35,31.8524793 35,28.5 C35,25.1475207 37.6219093,22.4545455 40.8330657,22.4545455 C44.0442222,22.4545455 46.6666667,25.1475207 46.6666667,28.5 C46.6666667,31.8524793 44.0442222,34.5454545 40.8330657,34.5454545"
                                id="Fill-5"
                                fillRule="evenodd"
                            />
                            <path
                                d="M33.213628,43.6357809 L50.0644567,43.6357809 C48.8489871,39.9813811 45.53407,37.4901224 41.611418,37.4901224 C37.7440147,37.5460373 34.4290976,40.037296 33.213628,43.6357809 L33.213628,43.6357809 Z M51.6666667,44.9090909 L31.6666667,44.9090909 L31.8324125,44.133479 C32.8821363,39.5390443 36.9705341,36.2727273 41.6666667,36.2727273 C46.3627993,36.2727273 50.3959484,39.483683 51.5009208,44.133479 L51.6666667,44.9090909 Z"
                                id="Fill-7"
                                fillRule="evenodd"
                            />
                            <path
                                d="M44.4457641,19.5407816 C41.5083135,13.0998301 35.1900238,8.63636364 27.8186857,8.63636364 C25.7125891,8.63636364 23.6064925,9.03186066 21.6666667,9.70985556 L22.0546318,10.8963466 C23.8836105,10.2183517 25.8234363,9.87935429 27.8186857,9.87935429 C34.469517,9.87935429 40.2335709,13.777825 43.1155978,19.4842821 C43.3372922,19.8797791 43.5035629,20.3317757 43.6698337,20.7272727 L45,20.7272727 C44.7783056,20.3317757 44.6120348,19.9362787 44.4457641,19.5407816"
                                id="Fill-9"
                                fillRule="evenodd"
                            />
                            <path
                                d="M27.0945946,45.3260188 C21.2387387,45.0883699 15.777027,41.69279 12.7364865,36.2727273 L11.6666667,36.9278997 C14.9324324,42.7059248 20.7882883,46.3981191 27.0945946,46.6363636 L27.713964,46.6363636 L28.3333333,46.6363636 L28.3333333,45.3260188 L27.713964,45.3260188 L27.0945946,45.3260188 Z"
                                id="Fill-11"
                                fillRule="evenodd"
                            />
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <g id="Clip-2" />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Clienteling;
