import { asyncModels, locales as localeDefinitions } from '@btc-frontend/config';
import { observable, computed, action, autorun, toJS, set } from 'mobx';
import { saveSessionItem } from '@btc-frontend/middleware/services/session';
import GraphQLFetch from '@btc-frontend/middleware/api/graphql';

import {
    login as loginFirebase,
    userlogin,
    loginWithToken,
    logout as logoutFirebase,
    createAccount as createFirebaseAccount,
    subscribeIsLogged,
    writeUserData,
    checkEmailExists,
    resetPassword as resetPasswordFirebase,
    confirmPasswordReset as confirmPasswordResetFirebase,
} from '@btc-frontend/middleware/services/user';

import Logger from '@btc-frontend/middleware/services/logger';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import requestPWS, { getSocials } from '@btc-frontend/middleware/api/phoenix';
import { setSignature, getSignature } from '@beautycounter/utils/session/signature';

import { cloneDeep, find, pick, debounce } from 'lodash';
import {
    CLIENT,
    MEMBER,
    CONSULTANT,
    EMPLOYEE,
    GUEST,
    userTypes,
} from '@btc-frontend/middleware/constants/userStates';
import { languageIds } from '@btc-frontend/middleware/constants/language';
import { PWS_EXPIRED, PWS_ACTIVE, PWS_RENEWAL_NOTICE } from '@beautycounter/constants/pwsStatus';
import { DAYS_IN_MS, ENROLLED_90_DAYS } from '@btc-frontend/constants/enrollmentDate';

import contentStore from '@btc-frontend/stores/contentStore';
import sessionStore from '@btc-frontend/stores/sessionStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import localeStore from '@btc-frontend/stores/localeStore';
import analyticsStore from '@btc-frontend/stores/analyticsStore';
import formStore from '@btc-frontend/stores/formStore';
import flagStore from '@btc-frontend/stores/flagStore';

import { userQuery, coreBusinessQuery } from './queries';
import { getPolicy, updatePolicy } from '@btc-frontend/middleware/services/policiesProcedures';

const userAccountDefault = {
    userAccount: {
        pwsKey: '',
        pwsStatus: PWS_ACTIVE, // do not set this to expired.
        leads: [],
        userType: CONSULTANT,
    },
};

// To be deprecated in Nogento
const typeMappings = [
    {
        magento: '1',
        phoenix: '0',
    },
    {
        magento: '7',
        phoenix: '3', // client
    },
    {
        magento: '8',
        phoenix: '2', // member
    },
    {
        magento: '9',
        phoenix: '1', // consultant
    },
    {
        magento: '10',
        phoenix: '4', // employee
    },
];
// //////////////////////////////////

class UserStore {
    @observable userInfo;
    @observable coreBusiness;
    @observable isLogged;
    @observable guest;
    @observable isEnrolling;
    @observable titleConfig;
    @observable latestPolicy;
    @observable showPolicyPrompt;

    constructor() {
        this.userInfo = {};
        this.subscriptionInfo = {
            pwsStatus: PWS_ACTIVE,
        };
        this.isLogged = false;
        this.guest = {};
        this.isEnrolling = false;
        this.groupPricingRules = {};
        this.showPolicyPrompt = false;

        __BROWSER__ &&
            requestAnimationFrame(() => {
                if (sessionStore.getKey('user')) {
                    if (contentStore && contentStore.flags('nogento')) {
                        this.getTitleConfig();
                        debounce(() => this.verifyLoggedIn(), 100);
                    } else {
                        interfaceStore.asyncLoad(this.verifyLoggedIn);
                    }
                }
                this.mountReactions();
            });
    }

    mountReactions() {
        autorun(() => {
            if (this.isLogged) {
                sessionStore.saveKey('user', this.userInfo);
            }
        });
    }

    // plain text return of user group type.
    @computed
    get stringType() {
        switch (this.userInfo.default.groupid) {
            case '1':
                return 'Consultant';
            case '3':
                return 'Client';
            default:
                return 'Unknown';
        }
    }

    @computed
    get user() {
        return getSafe(() => JSON.parse(JSON.stringify(this.userInfo)));
    }

    @computed
    get userId() {
        return getSafe(() => this.userInfo.uid || this.userInfo.magentoid);
    }

    @computed
    get userType() {
        return this.isLogged ? getSafe(() => this.userInfo.userType.toString()) : GUEST;
    }

    // To be deprecated in Nogento
    @computed
    get groupId() {
        return getSafe(
            () => this.userType || this.convertMagentoId(this.userInfo.groupid).toString(),
        );
    }
    // //////////////////////////////////

    // To be deprecated in Nogento
    @computed
    get magentoGroupId() {
        return getSafe(() =>
            this.userType
                ? this.convertPhoenixId(this.userType).toString()
                : this.userInfo.groupid.toString(),
        );
    }
    // //////////////////////////////////

    // To be deprecated in Nogento (no longer needed with new promo engine) */
    @computed
    get phoenixGroupId() {
        return this.userType
            ? parseInt(this.userType)
            : this.convertMagentoId(this.userInfo.groupid);
    }
    // //////////////////////////////////

    // To be deprecated in Nogento
    convertMagentoId(input) {
        return getSafe(() => find(typeMappings, { magento: input }).phoenix) || 7;
    }
    // //////////////////////////////////

    // To be deprecated in Nogento
    convertPhoenixId(input) {
        return getSafe(() => find(typeMappings, { phoenix: input }).magento) || 7;
    }
    // //////////////////////////////////

    @computed
    get hasOpenSocial() {
        return getSafe(() => this.socials.length > 0);
    }

    @computed
    get consultantId() {
        return getSafe(() => this.userInfo.consultantId);
    }

    @computed
    get consultant() {
        return getSafe(() => this.userInfo.consultant);
    }

    @computed
    get socials() {
        return getSafe(() => this.userInfo.socials);
    }

    @computed
    get isConsultant() {
        return this.groupId === CONSULTANT;
    }

    @computed
    get isMember() {
        return this.groupId === MEMBER;
    }

    @computed
    get isClient() {
        return this.groupId === CLIENT || (this.isLogged && !this.groupId);
    }

    @computed
    get isEmployee() {
        return this.groupId === EMPLOYEE;
    }

    @computed
    get pwsUrl() {
        return getSafe(() => this.consultant.pwsKey);
    }

    @computed
    get countryId() {
        return getSafe(() => this.userInfo.countryId);
    }

    @computed
    get fullName() {
        const firstName = getSafe(() => this.userInfo.firstName);
        const lastName = getSafe(() => this.userInfo.lastName);
        return firstName && lastName ? `${firstName} ${lastName}` : '';
    }

    @computed
    get email() {
        return getSafe(() => this.userInfo.email);
    }

    @computed
    get isGuest() {
        return getSafe(() => this.guest.email);
    }

    @computed
    get phoenixId() {
        return getSafe(() => this.userInfo.phoenixId);
    }

    @computed
    get isPWSRenewal() {
        return getSafe(() => this.subscriptionInfo.pwsStatus === PWS_RENEWAL_NOTICE);
    }

    @computed
    get leads() {
        return getSafe(() => this.userInfo.leads);
    }

    @computed
    get uplineCount() {
        return getSafe(() => this.userInfo.uplineCount);
    }

    @computed
    get hasTeam() {
        return getSafe(() => this.coreBusiness.flatCount) >= 1;
    }

    @computed
    get enrolled90DaysOrMore() {
        const enrolledDate = this.userInfo.enrollmentDateUTC;
        const timeDiff = new Date().getTime() - new Date(enrolledDate).getTime();
        const days = Math.ceil(timeDiff / DAYS_IN_MS);

        return days >= ENROLLED_90_DAYS;
    }

    @action
    verifyLoggedIn = async (force = false) => {
        // New authentication: To be kept
        if (this.isLogged) return true;
        return new Promise((resolve, reject) =>
            subscribeIsLogged(async auth => {
                if (!auth) resolve(false);
                const login = await auth();
                if (login && login.success) {
                    const response = await this.fetchNogentoUserData();
                    const { data, success } = response;
                    if (success && data && data.userAccount) {
                        this.addLoginState({ ...data.userAccount });
                        resolve(true);
                    } else {
                        this.addLogoutState();
                        resolve(false);
                    }
                } else {
                    this.addLogoutState();
                    resolve(false);
                }
            }),
        );
    };

    @action
    loginWithToken = async (token, options = {}) => {
        if (options.progress) interfaceStore.showProgress('user-login');
        this.verifyLoggedIn();
        const response = await loginWithToken(token);
        interfaceStore.hideProgress('user-login');
        if (response.email) {
            this.addLoginState(response);
            return {
                success: true,
            };
        }
        logoutFirebase();
        return {
            ...response,
            success: false,
        };
    };

    @action
    login = async (form, options = {}) => {
        const cleanLoginFeatureFlag = flagStore.isFeatureEnabled('cleanLogin');
        // New authentication: To be kept
        if (options.progress) interfaceStore.showProgress('user-login');
        let response;
        if (cleanLoginFeatureFlag) {
            response = await userlogin(form.email, form.password);
        } else response = await loginFirebase(form.email, form.password);

        const pickedKeys = [
            'code',
            'message',
            'email',
            'magentoId',
            'phoenixId',
            'phoenixCredential',
            'uid',
        ];

        Logger.save({
            key: 'nogento-login',
            data: {
                response: pick(response, pickedKeys),
                payload: { email: form.email },
            },
        });

        interfaceStore.hideProgress('user-login');

        if (response.email) {
            this.addUserInfo(response);
            this.isLogged = true;
            return {
                uid: getSafe(() => response.uid),
                success: true,
            };
        }
        logoutFirebase();
        return {
            ...response,
            success: false,
        };
    };

    fetchCoreBusinessData = async () => {
        const queryList = [{ name: 'coreBusiness', variables: coreBusinessQuery }];
        const response = await this.fetchUserFields(queryList, 'core-business');
        const { success, data } = response;
        if (!(success && data && data.coreBusiness)) return;
        this.coreBusiness = data.coreBusiness;
    };

    fetchNogentoUserData = async () => {
        const queryList = [{ name: 'userAccount', variables: userQuery }];
        const response = await this.fetchUserFields(queryList);
        return response;
    };

    fetchUserFields = async (types = [], tag = undefined) => {
        try {
            const options = {};
            if (tag !== undefined) options.urlTag = tag;
            const graphql = new GraphQLFetch(options);
            await graphql.useAuth();
            types.forEach(({ name, args, variables }) => graphql.addType(name, args, variables));
            const response = await graphql.execute();
            if (response && response.status && response.status === 403) {
                this.subscriptionInfo.pwsStatus = PWS_EXPIRED;
                return { success: false };
            }
            if (response && response.data) {
                if (response?.data?.userAccount)
                    this.subscriptionInfo.pwsStatus = response?.data?.userAccount?.pwsStatus;
                return { success: true, data: response.data };
            }
            return { success: true, data: userAccountDefault };
        } catch (error) {
            return { success: false, error };
        }
    };

    updateUserInfo = (key, value) => {
        switch (key) {
            case 'demographics':
                this.userInfo = cloneDeep({
                    ...toJS(this.userInfo),
                    [key]: value,
                });
                break;
            default:
        }
    };

    addUserInfo = account => {
        const {
            firstname,
            firstName,
            lastname,
            lastName,
            groupid,
            phoenixId,
            encoreid,
            email,
            emailAddress,
            pwsStatus,
            userType,
        } = account;

        // Allow alternate variable names
        // for backwards compatibility with Magento login
        // (consider cleaning this up once we get rid of Magento)
        this.userInfo = {
            name: firstname || firstName || 'Unknown',
            ...account,
            firstName: firstname || firstName || 'Unknown',
            lastName: lastname || lastName || 'Unknown',
            groupid: (groupid && groupid.toString()) || 0,
            consultantId: phoenixId || encoreid || null,
            email: email || emailAddress,
            pwsStatus,
            userType,
        };
    };

    @action
    addLoginState = account => {
        this.addUserInfo(account);
        this.fetchCoreBusinessData();
        this.saveSignature();

        // DS-1477: calls requestPWS to obtain the pwsKey
        this.lookupConsultantDetails();
        flagStore.isFeatureEnabled('policiesAndProcedures') && this.getCurrentPolicy();
        let activeLocale = localeDefinitions.find(
            x => x.countryId === account.countryId && x.languageId === account.languageId,
        );

        // failsafe fallback to ensure that an active locale will always be set as long as a country id is set.
        if (!activeLocale) {
            activeLocale = localeDefinitions.find(x => x.countryId === account.countryId);
            console.error(
                'there was no matching language id for this user. A default language has been selected, but this might indicate a larger problem.',
            );
        }
        if (activeLocale) localeStore.setActive(activeLocale, true);
        else {
            console.error(
                'There is no matching country id for this user which may cause the app to be be unstable. This must be corrected and not set to default or else the user data may be corrupted.',
            );
        }

        this.isLogged = true;
        this.guest = {};

        interfaceStore.hideProgress('user-login');

        saveSessionItem('user', {
            loggedIn: true,
            ...this.userInfo,
        });
    };

    @action
    getRefreshedLeads = async () => {
        const response = await this.fetchUserFields([
            {
                name: 'userAccount',
                variables: `
            leads {
                firstName
                lastName
                address {
                    city
                    state
                }
                phoenixId
                assignedDate
                }
            `,
            },
        ]);

        // return existing leads if there was a fetch issue
        if (
            !(
                response.success &&
                response.data &&
                response.data.userAccount &&
                response.data.userAccount.leads
            )
        ) {
            return this.userInfo.leads;
        }

        const { leads } = response.data.userAccount;
        this.userInfo = cloneDeep({
            ...toJS(this.userInfo),
            leads,
        });
        return leads;
    };

    @action
    processConsultantData = consultant => ({
        ...consultant,
        fullName: `${consultant.firstName} ${consultant.lastName}`,
        url: consultant.key || '/',
    });

    @action
    setConsultant = consultant => {
        this.userInfo = cloneDeep({
            ...toJS(this.userInfo),
            consultant: this.processConsultantData(consultant),
        });
    };

    @action
    setGuest = (email, password) => {
        this.guest = {
            email,
            password,
        };
        this.guest = cloneDeep(this.guest);
    };

    @action
    clearGuest = () => {
        this.guest = {};
    };

    @action
    lookupConsultantDetails = async () => {
        try {
            const result = await requestPWS({ id: this.consultantId });
            if (result && result.data && result.data.id && result.data.active) {
                const data = {
                    ...result.data,
                };
                this.setConsultant(data);
                return toJS(this.consultant);
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    @action
    lookupOpenSocials = async () => {
        try {
            const result = await getSocials(
                this.consultantId,
                localeStore.activeLocale.phoenixCountry,
            );
            if (result) {
                this.setSocials(result);
                return toJS(this.socials);
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    @action
    setSocials = socials => {
        this.userInfo = cloneDeep({
            ...toJS(this.userInfo),
            socials,
        });
    };

    @computed
    get userFlags() {
        return getSafe(() => this.userInfo.flags) || {};
    }

    saveSignature(customId, reset) {
        const signature = getSignature('userSig') || {};

        setSignature({
            name: 'userSig',
            value: {
                ...signature,
                loggedIn: this.isLogged ? '1' : '0',
                type: String(this.userInfo.userType) || String(GUEST),
                email: this.email || '',
                savedLanguage: localeStore.activeLocale.lang,
                savedCountry: localeStore.activeLocale.countryShort,
                uid: customId || signature.uid || this.userId,
                flags: this.userFlags,
            },
        });
        flagStore.rebucket();
    }

    @action
    logout = async (load = true) => {
        // New authentication: To be kept
        if (load) interfaceStore.showProgress('user-logout');
        formStore.clear('shipping', 'billing', 'promo');

        const loggingOut = await logoutFirebase(async () => {
            this.addLogoutState();
            requestAnimationFrame(() => {
                sessionStore.logoutOperations();
                sessionStore.clearAllKeyInstances('survey-selections');
            });
            interfaceStore.closeStickyModal();
            interfaceStore.hideProgress('user-logout');
            analyticsStore.reset();
        });
    };

    @action
    addLogoutState = async () => {
        if (this.isEnrolling) this.isEnrolling = false;
        this.isLogged = false;
        this.userInfo = {};
        saveSessionItem('user', {
            loggedIn: false,
        });
        this.saveSignature();
    };

    // CURRENTLY NOT BEING USED
    @action
    createAccount = async (params, autologin = true) => {
        const input = {
            ...params,
            language: localeStore.activeLocale.lang,
            storeid: localeStore.activeLocale.magentoStoreView,
            sailthru_subscription_source: 'account_creation',
        };

        // New authentication: To be kept
        interfaceStore.showProgress('login');
        const response = await createFirebaseAccount(input);
        interfaceStore.hideProgress('login');

        if (response.success && response.data && response.data.email) {
            this.addLoginState(response.data);
            return {
                success: true,
            };
        }
        return {
            success: false,
            message: getSafe(() => response.error.message) || 'Error',
        };
    };

    // CURRENTLY NOT BEING USED
    @action saveInfo = async data => await writeUserData(data);

    @action requestPasswordReset = async params => resetPasswordFirebase(params);

    @action resetPassword = async params => confirmPasswordResetFirebase(params);

    @action checkPasswordToken = async params => confirmPasswordResetFirebase(params);

    // CURRENTLY NOT BEING USED
    @action checkEmailExists = email => checkEmailExists(email);

    // User Title Logic
    getTitleConfig = async () => {
        try {
            const titleContent = await contentStore.fetchContentfulConfig({
                ...asyncModels.commissions,
            });
            set(this, 'titleConfig', titleContent[0].configCommissions[0].titleRequirements);
        } catch (e) {}
    };

    @computed
    get accountTitles() {
        if (!this.titleConfig) return {};
        const { EN, FR } = languageIds;
        return Object.keys(this.titleConfig).reduce((output, id) => {
            const title = this.titleConfig[id] || {};
            output[id] = {
                [EN]: title.nameEn,
                [FR]: title.nameFr,
            };
            return output;
        }, {});
    }

    @action
    getTitleByTitleId(titleId) {
        if (!this.accountTitles) return null;
        if (!titleId) return null;
        const locale = localeStore.activeLocale;
        const title = getSafe(() => this.accountTitles[titleId][locale.languageId]);
        if (title) return title;
        return getSafe(() => this.accountTitles[titleId][languageIds.EN]);
    }
    // End of User Title Logic

    @computed
    get userTypeLabel() {
        return userTypes[this.userType] || '';
    }

    @action
    getCurrentPolicy = async () => {
        const { success, data } = await getPolicy({ phoenixId: this.phoenixId });

        if (success) {
            this.latestPolicy = data.latestVersion;
            this.showPolicyPrompt = data.showPrompt;
        }
    };

    @action
    updateUserPolicy = async ({ version, accepted }) => {
        // we don't want to show the prompt whether or not they've accepted
        this.showPolicyPrompt = false;
        await updatePolicy({ phoenixId: this.phoenixId, version, accepted });
    };
}

const userStore = new UserStore();

export default userStore;
export { UserStore };
