import React from 'react';
import s from './MarkdownWrapper.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReactMarkdown from 'react-markdown';
import Link from '@btc-frontend/components/Common/Link';
import Button from '@btc-frontend/components/Common/Button';
import { COLOR_BLACK } from '@btc-frontend/middleware/constants/colors';

const createElement = React.createElement;

export const generateStyleList = (so = {}) => ({
    h1: (() => so.h1 ? so.h1 : {})(),
    h2: (() => so.h2 ? so.h2 : {})(),
    h3: (() => so.h3 ? so.h3 : {})(),
    h4: (() => so.h4 ? so.h4 : {})(),
    // em: (() => so.em ? so.em : {})(),
    hr: (() => so.hr ? so.hr : {})(),
    strong: (() => so.strong ? so.strong : {})(),
    p: (() => so.div ? so.div : {})(),
    image: (() => so.image ? so.image : {})(),
    Link: (() => so.Link ? so.Link : {})(),
});

// TODO: fix link and em issue.
export const generateRenderers = ((props, i) => {
    let si = {}
    props && props.cssInherits ? si = props.cssInherits : null;
    return {
        link: (props => {
            if (props.title && props.title.includes('isButton')) {
                const [isButton, buttonColor] = props.title.split(',').map(prop => prop.trim());
                return (
                    <Link to={props.href}>
                        <Button
                            style={i.Link}
                            className={cx(s.Link, si.Link)}
                            thin={true}
                            color={buttonColor || COLOR_BLACK}
                        >
                            {props.children[0]}
                        </Button>
                    </Link>
                )
            } else {
                return (
                    createElement(Link, {
                        to: props.href,
                        style: i.Link,
                        name: props.children[0],
                        underlined: !si.Link && true,
                        className: si.Link
                    }, null)
                )
            }
        }),
        thematicBreak: (props => <hr style={i.hr} className={cx(s.hr, si.hr)} />),
        // emphasis: (props => <em style={i.em} className={cx(s.em)}>{props.children}</em>),
        strong: (props => <strong style={i.strong} className={cx(s.strong, si.strong)}>{props.children}</strong>),
        paragraph: (props => <div style={i.p} className={cx(s.p, si.p)}>{props.children}</div>),
        image: (props => <div className={cx(s.image, si.image)}><img style={i.image} src={props.src} />{props.alt}</div>),
        heading: (props => {
            const l = props.level
            if (l===1) return <h1 style={i.h1} className={cx(s.h1, si.h1)}>{props.children}</h1>;
            if (l===2) return <h2 style={i.h2} className={cx(s.h2, si.h2)}>{props.children}</h2>;
            if (l===3) return <h3 style={i.h3} className={cx(s.h3, si.h3)}>{props.children}</h3>;
            return <h4 style={i.h4} className={cx(s.h4, si.h4)}>{props.children}</h4>;
        }),
    }
});

const MarkdownWrapper = (props) => {
    const { className, content, styleOverrides, wrapperClassName } = props;
    const renderers = generateRenderers(props, generateStyleList(styleOverrides));

    return (
        <div className={wrapperClassName ? cx(s.root, wrapperClassName) : ''}>
            <ReactMarkdown
                className={cx(s.root, className)}
                source={content}
                renderers={{ ...renderers }}
                escapeHtml={false}
            />
        </div>
    );
};

export default withStyles(s)(MarkdownWrapper);
