import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';

const demographicsEndpoint = `${nogento.btc}/rest/accounts/demographics`;

/* Deprecated. See userStore `fetchNogentoUserData` for demopgrahics graphql  */
export async function getUserSurvey() {
    const endpoint = demographicsEndpoint;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        };

        const response = await fetch(endpoint, options);
        const { data } = await response.json();

        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function saveUserSurvey(survey) {
    const endpoint = demographicsEndpoint;
    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ ...survey }),
        };

        const response = await fetch(endpoint, options);
        const { data } = await response.json();

        return {
            success: true,
            userAnswers: data,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}
