import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -5,
    y: -7,
    width: 65,
    height: 55,
};

class Ordering extends Component {
    render() {
        return (
            <Icon size={{ width: 51, height: 41 }} {...this.props} altText='Ordering' viewBox={viewBox}>
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g
                        id="orders"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="2.5"
                    >
                        <g id="icon-btc-ordering">
                            <path
                                d="M19.29,4.48 C22.12,4.48 24.96,4.48 27.79,4.48 C32.8,4.48 38.11,3.97 43.07,4.61 C44.17,4.75 45.32,5.03 46.15,5.77 C46.79,6.34 47.19,7.16 47.24,8.02 C47.38,10.23 43.63,19.67 42.9,22.33 C42.55,23.61 42.21,24.93 41.43,26.01 C40.28,27.6 38.48,27.81 36.66,27.71 C32.31,27.48 27.97,27.86 23.61,27.54 C20.63,27.32 17.47,26.23 15.9,23.69 C14.86,22.01 14.69,19.99 14.36,18.09 C13.58,13.61 12.57,9.15 11.7,4.68 C11.53,3.79 11.34,2.86 10.8,2.13 C10.13,1.21 9,0.72 7.88,0.58 C6.32,0.38 4.66,0.65 3.09,0.64 C2.09,0.64 1.09,0.64 0.09,0.64"
                                id="Path"
                            />
                            <circle id="Oval" cx="21.66" cy="35.47" r="3.67" />
                            <circle id="Oval" cx="38.77" cy="35.47" r="3.67" />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Ordering;
