import gray from '../colors/gray';
import navy from '../colors/navy';
import indigo from '../colors/indigo';
import green from '../colors/green';
import sienna from '../colors/sienna';
import red from '../colors/red';
import orange from '../colors/orange';
import yellow from '../colors/yellow';
import common from '../colors/common';

export default {
    // Our own custom colors
    white: common.white,
    black: common.black,
    bone: common.bone,

    // Destructured colors (ex: gray10, orange100, etc.)
    ...gray,
    ...navy,
    ...indigo,
    ...green,
    ...sienna,
    ...red,
    ...orange,
    ...yellow,

    // Our overrides for the MUI preset theme colors - some of these must have a main key
    common: {
        black: {
            main: common.black,
        },
        white: {
            main: common.white,
        },
    },
    primary: {
        main: common.black,
        light: gray.gray20,
        dark: common.black,
        contrastText: common.white,
    },
    secondary: {
        main: common.white,
        contrastText: common.black,
    },
    success: {
        main: green.green100,
    },
    error: {
        main: red.red100,
    },
    status: {
        primary: indigo.indigo80,
    },
    grey: {
        main: gray.gray100,
    },
    background: {
        default: common.white,
        contrast: common.black,
        primary: gray.gray1,
        secondary: green.green1,
    },
    text: {
        primary: common.black,
        secondary: common.black,
        disabled: gray.gray80,
    },
    disabled: gray.gray40,
};
