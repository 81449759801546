import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -12,
    y: -8,
    width: 80,
    height: 80,
};

class Prospects extends Component {
    render() {
        return (
            <Icon size={{ width: 54, height: 60 }} {...this.props} altText='Prospects' viewBox={viewBox}>
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g id="prospects" transform="translate(1.000000, 1.000000)" stroke="#000000">
                        <g id="icon-btc-prospects">
                            <path
                                d="M6.03,32.63 C6.03,32.63 9.03,27.63 17.03,26.63 L17.03,25.63 C17.03,25.63 13.03,23.63 14.03,17.63 C15.03,11.63 20.03,10.63 20.03,10.63"
                                id="Path"
                                strokeWidth="2.5"
                            />
                            <path
                                d="M35.03,32.63 C35.03,32.63 32.03,27.63 24.03,26.63 L24.03,25.63 C24.03,25.63 28.03,23.63 27.03,17.63 C26.54,14.66 24.34,11.09 21.09,10.68 C17.31,10.21 14.57,14.37 14.03,17.62 C13.03,23.62 17.03,25.62 17.03,25.62 L17.03,26.62 C9.03,27.62 6.03,32.62 6.03,32.62"
                                id="Path"
                                strokeWidth="2.5"
                            />
                            <circle id="Oval" strokeWidth="2.5" cx="20.03" cy="20.13" r="19.5" />
                            <path d="M51.03,56.63 L32.03,36.63" id="Path" strokeWidth="3" />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Prospects;
