import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: 9,
    y: -5,
    width: 50,
    height: 50,
};

class Team extends Component {
    render() {
        return (
            <Icon size={{ width: 66, height: 37 }} {...this.props} altText='Team' viewBox={viewBox}>
                <g
                    id="Symbols"
                    stroke="#000000"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g
                        id="team"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="2"
                    >
                        <g id="icon-btc-my-team">
                            <path
                                d="M21,21 C20.1,20.15 18.39,18.78 15.63,17.88 L15.63,17.12 C15.63,17.12 18.94,15.59 18.11,11.02 C17.7,8.76 15.55,5.94 12.99,5.94 C9.84,5.94 7.81,8.54 7.36,11.02 C6.53,15.6 9.84,17.12 9.84,17.12 L9.84,17.88 C3.23,18.64 0.75,27.13 0.75,27.13 C5.68,27.13 10.61,27.13 15.53,27.13 C15.56,27.13 16.22,26.49 17.08,25.64 C17.73,25 17.68,25.03 18.47,24.26 C19.5,23.25 19.69,23.08 20.07,22.62 C20.73,21.82 21.31,21.29 21,21 Z"
                                id="Path"
                            />
                            <path
                                d="M63.3,27.13 C63.3,27.13 60.82,18.64 54.21,17.88 L54.21,17.12 C54.21,17.12 57.52,15.59 56.69,11.02 C56.28,8.76 54.13,5.94 51.57,5.94 C48.42,5.94 46.39,8.54 45.94,11.02 C45.11,15.6 48.42,17.12 48.42,17.12 L48.42,17.88 C46.09,18.15 43.38,19.52 42,21 C42,21 45,23 46,24 C48,26 49,27 49,27 L63.3,27.13 Z"
                                id="Path"
                            />
                            <path
                                d="M51.53,34.61 C51.53,34.61 47.49,20.79 36.73,19.55 L36.73,18.31 C36.73,18.31 42.11,15.83 40.77,8.38 C40.11,4.7 36.6,0.11 32.44,0.11 C27.32,0.11 24.01,4.35 23.28,8.38 C21.93,15.83 27.32,18.31 27.32,18.31 L27.32,19.55 C16.56,20.79 12.52,34.61 12.52,34.61 L51.53,34.61 Z"
                                id="Path"
                            />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Team;
