exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2XZrO{display:block;padding:7px 9px;background-color:#231f20;color:#fefefe;opacity:0;pointer-events:all;vertical-align:middle;text-align:center;width:26px;height:28px}._2XZrO,._2XZrO._26BAd{-webkit-transition:opacity 1s ease-in-out,visibility 1s linear;-o-transition:opacity 1s ease-in-out,visibility 1s linear;transition:opacity 1s ease-in-out,visibility 1s linear}._2XZrO._26BAd{visibility:visible;opacity:1;cursor:pointer}._2XZrO._26BAd:hover{-webkit-transition:opacity .5s ease;-o-transition:opacity .5s ease;transition:opacity .5s ease;opacity:.6}._22uzz{line-height:normal;font-size:10px}._2RkL6{margin-left:-100%;margin-right:-100%}", ""]);

// exports
exports.locals = {
	"scrollToTop": "_2XZrO",
	"shown": "_26BAd",
	"showText": "_22uzz",
	"text": "_2RkL6"
};