import Router from 'universal-router';
import React from 'react';

import { locales as configLocales, prefix } from '@btc-frontend/config';
import init from '@btc-frontend/init';
import { routeConfig } from '@btc-frontend/router/routes';
import { removeParam } from '@btc-frontend/middleware/utils/url';
import SiteDown from '@btc-frontend/containers/PageContainer/SiteDown';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { routeListGenerator } from './routeListGenerator';

const renderedRouteCollection = JSON.stringify(routeConfig());

// TODO: cache routes here.

/**
 * Initialize the router instance
 */

export default () =>
    new Router([
        {
            path: '/',
            action() {
                return { redirect: prefix, replace: prefix };
            },
        },
        {
            path: [`${prefix}/`],
            async action({ next, url, stores, isInitialRender, query }) {
                url && (await init(stores, url, isInitialRender));
                stores.interfaceStore.hideProgress('page-load');

                if (__BROWSER__) {
                    if (query.errorMessage) {
                        setTimeout(
                            () =>
                                stores.messagingStore.addMessage(
                                    query.errorMessage || 'There was an error.',
                                    { type: 'error' },
                                ),
                            500,
                        );
                        removeParam('errorMessage');
                    }
                }

                if (getSafe(() => stores.contentStore.content.configGeneral[0].siteDown)) {
                    return {
                        component: <SiteDown />,
                    };
                }
                return await next();
            },
            children: routeListGenerator(JSON.parse(renderedRouteCollection)),
        },
    ]);
