/* export const
    CLIENT = '7',
    MEMBER = '8',
    CONSULTANT = '9',
    EMPLOYEE = '10';
 */

export const CONSULTANT = '1',
    MEMBER = '2',
    CLIENT = '3',
    EMPLOYEE = '4',
    PROSPECT = '5',
    PARTNER = '6',
    GUEST = '7';

export const userTypes = {
    1: 'Consultant',
    2: 'Member',
    3: 'Client',
    4: 'Employee',
    7: 'Guest',
};
