import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -7,
    y: -7,
    width: 65,
    height: 65,
};

class CounterSupport extends Component {
    render() {
        return (
            <Icon size={{ width: 51, height: 51 }} {...this.props} altText='Counter Support' viewBox={viewBox}>
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <g
                        id="counter-support"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="2.5"
                    >
                        <g id="icon-btc-help">
                            <circle id="Oval" cx="24.53" cy="24.91" r="24.11" />
                            <circle id="Oval" cx="24.53" cy="25.56" r="11.73" />
                            <path
                                d="M6.26,40.65 L15.01,32.73 C15.01,32.73 17.15,35.74 19.99,36.73 L14.75,47.07 C14.75,47.07 9.09,44.71 6.26,40.65 Z"
                                id="Path"
                            />
                            <path d="M34.69,32.73 L43.43,39.93" id="Path" />
                            <path d="M29.72,36.37 L34.95,45.76" id="Path" />
                            <path d="M14.79,18.02 L4.92,11.54" id="Path" />
                            <path d="M19.64,14.76 L12.47,4.5" id="Path" />
                            <path d="M34.69,18.39 L43.43,11.19" id="Path" />
                            <path d="M29.72,14.76 L34.95,3.16" id="Path" />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default CounterSupport;
