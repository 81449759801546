import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

let portalRoot;

if (typeof window !== 'undefined') {
    portalRoot = document.getElementById('portal-root');

    if (portalRoot === null) {
        portalRoot = document.createElement('div');
        portalRoot.id = 'portal-root';
        portalRoot.setAttribute('data-testid', 'portal');
        document.body.appendChild(portalRoot);
    }
}

class Portal extends React.Component {
    constructor(props) {
        super(props);
        if (typeof window !== 'undefined') {
            this.el = document.createElement('div');
        }
    }

    componentDidMount() {
        portalRoot.appendChild(this.el);
    }
    componentWillUnmount() {
        portalRoot.removeChild(this.el);
    }
    render = () => ReactDOM.createPortal(this.props.children, this.el);
}

export default class PortalWrapper extends React.PureComponent {
    static propTypes = {
        visibility: PropTypes.bool.isRequired,
    };
    render() {
        if (typeof window === 'undefined') return null;
        return this.props.visibility ? <Portal>{this.props.children}</Portal> : null;
    }
}
