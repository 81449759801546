import { isEmpty, mergeWith } from 'lodash';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import firebase from '@btc-frontend/middleware/firebase/init';

/**
 * Save quiz data to server
 * @param {Number} quizId
 * @param {Object} selections
 * @param {Object} previouslyUsedUniqueValues
 * @param {Number} userId
 * @param {String} slug
 * @param {Number} step
 */

const USERS_NEWSFEED_STATES = 'users-newsfeed-states';

export async function setNewsfeedState(userId, state) {
    try {
        const database = await firebase.firestore();

        let doc = await database.collection(USERS_NEWSFEED_STATES).doc(userId);

        // save record
        if (doc.exists) {
            doc.update(state);
        } else {
            doc.set(state);
        }
    } catch (err) {
        console.info(err);
        return err;
    }
}

/**
 * Load user cart
 * @param {String} userEmail
 */

export async function getNewsfeedState(userId) {
    const database = await firebase.firestore();
    try {
        const doc = await database.collection(USERS_NEWSFEED_STATES).doc(userId);

        const snap = await doc.get();
        const data = await snap.data();
        if (typeof data === 'undefined') throw 'undefined';
        return data;
    } catch (e) {
        return {
            readStatuses: [],
            bookmarks: [],
            deleted: [],
        };
    }
}
