import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -8,
    y: -2,
    width: 38,
    height: 25,
};

class CounterComms extends Component {
    render() {
        return (
            <Icon
                size={{ width: 31, height: 22 }}
                {...this.props}
                altText="Counter Comms"
                viewBox={viewBox}
            >
                <defs>
                    <filter id="filter-1">
                        <feColorMatrix
                            in="SourceGraphic"
                            type="matrix"
                            values="0.14 0 0 0 0 0 0.12 0 0 0 0 0 0.13 0 0 0 0 0 1 0"
                        />
                    </filter>
                </defs>
                <g
                    id="Symbols"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <g
                        id="counter-comms"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="1"
                    >
                        <path
                            d="M10.0036665,20 C9.95134475,18.3914408 10.4609211,16.8296837 11.4094954,15.6913945 C12.3580698,14.5531053 13.659534,13.9416137 15,14.0043998 C16.340466,13.9416137 17.6419302,14.5531053 18.5905046,15.6913945 C19.5390789,16.8296837 20.0486553,18.3914408 19.9963335,20 L10.0036665,20 Z"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <path
                            d="M12,10 C12,11.0717968 12.5717967,12.0621778 13.5,12.5980762 C14.4282032,13.1339746 15.5717968,13.1339746 16.5,12.5980762 C17.4282033,12.0621778 18,11.0717968 18,10 C18,8.92820324 17.4282033,7.93782218 16.5,7.40192379 C15.5717968,6.8660254 14.4282032,6.8660254 13.5,7.40192379 C12.5717967,7.93782218 12,8.92820324 12,10 L12,10 Z"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <path
                            d="M18,10.3395 C17.4939114,10.7754399 16.9065211,11.0046515 16.3075504,10.9999285 C14.886676,10.8905039 13.6208523,9.74240937 13,8"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <line
                            x1="2"
                            y1="13.25"
                            x2="10"
                            y2="13.25"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <line
                            x1="2"
                            y1="3.25"
                            x2="14.5"
                            y2="3.5"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <line
                            x1="10"
                            y1="8.25"
                            x2="0"
                            y2="8.25"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <path
                            d="M7.59601019,16 C3.25801811,15.7492882 -0.0977026886,12.2154076 0.00217250116,8.00299498 C0.0077573075,4.13378967 2.86390786,0.821039005 6.7902687,0.12971597 C10.7166295,-0.561607065 14.5917041,1.5659619 16,5.18622212"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <path
                            d="M7.96797362,0 C2.66705551,4.52910282 2.67699473,10.43305 8,16"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                        <path
                            d="M8,0 C10.1499544,1.66158123 11.5451622,3.75439296 12,6"
                            id="Path"
                            stroke="#000000"
                            stroke-width="0.75"
                        />
                    </g>
                </g>
            </Icon>
        );
    }
}

export default CounterComms;
