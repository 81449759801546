/* translationGroup: myAccount */
import { asyncModels, square } from '@btc-frontend/config';
import { observable, computed, action, toJS, set } from 'mobx';
import { keyBy, cloneDeep, find, isEmpty, get } from 'lodash';
import dateFormat from 'dateformat';
import uuidv4 from 'uuid/v4';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { routeKeys as keys } from '@btc-frontend/middleware/constants/routes';
import { userTitleIds } from '@btc-frontend/middleware/constants/userCodes';
import { generateFirebaseId } from '@btc-frontend/middleware/utils/id';
import firebase from '@btc-frontend/middleware/firebase/init';
import { statesProvinces, countries } from '@btc-frontend/middleware/constants/geographies';
import { phone } from '@btc-frontend/middleware/utils/masks';
import { creditSubscriber } from '@btc-frontend/middleware/services/user/storeCredit';
import getVtexProductCredit from '@btc-frontend/middleware/services/vtex/productCredit';
import GraphQLFetch from '@btc-frontend/middleware/api/graphql';
import {
    UPDATE_ACCOUNT_EVENT_TITLE,
    CREDIT_CARD_PAYMENT_TYPE,
    SQUARE_PAYMENT_GATEWAY,
} from '@beautycounter/constants/sift';
import {
    updateUser,
    updatePassword,
    verifyCurrentPassword,
} from '@btc-frontend/middleware/services/user/utilities';
import { track } from '@btc-frontend/middleware/api/analytics';
import flagStore from '@btc-frontend/stores/flagStore';
import {
    writeUserData,
    uploadUserPhoto,
} from '@btc-frontend/middleware/services/user';
import {
    getAddressData,
    writeAddressData,
    deleteAddressData,
} from '@btc-frontend/middleware/services/user/address';
import {
    getCustomerCards,
    createCustomerCard,
    deleteCustomerCard,
    updateCustomerCard,
} from '@btc-frontend/middleware/services/user/payment';
import getVtexCustomerCards from '@btc-frontend/middleware/services/vtex/payments';

import contentStore from '@btc-frontend/stores/contentStore';
import sessionStore from '@btc-frontend/stores/sessionStore';
import userStore from '@btc-frontend/stores/userStore';
import formStore from '@btc-frontend/stores/formStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import messagingStore from '@btc-frontend/stores/messagingStore';
import navigationStore from '@btc-frontend/stores/navigationStore';
import localeStore from '@btc-frontend/stores/localeStore';
import analyticsStore from '@btc-frontend/stores/analyticsStore';

import {
    SDK_GENERATE_CARD_TOKEN_ERROR,
    SDK_PAYMENT_NOT_INITIALIZED,
} from '@beautycounter/constants/squarePaymentErrors';

export class AccountStore {
    @observable config;
    @observable userProfile;
    @observable userContact;
    @observable userPhoto;
    @observable contactForm;
    @observable passwordConfig;
    @observable changePassword;
    @observable userMainAddress;
    @observable infoLoading;

    @observable step;
    @observable selections;
    @observable answerSelections;
    @observable unansweredCount;
    @observable surveyAnswers;
    @observable isReview;
    @observable surveyLoading;

    @observable addresses;
    @observable addressForm;
    @observable editAddress: boolean;
    @observable editAddressId;
    @observable addressLoading;
    @observable activeAddress;

    @observable paymentOptions;
    @observable paymentForm;
    @observable editPayment;
    @observable editPaymentId;
    @observable paymentHandler;
    @observable paymentLoading;

    @observable credit;
    @observable creditLoading;
    @observable country;
    @observable cardData;
    @observable language;
    @observable languageId;

    @observable recognitionTitleId;
    @observable paidAsTitleId;
    @observable uplineCount;
    @observable sponsor;
    @observable uplineDirector;
    @observable managingDirector;
    @observable isInitial;
    @observable disbursementBankForm;
    @observable squareSDKCard;

    constructor() {
        this.config = {};
        this.userPhoto = null;
        this.userContact = {};
        this.contactForm = null;
        this.passwordConfig = {};
        this.changePassword = false;
        this.userMainAddress = {};
        this.infoLoading = true;

        this.step = 0;
        this.selections = {};
        this.answerSelections = {};
        this.unansweredCount = 0;
        this.surveyAnswers = [];
        this.isReview = false;
        this.surveyLoading = true;

        this.addresses = [];
        this.addressForm = null;
        this.editAddress = false;
        this.editAddressId = null;
        this.addressLoading = true;
        this.activeAddress = {};

        this.editPayment = false;
        this.editPaymentId = null;
        this.paymentOptions = [];
        this.paymentHandler = {};
        this.paymentLoading = true;

        this.credit = {};
        this.creditLoading = true;
        this.country = toJS(getSafe(() => localeStore.activeLocale.countryShort));
        this.language = null;
        this.languageId = null;
        this.cardData = {};
        this.titleConfig = {};
        this.uplineCount = 0;
        
        this.userProfile = {
            [keys.account.about]: false,
            [keys.account.contactInfo]: false,
            [keys.account.addresses]: false,
            [keys.account.payment]: false,
            [keys.account.photoUpload]: false,
        };

        this.squareSDKCard = {}
    }

    getAccountInfo({
        sessionId = '',
        email = '',
        fullName = '',
        userInfo: { telephone = '', uid: userId = '' } = {},
        userTypeLabel,
    }) {
        return {
            userId,
            sessionId,
            userEmail: email,
            name: fullName,

            // Need to have undefined to correspond to the Sift event validation schema
            phone: telephone || undefined,
            accountTypes: [userTypeLabel],
        };
    }

    @action
    async init() {
        return await new Promise(async resolve => {
            userStore.isLogged;
            resolve();
        })
            .then(async () => {
                if (userStore.isLogged) {
                    await Promise.all([
                        this.getContentful(),
                        this.getSurvey(),
                        this.getUserContact(),
                        this.getAddresses(),
                        this.getPaymentOptions(),
                        this.useVtexPC ? await this.getVtexCredit() : this.getStoreCredit(),
                    ]);
                }
            })
            .then(() => {
                return true;
            });
    }

    @computed
    get profileCompleted() {
        return Object.keys(this.userProfile).every(i => this.userProfile[i]);
    }

    @computed
    get stateOptions() {
        return statesProvinces.map(option => ({
            name: option.name,
            selectable: true,
            key: option.short,
        }));
    }

    @action getContentful = async () => {
        try {
            if (!contentStore) return;
            await contentStore.fetchContentfulConfig({
                ...asyncModels.account,
            });
            this.config =
                getSafe(() => contentStore.extensions.account.data.configAccount[0]) || {};
            this.passwordConfig =
                getSafe(
                    () =>
                        contentStore.extensions.account.data.configAccount[0].strictPassword.fields,
                ) || {};
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    @computed
    get useVtexAddresses() {
        return flagStore.isFeatureEnabled('useVtexAddresses');
    }

    @computed
    get useVtexPayments() {
        return flagStore.isFeatureEnabled('useVtexPayments');
    }

    @computed
    get invalidPasswordError() {
        return getSafe(
            () =>
                contentStore['_extensions']['account-contactinfo'].data.translationsErrors[0]
                    .invalidPasswordError,
        );
    }

    // About Me Logic
    @action
    async getSurvey() {
        this.surveyLoading = true;
        const data = getSafe(() => userStore.userInfo.demographics.userAnswers);
        if (data) set(this, 'unansweredCount', data.unansweredCount);

        if (this.unansweredCount > 0) {
            this.isReviewPage();
            this.setSurvey(false);
        }

        if (this.unansweredCount === 0) {
            this.isReviewPage(true);
            this.setSurvey(true);
        }

        // answers users has already selected.
        this.surveyAnswers = getSafe(() =>
            this.surveyRef.map(ref => {
                let type;
                let values;
                for (let i = 0; i < ref.values.length; i += 1) {
                    const value = data[ref.values[i].type];
                    type = ref.values[i].type;
                    values =
                        value && value.length
                            ? value.map(t => {
                                const index = t - 1;
                                return ref.values[index].text;
                            })
                            : [];
                }
                return {
                    type,
                    values,
                };
            }),
        );

        // save answers data structure to session storage
        const selectionsToSave = {};
        const answerSelectionsToSave = {};
        let step = null;
        getSafe(() =>
            this.surveyRef.forEach((ref, i) => {
                const selection = [];
                for (let j = 0; j < ref.values.length; j += 1) {
                    const value = parseInt(ref.values[j].value, 10);
                    if (data[ref.matchingKey] && data[ref.matchingKey].includes(value)) {
                        selection.push(ref.values[j].text);
                    }
                    if (
                        Object.hasOwnProperty.call(data, ref.matchingKey) &&
                        data[ref.matchingKey] !== null
                    ) {
                        const { type } = ref.values[j];
                        answerSelectionsToSave[type] = data[type];
                    }
                }
                if (
                    Object.hasOwnProperty.call(data, ref.matchingKey) &&
                    data[ref.matchingKey] !== null
                ) {
                    selectionsToSave[i] = selection;
                }
            }),
        );
        step = Object.keys(answerSelectionsToSave).length;

        const params = {
            answerSelections: answerSelectionsToSave,
            selections: selectionsToSave,
            step,
        };

        sessionStore.saveKey('survey-selections', params, { localized: true });

        this.surveyLoading = false;
        return true;
    }

    @computed
    get surveyRef() {
        return getSafe(() =>
            this.config.steps.reduce((res, { sys, fields: { questions } }) => {
                const step = [];
                for (let i = 0; i < questions.length; i += 1) {
                    step.push({
                        matchingKey: toJS(questions[i].fields.matchingKey),
                        values: toJS(
                            questions[i].fields.values.map(v => ({
                                text: v.fields.text,
                                value: v.fields.value,
                                type: v.fields.matchingKey,
                            })),
                        ),
                    });
                }
                return res.concat(step);
            }, []),
        );
    }

    @action
    isReviewPage = (bool = false) => {
        this.isReview = bool;
    };

    @action
    setSurvey = bool => {
        this.userProfile[keys.account.about] = bool;
    };

    @computed
    get totalSteps() {
        try {
            return this.config.steps.length;
        } catch (error) {
            return 0;
        }
    }

    @computed
    get stepInfo() {
        if (this.totalSteps > this.step) {
            const step = this.config.steps[this.step];
            const questions = step.fields.questions.map((q, i) => {
                const values = q.fields.values.map(v => {
                    const selected = (() => {
                        const stepSelection = this.selections[this.step];
                        if (stepSelection) {
                            if (step.fields.isMultipleChoice) {
                                return stepSelection.includes(v.fields.key);
                            }
                            return (
                                stepSelection.includes(v.fields.key) && stepSelection.length === 1
                            );
                        }
                        return false;
                    })();
                    return {
                        ...v.fields,
                        selected,
                    };
                });
                return {
                    key: q.fields.matchingKey,
                    values,
                    questionType: q.fields.questionType,
                    questionText: q.fields.questionText,
                };
            });
            const title = step.fields.stepTitle;
            return {
                questions,
                title,
            };
        }
        return {
            pageType: this.reviewPageKey,
        };
    }

    @action
    setAnswer = (questionIndex, key, value, matchingKey) => {
        const step = this.config.steps[this.step];

        if (!this.selections[this.step]) {
            this.selections[this.step] = [];
        }

        if (!this.answerSelections[matchingKey]) {
            this.answerSelections[matchingKey] = [];
        }

        let stepSelections: string[];

        if (step.fields.isMultipleChoice) {
            if ((this.selections[this.step] || []).includes(key)) {
                stepSelections = this.selections[this.step].filter(x => x != key);
            } else stepSelections = this.selections[this.step].concat([key]);
            stepSelections = [...new Set(stepSelections)];
        } else stepSelections = [key];

        let stepAnswers: number[];
        const intValue = parseInt(value, 10);

        if (step.fields.isMultipleChoice) {
            if (this.answerSelections[matchingKey].includes(intValue)) {
                stepAnswers = this.answerSelections[matchingKey].filter(x => x != intValue);
            } else stepAnswers = this.answerSelections[matchingKey].concat(intValue);
            stepAnswers = [...new Set(stepAnswers)];
        } else stepAnswers = [intValue];

        set(this, `selections`, {
            ...this.selections,
            [this.step]: stepSelections,
        });

        set(this, `answerSelections`, {
            ...this.answerSelections,
            [matchingKey]: stepAnswers,
        });
    };

    @action
    isQuestionAnswered(step, verbose = false) {
        try {
            if (this.selections[step].length) {
                return true;
            }
        } catch (error) {
            verbose &&
                messagingStore.addMessage(this.config.labelsAboutMe.questionNotAnswered, {
                    type: 'error',
                });
            return false;
        }
    }

    questionsPerStep(step) {
        return this.config.steps[step].fields.questions.length;
    }

    @action
    nextStep = () => {
        this.goToStep({ step: this.step + 1, urlChange: true });
    };

    @action
    goToStep = ({ step = 0, override = false }) => {
        if (step === 0 || this.isQuestionAnswered(step - 1, true) || override) {
            this.step = step;
            this.saveSurvey();
            this.selections = JSON.parse(JSON.stringify(this.selections));
        }
        if (step === this.totalSteps) {
            this.isReviewPage(true);
        }
    };

    saveSurvey = () => {
        sessionStore.saveSurvey({
            answerSelections: this.answerSelections,
            selections: this.selections,
            step: this.step,
        });
    };

    hydrateSurvey = () => {
        const saveData = sessionStore.getSurvey();
        if (saveData) {
            set(this, `selections`, {
                ...saveData.selections,
            });
            set(this, `answerSelections`, {
                ...saveData.answerSelections,
            });
            set(this, `step`, saveData.step);
            return true;
        }
        return false;
    };
    // End of About Me Logic

    // User Contact Logic
    fetchUserMainAddress = async () => {
        try {
            set(this, 'infoLoading', true);
            const graphql = new GraphQLFetch();
            await graphql.useAuth();
            graphql.addType(
                'userAccount',
                {},
                `
                mainAddress {
                    id
                    address
                    address2
                    city
                    region
                    postalCode
                    telephone
                    countryId
                }
                shippingAddresses {
                    id
                    address
                    address2
                    city
                    region
                    postalCode
                    telephone
                }
            `,
            );
            const response = await graphql.execute();
            if (response && response.data) {
                return getSafe(() => response.data.userAccount);
            }
            set(this, 'infoLoading', false);
            return userStore.userInfo;
        } catch (error) {
            return { success: false, error };
        }
    };

    @action
    async getUserContact() {
        set(this, 'infoLoading', true);
        const DEFAULT_COUNTRYID = 1;
        const country = countries.find(c => c.id === userStore.userInfo.countryId) || '';
        const birthday = userStore.userInfo.birthDateUTC
            ? dateFormat(new Date(userStore.userInfo.birthDateUTC), 'mm/dd/yyyy', true)
            : '';
        const userContact = {
            firstName: userStore.userInfo.firstName,
            lastName: userStore.userInfo.lastName,
            email: userStore.userInfo.email || null,
            birthday,
            telephone: getSafe(() => phone(userStore.userInfo.telephone)) || '',
            country: country.name,
        };

        const { mainAddress, shippingAddresses } = await this.fetchUserMainAddress();
        const defaultAddress = mainAddress
            ? mainAddress || {}
            : !!shippingAddresses && shippingAddresses.length
                ? shippingAddresses[0] || {}
                : {};

        const userAddress = {
            name: `${userStore.userInfo.firstName} ${userStore.userInfo.lastName}`,
            firstName: `${userStore.userInfo.firstName}`,
            lastName: `${userStore.userInfo.lastName}`,
            address: defaultAddress.address || '',
            address2: defaultAddress.address2 || '',
            city: defaultAddress.city || '',
            region: defaultAddress.region || '',
            postalCode: defaultAddress.postalCode || '',
            telephone: getSafe(() => phone(defaultAddress.telephone)) || '',
            id: defaultAddress.id || generateFirebaseId(),
            countryId: defaultAddress.countryId || DEFAULT_COUNTRYID,
        };

        set(this, 'userContact', userContact);
        set(this, 'userPhoto', userStore.userInfo.image);
        set(this, 'userMainAddress', userAddress);
        set(
            this,
            'recognitionTitleId',
            getSafe(() => userStore.userInfo.titles.recognitionTitleId),
        );
        set(this, 'paidAsTitleId', getSafe(() => userStore.userInfo.titles.paidAsTitleId));
        set(this, 'uplineCount', userStore.userInfo.uplineCount);
        set(this, 'sponsor', userStore.userInfo.sponsor);
        set(this, 'language', userStore.userInfo.language);
        set(this, 'languageId', userStore.userInfo.languageId);
        set(this, 'uplineDirector', userStore.userInfo.director);
        set(this, 'managingDirector', userStore.userInfo.managingDirector);

        localeStore.selectActive({
            languageId: userStore.userInfo.languageId,
            countryId: userStore.userInfo.countryId,
        });

        if (this.isValidMainAddress(this.userMainAddress)) this.setUserAddresses(true);
        if (!isEmpty(userContact)) this.setUserContact(true);
        if (userStore.userInfo.image) this.setUserProfile(true);
        set(this, 'infoLoading', false);
    }

    private isValidMainAddress(mainAddress): boolean {
        const { address, city, region, postalCode } = mainAddress;
        return !!address && !!city && !!region && !!postalCode;
    }

    @action
    setUserContact(bool) {
        this.userProfile[keys.account.contactInfo] = bool;
    }

    @action
    setUserProfile(bool) {
        this.userProfile[keys.account.photoUpload] = bool;
    }

    @action
    setupContactForm(changePassword = false, config = {}) {
        this.changePassword = changePassword;

        const createAction = () => {
            this.contactForm = formStore.create({
                key: 'contactForm',
                persist: false,
                config,
            });

            this.contactForm.add([
                {
                    key: 'email',
                    label: 'email',
                    type: 'email',
                    value: this.userContact.email || '',
                },
                {
                    key: 'country',
                    label: 'country',
                    type: 'text',
                    value: this.userContact.country,
                },
                {
                    key: 'dateOfBirth',
                    label: 'date of birth',
                    type: 'required',
                    value: this.userContact.birthday || '',
                },
                {
                    key: 'telephone',
                    label: 'telephone',
                    type: 'phoneOptional',
                    value: this.userContact.telephone || '',
                },
                {
                    key: 'currentPassword',
                    label: 'currentPassword',
                    type: 'password',
                },
            ]);
            this.changePasswordToggle(this.changePassword);
        };

        formStore.clearForm('contactForm');
        formStore.createReactiveForm('contactForm', 'forms', createAction);
    }

    // May be deprecated
    @action
    async verifyPassword(form) {
        const validPassword = await verifyCurrentPassword(form.fields.currentPassword.value);
        if (validPassword.success) {
            return updatePassword(form.fields.password.value);
        }
        return validPassword;
    }

    handleUpdateAccountTrack = props => {
        const { userId, sessionId = '', userEmail, name, accountTypes, phone } = props;
        if (!userId) {
            analyticsStore.sendSiftUpdateAccountFromNogento({
                email: userEmail,
                sessionId,
                name,
                accountTypes,
                phone,
            });
            return;
        }
        track(UPDATE_ACCOUNT_EVENT_TITLE, props);
    };

    @action
    async saveContact(): Promise<boolean> {
        const form = this.contactForm;
        const { t } = this;
        form.validate();

        if (form.valid) {
            try {
                const { fields } = form;
                interfaceStore.showProgress('account-update');
                this.contactForm.field('currentPassword').clearError();

                const reauth = firebase.getFirebase().auth().currentUser;

                let password = null;
                if (
                    fields.passwordStrict &&
                    fields.passwordStrict.value &&
                    fields.currentPassword.value !== fields.passwordStrict.value
                )
                    password = fields.passwordStrict.value;
                if (
                    fields.password &&
                    fields.password.value &&
                    fields.currentPassword.value !== fields.password.value
                )
                    password = fields.password.value;

                const body = toJS({
                    ...userStore.userInfo,
                    email: fields.email.value,
                    currentEmail: reauth.email,
                    birthDateUTC: fields.dateOfBirth.value,
                    telephone: fields.telephone.value,
                    currentPassword: fields.currentPassword.value,
                    localeCode: localeStore.activeLocale.code,
                    ...(password && { password }),
                });
                const credential = await firebase
                    .getFirebase()
                    .auth.EmailAuthProvider.credential(
                        fields.email.value !== reauth.email ? fields.email.value : reauth.email,
                        password || fields.currentPassword.value,
                    );

                //remove sponsorId to pass update user api validations
                delete body.sponsorId;

                const updatedInfo = await updateUser(body);

                if (!updatedInfo.success) {
                    interfaceStore.hideProgress('account-update');
                    if (updatedInfo.error === 'INVALID_PASSWORD')
                        this.contactForm
                            .field('currentPassword')
                            .updateError(this.invalidPasswordError);
                    return false;
                } else {
                    await reauth.reauthenticateWithCredential(credential);
                    userStore.userInfo = {
                        ...userStore.userInfo,
                        email: fields.email.value,
                        birthDateUTC: fields.dateOfBirth.value,
                        telephone: fields.telephone.value,
                    };

                    this.handleUpdateAccountTrack(
                        this.getAccountInfo({
                            email: userStore.email,
                            fullName: userStore.fullName,
                            userInfo: userStore.userInfo,
                            userTypeLabel: userStore.userTypeLabel,
                        }),
                    );
                    this.getUserContact();
                    interfaceStore.hideProgress('account-update');
                    messagingStore.addMessage(
                        t('successfullySavedContactInformation', {
                            defaultValue: `Successfully saved contact information.`,
                        }),
                        {
                            type: 'success',
                        },
                    );
                    form.clearField('currentPassword');
                    form.clearField('password');
                    form.clearField('passwordConfirmation');
                    navigationStore.to({
                        key: keys.account.root,
                    });
                    return true;
                }
            } catch (error) {
                console.log(error);
                interfaceStore.hideProgress('account-update');
                return false;
            }
        }
        return false;
    }

    // May be deprecated 
    @action
    async saveContactOld() {
        const form = this.contactForm;
        form.validate();
        if (form.valid) {
            try {
                const { fields } = form;
                interfaceStore.showProgress('account-update');

                if (this.changePassword) {
                    const passwordVerify = await this.verifyPassword(form);
                    if (!passwordVerify.success) {
                        messagingStore.addMessage(
                            passwordVerify.error
                                ? passwordVerify.error.message
                                : 'Invalid password',
                            { type: 'error' },
                        );
                        throw passwordVerify.error;
                    }
                }

                const body = toJS({
                    email: fields.email.value,
                    birthDateUTC: fields.dateOfBirth.value,
                    telephone: fields.telephone.value,
                });

                const updatedInfo = await writeUserData(body);

                if (!updatedInfo.success) {
                    interfaceStore.hideProgress('account-update');
                    throw updatedInfo.error;
                } else {
                    interfaceStore.hideProgress('account-update');
                    messagingStore.addMessage('Successfully saved contact information.', {
                        type: 'success',
                    });
                    this.getUserContact();
                    form.clearField('currentPassword');
                    form.clearField('password');
                    form.clearField('passwordConfirmation');
                    setTimeout(
                        () =>
                            navigationStore.to({
                                key: keys.account.root,
                            }),
                        600,
                    );
                    return true;
                }
            } catch (error) {
                console.log(error);
                interfaceStore.hideProgress('account-update');
                return false;
            }
        }
        return false;
    }

    @action
    changePasswordToggle(toggle) {
        if (toggle) {
            this.changePassword = true;
            this.contactForm.add([
                {
                    key: flagStore.isFeatureEnabled('newPasswordEndpoint')
                        ? 'passwordStrict'
                        : 'password',
                    label: 'newPassword',
                    type: flagStore.isFeatureEnabled('newPasswordEndpoint')
                        ? 'passwordStrict'
                        : 'password',
                },
                {
                    key: 'passwordConfirmation',
                    label: 'passwordConfirmation',
                    type: 'passwordConfirm',
                },
            ]);
        } else {
            this.changePassword = false;
            this.contactForm.removeFields(['password', 'passwordStrict', 'passwordConfirmation']);
        }
    }

    @action
    async uploadUserPhoto(form) {
        try {
            interfaceStore.showProgress('account-update');
            const uploadPhoto = await uploadUserPhoto(form);

            if (!uploadPhoto.success) {
                interfaceStore.hideProgress('account-update');
                messagingStore.addMessage('Something went wrong.', { type: 'error' });
                throw uploadPhoto.error;
            } else {
                set(this, 'userPhoto', uploadPhoto.data.image);
                if (this.userPhoto) this.setUserProfile(true);
                interfaceStore.hideProgress('account-update');
                messagingStore.addMessage('Successfully added photo.', { type: 'success' });
                return true;
            }
        } catch (error) {
            console.log(error);
            interfaceStore.hideProgress('account-update');
            return false;
        }
    }
    // End of User Contact Logic

    // User Address Logic
    @action
    async getAddresses() {
        try {
            this.addressLoading = true;
            const response = await getAddressData();
            if (response.success && Array.isArray(response.addresses)) {
                set(this, 'addresses', response.addresses);
                this.setUserAddresses(!isEmpty(this.addresses));
                this.addressLoading = false;
                return true;
            }
            throw Error('Failed to retrieve addresses.');
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    @action
    setUserAddresses(bool) {
        this.userProfile[keys.account.addresses] = bool;
    }

    @computed
    get defaultShipping() {
        if (!this.addresses || !this.addresses.length) return {};
        const shipping = find(this.addresses, { isDefaultShipping: true });

        if (shipping) {
            return {
                id: shipping.id,
                firstName: shipping.firstName,
                lastName: shipping.lastName,
                address: shipping.address,
                address2: shipping.address2,
                city: shipping.city,
                region: shipping.region,
                postalCode: shipping.postalCode,
                telephone: shipping.telephone ? getSafe(() => phone(shipping.telephone)) : '',
            };
        }
        return false;
    }

    @computed
    get defaultBilling() {
        if (!this.addresses || !this.addresses.length) return {};
        const billing = find(this.addresses, { isDefaultBilling: true });

        if (billing) {
            return {
                id: billing.id,
                firstName: billing.firstName,
                lastName: billing.lastName,
                address: billing.address,
                address2: billing.address2,
                city: billing.city,
                region: billing.region,
                postalCode: billing.postalCode,
                telephone: billing.telephone ? getSafe(() => phone(billing.telephone)) : '',
            };
        }
        return false;
    }

    @computed
    get addressesById() {
        return keyBy(this.addresses, 'id');
    }

    @computed
    get isMainAddress() {
        return this.editAddressId === this.userMainAddress.id;;
    }

    @action
    setupAddressForm() {
        const createAction = () => {
            this.addressForm = formStore.create({
                key: 'addressForm',
                persist: false,
            });
            const { userInfo } = userStore;
            const addressToEdit = this.addressesById[this.editAddressId] || {};
            const notMatchMainCountry = this.isMainAddress && this.userMainAddress.countryId !== userInfo.countryId;
            const activeAddress = this.isMainAddress ? this.userMainAddress : addressToEdit;
            if (!this.isMainAddress && !this.addressesById[this.editAddressId])
                this.editAddressId = null;
            this.activeAddress = activeAddress;

            const mapStates = region =>
                getSafe(() => find(this.stateOptions, { key: region }).name) ||
                getSafe(() => find(this.stateOptions, { name: region }).name);

            this.addressForm.add([
                {
                    key: 'firstName',
                    label: 'firstName',
                    type: 'required',
                    value: notMatchMainCountry ? '' : activeAddress.firstName || userInfo.firstName,
                },
                {
                    key: 'lastName',
                    label: 'lastName',
                    type: 'required',
                    value: notMatchMainCountry ? '' : activeAddress.lastName || userInfo.lastName,
                },
                {
                    key: 'companyName',
                    label: 'companyName',
                    value: notMatchMainCountry ? '' : activeAddress.companyName || '',
                },
                {
                    key: 'address',
                    label: 'address',
                    type: 'required',
                    value: notMatchMainCountry ? '' : activeAddress.address || '',
                },
                {
                    key: 'address2',
                    label: 'apt',
                    value: notMatchMainCountry ? '' : activeAddress.address2 || '',
                },
                {
                    key: 'city',
                    label: 'city',
                    type: 'required',
                    value: notMatchMainCountry ? '' : activeAddress.city || '',
                },
                {
                    key: 'postalCode',
                    label: 'zip',
                    type: 'required',
                    value: notMatchMainCountry ? '' : activeAddress.postalCode || '',
                },
                {
                    key: 'region',
                    label: 'state',
                    type:
                        this.isMainAddress && flagStore.isFeatureEnabled('disallowquebecaddresses')
                            ? 'region'
                            : 'required',
                    value: notMatchMainCountry ? '' : mapStates(activeAddress.region) || '',
                },
                {
                    key: 'telephone',
                    label: 'telephone',
                    type: 'phoneOptional',
                    value: notMatchMainCountry ? '' : getSafe(() => phone(activeAddress.telephone)) || '',
                },
                {
                    key: 'isDefaultShipping',
                    label: 'isDefaultShipping',
                    type: 'optional',
                    value: notMatchMainCountry ? false : activeAddress.isDefaultShipping || false,
                },
                {
                    key: 'isDefaultBilling',
                    label: 'isDefaultBilling',
                    type: 'optional',
                    value: notMatchMainCountry ? false : activeAddress.isDefaultBilling || false,
                },
            ]);
        };
        formStore.clearForm('addressForm');
        formStore.createReactiveForm('addressForm', 'forms', createAction);
    }

    @action
    async saveAddress() {
        const form = this.addressForm;
        form.validate();
        if (form.valid) {
            try {
                const { fields } = form;
                interfaceStore.showProgress('account-update');

                const mapStates = region =>
                    getSafe(() => find(this.stateOptions, { name: region }).key) ||
                    getSafe(() => find(this.stateOptions, { key: region }).key);

                const body = toJS({
                    phoenixId: userStore.userInfo.phoenixId,
                    addressName: this.activeAddress.addressName || uuidv4(), // vtex address id
                    firstName: fields.firstName.value,
                    lastName: fields.lastName.value,
                    address: fields.address.value,
                    address2: fields.address2.value,
                    city: fields.city.value,
                    region: mapStates(fields.region.value),
                    postalCode: fields.postalCode.value,
                    telephone: fields.telephone.value,
                    country: localeStore.activeLocale.country,
                    countryId: localeStore.activeLocale.countryId,
                    isDefaultShipping: fields.isDefaultShipping.value ? true : false,
                    isDefaultBilling: fields.isDefaultBilling.value ? true : false,
                    id: this.editAddressId || generateFirebaseId(),
                });

                const writeAddress = await writeAddressData(body);

                if (!writeAddress.success) {
                    interfaceStore.hideProgress('account-update');
                    throw writeAddress.error;
                } else {
                    interfaceStore.hideProgress('account-update');
                    messagingStore.addMessage('Successfully saved address.', { type: 'success' });

                    this.handleUpdateAccountTrack({
                        ...this.getAccountInfo({
                            email: userStore.email,
                            fullName: userStore.fullName,
                            userInfo: userStore.userInfo,
                            userTypeLabel: userStore.userTypeLabel,
                        }),
                        shippingAddress: {
                            name: `${body.firstName} ${body.lastName}`,
                            address1: body.address,
                            address2: body.address2,
                            city: body.city,
                            region: body.region,
                            country: localeStore.activeLocale.countryShort,
                            phone: body.telephone,
                            zipcode: body.postalCode,
                        },
                    });
                    this.getAddresses();
                    this.clearAddressForms();
                    window.scrollTo(0, 0);
                    return true;
                }
            } catch (error) {
                console.log(error);
                interfaceStore.hideProgress('account-update');
                return false;
            }
        }
        return false;
    }

    @action
    async saveMainAddress() {
        const form = this.addressForm;
        form.validate();
        if (form.valid) {
            try {
                const { fields } = form;
                interfaceStore.showProgress('account-update');

                const body = toJS({
                    phoenixId: userStore.userInfo.phoenixId,
                    email: this.userContact.email,
                    birthDateUTC: this.userContact.birthday,
                    phone: this.userContact.telephone,
                    mainAddress: {
                        firstName: fields.firstName.value,
                        lastName: fields.lastName.value,
                        address: fields.address.value,
                        address2: fields.address2.value,
                        city: fields.city.value,
                        region: fields.region.value,
                        postalCode: fields.postalCode.value,
                        telephone: fields.telephone.value,
                        country: localeStore.activeLocale.country,
                        countryId: localeStore.activeLocale.countryId,
                        id: this.userMainAddress.id || generateFirebaseId(),
                    },
                });

                const updatedAddress = await writeUserData(body);

                if (!updatedAddress.success) {
                    interfaceStore.hideProgress('account-update');
                    throw updatedAddress.error;
                } else {
                    interfaceStore.hideProgress('account-update');
                    messagingStore.addMessage('Successfully updated main address information.', {
                        type: 'success',
                    });
                    this.getUserContact();
                    return true;
                }
            } catch (error) {
                console.log(error);
                interfaceStore.hideProgress('account-update');
                return false;
            }
        }
        return false;
    }

    @action
    async deleteAddress(address) {
        try {
            interfaceStore.showProgress('account-update');
            window.scrollTo(0, 0);

            const deletedAddress = await deleteAddressData(address);

            if (!deletedAddress.success) {
                interfaceStore.hideProgress('account-update');
                throw deletedAddress.error;
            } else {
                interfaceStore.hideProgress('account-update');
                messagingStore.addMessage('Successfully deleted address.', { type: 'success' });
                await this.getAddresses();
                return true;
            }
        } catch (error) {
            console.log(error);
            interfaceStore.hideProgress('account-update');
            return false;
        }
    }

    @action
    toggleAddressEdit(id: string) {
        this.editAddress = !!id || false;
        this.editAddressId = id;
        this.setupAddressForm();
    }

    @action
    clearAddressForms(options: any = {}) {
        this.editAddress = false;
        this.editAddressId = null;
        this.addressForm = null;
        if (options.scrollToTop) {
            window.scrollTo(0, 0);
        }
    }
    // End of User Address Logic

    // make best effort to return a title id using data available
    @computed
    get titleId() {
        if (this.recognitionTitleId)
            return this.recognitionTitleId
        if (userStore.userInfo?.titles?.recognitionTitleId)
            return userStore.userInfo.titles.recognitionTitleId
        return userTitleIds.consultant;
    }

    @computed
    get accountTitle() {
        return userStore.getTitleByTitleId(this.titleId);
    }

    @action
    getBvRequiredForNextLevel() {
        if (!this.paidAsTitleId) return 0;
        if (isEmpty(toJS(userStore.titleConfig))) return 0;
        const { managingDirector: MD } = userTitleIds;
        // if user is MD, return MD requirement
        const nextTitleId = this.paidAsTitleId == MD ? MD : this.paidAsTitleId + 1;
        return userStore.titleConfig[nextTitleId].requiredBv;
    }
    // End of Account Title Logic

    // Payment Options Logic
    @action
    async getPaymentOptions() {
        try {
            this.paymentLoading = true;
            const response = this.useVtexPayments ? await getVtexCustomerCards() : await getCustomerCards({ country: this.country });
            if (response.success && response.cards) {
                set(this, 'paymentOptions', response.cards);
                this.setPaymentOptions(!isEmpty(this.paymentOptions));
                this.paymentLoading = false;
                return true;
            }
            throw Error('Failed to retrieve cards.');
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    @computed
    get paymentOptionsById() {
        return keyBy(this.paymentOptions, 'id');
    }

    @action
    setPaymentOptions(bool) {
        this.userProfile[keys.account.payment] = bool;
    }

    @action
    setupPaymentForm() {
        const createAction = () => {
            this.paymentForm = formStore.create({
                key: 'paymentForm',
                persist: false,
            });
            const { userInfo } = userStore;
            const activePayment = this.paymentOptionsById[this.editPaymentId] || {};
            if (!this.paymentOptionsById[this.editPaymentId]) this.editPaymentId = null;

            let { firstName } = userInfo;
            let { lastName } = userInfo;
            if (activePayment.cardholder_name) {
                firstName = activePayment.cardholder_name.split(' ')[0];
                lastName = activePayment.cardholder_name.split(' ')[1];
            }

            this.paymentForm.add([
                {
                    key: 'firstName',
                    label: 'firstName',
                    type: 'required',
                    value: firstName || '',
                },
                {
                    key: 'lastName',
                    label: 'lastName',
                    type: 'required',
                    value: lastName || '',
                },
            ]);
        };
        formStore.clearForm('paymentForm');
        formStore.createReactiveForm('paymentForm', 'forms', createAction);
    }

    // CURRENTLY NOT BEING USED
    @action
    validatePayment = ({ action = null }) => {
        if (this.paymentHandler.square && this.paymentHandler.square.requestCardNonce) {
            this.paymentHandler.validateCallback = action;
            this.paymentHandler.square.requestCardNonce(action);
        }
    };

    // CURRENTLY NOT BEING USED
    @action
    addPaymentErrors = errors => {
        this.paymentHandler.errors = {};
        errors.map(error => {
            this.paymentHandler.errors[error.field] = error;
        });
        this.paymentHandler = cloneDeep(toJS(this.paymentHandler));
    };

    // CURRENTLY NOT BEING USED
    @action
    updatePaymentError = field => {
        if (this.paymentHandler.errors) {
            if (this.paymentHandler.errors[field]) delete this.paymentHandler.errors[field];
            this.paymentHandler = cloneDeep(toJS(this.paymentHandler));
        }
    };

    @action
    addCardData = cardData => {
        this.cardData = cardData;
        this.paymentHandler.squareNonce = cardData.nonce;
    };

    @action
    savePayment = async () => {
        const form = this.paymentForm;
        form.validate();

        const updateCard = async () => {
            try {
                const fields = form.fields;
                interfaceStore.showProgress('account-update');
                const cardAction = this.editPaymentId ? updateCustomerCard : createCustomerCard;

                const cardSave = await cardAction({
                    country: this.country,
                    nonce: this.paymentHandler.squareNonce,
                    firstName: fields.firstName.value,
                    lastName: fields.lastName.value,
                    cardId: this.editPaymentId,
                    postalCode: this.cardData.billing_postal_code,
                });

                if (!cardSave.success) {
                    interfaceStore.hideProgress('account-update');
                    throw cardSave.error;
                } else {
                    interfaceStore.hideProgress('account-update');
                    messagingStore.addMessage('Payment method was saved.', {
                        type: 'success',
                    });

                    this.handleUpdateAccountTrack({
                        ...this.getAccountInfo({
                            email: userStore.email,
                            fullName: userStore.fullName,
                            userInfo: userStore.userInfo,
                            userTypeLabel: userStore.userTypeLabel,
                        }),
                        paymentMethod: {
                            paymentType: CREDIT_CARD_PAYMENT_TYPE,
                            paymentGateway: SQUARE_PAYMENT_GATEWAY,
                            cardLast4:
                                get(cardSave, 'data.card.last_4', '') ||
                                get(cardSave, 'card.last_4', ''),
                        },
                    });
                    await this.getPaymentOptions();
                    this.clearPaymentForms();
                    return true;
                }
            } catch (error) {
                console.log(error);
                messagingStore.addMessage('Failed to authorize payment method.', {
                    type: 'error',
                });
                interfaceStore.hideProgress('account-update');
                return false;
            }
        };

        if (form.valid) {
            return updateCard();
        }
    };

    @action
    deletePayment = async method => {
        try {
            interfaceStore.showProgress('account-update');
            window.scrollTo(0, 0);
            const deletedCard = await deleteCustomerCard({
                country: this.country,
                cardId: method.id,
            });

            if (!deletedCard.success) {
                interfaceStore.hideProgress('account-update');
                throw deletedCard.error;
            } else {
                messagingStore.addMessage('Payment method was deleted.', {
                    type: 'success',
                });
                interfaceStore.hideProgress('account-update');
                await this.getPaymentOptions();
                return true;
            }
        } catch (error) {
            messagingStore.addMessage('There was an error deleting the payment method.', {
                type: 'error',
            });
            interfaceStore.hideProgress('account-update');
            return false;
        }
    };

    @action
    togglePaymentEdit = id => {
        if (id !== this.editPaymentId) this.clearPaymentForms();
        this.editPayment = id || false;
        this.editPaymentId = id;
        this.setupPaymentForm();
    };

    @action
    clearPaymentForms = () => {
        this.editPayment = false;
        this.editPaymentId = null;
        this.paymentForm = null;
        if (this.paymentHandler.square) {
            this.paymentHandler.square.destroy();
            this.paymentHandler = {};
        }
    };
    // End of Payment Options Logic

    // Product Credit Logic
    @computed
    get eligibleForCredit() {
        return !userStore.isConsultant && !userStore.isEmployee;
    }

    @computed
    get useVtexPC() {
        return flagStore.isFeatureEnabled('useVtexPC');
    }

    @action
    getStoreCredit() {
        this.creditLoading = true;
        creditSubscriber.addListener(
            this.loadStoreCredit.bind(this),
            localeStore.activeLocale.code,
        );
    }

    @action
    loadStoreCredit(credit) {
        if (credit) {
            this.credit = credit;
            this.creditInformtion =
                credit[localeStore.activeLocale.countryShort.toLowerCase()] || {};
        }
        this.creditLoading = false;
    }

    @action
    async getVtexCredit() {
        this.creditLoading = true;
        const response = await getVtexProductCredit();
        if (response.success) {
            this.credit = response.data;
            this.creditLoading = false;
        }
    }
    // End of Product Credit Logic

    // Square Web Payments SDK Logic

    getSquareCardData = async () => {
        try {
            const tokenResult = await this.squareSDKCard.tokenize();

            if (tokenResult.status === 'OK') {
                const { token, details = {} } = tokenResult;

                const { card = {}, billing = {} } = details;

                const cardData = {
                    billing_postal_code: billing?.postalCode,
                    card_brand: card?.brand,
                    digital_wallet_type: 'NONE',
                    exp_month: card?.expMonth,
                    exp_year: card?.expYear,
                    last_4: card?.last4,
                };

                return { token, cardData };
            }
            throw tokenResult;
        } catch (e) {
            console.error('Error trying to generate token with Square SDK', e);
            analyticsStore.customTracked(SDK_GENERATE_CARD_TOKEN_ERROR, e);
            return {};
        }
    };

    @action
    initializeSquareSDK = async elementId => {
        const { appId, locationId } = this.squareCredentials;

        try {
            const payments = Square.payments(appId, locationId);
            const newCard = await payments.card();
            this.squareSDKCard = newCard;

            await this.squareSDKCard.attach(elementId);
        } catch (e) {
            console.error('Error attaching card', e);
            analyticsStore.customTracked(SDK_PAYMENT_NOT_INITIALIZED, e);
        }
    };

    @computed
    get squareCredentials() {
        const { countryShort } = localeStore.activeLocale;
        const country = countryShort || 'US';

        const { appId, locationId } = square[country];

        return { appId, locationId };
    }

    // End of Square Web Payments SDK Logic

}

const accountStore = new AccountStore();
export default accountStore;
