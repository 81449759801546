exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._6w3Xd{position:fixed;bottom:25px;left:0;z-index:10;-webkit-animation-duration:.3s;animation-duration:.3s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;width:100%;padding:0 25px;-webkit-box-sizing:border-box;box-sizing:border-box;pointer-events:none}@media screen and (max-width:667px){.Ux6hT{bottom:23%}}._2AIDp,._2CKf0{width:100%}._2AIDp{-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:end;-webkit-align-items:flex-end;-ms-flex-align:end;align-items:flex-end;text-align:right}._2AIDp,.FJ3to{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row}.FJ3to{position:fixed;width:100%;padding:0 25px;-webkit-box-sizing:border-box;box-sizing:border-box;z-index:10;top:25px;right:0;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:start;-webkit-align-items:flex-start;-ms-flex-align:start;align-items:flex-start;text-align:left}", ""]);

// exports
exports.locals = {
	"root": "_6w3Xd fadein forward-fill",
	"activeTray": "Ux6hT",
	"left": "_2CKf0",
	"right": "_2AIDp",
	"top": "FJ3to"
};