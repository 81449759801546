import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -1,
    y: -8,
    width: 60,
    height: 60,
};

class Newspaper extends Component {
    render() {
        return (
            <Icon size={{ width: 52, height: 43 }} {...this.props} altText='Newspaper' viewBox={viewBox}>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g
                        id="newspaper"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="2"
                    >
                        <g id="icon-btc-newsfeed">
                            <path
                                d="M6.3,40.05 C9.78,40.05 10.67,36.83 10.83,34.65 C10.92,33.49 10.75,32.33 10.76,31.17 C10.76,29.51 10.82,27.84 10.82,26.18 L10.82,12.65 L10.82,8.8 L10.82,5.53 C10.82,4.53 10.6,3.37 10.96,2.4 C11.77,0.22 14.69,0.53 16.97,0.53 L33.88,0.53 L42.32,0.53 C43.71,0.53 45.11,0.54 46.5,0.53 C47.53,0.53 48.7,0.55 49.32,1.31 C49.78,1.86 49.79,2.57 49.77,3.2 C49.75,4.03 49.72,4.85 49.72,5.68 C49.71,7.89 49.7,10.1 49.69,12.31 C49.67,16.77 49.65,21.22 49.63,25.68 C49.62,27.9 49.61,30.12 49.6,32.34 C49.59,34.33 49.62,36.36 48.15,38.09 C46.71,39.79 44.44,40.24 41.99,40.25 C39.24,40.27 36.48,40.25 33.73,40.25 L16.83,40.25 C14.06,40.25 11.25,40.39 8.48,40.27 C6.57,40.18 4.53,40.02 2.81,39.31 C1.36,38.72 0.28,37.68 0.28,36.35 L0.28,21.41 C0.28,21.41 0.01,19.35 2.06,19.35 L9.73,19.35"
                                id="Path"
                            />
                            <path d="M32.23,6.29 L42.77,6.29" id="Path" strokeLinecap="round" />
                            <path d="M32.23,11.27 L42.77,11.27" id="Path" strokeLinecap="round" />
                            <path d="M32.23,16.14 L42.77,16.14" id="Path" strokeLinecap="round" />
                            <path d="M16.53,21.06 L42.91,21.06" id="Path" strokeLinecap="round" />
                            <path d="M16.53,27.63 L42.91,27.63" id="Path" strokeLinecap="round" />
                            <path d="M16.53,33.9 L42.91,33.9" id="Path" strokeLinecap="round" />
                            <path
                                d="M27.62,15.65 C27.62,16.2 27.17,16.65 26.62,16.65 L17.55,16.65 C17,16.65 16.55,16.2 16.55,15.65 L16.55,6.58 C16.55,6.03 17,5.58 17.55,5.58 L26.62,5.58 C27.17,5.58 27.62,6.03 27.62,6.58 L27.62,15.65 Z"
                                id="Path"
                            />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Newspaper;
