import React, { Component } from 'react';
import { analytics, routes } from '@btc-frontend/config';
import { observer, inject } from 'mobx-react';

@inject('interfaceStore', 'analyticsStore', 'sessionStore')
@observer
class Segment extends Component {
    render() {
        const config = analytics.segment;

        if (__BROWSER__ && config.enabled) {
            function segmentLoader() {
                if (window.segmentCalled) return false;
                window.segmentCalled = true;
                var analytics = (window.analytics = window.analytics || []);
                if (!analytics.initialize)
                    if (analytics.invoked)
                        window.console &&
                            console.info &&
                            console.info('Segment snippet included twice.');
                    else {
                        analytics.invoked = !0;
                        analytics.methods = [
                            'trackSubmit',
                            'trackClick',
                            'trackLink',
                            'trackForm',
                            'pageview',
                            'identify',
                            'reset',
                            'group',
                            'track',
                            'ready',
                            'alias',
                            'debug',
                            'page',
                            'once',
                            'off',
                            'on',
                        ];
                        analytics.factory = function(t) {
                            return function() {
                                var e = Array.prototype.slice.call(arguments);
                                e.unshift(t);
                                analytics.push(e);
                                return analytics;
                            };
                        };
                        for (var t = 0; t < analytics.methods.length; t++) {
                            var e = analytics.methods[t];
                            analytics[e] = analytics.factory(e);
                        }
                        analytics.load = function(t, e) {
                            var n = document.createElement('script');
                            n.type = 'text/javascript';
                            n.async = 1;
                            n.src =
                                ('https:' === document.location.protocol ? 'https://' : 'http://') +
                                'cdn.segment.com/analytics.js/v1/' +
                                t +
                                '/analytics.min.js';
                            var o = document.getElementsByTagName('script')[0];
                            o.parentNode.insertBefore(n, o);
                            analytics.integrationOptions = e;
                        };
                        analytics.SNIPPET_VERSION = '4.0.1';
                        analytics.load(config.key);
                        var query = window.location.search,
                            queries;
                        if (query) {
                            queries = query
                                .substring(1)
                                .split('&')
                                .filter(function(q) {
                                    return q.substring(0, 4) === 'utm_';
                                })
                                .join('&');
                            queries &&
                                this.props.sessionStore.saveKey('utmString', queries, {}, true);
                        }
                        var title =
                            window.document.title &&
                            window.document.title.replace(' - Beautycounter', '');
                        this.props.analyticsStore.pageViewed(title);
                    }
            }

            const initialize = segmentLoader.bind(this);
            !window.location.pathname.includes(routes.ORDER_CONFIRM)
                ? this.props.interfaceStore.asyncLoad(initialize)
                : initialize();
        }

        return false;
    }
}

export default Segment;
