import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SiteDown.scss';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { getSafe, setSEOMeta } from '@btc-frontend/middleware/utils/object';
import MarkdownWrapper from '@btc-frontend/components/MarkdownWrapper';

@withStyles(s)
@inject('contentStore')
@observer
class SiteDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: window.innerWidth <= 999 ? true : false,
        };
        this.handleResize = this.handleResize.bind(this);
    }

    static pageName = 'siteDown';

    static contextTypes = {
        setTitle: PropTypes.func.isRequired,
        onSetMeta: PropTypes.func.isRequired,
    };

    @computed get config() {
        return getSafe(() => this.props.contentStore.content.configGeneral[0] || {});
    }

    updateTitle() {
        this.context.setTitle(getSafe(() => this.config.configSiteDown.displayName));
    }

    setPageMeta() {
        setSEOMeta(this.context, getSafe(() => this.config.configSiteDown.configSEO.fields));
    }

    handleResize() {
        if (!this.state.mobile && window.innerWidth <= 999) {
            this.setState({ mobile: true });
        } else if (this.state.mobile && window.innerWidth > 999) {
            this.setState({ mobile: false });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    render() {
        const {
            configSiteDown,
            siteDownWidgets: widgets,
            siteDownImage: image,
            siteDownLogo: logo,
        } = this.config;
        const { heading, displayName, signatureText } = configSiteDown || {};
        this.updateTitle();
        this.setPageMeta();

        return (
            <div className={s.root}>
                <div className={s.bgOverlay} />
                <div
                    style={{ backgroundImage: `url(${getSafe(() => image.fields.file.url)})` }}
                    className={s.imageContainer}
                >
                    {this.state.mobile && <img src={getSafe(() => image.fields.file.url)} />}
                </div>
                <div className={s.textContainer}>
                    <div className={s.textBox}>
                        <div className={s.title}>
                            <h2>{heading}</h2>
                        </div>
                        <div className={s.markdown}>
                            <MarkdownWrapper content={getSafe(() => widgets.fields.body)} />
                        </div>
                        <div className={s.signatureText}>{signatureText}</div>
                        <div className={s.signatureLogo}>
                            <img src={getSafe(() => logo.fields.file.url)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SiteDown;
