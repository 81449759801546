export const userTitleIds = {
    consultant: 1, // consultant tree
    srConsultant: 2, // consultant tree
    manager: 3, // consultant tree
    srManager: 4, // consultant tree
    director: 5, // director tree
    srDirector: 6, // director tree
    execDirector: 7, // director tree
    managingDirector: 8, // md tree
};

// Depreciate: contentful values available through userStore.accountTitles
export const userTitles = {
    managingDirector: 'Manager Director',
    managingDirectorFR: 'FR:Managing Director',
    executiveDirector: 'Executive Director',
    executiveDirectorFR: 'FR:Executive Director',
    seniorDirector: 'Sr. Director',
    seniorDirectorFR: 'FR:Sr. Director',
    director: 'Director',
    directorFR: 'FR:Director',
    seniorManager: 'Sr. Manager',
    seniorManagerFR: 'FR:Sr. Manager',
    manager: 'Manager',
    managerFR: 'FR:Manager',
    seniorConsultant: 'Sr. Consultant',
    seniorConsultantFR: 'FR:Sr. Consultant',
    consultant: 'Consultant',
    consultantFR: 'FR:Consultant',
};

export const userTitlesByIds = {
    1: 'Consultant',
    2: 'Senior Consultant',
    3: 'Manager',
    4: 'Senior Manager',
    5: 'Director',
    6: 'Senior Director',
    7: 'Executive Director',
    8: 'Managing Director',
};
