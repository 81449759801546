import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { observer, inject } from 'mobx-react';
import s from './EmptyLayout.scss';

@withStyles(s)
@observer
class EmptyLayout extends Component {
    static propTypes = {
        children: pt.node,
    };

    render() {
        if (__BROWSER__)
            return (
                <div className={s.root}>
                    <div className={s.content}>{this.props.children}</div>
                </div>
            );
        else return null;
    }
}

export default EmptyLayout;
