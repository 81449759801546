import React from 'react';

const I18_COMMON_SPACE_DELIMITER = '|';

export default function AutoCommonTranslationsProvider(Wrapped) {
    return class CommonalityUtil extends React.Component {
        constructor(props) {
            super(props);
            this.trans = this.trans.bind(this);
        }

        trans(key, options = {}) {
            if (!this.props.t) return !!!console.warn(`missing translator for ${key}`) && key;
            return this.props.t(`common${I18_COMMON_SPACE_DELIMITER}${key}`, options);
        }

        // const Child = props.children;
        render() {
            return <Wrapped {...this.props} t={this.trans} />;
        }
    };
}
