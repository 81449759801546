import teamService from '@btc-frontend/middleware/services/user/team';
import { getCurrentPeriod } from '@btc-frontend/middleware/utils/period';
import { isEmpty } from 'lodash';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import accountStore from '@btc-frontend/stores/accountStore';

async function fetchDownlineUsers({
    phoenixIds,
    periods,
    accountDetails,
    performance,
    accountLevel,
}: {
    phoenixIds: number[];
    periods?: number[];
    accountDetails: any;
    performance: any;
    accountLevel: any;
}) {
    const currentPeriod = getCurrentPeriod();
    if (!(periods && periods.length)) periods = [];
    else periods = [...periods, currentPeriod];
    // attempt to yeild user from store
    const notFoundInStorage = [];
    const accounts = {};

    for (let id of phoenixIds) {
        if (!(accountDetails[id] && performance[id] && accountLevel[id])) {
            notFoundInStorage.push(id);
            continue;
        }

        // we may not have all the performance data we need for this user.
        const missingPeriod = periods.some(period => !performance[id][period]);
        if (missingPeriod) {
            notFoundInStorage.push(id);
            continue;
        }

        accounts[id] = {
            ...accountDetails,
            ...getSafe(() => performance[id][currentPeriod].title),
            performance,
            level: accountLevel[id],
        };
    }

    // for non-sotrred users, fetch data from nogento
    const downlines = await teamService.fetchDownlineUsers(notFoundInStorage, periods);
    let updatedAccountDetails = deepClone(accountDetails);
    let updatedPerformance = deepClone(performance);
    let updatedAccountLevel = deepClone(accountLevel);

    if (downlines && Object.keys(downlines).length > 0) {
        notFoundInStorage.forEach(id => {
            updatedAccountDetails = {
                ...updatedAccountDetails,
                [id]: { ...downlines.accountDetails[id] },
            };

            // preserve upline count for better level determination on downlines
            const userHasUplineCount = getSafe(() => downlines.uplineCount[id]);
            if (userHasUplineCount)
                updatedAccountDetails[id].uplineCount = downlines.uplineCount[id];

            updatedPerformance = {
                ...updatedPerformance,
                [id]: {
                    // performance[userId] may have existing periods we don't want to overwrite
                    ...updatedPerformance[id],
                    ...downlines.performance[id],
                },
            };
            let uplineCount = 0;
            if (
                downlines.uplineCount &&
                !isEmpty(downlines.uplineCount) &&
                downlines.uplineCount[id]
            )
                uplineCount = downlines.uplineCount[id];
            if (downlines.uplines && !isEmpty(downlines.uplines) && downlines.uplines[id])
                uplineCount = downlines.uplines[id].length;

            updatedAccountLevel = {
                ...updatedAccountLevel,
                [id]: getLevelFromUpline(uplineCount),
            };

            if (downlines.accountDetails[id] && downlines.performance[id] && uplineCount) {
                const title = updatedPerformance[id][currentPeriod].title;
                accounts[id] = {
                    ...downlines.accountDetails[id],
                    ...title,
                    performance: downlines.performance[id],
                    level: accountLevel[id],
                };
                if (userHasUplineCount) accounts[id].uplineCount = downlines.uplineCount[id];
            }
        });
    }

    return {
        accountDetails: updatedAccountDetails,
        performance: updatedPerformance,
        accountLevel: updatedAccountLevel,
        accounts: phoenixIds.map(id => accounts[id]),
    };
}

function deepClone(toClone) {
    return JSON.parse(JSON.stringify(toClone));
}

function getLevelFromUpline(uplineCount: number) {
    return uplineCount - accountStore.uplineCount;
}

export default {
    fetchDownlineUsers,
    getLevelFromUpline,
};
