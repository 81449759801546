/**
 * Container declarations
 *
 * Use dynamic import() chunk for container that should be
 * loaded on demand and/or in parallel.
 * https://webpack.js.org/guides/code-splitting/
 */

const containers = {
    LoginPage: () =>
        import(/* webpackChunkName: "LoginPage" */
        '@btc-frontend/containers/PageContainer/LoginPage/LoginPage.jsx'),
    ZendeskPage: () =>
        import(/* webpackChunkName: "ZendeskPage" */
        '@btc-frontend/containers/PageContainer/ZendeskPage/ZendeskPage.jsx'),
    AccountContainer: () =>
        import(/* webpackChunkName: "AccountContainer" */
        '@btc-frontend/containers/AccountContainer/AccountContainer.jsx'),
    AccountStore: () =>
        import(/* webpackChunkName: "accountStore" */
        '@btc-frontend/stores/accountStore/accountStore'),
    NotFoundPage: () =>
        import(/* webpackChunkName: "notFoundPage" */
        '@btc-frontend/containers/PageContainer/NotFoundPage'),
    InventoryContainer: () =>
        import(/* webpackChunkName: "inventoryContainer" */
        '@btc-frontend/containers/InventoryContainer/InventoryContainer'),
    IncentivesContainer: () =>
        import(/* webpackChunkName: "incentivesContainer" */
        '@btc-frontend/containers/IncentivesContainer/IncentivesContainer'),
    GrowthTrackerContainer: () =>
        import(/* webpackChunkName: "growthTrackerContainer" */
        '@btc-frontend/containers/GrowthTrackerContainer/GrowthTrackerContainer'),
    NewsfeedContainer: () =>
        import(/* webpackChunkName: "NewsfeedContainer" */
        '@btc-frontend/containers/NewsfeedContainer'),
    NewsfeedStore: () =>
        import(/* webpackChunkName: "NewsfeedStore" */
        '@btc-frontend/stores/newsfeedStore/newsfeedStore.ts'),
    TeamContainer: () =>
        import(/* webpackChunkName: "TeamContainer" */
        '@btc-frontend/containers/TeamContainer/TeamContainer'),
    TeamStore: () =>
        import(/* webpackChunkName: "teamStore" */
        '@btc-frontend/stores/teamStore/teamStore'),
    OrderHistoryStore: () =>
        import(/* webpackChunkName: "OrderhistoryStore" */
        '@btc-frontend/stores/orderHistoryStore/orderHistoryStore.ts'),
    OrderHistoryContainer: () =>
        import(/* webpackChunkName: "OrderContainer" */
        '@btc-frontend/containers/OrderHistoryContainer/OrderHistoryContainer.tsx'),
    OrderDetailPage: () =>
        import(/* webpackChunkName: "OrderDetailPage" */
        '@btc-frontend/containers/PageContainer/OrderDetailPage/OrderDetailPage.tsx'),
    OrderDetailStore: () =>
        import(/* webpackChunkName: "OrderDetailStore" */
        '@btc-frontend/stores/orderDetailStore/orderDetailStore.ts'),
    ToolsContainer: () =>
        import(/* webpackChunkName: "ToolsContainer" */
        '@btc-frontend/containers/ToolsContainer'),
    ToolsStore: () =>
        import(/* webpackChunkName: "ToolsStore" */
        '@btc-frontend/stores/toolsStore'),
    EarningsContainer: () =>
        import(/* webpackChunkName: "EarningsContainer" */
        '@btc-frontend/containers/EarningsContainer/EarningsContainer'),
    EarningsStore: () =>
        import(/* webpackChunkName: "EarningsStore" */
        '@btc-frontend/stores/earningsStore/earningsStore'),
    StartCountingContainer: () =>
        import(/* webpackChunkName: "StartCountingContainer" */
        '@btc-frontend/containers/StartCountingContainer/StartCountingContainer.tsx'),
    PerformanceDashboardContainer: () =>
        import(/* webpackChunkName: "PerformanceDashboardContainer" */
        '@btc-frontend/containers/PerformanceDashboardContainer/PerformanceDashboardContainer.tsx'),
    MyCustomersContainer: () =>
        import(/* webpackChunkName: "MyCustomersContainer" */
        '@btc-frontend/containers/MyCustomersContainer/MyCustomersContainer.tsx'),
    MyCustomersStore: () =>
        import(/* webpackChunkName: "MyCustomersStore" */
        '@btc-frontend/stores/myCustomersStore/myCustomersStore.ts'),
    CustomerDetailPage: () =>
        import(/* webpackChunkName: "CustomerDetailPage" */
        '@btc-frontend/containers/PageContainer/CustomerDetailPage/CustomerDetailPage.tsx'),
    CustomerDetailStore: () =>
        import(/* webpackChunkName: "CustomerDetailStore" */
        '@btc-frontend/stores/customerDetailStore/customerDetailStore.ts'),
    CustomerDashboardContainer: () =>
        import(/* webpackChunkName: "CustomerDashboardContainer" */
        '@btc-frontend/containers/CustomerDashboardContainer/CustomerDashboardContainer.tsx'),
    CustomerDashboardStore: () =>
        import(/* webpackChunkName: "CustomerDashboardStore" */
        '@btc-frontend/stores/customerDashboardStore/customerDashboardStore.ts'),
    ConsultantDetailPage: () =>
        import(/* webpackChunkName: "ConsultantDetailPage" */
        '@btc-frontend/containers/PageContainer/ConsultantDetailPage/ConsultantDetailPage.jsx'),
    HomePageContainer: () =>
        import(/* webpackChunkName: "HomePageContainer" */
        '@btc-frontend/containers/HomePageContainer/HomePageContainer.tsx'),
    NudgesContainer: () =>
        import(/* webpackChunkName: "NudgesContainer" */
        '@btc-frontend/containers/NudgesContainer/NudgesContainer.jsx'),
    ProspectContainer: () =>
        import(/* webpackChunkName: "ProspectContainer" */
        '@btc-frontend/containers/ProspectContainer/ProspectContainer.tsx'),
    ProspectStore: () =>
        import(/* webpackChunkName: "ProspectStore" */
        '@btc-frontend/stores/prospectStore/prospectStore.ts'),
    PerformanceStore: () =>
        import(/* webpackChunkName: "PerformanceStore" */
        '@btc-frontend/stores/performanceStore/performanceStore.ts'),
    GeneralStore: () =>
        import(/* webpackChunkName: "GenearalStore" */
        '@btc-frontend/stores/generalStore/generalStore.ts'),
    RenewalOverrideContainer: () =>
        import(/* webpackChunkName: "RenewalOverrideContainer" */
        '@btc-frontend/containers/RenewalOverrideContainer'),
    PoliciesProceduresContainer: () =>
        import(/* webpackChunkName: "PoliciesProceduresContainer" */
        '@btc-frontend/containers/PoliciesProceduresContainer'),
    RedirectPage: () =>
        import(/* webpackChunkName: "RedirectPage" */
        '@btc-frontend/containers/PageContainer/RedirectPage'),
};

const preloadIgnore = ['SiteMapPage', 'CustomCategoryPage'];

export { containers, preloadIgnore };
