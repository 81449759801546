const breakpointValues = {
    xs: 0,
    sm: 667,
    md: 999,
    lg: 1000,
    xl: 1170,
};

export const themeBreakpoints = {
    values: breakpointValues,
};

const breakpoints = {
    phone: breakpointValues.sm,
    mobile: breakpointValues.md,
    laptop: breakpointValues.lg,
    desktop: breakpointValues.xl,
    ...breakpointValues,
};

export const up = (size, breakpoint) => size > Number(breakpoints[breakpoint]);
export const down = (size, breakpoint) => size <= Number(breakpoints[breakpoint]);

export default breakpoints;
