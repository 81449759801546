import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ScrollToTop.scss';
import cx from 'classnames';
import { throttle } from 'lodash';
import mixin from '@btc-frontend/middleware/utils/decorators/mixinComponent';
import ScrollToMixin from '@btc-frontend/components/Extensions/ScrollToMixin';
import { getSafe } from '@btc-frontend/middleware/utils/object';

import AngleUpIcon from '@counter-ui/Icon/Icons/Controls/AngleUp.jsx';
import { COLOR_WHITE } from '@btc-frontend/middleware/constants/colors';
import { computed } from 'mobx';
import { inject } from 'mobx-react/index';

@withStyles(s)
@mixin(ScrollToMixin)
@inject('contentStore')
class ScrollToTop extends Component {
    constructor(props) {
        super(props);

        this.handleClickScrollIcon = this.handleClickScrollIcon.bind(this);
        this.handleIconScroll = this.handleIconScroll.bind(this);

        this.state = {
            iconVisible: false,
        };
    }

    componentDidMount() {
        this.scrollListener = throttle(this.handleIconScroll, 100);
        this.touchListener = throttle(this.handleIconScroll, 120);
        window.addEventListener('scroll', this.scrollListener, { passive: true });
        window.addEventListener('touchmove', this.touchListener, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
        window.removeEventListener('touchmove', this.touchListener);
    }

    handleClickScrollIcon() {
        ScrollToMixin.scrollTo({
            target: '$top',
            duration: 400,
        });
    }

    handleIconScroll() {
        if (window.scrollY <= 260) {
            this.state.iconVisible && this.setState({ iconVisible: false });
        } else {
            !this.state.iconVisible && this.setState({ iconVisible: true });
        }
    }

    @computed get config() {
        return getSafe(() => this.props.contentStore.content.shop[0].topButton);
    }

    render() {
        const topButton = this.config;
        const showText = topButton && topButton.length !== 0;
        const classNames = cx(s.scrollToTop, {
            [s.shown]: this.state.iconVisible,
            [s.showText]: showText,
        });

        return (
            <div>
                <div
                    data-testid="scrollToTop"
                    className={classNames}
                    onClick={this.handleClickScrollIcon}
                >
                    <AngleUpIcon
                        color={COLOR_WHITE}
                        size={{
                            width: 16,
                            height: 16,
                        }}
                    />
                    {showText && (
                        <div className={s.text}>
                            <span>{topButton}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ScrollToTop;
