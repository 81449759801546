import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Waypoint from 'react-waypoint';

const DEFAULT_OFFSET = 50;

@inject('interfaceStore')
class LazyLoad extends React.Component {

    static defaultProps = {
        offset: DEFAULT_OFFSET,
        guessHeight: '500px',
    };

    state = {
        entered: false,
    }

    handleEnter = () => {
        !this.state.entered && this.setState({ entered: true });
    }

    render() {
        const { 
            offset,
            children,
            guessHeight,
            className,
        } = this.props;
        const { appIsLoaded, appIsMounted } = this.props.interfaceStore;

        const isDefault = offset === DEFAULT_OFFSET;
        const visible = appIsMounted && this.state.entered;
        const style = !visible && isDefault ? { height: guessHeight } : {};

        return  (
            <div className={className} style={style}>
                <Waypoint 
                    scrollableAncestor={'window'} 
                    topOffset={isDefault && appIsLoaded ? 10 : offset} 
                    onEnter={this.handleEnter}>
                        {visible && children}
                </Waypoint>
            </div>
        );
    }
}

export default LazyLoad;
