import React from 'react';
import injectSheet from 'react-jss';
import Drawer from '@material-ui/core/Drawer';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '@beautycounter/counter-ui/src/theme';
import className from 'classnames';

const styles = {
    drawerOpen: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: `${theme.palette.bone}`,
        width: 198,
        top: 55,
        height: '90vh',
        overflowX: 'hidden',
        transition: 'width 300ms cubic-bezier(0.6, 0, 0.6, 1)',
        boxShadow: `1px 0px 20px -2px ${theme.palette.gray80}`,
    },
    drawerClose: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        transition: 'width 300ms cubic-bezier(0.6, 0, 0.6, 1)',
        overflowX: 'hidden',
        overflowY: 'hidden',
        background: `${theme.palette.bone}`,
        width: 55,
        top: 55,
        height: '90vh',
    },
};

class SideDrawer extends React.Component {
    render() {
        const { classes, ...props } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: className({
                            [classes.drawerOpen]: this.props.open,
                            [classes.drawerClose]: !this.props.open,
                        }),
                    }}
                    {...props}
                >
                    {this.props.children}
                </Drawer>
            </MuiThemeProvider>
        );
    }
}

export default injectSheet(styles)(SideDrawer);
