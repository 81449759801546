import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Button.scss';
import cx from 'classnames';
import { observer, inject } from 'mobx-react';
import { startsWith } from 'lodash';
import {
    COLOR_BLACK,
    COLOR_WHITE,
    COLOR_BLUE,
    COLOR_CAT,
} from '@beautycounter/btc-frontend/src/middleware/constants/colors';

import Ink from 'react-ink';
import { redirectUrl } from '@beautycounter/btc-frontend/src/config';

@withStyles(s)
@inject('navigationStore', 'analyticsStore')
@observer
class Button extends Component<{
    round: boolean;
    thinBorder: boolean;
    nonuppercase: boolean;
    opacityHighlight: boolean;
    fontSize: number;
}> {
    static propTypes = {
        style: pt.object,
        onClick: pt.func,
        wide: pt.bool,
        to: pt.string,
        small: pt.bool,
        mobile: pt.bool,
        regular: pt.bool,
        fat: pt.bool,
        fullWidth: pt.bool,
        linkType: pt.bool,
        cat: pt.bool,
        noBorder: pt.bool,
        frame: pt.bool,
        thin: pt.bool,
        wrapText: pt.bool,
        error: pt.bool,
        color: pt.oneOf([COLOR_WHITE, COLOR_BLACK, COLOR_CAT, 'deepWhite', 'transparent', 'ghost']),
        icon: pt.element,
        disabled: pt.bool,
        disableStyleOnly: pt.bool,
        form: pt.string,
        className: pt.string,
        isSubmit: pt.bool,
        children: pt.node,
        tabindex: pt.string,
        analyticsData: pt.object,
        id: pt.string,
        newWindow: pt.bool,
    };

    static defaultProps = {
        wide: false,
        noBorder: false,
        frame: true,
        color: COLOR_BLACK,
        disabled: false,
        small: false,
        error: false,
        fat: false,
        cat: false,
        fullWidth: false,
        thin: false,
        isSubmit: false,
        regular: false,
        wrapText: false,
        tabindex: '',
        analyticsData: {},
        testId: null,
        id: null,
        round: false,
        nonuppercase: false,
        thinBorder: false,
        opacityHighlight: false,
        fontSize: 11,
        newWindow: false,
    };

    handleClick = event => {
        if (this.props.to) {
            event.preventDefault();

            const assign = startsWith(this.props.to, 'http') || startsWith(this.props.to, 'mailto');
            const newWindow =
                !startsWith(this.props.to, 'mailto') && !startsWith(this.props.to, redirectUrl);

            this.props.navigationStore.to({
                url: this.props.to,
                assign: assign,
                newWindow: newWindow,
            });
        }

        this.props.analyticsStore.buttonClicked({
            ...this.props,
            ...this.props.analyticsData,
        });

        this.props.onClick && this.props.onClick(event);
    };

    renderIcon() {
        return <span className={s.icon}>{this.props.icon}</span>;
    }

    render() {
        const {
            iconPosition,
            round,
            thinBorder,
            nonuppercase,
            opacityHighlight,
            fontSize,
        } = this.props;
        return (
            <button
                id={this.props.id}
                type={this.props.isSubmit ? 'submit' : 'button'}
                form={this.props.form}
                onClick={this.handleClick}
                style={typeof this.props.style !== undefined ? this.props.style : {}}
                className={cx(
                    s.root,
                    {
                        [s.black]: this.props.color === COLOR_BLACK,
                        [s.white]: this.props.color === COLOR_WHITE,
                        [s.deepWhite]: this.props.color === 'deepWhite',
                        [s.transparent]: this.props.color === 'transparent',
                        [s.ghost]: this.props.color === 'ghost',
                        [s.cat]: this.props.color === COLOR_CAT,
                        [s.noBorder]: this.props.noBorder,
                        [s.frame]: this.props.frame,
                        [s.regular]: this.props.regular,
                        [s.fat]: this.props.fat,
                        [s.thin]: this.props.thin,
                        [s.small]: this.props.small,
                        [s.mobile]: this.props.mobile,
                        [s.cat]: this.props.cat,
                        [s.wide]: this.props.wide,
                        [s.error]: this.props.error,
                        [s.disabled]: this.props.disabled || this.props.disableStyleOnly,
                        [s.wrapText]: this.props.wrapText,
                        [s.fullWidth]: this.props.fullWidth,
                        [s.link]: this.props.linkType,
                        [s.round]: round,
                        [s.thinBorder]: thinBorder,
                        [s.nonuppercase]: nonuppercase,
                        [s.opacityHighlight]: opacityHighlight,
                    },
                    this.props.className,
                )}
                disabled={this.props.disabled}
                tabIndex={this.props.tabindex}
                data-testid={this.props.testId}
            >
                {!s.link && (
                    <div className={s.ripple}>
                        <Ink duration="600" opacity={0.1} />
                    </div>
                )}
                <div className={cx(s.buttonContent, iconPosition && s.reversed)}>
                    <div style={{ fontSize }} className={s.text}>
                        {this.props.children}
                    </div>
                    {this.props.icon && this.renderIcon()}
                </div>
            </button>
        );
    }
}

export default Button;
