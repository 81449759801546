import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import { flags } from '@btc-frontend/config';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Notices.scss';
import cx from 'classnames';

@withStyles(s)
@inject('contentStore')
@observer
class Notices extends Component {
    render() {
        return (
            <>
                {this.props.contentStore.flags('contentDrafts') ? (
                    <div className={cx(s.notice, s.red)}>Drafts Mode</div>
                ) : (
                    <div className={cx(s.notice, s.gray)}>Draft Disabled</div>
                )}
            </>
        );
    }
}

export default Notices;
