import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import s from './DiscreteSlider.scss';

function valuetext(value: number) {
    return `${value} ms`;
}

export default function DiscreteSlider(props) {
    const [value, setValue] = useState(0);
    const { handleDialClick, label } = props;

    const handleChange = (event: any, newValue: number | number[]) => {
        setValue(newValue);
        handleDialClick && handleDialClick(newValue);
    };

    return (
        <div className={s.root}>
            <Typography id="discrete-slider" gutterBottom>
                {label} {`${value} ms`}
            </Typography>
            <Slider
                defaultValue={0}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={10000}
                marks
                onChange={handleChange}
                min={0}
                max={50000}
            />
        </div>
    );
}
