function getFirstObjectProperty(object) {
    return object[Object.keys(object)[0]];
}

function getFirstObjectPropertyName(object) {
    return Object.keys(object)[0];
}

function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    function isObject(item) {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

function getSafe(fn) {
    try {
        return fn();
    } catch (e) {
        return undefined;
    }
}

function validImage(imageURL) {
    return imageURL ? `https:${getSafe(() => imageURL)}` : '';
}

function setSEOMeta(context, seo) {
    if (seo) {
        return (
            context.onSetMeta('description', seo.seoDescriptionFacebook || null),
            context.onSetMeta('og:title', seo.seoTitle || null, 'property'),
            context.onSetMeta('og:description', seo.seoDescriptionFacebook || null, 'property'),
            context.onSetMeta(
                'og:image',
                validImage(getSafe(() => seo.seoImageFacebook.fields.file.url)),
                'property',
            ),
            context.onSetMeta(
                'og:image:secure_url',
                validImage(getSafe(() => seo.seoImageFacebook.fields.file.url)),
                'property',
            ),
            context.onSetMeta('og:type', 'website', 'property'),
            context.onSetMeta('twitter:name', 'summary_large_image'),
            context.onSetMeta('twitter:site', '@beautycounterhq'),
            context.onSetMeta('twitter:title', seo.seoTitleTwitter || null),
            context.onSetMeta('twitter:description', seo.seoDescriptionTwitter || null),
            context.onSetMeta(
                'twitter:image',
                validImage(getSafe(() => seo.seoImageTwitter.fields.file.url)) || null,
            )
        );
    }
}

export { getFirstObjectProperty, getFirstObjectPropertyName, mergeDeep, getSafe, setSEOMeta };
