import { observable, computed, action, toJS } from 'mobx';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { asyncModels } from '@btc-frontend/config';
import { isEmpty } from 'lodash';
import graphqlFetch from '@btc-frontend/middleware/api/graphql';
import {
    getNudgeData,
    getNudgeDataV2,
    markComplete,
    markDelete,
    createFollowUp,
    addNudgeNote,
    getAllUsersNotes,
    getUserNotes,
    createUserNotes,
    updateUserNotes,
    snooze,
    unsnooze,
} from '@btc-frontend/middleware/services/user/nudges';
import {
    CATEGORY_PERSONAL,
    CATEGORY_TEAM,
    CATEGORY_MEMBER,
    CATEGORY_CLIENTELING,
    STATUS_OPEN,
    STATUS_COMPLETE,
    STATUS_DELETE,
    SUBCAT_FOR_PERSONAL_SC,
    SUBCAT_FOR_DEGRADE,
    SUBCAT_FOR_RALLYWARE,
    SUBCAT_FOR_POPUPS,
} from '@btc-frontend/middleware/constants/nudge.js';
import { NUDGE_INITIAL_FETCH, NUDGE_BULK_OPERATION, NUDGE_FETCH } from '@btc-frontend/constants';

import contentStore from '@btc-frontend/stores/contentStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import navigationStore from '@btc-frontend/stores/navigationStore';
import messagingStore from '@btc-frontend/stores/messagingStore';
import analyticsStore from '@btc-frontend/stores/analyticsStore';
import localeStore from '@btc-frontend/stores/localeStore';
import orderDetailStore from '@btc-frontend/stores/orderDetailStore/orderDetailStore';

import { formatNumBySeparators } from '@btc-frontend/middleware/utils/amount';
import { bccomLocalizedRedirector } from '@btc-frontend/middleware/utils/url';
import { differenceInCalendarDays } from 'date-fns';
import generalStore from '@btc-frontend/stores/generalStore/generalStore';
import userStore from '@btc-frontend/stores/userStore/userStore';
import flagStore from '@btc-frontend/stores/flagStore/flagStore';
import { generateRallywareSsoLink } from '@btc-frontend/middleware/services/rallyware/sso';

export class NudgeStore {
    @observable nudges;
    @observable editNudge;
    @observable snoozedUsers;
    @observable isSnoozedUser;
    @observable completionForm;
    @observable allNotes;
    @observable currentNudge;
    @observable typeOfDashboard;

    constructor() {
        this.nudges = [];
        this.snooze = [];
        this.snoozedUsers;
        this.editNudge = false;
        this.isSnoozedUser = false;
        this.completionForm = null;
        this.currentNudge = null;
        this.mockData = {};
        this.typeOfDashboard = null;
    }

    @action
    async init() {
        interfaceStore.progress[NUDGE_INITIAL_FETCH] = true;
        interfaceStore.showProgress(NUDGE_INITIAL_FETCH);
        await new Promise(async resolve => {
            await this.getContentful();
            await this.getNudges();
            this.getMockData();
            resolve();
        }).then(() => {
            interfaceStore.progress[NUDGE_INITIAL_FETCH] = false;
            interfaceStore.hideProgress(NUDGE_INITIAL_FETCH);
        });
    }

    /* Nudges with uncompleted status */
    @computed get openNudges() {
        return getSafe(() => this.nudges.filter(r => r.status === STATUS_OPEN));
    }

    /* Nudges with completed status */
    @computed get completedNudges() {
        return getSafe(() => this.nudges.filter(r => r.status === STATUS_COMPLETE));
    }

    /* Team nudges that are uncompleted */
    @computed get teamNudges() {
        return getSafe(() =>
            this.nudges.filter(r => r.status === STATUS_OPEN && r.category === CATEGORY_TEAM),
        );
    }

    /* Clientele nudges that are uncompleted */
    @computed get clienteleNudges() {
        return getSafe(() =>
            this.nudges.filter(
                r =>
                    r.status === STATUS_OPEN &&
                    (r.category === CATEGORY_CLIENTELING || r.category === CATEGORY_MEMBER),
            ),
        );
    }

    /* Fetching Contentful data */
    @action getContentful = async () => {
        try {
            await contentStore.fetchContentfulConfig({
                ...asyncModels.nudges,
            });
            this.config = getSafe(() => contentStore.extensions.nudges.data.configNudges[0]) || {};
            this.labels =
                getSafe(() => contentStore.extensions.nudges.data.configNudges[0].labels) || {};
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    /* Get nudges from nogento */
    @action getNudges = async () => {
        try {
            let response;
            if (flagStore.isFeatureEnabled('nudgesv2')) {
                const { phoenixId } = userStore.userInfo;
                response = await getNudgeDataV2({
                    phoenixId: String(phoenixId),
                    status: ['open', 'complete'],
                });
            } else {
                response = await getNudgeData();
            }
            if (response.success) {
                if (response.feed) {
                    this.nudges = await Promise.all(
                        response.feed.nudges.map(async r => {
                            if (
                                r.category === CATEGORY_PERSONAL &&
                                SUBCAT_FOR_RALLYWARE.includes(r.subCategory)
                            ) {
                                r.meta.ssoUrl = await generateRallywareSsoLink(
                                    flags.newCounterULink,
                                    userStore.enrolled90DaysOrMore,
                                );
                            }
                            return { ...r, checked: false };
                        }),
                    );
                    this.snoozedUsers = response.feed.snoozedUsers || '';
                }
                interfaceStore.hideProgress(NUDGE_BULK_OPERATION);
                return { success: true, feed: this.nudges };
            }
        } catch (error) {
            console.log('Error fetching nudges: ', error);
            return false;
        }
    };

    /* counting number of nudges per specific event user (phoenixId) */
    @action countNudges = (list, id) => list.filter(r => r.eventUserId === Number(id)).length;

    /* get completed nudges per specific event user (phoenixId) */
    @action completedNudgesList = (list, id) => list.filter(r => r.eventUserId === Number(id));

    // category details from contentful
    @action getSubcategoryDetails = nudge => {
        let category =
            Object.keys(this.config).length &&
            this.config.category.filter(r => r.fields.categoryType === nudge.category);
        if (!Array.isArray(category) || !category.length) return;
        category = category[0].fields.categoryDetails;

        const subcategoryFilter = category.filter(r => r.fields.key === nudge.subCategory)[0];
        const subcategory = subcategoryFilter && subcategoryFilter.fields;
        if (!subcategory) return;
        return subcategory;
    };

    // formats title on nudge event
    @action getNudgeTitle = (title, data) =>
        title
            .replace('%s', `${data.eventUser.firstName} ${data.eventUser.lastName}`)
            .replace('%amount', formatNumBySeparators(data.meta.balance))
            .replace('%order', data.meta.orderId)
            .replace('%qv', formatNumBySeparators(data.meta.qvNeeded) || 0)
            .replace('%month', generalStore.getTranslatedPeriodName(data.meta.activityEndPeriod))
            .replace('%message', data.meta.message)
            .replace('%popupName', `"${data.meta.name}"`)
            .replace(
                '%closingDate',
                localeStore.formatDate(Date.parse(data.meta.closingDate), {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }),
            )
            .replace(
                '%expirationDate',
                localeStore.formatDate(Date.parse(data.meta.expirationDate), {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }),
            );

    // fetch data from contentful for empty state mock notification
    @action getMockData = () => {
        const mockNudge = {
            category: 'clienteling',
            subCategory: 'customFollowUp',
            eventUser: {
                firstName: 'Jane',
            },
        };
        const mockSubCategory = this.getSubcategoryDetails(mockNudge);
        this.mockData = { nudge: mockNudge, subCategory: mockSubCategory };
        return this.mockData;
    };

    // get date duration for each nudge
    @action duration = (dueDate, today = new Date()) => differenceInCalendarDays(dueDate, today);

    // group nudges by subcategory
    @action groupNudges = nudges => {
        const subCatName = [];
        const group = [];
        const index = 0;
        if (nudges) {
            nudges.map((data, index) => {
                const a = subCatName.findIndex(
                    element => element === `${data.category}${data.subCategory}`,
                );
                if (a === -1) {
                    subCatName.push(`${data.category}${data.subCategory}`);
                    index = subCatName.length - 1;
                    group.push([]);
                } else {
                    index = a;
                }
                group[index].push(data);
            });
            return group;
        }
    };

    // color of tag based on category
    @action colorPill = category => {
        // TODO: maybe add colors in contentful?
        if (category === CATEGORY_TEAM) {
            return '#328DAD';
        }
        if (category === CATEGORY_CLIENTELING) {
            return '#EA7365';
        }
        if (category === CATEGORY_MEMBER) {
            return '#F1B94F';
        }
        return 'black';
    };

    // link to consultant or customer or order detail page
    @action setLink = nudge => {
        if (nudge.category === CATEGORY_TEAM) {
            return `/consultant/${nudge.eventUserId}`;
        }
        if (nudge.category === CATEGORY_CLIENTELING || nudge.category === CATEGORY_MEMBER) {
            return `/customers/customer/${nudge.eventUserId}`;
        }
        if (
            nudge.category === CATEGORY_PERSONAL &&
            SUBCAT_FOR_DEGRADE.includes(nudge.subCategory)
        ) {
            return '/performance/dashboard';
        }
        if (SUBCAT_FOR_PERSONAL_SC.includes(nudge.subCategory)) {
            return `/performance/counting`;
        }
        if (
            nudge.category === CATEGORY_PERSONAL &&
            SUBCAT_FOR_RALLYWARE.includes(nudge.subCategory)
        ) {
            return nudge.meta.ssoUrl;
        }
        if (nudge.category === CATEGORY_PERSONAL && SUBCAT_FOR_POPUPS.includes(nudge.subCategory)) {
            const { useVtexPopups } = orderDetailStore;
            const link = useVtexPopups
                ? `/pop-ups/details/${nudge.meta.id}`
                : `/pop-ups/details?socialId=${nudge.meta.id}`;
            return bccomLocalizedRedirector(link);
        }
    };

    // sorting nudges by dashboard (customer, team, homepage)
    @action sortByDashboard = nudges => {
        if (navigationStore.path.includes('team')) {
            this.typeOfDashboard = CATEGORY_TEAM;
            return nudges.filter(r => r.category === CATEGORY_TEAM);
        }
        if (navigationStore.path.includes('customers')) {
            this.typeOfDashboard = CATEGORY_CLIENTELING;
            return nudges.filter(
                r => r.category === CATEGORY_MEMBER || r.category === CATEGORY_CLIENTELING,
            );
        }
        this.typeOfDashboard = null;
        return nudges;
    };

    // sorting completion logs in descending
    @action sortCompletionLogs = list =>
        list &&
        list.sort((a, b) => {
            a = new Date(a.completedDate);
            b = new Date(b.completedDate);
            return a > b ? -1 : a < b ? 1 : 0;
        });

    // get selected nudge data
    @action selectNudge = id => {
        const filterNudge = this.nudges.filter(r => r.id === id);
        this.currentNudge = getSafe(() => filterNudge[0]);
    };

    // clears selected nudge
    @action clearSelectedNudge = () => {
        this.currentNudge = null;
    };

    // get snooze data on user
    @action getSnoozedUser = async id => {
        await this.getNudges();
        if (this.snoozedUsers) return this.snoozedUsers[id];
    };

    @action addFollowUp = async form => {
        const body = toJS({
            category: CATEGORY_CLIENTELING,
            subCategory: 'customFollowUp',
            eventUserId: Number(form.eventUserId),
            idempotencyKey: form.eventUserId + new Date().toISOString(),
            dueDate: form.date,
            meta: { message: form.message },
        });
        try {
            interfaceStore.showProgress(NUDGE_FETCH);
            const response = await createFollowUp(body);
            if (response.success) {
                await this.getNudges();
                interfaceStore.hideProgress(NUDGE_FETCH);
                return true;
            }
            interfaceStore.hideProgress(NUDGE_FETCH);
            throw Error('Failed to add follow up ');
        } catch (error) {
            console.log(error);
            interfaceStore.hideProgress(NUDGE_FETCH);
            return false;
        }
    };

    @action nudgeStateCompleted = async nudges => {
        interfaceStore.showProgress(NUDGE_BULK_OPERATION);
        return new Promise(async resolve => {
            const checkedNudges = nudges.filter(nudge => nudge.checked === true);
            if (!isEmpty(checkedNudges)) {
                checkedNudges.map(async nudge => {
                    const response = await markComplete(nudge);
                    if (response.success) {
                        this.getNudges();
                        messagingStore.addMessage(this.config.labels.completeSuccess, {
                            type: 'success',
                        });
                    } else {
                        messagingStore.addMessage(this.config.labels.errorCompletingChecks, {
                            type: 'error',
                        });
                    }
                });
                analyticsStore.customTracked('Consultant BTC Notification Bulk Complete', {
                    notificationCounter: checkedNudges.length,
                });
                return true;
            }
            resolve();
        })
            .then(() => {
                this.getNudges();
                interfaceStore.hideProgress(NUDGE_BULK_OPERATION);
                return true;
            })
            .catch(error => {
                messagingStore.addMessage(this.config.labels.checkedNone, {
                    type: 'error',
                });
                return false;
            });
    };

    @action nudgeStateDeleted = async nudges => {
        interfaceStore.showProgress(NUDGE_BULK_OPERATION);
        return new Promise(async resolve => {
            const checkedNudges = nudges.filter(nudge => nudge.checked === true);
            if (!isEmpty(checkedNudges)) {
                checkedNudges.map(async nudge => {
                    const response = await markDelete(nudge);
                    if (response.success) {
                        this.getNudges();
                        messagingStore.addMessage(this.config.labels.deleteSuccess, {
                            type: 'success',
                        });
                    } else {
                        messagingStore.addMessage(this.config.labels.errorDeletingTitle, {
                            type: 'error',
                        });
                    }
                });

                analyticsStore.customTracked('Consultant BTC Notification Bulk Delete Complete', {
                    notificationCounter: checkedNudges.length,
                });
                return true;
            }
            resolve();
        })
            .then(() => {
                this.getNudges();
                interfaceStore.hideProgress(NUDGE_BULK_OPERATION);
                return true;
            })
            .catch(error => {
                messagingStore.addMessage(this.config.labels.checkedNone, {
                    type: 'error',
                });
                interfaceStore.hideProgress(NUDGE_BULK_OPERATION);
                return false;
            });
    };

    @action
    saveCompletionForm = async (nudge, form) => {
        const body = toJS({
            message: form.message,
            title: form.title,
            method: form.method,
            date: form.date || new Date().toISOString(),
            notes: form.notes,
        });
        try {
            if (nudge) {
                nudge.checked = true;
                if (body.notes) {
                    await addNudgeNote(nudge, body);
                }
                const response = await markComplete(nudge, body);

                if (response.success) {
                    this.getNudges();
                    return true;
                }
                messagingStore.addMessage('ERROR: Fetching Failed', { type: 'error' });
                return false;
            }
            throw Error('Failed to save nudge');
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    @action
    updateCompletionForm = async (nudge, form) => {
        const body = toJS({
            message: form.message,
            title: form.title,
            method: form.method,
            date: form.date || new Date().toISOString(),
            notes: form.notes,
        });
        try {
            if (nudge) {
                nudge.checked = true;
                if (body.notes) {
                    await addNudgeNote(nudge, body);
                }
                const response = await markComplete(nudge, body);

                if (response.success) {
                    this.getNudges();
                    return true;
                }
                messagingStore.addMessage('ERROR: Fetching Failed', { type: 'error' });
                return false;
            }
            throw Error('Failed to update nudge');
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    @action
    saveCompletionFormByEmail = async (nudge, form) => {
        const body = toJS({
            message: form.message,
            method: 'email',
        });

        try {
            if (nudge) {
                nudge.checked = true;
                const response = await markComplete(nudge, body);
                if (response.success) {
                    this.getNudges();
                    return true;
                }
                messagingStore.addMessage('ERROR: Fetching Failed', { type: 'error' });
                return false;
            }
            throw Error('Failed to mark Complete');
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    @action getAllUsersNotes = async () => {
        try {
            const response = await getAllUsersNotes();
            if (response.success) {
                this.allNotes = response;
                return this.allNotes;
            }
            throw Error('Failed to retrieve all users notes');
        } catch (error) {
            console.log(error);
            return '';
        }
    };

    @action getUserNotes = async id => {
        try {
            const response = await getUserNotes(Number(id));
            if (response.success) {
                if (response.data.length) return response.data[0];
                return;
            }
            throw Error('Failed to retrieve notes');
        } catch (error) {
            console.log(error);
            return '';
        }
    };

    @action saveUserNotes = async form => {
        try {
            const response = await createUserNotes(form);
            if (response.success) {
                return true;
            }
            throw Error('Failed to save notes');
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    @action updateUserNotes = async form => {
        try {
            const response = await updateUserNotes(form);
            if (response.success) {
                return true;
            }
            throw Error('Failed to update notes');
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    @action setSnooze = async (value, userId) => {
        try {
            let response;

            if (value.snoozeTillDate === 'always') response = await unsnooze(userId);
            else response = await snooze(value, userId);

            if (response.success) {
                this.isSnoozedUser = !!response.user;
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    };
}

const nudgeStore = new NudgeStore();

export default nudgeStore;
