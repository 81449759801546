import { observable, action, toJS } from 'mobx';
import {
    getSessionItem,
    saveSessionItem,
    clearStorage,
} from '@btc-frontend/middleware/services/session';
import { locales } from '@btc-frontend/config';
import { setNewsfeedState, getNewsfeedState } from '@btc-frontend/middleware/services/newsfeed';
import { saveUserSurvey } from '@btc-frontend/middleware/services/user/demographics';

import localeStore from '@btc-frontend/stores/localeStore';
import userStore from '@btc-frontend/stores/userStore';
import analyticsStore from '@btc-frontend/stores/analyticsStore';
import { generateSiftSessionId } from '@beautycounter/utils/id';

class SessionStore {
    @observable newsfeedState;
    @observable siftSessionId;

    constructor() {
        // app lifecycle storage for newsfeed.
        this.newsfeedState = {
            readStatuses: [],
            bookmarks: [],
            deleted: [],
        };
        this.siftSessionId = getSessionItem('siftSessionId', true) || generateSiftSessionId();
        saveSessionItem('siftSessionId', this.siftSessionId, true);
    }

    @action async restoreSessions() {
        const { activeLocale } = localeStore;

        const user = this.getKey('user');
        if (user && typeof user.loggedIn != 'undefined' && user.loggedIn === true) {
            userStore.isLogged;
        }
    }

    @action logoutOperations() {
        clearStorage();
    }

    @action
    getKey = (key, options = {}, sessionStorage = false) => {
        if (!__BROWSER__) return false;

        const locale = options.localized ? `-${localeStore.activeLocale.code}` : '';
        return getSessionItem(`${key}${locale}`, sessionStorage);
    };

    @action
    saveKey = (key, data, options = {}, sessionStorage = false) => {
        if (!__BROWSER__) return false;

        const locale = options.localized ? `-${localeStore.activeLocale.code}` : '';
        saveSessionItem(`${key}${locale}`, toJS(data), sessionStorage);
    };

    @action
    getAllKeyInstances = key => {
        const keys = {};
        locales.map(locale => {
            keys[locale.code] = this.getKey(`${key}-${locale.code}`);
        });

        return keys;
    };

    @action
    clearAllKeyInstances = (key, sessionStorage) => {
        locales.map(locale => saveSessionItem(`${key}-${locale.code}`, '', sessionStorage));
    };

    @action
    saveCart = params => {
        const entry = this.saveKey('cart', params, { localized: true });
    };

    get anonymousId() {
        return (
            __BROWSER__ &&
            window.analytics &&
            window.analytics.user() &&
            window.analytics.user().anonymousId()
        );
    }

    @action
    async hydrateNewsfeedState() {
        // TODO: mocked user id while waiting for user auth to be added to site.
        this.newsfeedState = await getNewsfeedState(userStore.userInfo.uid);
    }

    @action
    async addSavedFeedItemBookmark(id, title) {
        // also remove the bookmark if it already exists
        if (this.newsfeedState.bookmarks.includes(id)) {
            this.newsfeedState.bookmarks.remove(id);
            analyticsStore.customTracked('Consultant BTC Newsfeed Bookmark Remove Complete');
        } else {
            this.newsfeedState.bookmarks = [...this.newsfeedState.bookmarks, id];
            analyticsStore.customTracked('Consultant BTC Newsfeed Bookmark Add Complete', {
                assetBookmarked: title,
            });
        }

        setNewsfeedState(userStore.userInfo.uid, this.newsfeedState);
    }

    // takes a feed item id and adds it to the user's saved feed items.
    @action
    async addSavedFeedItemReadStatus(id) {
        // also remove the bookmark if it already exists
        if (!this.newsfeedState.readStatuses.includes(id)) {
            this.newsfeedState.readStatuses = [...this.newsfeedState.readStatuses, id];
            setNewsfeedState(userStore.userInfo.uid, this.newsfeedState);
        }
    }

    @action
    async addFeedItemDeleted(id) {
        // also remove the bookmark if it already exists
        if (!this.newsfeedState.deleted.includes(id)) {
            this.newsfeedState.deleted = [...this.newsfeedState.deleted, id];
            setNewsfeedState(userStore.userInfo.uid, this.newsfeedState);
        }
    }

    @action
    saveSurvey = async params => {
        this.saveKey('survey-selections', params, { localized: true });
        const response = await saveUserSurvey(params.answerSelections);
        userStore.updateUserInfo('demographics', response);
    };

    @action
    getSurvey = () => this.getKey('survey-selections', { localized: true });

    regenerateSiftSessionId() {
        const siftSessionId = generateSiftSessionId();
        this.siftSessionId = siftSessionId;
        saveSessionItem('siftSessionId', siftSessionId, true);
    }
}

const sessionStore = new SessionStore();

export default sessionStore;
export { SessionStore };
