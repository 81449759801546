const bcGreen = {
    50: '',
    100: '#9FCFCA', // seafoam green
    200: '',
    300: '#9FCFCA', // seafoam green
    400: '',
    500: '#9FCFCA', // seafoam green
    600: '',
    700: '#18978A', // glass green
    800: '',
    900: '#007566', // forest green
    A100: '',
    A200: '',
    A400: '',
    A700: '',
};

export default bcGreen;
