export const LOG_IN_EVENT = 'login';
export const LOG_OUT_EVENT = 'logout';
export const UPDATE_ACCOUNT_EVENT = 'update_account';
export const UPDATE_PASSWORD_EVENT = 'update_password';

export const NOGENTO_SIFT_ROUTES = {
    [LOG_IN_EVENT]: 'send-sift-sign-in-event',
    [LOG_OUT_EVENT]: 'send-sift-sign-out-event',
    [UPDATE_ACCOUNT_EVENT]: 'send-sift-update-account-event',
    [UPDATE_PASSWORD_EVENT]: 'send-sift-update-password-event',
};
