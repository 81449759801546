import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Notifications.scss';
import cx from 'classnames';
import Portal from '@btc-frontend/components/Common/Portal';
import { observer, inject } from 'mobx-react';
import IconClose from '@counter-ui/Icon/Icons/Controls/Close.jsx';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import { Error, Success, Info } from '@btc-frontend/middleware/constants/messageTypes';

@withStyles(s)
@inject('messagingStore')
@observer
class Notifications extends Component {
    state = {
        appIsMounted: false,
    };

    componentDidMount() {
        __BROWSER__ && requestAnimationFrame(() => this.setState({ appIsMounted: true }));
    }

    handleClose = id => {
        this.props.messagingStore.closeMessage(id);
    };

    render() {
        if (!this.state.appIsMounted) return false;

        const store = this.props.messagingStore;
        const activeMessage = store.activeBannerMessage;
        const message = store.readMessage(activeMessage);
        let style = {};
        if (message) {
            if (message.type == Error) style = { backgroundColor: '#d10402' };
            else if (message.type == Success) style = { backgroundColor: 'green' };
            else if (message.type == Info) style = { backgroundColor: '#F8E9CF', color: '#000' };
        }

        const Icon = message.icon;

        return (
            <Portal visibility={true}>
                <TransitionGroup>
                    {message && (
                        <CSSTransition
                            classNames={{
                                enter: s.fullscreenEnter,
                                enterActive: s.fullscreenEnterActive,
                                exit: s.fullscreenExit,
                                exitActive: s.fullscreenExitActive,
                            }}
                            key={this.props.messagingStore.activeMessage.id}
                            timeout={{ enter: 600, appear: 600, exit: 800 }}
                        >
                            <div style={style} className={cx(s.root)}>
                                <div className={s.close}>
                                    <IconClose
                                        size={{ width: 12, height: 12 }}
                                        className={s.close}
                                        color={'#fff'}
                                        onClick={() => this.handleClose(message.id)}
                                    />
                                </div>
                                <div className={s.message}>
                                    {!!message.icon && <Icon />}
                                    {message.message}
                                </div>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </Portal>
        );
    }
}

export default Notifications;
