import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -3,
    y: -8,
    width: 65,
    height: 65,
};

class GraduationCap extends Component {
    render() {
        return (
            <Icon size={{ width: 57, height: 49 }} {...this.props} altText='Graduation Cap' viewBox={viewBox}>
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <g
                        id="counter-u"
                        transform="translate(1.000000, 1.000000)"
                        stroke="#000000"
                        strokeWidth="3"
                    >
                        <g id="icon-btc-counter-u">
                            <g id="Group">
                                <path
                                    d="M29.05,28.33 L16.21,21.91 L12.36,21.91 L12.36,37.32 C12.36,37.32 12.36,42.46 14.93,43.74 C17.5,45.02 21.35,46.31 27.77,46.31 C34.19,46.31 40.61,43.74 40.61,43.74 C40.61,43.74 44.46,42.46 44.46,37.32 C44.46,32.18 44.46,21.91 44.46,21.91 L40.72,21.91 L29.05,28.33 Z"
                                    id="Path"
                                />
                                <polyline
                                    id="Path"
                                    points="40.72 21.91 54.73 14.2 29.05 0.08 0.8 14.2 16.21 21.91"
                                />
                            </g>
                            <path d="M4.65,16.77 L4.65,37.31" id="Path" />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default GraduationCap;
