import React, { useState } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../TestingUtilitiesLink/TestingUtilitiesLink.scss';
import { COLOR_WHITE } from '@btc-frontend/middleware/constants/colors';
import Draggable from 'react-draggable';

function DevToolDialog(props) {
    const [active, setActive] = useState(false);
    const [activeDrags, setActiveDrags] = useState(0);
    const { overrides } = props;

    const {
        Top: { component: Top, props: topProps },
        Optional: { component: Optional, props: otherProps },
    } = getComponents(overrides);

    const onStart = () => {
        setActiveDrags({ activeDrags: activeDrags + activeDrags });
    };

    const onStop = () => {
        setActiveDrags({ activeDrags: activeDrags - activeDrags });
    };

    const handleClickDialog = event => {
        event.stopPropagation();
        event.preventDefault();
        setActive(true);
    };

    const dragHandlers = { onStart, onStop };

    return (
        <>
            <div className={s.shareIcon} onClick={handleClickDialog}>
                <span style={{ color: COLOR_WHITE }}> Dev Tool Dialog </span>
            </div>
            {active && (
                <Draggable grid={[25, 25]} {...dragHandlers}>
                    <div className={s.defaultDevToolContent}>
                        <Top {...topProps} />
                        <Optional {...otherProps} />
                    </div>
                </Draggable>
            )}
        </>
    );
}

function getComponents(overrides) {
    return Object.keys(overrides).reduce((acc, name) => {
        const override = overrides[name] || {};
        acc[name] = {
            component: override.component,
            props: { $style: override.style, ...override.props },
        };
        return acc;
    }, {});
}

export default withStyles(s)(DevToolDialog);
