import { observable, computed, action, autorun } from 'mobx';
import { throttle } from 'lodash';
import analyticsStore from '@btc-frontend/stores/analyticsStore';

export interface SearchStoreInterface {
    headerValue: string;
    handleInput(area: string, e: object): boolean;
}

class SearchStore<SearchStoreInterface> {
    @observable headerValue;

    constructor() {
        const { box } = observable;
        this.headerValue = box('');
    }

    @action handleInput(area, e) {
        this.headerValue.set(e.target.value);
        return true;
    }
}

const searchStore = new SearchStore();

export default searchStore;
export { SearchStore };
