import { mergeDeep } from '@beautycounter/btc-frontend/src/middleware/utils/object';
import path from 'path';

const canUseDOM = !!(
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
    ),
    canUseWorkers = typeof Worker !== 'undefined',
    canUseEventListeners = canUseDOM && !!(window.addEventListener || window.attachEvent),
    canUseViewport = canUseDOM && !!window.screen;

/**
 * Gets the environment name passed in via command line
 * @param {Object} args
 */

function getEnvironmentName(args) {
    let customEnvironment = null;

    if (typeof process.env.NODE_ENV_SPECIAL == 'undefined' || args.test) {
        if (args.release && !args.environment) {
            customEnvironment = 'production';
        } else if (args.environment) {
            customEnvironment = args.environment;
        }
    }

    return customEnvironment;
}

/**
 * Finds the environment configuration passed in via command line
 * @param {String} env
 */

function getEnvironmentConfig(env) {
    let defaultEnvironment = require('../../../src/environment'),
        overrideEnvironment = {};

    if (typeof env === 'string')
        overrideEnvironment = require('../../../environments/environment.' + env);
    else if (typeof __TEST__ !== 'undefined' && __TEST__) {
        try {
            const fs = require('fs');
            const name = fs.readFileSync(path.resolve(__dirname, '../../../build/.env'), 'utf8');
            if (name.length > 2)
                overrideEnvironment = require(path.resolve(
                    __dirname,
                    '../../../environments/environment.' + name,
                ));
        } catch (e) {}
    }

    let environment = mergeDeep(defaultEnvironment.environment, overrideEnvironment.environment, {
        env: env,
    });

    // If in static rendering mode, override values with the render config
    // if(typeof process.env.NODE_ENV_SPECIAL != 'undefined') {
    //     environment = mergeDeep(
    //         environment,
    //         require('../../../environments/environment.render')
    //     );
    // }

    return environment;
}

export {
    canUseDOM,
    canUseWorkers,
    canUseEventListeners,
    canUseViewport,
    getEnvironmentConfig,
    getEnvironmentName,
};
