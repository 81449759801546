exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3j1k-{margin-left:10px;z-index:10;background-color:#231f20;font-size:12.8px;font-size:.8rem;font-stretch:expanded;color:#fefefe;padding:10px 14px;cursor:pointer;-webkit-transition:background-color .5s ease;-o-transition:background-color .5s ease;transition:background-color .5s ease;pointer-events:all;-webkit-animation-duration:.4s;animation-duration:.4s}._3j1k-:hover{background-color:#73777c}.JuqP0{background-color:#fefefe;border:1px solid #73777c;border-radius:3px;margin:10px;padding:10px;float:left;-webkit-box-sizing:border-box;box-sizing:border-box}", ""]);

// exports
exports.locals = {
	"shareIcon": "_3j1k- fadein forwards",
	"defaultDevToolContent": "JuqP0"
};