/**
 * Production environment
 * This configuration defines the final LIVE website environment
 * Run 'yarn start -- -environment=prod' to use this config
 */

const environment = {
    // base URL that the site will appear on
    liveUrl: 'https://behindthecounter-prod.web.app',
    redirectUrl: 'https://my.beautycounter.com',
    bccomUrl: 'https://www.beautycounter.com',
    newBccomUrl: 'https://www.beautycounter.com', // temp variable for vtex launch
    firebaseProject: 'beautycounter-prod',
    cdnUrl: 'https://assets.beautycounter.com',
    environmentName: 'production',
    vtexScope: 'beautycounter',

    // whether to use shortened CSS class names
    cssShortening: true,

    // whether to use JS minification and ahead-of-time compilation
    codeOptimization: true,

    // whether to enable detailed console messaging
    verbose: false,

    developerTools: false,

    flags: {
        contentDrafts: false,
        ordersCollection: false,
        teamTree: false,
        inventoryPage: true,
        renewalStatusOverride: false,
        renewalFeature: true,
        passwordStrength: false,
        rallyware: true,
        ssoLogin: true,
        useVtexUrls: true,
        newCounterULink: true,
    },

    firebase: {
        key: 'Prod',
        apiKey: 'AIzaSyA5Tr1PBGtBHFyDaMwXOTH0gjV6ZioSjOU',
        projectId: 'beautycounter-prod',
        apiBase: 'https://us-central1-beautycounter-prod.cloudfunctions.net',
        // images should point only to the drafts bucket, even in prod
        image: [
            'https://www.googleapis.com/storage/v1/b/beautycounter-drafts.appspot.com/o',
            'https://firebasestorage.googleapis.com/v0/b/beautycounter-drafts.appspot.com/o',
        ],
        assetBucket: 'beautycounter-prod-assets',
        assetURL: 'https://assets.beautycounter.com/btc',
        useChunkCDN: true,
    },

    narvarUrl: {
        base: 'https://beautycounter.narvar.com/beautycounter/tracking/',
    },

    rallyware: {
        redirectURI: 'https://counteru.beautycounter.com/oauth/v1/auth',
        trainingPage: '/digital-library',
        homePage: '/',
        clientId: 'LIrIrgKSqjOrYnHOT0WC',
    },

    klarna: {
        klarnaCanCutoverDateUTC: '2022-03-29T16:00:00.000Z',
    },

    nogento: {
        base: 'https://nogento.beautycounter.com',
        btc: 'https://api.btc.beautycounter.com',
    },
    legacyBtc: {
        base: 'https://behindthecounter.beautycounter.com',
    },

    phoenix: {
        pws: 'https://api2.beautycounter.com/pws',
        search: 'https://api.beautycounter.com',
        prospect:
            'https://7gabwec15f.execute-api.us-east-1.amazonaws.com/v1/account/addprospectaccount',
        leads: 'https://7gabwec15f.execute-api.us-east-1.amazonaws.com/v1/leads',
        geoSearch: 'https://7gabwec15f.execute-api.us-east-1.amazonaws.com/v1/account/geosearch',
        btcLink: 'https://behindthecounter.beautycounter.com',
    },

    square: {
        US: {
            url: 'https://js.squareup.com/v2/paymentform',
            appId: 'sq0idp-7s151C3XAsL-cehx07bvtw',
            locationId: 'WSBJ45H0VYF32',
            SDKUrl: 'https://web.squarecdn.com/v1/square.js',
        },
        CA: {
            url: 'https://js.squareup.com/v2/paymentform',
            appId: 'sq0idp-ct3CmwkwWp6lu-iki6bVig',
            locationId: 'D5GP0DNQ9Q6JK',
            SDKUrl: 'https://web.squarecdn.com/v1/square.js',
        },
    },

    // setting 'preview' to true will tell Contentful to pull drafts
    contentful: {
        preview: false,
    },

    info: {
        startupNotice: false,
    },
    redirect: true,

    analytics: {
        fullStory: {
            enabled: false,
        },
        optimizely: {
            enabled: true,
            datastore: 'https://cdn.optimizely.com/datafiles/BtyLTja8GVCjeUXz54jv4z.json',
        },
        newRelic: {
            enabled: true,
            sourceMaps: true,
            applicationId: '176353726',
        },
        segment: {
            enabled: true,
            key: '6qUtSHmy10kSn1RZcNnse3z2HDT7DV1e',
        },
    },

    sailthru: {
        customerId: '1b669ea68760541ca2f425b23a7d5e14',
        env: 'prod',
    },

    search: {
        noIndex: false,
    },

    paypal: {
        env: 'production',
        script: 'https://www.paypalobjects.com/api/checkout.js',
        US: {
            client: {
                production: 'AZv5KhCyr-ufiSAA4_OJLskWLjAYwRwTY-ZyDFIrLZtlgq7FRnbWvhhMg6Rf',
            },
        },
        CA: {
            client: {
                production:
                    'AV8yZA-_GbUdx-HX1NzXSjv-5fw9syyrexoqvuj7Ua1-HAWWe58hleJMNNJodH29Gy5y1Utp4lkmS9qQ',
            },
        },
    },

    bccomDomain: {
        base: 'https://www.beautycounter.com',
        newBase: 'https://my.beautycounter.com', // temp value for vtex drafts swap
    },
};

export { environment };
