import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';
import firebase from '@btc-frontend/middleware/firebase/init';
import { exportJsonAsCsv } from '@btc-frontend/middleware/services/export/csv';
import userStore from '@btc-frontend/stores/userStore';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import GraphQLFetch from '@btc-frontend/middleware/api/graphql';
import { getCurrentPeriod } from '@btc-frontend/middleware/utils/period';
import messagingStore from '@btc-frontend/stores/messagingStore';

async function getCoreTeamData(period, minimumTitleId) {
    const endpoint = `${nogento.btc
        }/rest/team/core-business?period=${period}&minimumTitleId=${minimumTitleId}`;
    const resp = await nogentoFetch(endpoint, 'GET');
    return resp;
}

async function fetchRankOneDownlineForUsers(phoenixIds, period, minimumTitleId) {
    if (!phoenixIds || !phoenixIds.length)
        return {
            success: false,
            error: 'No lookup accounts specified',
        };

    let endpoint = `${nogento.btc
        }/rest/team/level?period=${period}&minimumTitleId=${minimumTitleId}`;

    endpoint = endpoint.concat(phoenixIds.map(id => (id > 0 ? `&ids=${id}` : '')).join(''));

    return await nogentoFetch(endpoint, 'GET');
}

async function findUserInTeamTree(downlinePhoenixId: number) {
    const endpoint = `${nogento.btc}/rest/team/find-in-tree?phoenixId=${downlinePhoenixId}`;
    return await nogentoFetch(endpoint, 'GET');
}

async function getWatchList() {
    let endpoint = `${nogento.btc}/rest/team/watchlist`;

    return await nogentoFetch(endpoint, 'GET');
}

async function saveUserIdToWatchList(phoenixId: number) {
    if (!phoenixId) throw 'no user id specified';
    let endpoint = `${nogento.btc}/rest/team/watchlist/${phoenixId}`;
    return await nogentoFetch(endpoint, 'POST');
}

async function removeUserIdFromWatchList(phoenixId: number) {
    if (!phoenixId) throw 'no user id specified';
    let endpoint = `${nogento.btc}/rest/team/watchlist/${phoenixId}`;

    return await nogentoFetch(endpoint, 'DELETE');
}

async function fetchDownlineTable(
    searchArgs: IBtcTeamService.ITeamSearchArgs,
    country: string = 'US',
): Promise<IBtcTeamService.IElasticResponse> {
    let endpoint = `${nogento.btc}/rest/team/downline-table`;
    const resp: { data: IBtcTeamService.IElasticResponse } = await nogentoFetch(
        endpoint,
        'POST',
        searchArgs,
    );
    // console.log('fetchDownlineTable - response: ', JSON.stringify(resp));
    const { data } = resp;
    if (data && data.results && data.results.length) {
        const countryCommission = country == 'US' ? 'usCommission' : 'caCommission';
        data.results = data.results.map(acct => {
            const commission: IBtcTeamService.IPeriodVolume = acct[countryCommission];
            return { ...acct, ...commission };
        });
    }

    return data;
}

// CURRENTLY NOT BEING USED
async function fetchTreeGenerations(titleId) {
    let endpoint = `${nogento.base}/btc/team/generations?minimumTitleId=${titleId}`;
    const result = await nogentoFetch(endpoint, 'GET');
    return result;
}

async function fetchDownlineTableExport(
    searchArgs: IBtcTeamService.ITeamSearchArgs,
    baseLevel: number = null,
) {
    const data = await fetchDownlineTable(searchArgs);
    exportDownlineData(data, searchArgs.columnsToExport, baseLevel);
}

function exportDownlineData(data, columnsToExport, baseLevel) {
    const humanReadable = mapTableDataToCsvExportData(
        data,
        userStore.phoenixId,
        columnsToExport,
        baseLevel,
    );

    if (isEmpty(humanReadable)) {
        messagingStore.addMessage(`We couldn't find any data to download for the selected report. Please verify that the report contains the expected data and try again.`);
        return;
    }

    exportJsonAsCsv({
        records: humanReadable,
        fileName: 'my-team-export',
        appendTimestampToFileName: true,
    });
}

async function fetchDownlineUsers(phoenixIds: number[], periods?: number[]) {
    if (!phoenixIds || !phoenixIds.length) return {};
    let endpoint = `${nogento.btc}/rest/team/downline-account?`;
    if (periods.length > 1)
        endpoint = endpoint.concat(periods.map(period => `&periods=${period}`).join(''));
    const result = await nogentoFetch(endpoint, 'POST', {
        ids: phoenixIds,
    });
    return result.data;
}

async function nogentoFetch(url, method, body = null) {
    const auth = await firebase.auth();
    if (!auth.currentUser) return { success: false };
    const token = await auth.currentUser.getIdToken(true);

    const options = {
        method,
        headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }),
    };
    if (body) options.body = JSON.stringify(body);
    const response = await fetch(url, options);
    if (response.status < 200 || response.status >= 300) {
        const errorMessage = `[${response.status}] ${await response.text()}`;
        console.error('MY TEAM REQUEST FAILED: ', errorMessage);
        return {
            success: false,
            error: errorMessage,
        };
    }
    const resp = await response.json();
    return resp;
}

function mapTableDataToCsvExportData(
    esModel: IBtcTeamService.IElasticResponse,
    rootPhoenixId: number,
    selectedColumns: string[],
    baseLevel: number = null,
): IBtcTeamService.ICsvExportResult[] {
    if (!esModel) return [];

    const rootLevel =
        baseLevel ||
        getSafe(() => userStore.coreBusiness.absoluteRootLevel) ||
        esModel.results.map(x => x.uplineCount).sort((a, b) => a - b)[0];

    const csvModel = esModel.results.map(es => {
        const { firstName, lastName } = es;
        const fullName = `${es.firstName} ${es.lastName}`;
        const level = es.uplineCount - rootLevel;
        const enrollDate = es.enrollmentDateUTC
            ? dateFormat(new Date(es.enrollmentDateUTC), 'yyyy-mm-dd')
            : '';
        const nextRenewalDateUTC = es.nextRenewalDateUTC
            ? dateFormat(new Date(es.nextRenewalDateUTC), 'yyyy-mm-dd')
            : '';
        const paidAs = userStore.getTitleByTitleId(es.paidAs);
        const recognition = userStore.getTitleByTitleId(es.recognition);
        const birthday = es.birthDateUTC
            ? dateFormat(new Date(es.birthDateUTC), 'yyyy-mm-dd', true)
            : '';
        return <IBtcTeamService.ICsvExportResult>{
            firstName,
            lastName,
            level,
            country: es.countryId == 2 ? 'CA' : 'US',
            fullName,
            qv: es.qv || 0,
            bv: es.bv || 0,
            pv: es.pv || 0,
            cv: es.cv || 0,
            nv: es.nv || 0,
            fiftyPercentRule: es.fiftyPercentRule || 0,
            bvytd: es.bvytd || 0,
            directorLegs: es.directorLegs || 0,
            recognition,
            paidAs,
            recruitmentCount: es.recruitmentCount || 0,
            bobRecruitmentCount: es.bobRecruitmentCount || 0,
            flatCount: es.flatCount || 0,
            birthday,
            address: es.address || null,
            city: es.city || null,
            region: es.region || null,
            postalCode: es.postalCode || null,
            email: es.email || null,
            telephone: es.telephone || null,
            enrollDate,
            nextRenewalDateUTC,
            sponsorName: es.sponsorName || null,
            uplineDirectorName: es.uplineDirectorName,
            uplineMDName: es.uplineMDName,
        };
    });
    if (!(selectedColumns && selectedColumns.length)) return csvModel;

    // change internal column name to user-facing column name
    const exportColumns = selectedColumns.map(col => {
        switch (col) {
            case 'countryId':
                return 'country';
            case 'birthDateUTC':
                return 'birthday';
            default:
                return col;
        }
    });

    return csvModel.map(row => ({
        ...Object.keys(row)
            .filter(key => exportColumns.includes(key))
            .reduce((output, key): IBtcTeamService.ICsvExportResult => {
                output[key] = row[key];
                return output;
            }, {}),
    }));
}

async function fetchTopPromotions(
    size: number = 10,
    period: number,
    minFactor: number = 0.75,
    levels: number = 3,
) {
    try {
        const currentPeriod = getCurrentPeriod();

        const graphql = new GraphQLFetch();
        await graphql.useAuth();

        graphql.addType(
            'topPromotion',
            {
                size,
                period: currentPeriod,
                minFactor,
                levels,
            },
            `
                phoenixId
                firstName
                lastName
                paidAsTitle
                potentialTitle
                bvNeeded
                qvNeeded
                nvNeeded
                pvNeeded
                btlNeeded
                qvPercent
                bvPercent
                nvPercent
                pvPercent
                btlPercent
            `,
        );
        const {
            data: { topPromotion },
        } = await graphql.execute();
        return {
            success: true,
            data: topPromotion,
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            data: [],
        };
    }
}

export default {
    getWatchList,
    saveUserIdToWatchList,
    removeUserIdFromWatchList,
    getCoreTeamData,
    fetchRankOneDownlineForUsers,
    fetchDownlineTable,
    fetchDownlineTableExport,
    exportDownlineData,
    fetchDownlineUsers,
    findUserInTeamTree,
    fetchTreeGenerations,
    fetchTopPromotions,
};
