import { asyncModels } from '@btc-frontend/config';
import { observable, computed, action, autorun, set, toJS } from 'mobx';
import { getEnvironmentConfig } from '@btc-frontend/middleware/utils/env';
import getIncentives from '@btc-frontend/middleware/services/user/incentives';
import { fetchStartCounting } from '@btc-frontend/middleware/services/user/startCounting';
import { IStartCounting } from '@btc-frontend/middleware/services/user/startCounting/startCountingTypes';
import { STARTCOUNTING_FETCH } from '@btc-frontend/constants';
import contentStore from '@btc-frontend/stores/contentStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import flagStore from '@btc-frontend/stores/flagStore';
import userStore from '@btc-frontend/stores/userStore';
import localeStore from '@btc-frontend/stores/localeStore';
import { getPeriodDateComponents, getNameOfPeriod } from '@btc-frontend/middleware/utils/period';
import { OLD, ACTIVE } from '../../constants/startCountingStatuses';
import ordersApi from '@btc-frontend/stores/orderHistoryStore/ordersApi.js';
import { format, isWithinRange, addMonths } from 'date-fns';

// ABSTRACT: you need store, but you don't want to spam the list of stores. This is your store.

class GeneralStore {
    @observable incentives;
    @observable countryTranslations;
    @observable dateTranslations;
    @observable startCountingV2Translations;
    @observable startCounting: IStartCounting;
    @observable startCountingStatus: String;

    constructor() {
        this.incentives = {};
        this.startCounting = {};
        this.getCountryTranslations();
        this.getDateTranslations();
        this.getNewStartCountingTranslations();
    }

    @computed
    get useNewStartCounting() {
        return flagStore.isFeatureEnabled('usenewstartcounting');
    }

    @computed
    get useNewIbisVolumes() {
        return flagStore.isFeatureEnabled('ibis');
    }

    @computed
    get isIncentivesActive() {
        return flagStore.isFeatureEnabled('isIncentivesActive');
    }

    async init(what: string) {
        const { userInfo } = userStore;
        switch (what) {
            case 'incentives':
                interfaceStore.showProgress(STARTCOUNTING_FETCH);
                set(this, 'incentives', await getIncentives());
                interfaceStore.hideProgress(STARTCOUNTING_FETCH);
                break;
            case 'startCounting':
                interfaceStore.showProgress(STARTCOUNTING_FETCH);
                const startCounting = await fetchStartCounting(userInfo.phoenixId);
                set(this, 'startCounting', startCounting);
                interfaceStore.hideProgress(STARTCOUNTING_FETCH);
                break;
        }
    }

    @computed
    get isInStartCounting() {
        if (this.useNewStartCounting) {
            const { startCountingStatus = '' } = this.startCounting;
            if (!startCountingStatus) return false;
            if (startCountingStatus == ACTIVE || startCountingStatus == OLD) return true;
            return false;
        } 
            const { data = {}, success } = this.incentives;
            const { startDate = '', endDate = '', milestones, description } =
                data.program_enrollment || {};
            if (!success || !milestones || description == 'Not Found') return false;
            const currentDate = format(new Date(), 'MM-DD-YYYY');
            const start = format(startDate, 'MM-DD-YYYY');
            const end = format(addMonths(endDate, 1), 'MM-DD-YYYY');
            if (isWithinRange(currentDate, start, end)) return true;
            return false;
        
    }

    // global search
    globalSearch = async searchPayLoad => {
        const { searchText } = searchPayLoad;

        const canSearch = searchText.length >= 3;
        const result =
            (canSearch &&
                (await interfaceStore.withProgress(
                    searchPayLoad,
                    ordersApi.globalSearch,
                    'globalSearch',
                ))) ||
            null;

        return result;
    };

    async getNewStartCountingTranslations() {
        if (this.startCountingV2Translations) return this.startCountingV2Translations;
        try {
            const newSCContent = await contentStore.fetchContentfulConfig({
                ...asyncModels.startCountingV2,
            });
            set(this, 'startCountingV2Translations', newSCContent?.[0].translationsStartCounting?.[0].startCountingV2 || {})
            return this.startCountingV2Translations;
        } catch (e) {
            return null;
        }
    }

    async getCountryTranslations() {
        if (this.countryTranslations) return this.countryTranslations;
        try {
            const countryContent = await contentStore.fetchContentfulConfig({
                ...asyncModels.countries,
            });
            set(this, 'countryTranslations', countryContent[0].transalationsCountries[0].names);
            return this.countryTranslations;
        } catch (e) {
            return null;
        }
    }

    @action
    getLongCountryNameByCountryId(countryId) {
        const { languageId } = localeStore.activeLocale;
        const translations = this.countryTranslations;
        if (!translations) return null;
        return translations[countryId].longName[languageId];
    }

    @action
    getShortCountryNameByCountryId(countryId) {
        const { languageId } = localeStore.activeLocale;
        const translations = this.countryTranslations;
        if (!(translations && translations[countryId])) return null;
        return translations[countryId].shortName[languageId];
    }

    async getDateTranslations() {
        if (this.dateTranslations) return this.dateTranslations;
        try {
            const dateContent = await contentStore.fetchContentfulConfig({
                ...asyncModels.dates,
            });
            set(this, 'dateTranslations', dateContent[0].translationsDates[0].translations);
            return this.dateTranslations;
        } catch (e) {
            return null;
        }
    }

    @action
    getTranslatedPeriodName(period, langId) {
        const translations = this.dateTranslations;
        if (!translations) return getNameOfPeriod(period);
        const { month, year } = getPeriodDateComponents(period);
        const languageId = langId || localeStore.activeLocale.languageId;
        const monthName = translations.months[languageId][month];
        if (year < 1900) return monthName;
        return `${monthName} ${year}`;
    }
}

const generalStore = new GeneralStore();

export default generalStore;
export { GeneralStore };
