import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';
import { isEmpty } from 'lodash';

export default async function getVtexProductBySku(sku) {
    const endpoint = `${nogento.vtex}/productCatalog/${sku}`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        if (isEmpty(data.data.products)) return [];

        return data.data.products[0].items.filter(p => p.itemId === sku) || [];
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}
