import { locales as configLocales } from '@btc-frontend/config';
import { getSafe } from '@btc-frontend/middleware/utils/object';

import { containers, preloadIgnore } from '@btc-frontend/containers';
import { timeOut } from '@btc-frontend/middleware/utils/time';

export default async function init(stores, url, isInitialRender = true) {
    /**
     * Before resolving routes, ensure that all async requests
     * have resolved for essential data. We'll load them all
     * in parallel using a promise.
     */
    const { activeLocale } = stores.localeStore;
    const { content } = stores.contentStore;

    const startupPromises = [];
    let localeMatch = null;

    /**
     * Check URLs for existence of a locale code
     * If a code is found, set the language state.
     */
    if (!Object.keys(content).length || typeof window === 'undefined') {
        localeMatch = configLocales.filter(
            locale => url.toLowerCase().includes(locale.code.toLowerCase()) && locale,
        );
        if (localeMatch.length) {
            if (typeof activeLocale.code === 'undefined') {
                localeMatch[0].firstLoad = true;
                startupPromises.push(stores.localeStore.setActive(localeMatch[0]));
            } else if (activeLocale.code.toLowerCase() != localeMatch[0].code.toLowerCase()) {
                localeMatch[0].path = url;
                startupPromises.push(stores.localeStore.setActive(localeMatch[0], true));
            }
        }
    }

    if (typeof Object.keys(content).length < 1 || typeof window == 'undefined') {
        await Promise.all(startupPromises);
    }

    stores.navigationStore.newPath(
        url + (typeof window.location !== 'undefined' && window.location.search),
    );

    if (__BROWSER__ && isInitialRender) {
        stores.localeStore.autoDetect();
        stores.interfaceStore.addLoadListener();
    }

    // Prefetch routes, widgets and pages
    if (isInitialRender && __BROWSER__ && !__DEV__ && !stores.contentStore.flags('contentDrafts')) {
        async function preload() {
            if (!('serviceWorker' in navigator)) {
                for (const container in containers) {
                    if (!preloadIgnore.includes(container)) {
                        containers[container]();
                    }
                }
            }

            for (const page in stores.contentStore.content.page) {
                const pageConfig = getSafe(() => stores.contentStore.content.page[page]);
                pageConfig &&
                    pageConfig.url &&
                    stores.contentStore.getPage({
                        locale: activeLocale.code,
                        page: pageConfig.url,
                    });
                await timeOut(50);
            }
        }
        stores.interfaceStore.asyncLoad(preload);
    }
}
