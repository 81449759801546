const gray = {
    gray100: '#50555B',
    gray80: '#73777C',
    gray60: '#96999D',
    gray40: '#B9BBBD',
    gray20: '#DCDDDE',
    gray10: '#EDEEEE',
    gray1: '#F9F9F9',
};

module.exports = gray;
