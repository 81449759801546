import { observable, action, runInAction, set, computed } from 'mobx';
import getContent from '@btc-frontend/middleware/services/content';
import { isEmpty, cloneDeep } from 'lodash';
import { unlocalizeURL } from '@btc-frontend/middleware/utils/url';
import { developerTools, flags, locales } from '@btc-frontend/config';
import { rehydrateState } from '@btc-frontend/middleware/utils/hydration';
import { getCatalog } from '@btc-frontend/middleware/services/catalog';
import localeStore from '@btc-frontend/stores/localeStore';
import productsStore from '@btc-frontend/stores/productsStore';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import navigationStore from '@btc-frontend/stores/navigationStore';
import flagStore from '@btc-frontend/stores/flagStore';
import { initUtilities, handleUtilClick } from './testingUtilities';
import getVtexProductBySku from '@btc-frontend/middleware/services/vtex/productCatalog';

class ContentStore {
    @observable content;
    @observable utilities;
    @observable _extensions;

    constructor() {
        const serverState = rehydrateState('contentStore');

        this.content = serverState.content || {};
        this._extensions = observable(serverState.extensions || {});
        this.updateInProgress = 1;
        this.fetch = [];
        this.utilities = {};
        this.subscription;
        if (developerTools && __BROWSER__) this.initTestUtilities();
    }

    get extensions() {
        let callerName;
        try {
            throw new Error();
        } catch (e) {
            let re = /(\w+)@|at (\w+) \(/g,
                st = e.stack,
                m;
            re.exec(st), (m = re.exec(st));
            callerName = m[1] || m[2];
        }
        if (
            ![
                'asyncGeneratorStep',
                'doVisit',
                'updateClassComponent',
                'trackDerivedFunction',
                'fn',
            ].includes(callerName) &&
            __BROWSER__
        )
            console.warn(
                `Directly accessing translation extensions from contentStore is deprecated. Use i18n namespace instead in ${callerName}`,
            );
        return this._extensions;
    }

    set extensions(input) {
        set(this, '_extensions', input);
    }

    async retrieveAllModels(newLocale, page = false, loadCatalog = true, firstLoad = false) {
        const fetch = [];

        /* Retrieve all product catalogs */
        if (loadCatalog) {
            fetch.push(
                Promise.all(
                    locales.map(async locale => {
                        const params = {
                            locale,
                            useCache: this.flags('catalogCaching'),
                            firstLoad,
                            drafts: this.flags('contentDrafts'),
                        };
                        const catalog = await getCatalog(params);
                        return catalog;
                    }),
                ),
            );
        } else fetch.push(null);

        /* Retrieve extension data e.g. containers with CMS dependences */
        if (Object.keys(this._extensions).length) {
            Object.keys(this._extensions).map(extension => {
                const params = this._extensions[extension].params;
                fetch.push(this.getOnce({ ...params, firstLoad }));
            });
        }

        return Promise.all(fetch);
    }

    @computed
    get useVtexProductCatalog() {
        return flagStore.isFeatureEnabled('useVtexProductCatalog');
    }

    @action
    async getProductImage(sku) {
        const result = await getVtexProductBySku(sku);
        const image = (result[0] && result[0]) || '';
        return image;
    }

    // CURRENTLY NOT BEING USED
    @action
    async loadCatalog(code) {
        this.content = this.content || {};
        const locale = locales.find(x => x.code === code);

        if (!locale) return;
        const [, , , catalogs] = await this.retrieveAllModels();
        await productsStore.load(catalogs);
    }

    // Called in InventoryContainer for product catalog ONLY
    @action
    get = async ({
        locale = localeStore.activeLocale,
        page = false,
        showProgress = true,
        firstLoad = false,
        reload = false,
        loadCatalog = true,
    }) => {
        if (firstLoad) {
            this.content = {};
            this._extensions = observable({});
            this.fetch = [];
        }

        /* Create an immutable copy of the locale internally */
        const newLocale = JSON.parse(JSON.stringify(locale));

        showProgress && interfaceStore.showProgress(`content-get-${newLocale.code}`);

        const path = unlocalizeURL(__BROWSER__ ? window.location.pathname : newLocale.path);

        /* Refresh the URL and page to reflect the translated version */
        if (typeof this.content.page != 'undefined' && !isEmpty(this.content.page)) {
            for (const pageName in this.content.page) {
                if (pageName == path || `/${pageName}` == path) {
                    page = path;
                }
            }
            // TODO: Run pages that are not found through the router again
            // rather than defaulting
            if (!page) page = '/';
        }

        const response = await this.retrieveAllModels(newLocale, page, loadCatalog, firstLoad);

        const [catalog] = response;
        if (localeStore.activeLocale.code !== newLocale.code) {
            showProgress && interfaceStore.hideProgress(`content-get-${newLocale.code}`);
            return false;
        }

        runInAction('updating content', () => {
            if (loadCatalog) {
                productsStore.load(catalog);
            }

            function containsRoute(routes, url) {
                if (Array.isArray(routes))
                    return (
                        routes.filter(route => url.includes(route)).length || routes.includes(url)
                    );
                return url.includes(routes) || routes.includes(url);
            }

            // const pages = keyBy(pageIndex.page, 'url');

            // if (page && currentPage) {
            //     pages[page] = {
            //         ...cloneDeep(currentPage),
            //         activeLocale: newLocale,
            //     };
            // }

            this.content = {
                // ...coreContent,
                // page: { ...this.content.pages, ...pages },
            };
            set(this, '_extensions', { ...this._extensions });

            if (reload) navigationStore.to({ url: path + (location.search || '') });

            showProgress && interfaceStore.hideProgress(`content-get-${newLocale.code}`);
        });
    };

    @action
    getPage = async ({ locale, page, firstLoad }) => {
        if (
            this.content.page[page] &&
            this.content.page[page].activeLocale &&
            localeStore.activeLocale.code === this.content.page[page].activeLocale &&
            !this.contentDrafts
        )
            return true;

        const pageContent = await getContent({
            locale,
            type: 'singlePage',
            page,
            drafts: this.flags('contentDrafts'),
            firstLoad,
        });

        runInAction('updating page', async () => {
            if (locale == localeStore.activeLocale.code)
                this.content.page[page] = {
                    ...cloneDeep(pageContent),
                    activeLocale: locale,
                };
        });
    };

    @action
    getOnce = async item => {
        const data = await getContent({
            locale: localeStore.activeLocale.code,
            type: item.type,
            drafts: this.flags('contentDrafts'),
            firstLoad: item.firstLoad || false,
        });

        return { ...data, locale: localeStore.activeLocale.code, params: item };
    };

    @action
    createExtension = async (extension, options = {}) => {
        this._extensions[extension.id] = observable({
            data: null,
            params: extension,
            locale: localeStore.activeLocale.code,
        });

        const data = await getContent({
            locale: localeStore.activeLocale.code,
            type: extension.type,
            drafts: this.flags('contentDrafts'),
            spaceKey: options.contentfulSpace,
        });
        set(this._extensions, extension.id, {
            ...this._extensions[extension.id],
            data: cloneDeep(data),
        });
        try {
            this._extensions[extension.id].params.refreshCallback();
        } catch (e) {}
        return data;
    };

    @action
    addExtensionSubscription(location, callback) {
        try {
            this._extensions[location] = {
                ...(this._extensions[location] ? this._extensions[location] : {}),
                params: {
                    ...(this._extensions[location] && this._extensions[location].params
                        ? this._extensions[location].params
                        : {}),
                    refreshCallback: callback,
                },
            };
        } catch (e) {}
    }

    @action
    fetchContentfulConfig = async ({ id, route, models, callback = null, contentfulSpace }) => {
        if (
            this._extensions[id] &&
            this._extensions[id].data &&
            localeStore.activeLocale.code === this._extensions[id].locale &&
            !this.contentDrafts
        )
            return true;

        const actions = [
            this.createExtension(
                {
                    ...(this._extensions[id] ? this._extensions[id] : {}),
                    route,
                    id,
                    type: {
                        'sys.contentType.sys.id[in]': models.toString(),
                    },
                    ...(() => {
                        if (callback) return { refreshCallback: callback };
                        return {};
                    })(),
                },
                { contentfulSpace },
            ),
        ];
        return Promise.all(actions);
    };

    @action
    refresh = async () => {
        await this.get({ reload: true });
    };

    initTestUtilities(store) {
        if (!developerTools) return;
        initUtilities.call(this);
        this.handleUtilClick = handleUtilClick.bind(this);
    }

    @action
    flags(key, alt) {
        if (key === 'nogento') return true;
        if (
            this.utilities &&
            this.utilities[key] &&
            this.utilities[key].hasOwnProperty('enabled')
        ) {
            return typeof this.utilities[key].enabled === 'function'
                ? !!this.utilities[key].enabled()
                : !!this.utilities[key].enabled;
        }
        return flagStore.isFeatureEnabled[key] || flags[key];
    }
}

const contentStore = new ContentStore();

export default contentStore;
export { ContentStore };
