import errorMessages from '@btc-frontend/middleware/constants/errorMessages';

export const validators = {
    required: (title, value) => (value.length > 0 ? true : errorMessages['required'](title)),
    validEmail: (title, value) => (validEmail(value) ? true : errorMessages['validEmail']),
    validUsZipcode: (title, value) =>
        validUsZipcode(value) ? true : errorMessages['validUsZipcode'],
};

export function validEmail(email) {
    const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    // Return false if email domain extention is less than 2 characters long. (eg. test@gmail.c)
    const emailEndingExt = email.split('.');
    return emailEndingExt[emailEndingExt.length - 1].length <= 1
        ? false
        : re.test(email.toLowerCase());
}

export function validUsZipcode(zipcode) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
}

export function validCaZipcode(zipcode) {
    return /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/.test(zipcode);
}

export function validPhoneNumber(phone) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
}

export function validDate(date) {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return false;

    let parts = date.split('/');
    let day = parseInt(parts[1], 10);
    let month = parseInt(parts[0], 10);
    let year = parseInt(parts[2], 10);
    let dt = new Date();
    let currentYear = dt.getFullYear();

    if (year < 1900 || year > currentYear || month == 0 || month > 12) return false;

    let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

    return day > 0 && day <= monthLength[month - 1];
}

export function isOver18(date) {
    let dateUTC = new Date(date);
    let ageTimeStamp = Date.now() - dateUTC.getTime();
    let ageUTC = new Date(ageTimeStamp);
    let age = Math.abs(ageUTC.getUTCFullYear() - 1970);
    return age >= 18;
}

export function validUsSsn(ssn) {
    return /^\d{3}-\d{2}-\d{4}$/.test(ssn);
}

export function validCaSin(sin) {
    return /^\d{3}-\d{3}-\d{3}$/.test(sin);
}

export function validABA(aba) {
    const numericRegex = /^\d{9}$/;
    let total = null;

    const abaString = aba.toString();

    if (!numericRegex.test(abaString)) {
        return false;
    }

    for (let i = 0; i < 9; i += 3) {
        total +=
            parseInt(abaString.charAt(i), 10) * 3 +
            parseInt(abaString.charAt(i + 1), 10) * 7 +
            parseInt(abaString.charAt(i + 2), 10);
    }

    return total !== 0 && total % 10 === 0;
}

export function validHumanName(firstOrLastName) {
    if (!(firstOrLastName && firstOrLastName.length)) return false;
    // accept all latin characters (including those with accents), hyphens, and spaces
    var re = /^([A-Za-zÀ-ÖØ-öø-ÿ-' ]*)$/;
    return re.test(firstOrLastName.toLowerCase());
}

export function validRoutingNumber(value) {
    return /^[0-9-]{9}$/.test(value);
}

export function validNumber(value) {
    return /^[0-9]+$/.test(value);
}

export default validators;
