import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -4,
    y: -8,
    width: 65,
    height: 65,
};

class Home extends Component {
    render() {
        const { color = '#231f20', fill = '#231f20' } = this.props;
        return (
            <Icon
                size={{ width: 55, height: 47 }}
                {...this.props}
                color={color}
                fill={fill}
                altText="Home"
                viewBox={viewBox}
            >
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g id="home" transform="translate(1.000000, 1.000000)" stroke="#000000">
                        <g id="icon-btc-home">
                            <polyline
                                id="Path"
                                strokeWidth="2.5"
                                points="0 21.28 27 0.28 53 21.28"
                            />
                            <path
                                d="M7.97,15.27 L7.97,40.27 C7.97,40.27 7.97,44.27 10.97,44.27 L21.97,44.27 L21.97,33.27 C21.97,33.27 21.97,27.27 27.97,27.27"
                                id="Path"
                                strokeWidth="2.5"
                            />
                            <path
                                d="M46.97,16.27 L46.97,40.27 C46.97,40.27 46.97,44.27 43.97,44.27 L32.97,44.27 L32.97,33.27 C32.97,33.27 32.97,27.27 26.97,27.27"
                                id="Path"
                                strokeWidth="2.5"
                            />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Home;
