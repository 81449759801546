const red = {
    red100: '#E5503F',
    red80: '#EA7365',
    red60: '#EF968C',
    red40: '#F5B9B2',
    red20: '#FADCD9',
    red10: '#FCEDEB',
    red1: '#FEF9F9',
};

module.exports = red;
