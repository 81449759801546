export const ASSET_TYPE_SELECTION = 'asset-type-selection',
    TOOLS_FETCH = 'tools-fetch',
    FEATURED_TOOLS_CATEGORIES_FETCH = 'featured-tools-categories-fetch',
    NEWSLETTER_FETCH = 'newsletter-fetch',
    ORDERS_FETCH = 'order-history',
    STARTCOUNTING_FETCH = 'startcounting-fetch',
    CONTACT_CARD_FETCH = 'contact-card-fetch',
    TEAM_FETCH = 'team-fetch',
    TEAM_EXPORT = 'team-export',
    CUSTOMERS_FETCH = 'customer-fetch',
    TREE_NODE_FETCH = 'tree-node-fetch',
    CUSTOMER_DASHBOARD_FETCH = 'customer-dashboard-fetch',
    MY_CUSTOMERS_FILTERS = 'my-customers-filters',
    MY_CUSTOMERS_REORDER_FILTERS = 'my-customers-reorder-filters',
    MY_CUSTOMERS_USERTYPE_FILTERS = 'my-customers-usertype-filters',
    MY_CUSTOMERS_ENROLLED_30DAYS_FILTERS = 'my-customers-enrolled-30-days-filters',
    MY_CUSTOMERS_EXPIRING_30_DAYS = 'my-customers-expiring-30-days-filter',
    MY_CUSTOMERS_EXPIRING_7_DAYS = 'my-customers-expiring-7-days-filter',
    MY_CUSTOMERS_EXPIRING_8_TO_30_DAYS = 'my-customers-expiring-8-to-30-days-filter',
    MEMBER = 2,
    PROSPECT_INITIAL_FETCH = 'prospect-init-fetch',
    PROSPECT_FETCH = 'prospect-fetch',
    NUDGE_INITIAL_FETCH = 'nudge-init-fetch',
    NUDGE_FETCH = 'nudge-fetch',
    NUDGE_BULK_OPERATION = 'nudge-bulk-operation',
    ORDER_HISTORY_FILTERS = 'order-history-filters',
    ORDER_HISTORY_SORTBY = 'order-history-sortBy',
    USER_HOMEPAGE_STATE = 'user-homepage-state',
    DOWNLINE_REPORT_FILTERS = 'dlReportFilters',
    TEAM_REPORT_FILTERS = 'teamReportFilters',
    DOWNLINE_REPORT = 'downlineReport',
    WATCHLIST = 'watchlist',
    ENROLLER_TABLE_FILTERS = 'enroller-table-filters',
    TEAM_ENROLLER_TABLE_FILTERS = 'team-enroller-table-filters',
    PROSPECT_TABLE_FILTERS = 'prospect-table-filters',
    WATCHLIST_TABLE_FILTERS = 'watchlist-table-filters',
    ACTIVITY_REQUIREMENT_TABLE_FILTERS = 'activity-requirement-filters',
    DOWNLINE_TABLE_NAME = 'DownlineTable',
    WATCHLIST_TABLE_NAME = 'WatchlistTable',
    MEMBER_BREAKDOWN_FETCH = 'member-breakdown-fetch',
    DOWNLINE_NEXT_TITLE_FETCH = 'downline-next-title-fetch',
    EXPIRING_MEMBERS_FETCH = 'expiring-members-fetch',
    MY_CUSTOMERS_MEMBER_EXPIRATION_30_DAYS = 'my-customers-member-expiration-30-days';
