import { action, observable, computed } from 'mobx';
import firebase from '@btc-frontend/middleware/firebase/init';
import graphqlFetch from '@btc-frontend/middleware/api/graphql';
import { nogento, narvarUrl } from '@btc-frontend/config';
import interfaceStore from '@btc-frontend/stores/interfaceStore';
import flagStore from '@btc-frontend/stores/flagStore';
import format from 'date-fns/format';

const ORDERS_FETCH = 'order-history';

export interface IOrderDetailStore {
    t(a: string, b: {}): void; // TODO - define interface for translations
    orderDetailStore: {
        orderDetails: { narvarTrackingNumber: number; cart: any };
        trackingUrl: string;
    };
}
class OrderDetailStore {
    @observable orderDetailLine;
    @observable orderItems;
    @observable orderDetails;
    @observable openSocials;
    @observable itemTotal;
    @observable trackingUrl;
    @observable attachedSocial;
    @observable country;

    constructor() {
        this.clearCurrentOrder();
    }

    @computed
    get useVtexQuickOrderUrl() {
        return flagStore.isFeatureEnabled('useVtexQuickOrderUrl');
    }

    @computed
    get useVtexPopups() {
        return flagStore.isFeatureEnabled('useVtexPopups');
    }

    @action
    async init(id) {
        interfaceStore.showProgress(ORDERS_FETCH);
        try {
            const fetchedOrderDetails = await this.fetchOrderDetails(id);

            const { cart: { products = [] } = {} } = fetchedOrderDetails[0];
            this.orderDetails = fetchedOrderDetails[0];
            this.orderItems = products;
            this.attachedSocial = this.orderDetails.socialId;
            this.country = fetchedOrderDetails[0].country === 'US' ? 1 : 2;
            this.trackingUrl = this.narvarUrl();

            // here is where the pre-fetch for the open socials takes place:
            await this.fetchOpenSocials();
        } catch (e) {
            console.error('Error retrieving order', e);
        }
        interfaceStore.hideProgress(ORDERS_FETCH);
    }

    @action
    async attachSocials({
        socialId,
        name,
        orderId,
        country,
        timestamp,
        firstName,
        lastName,
        vtexId,
        orderType,
        status,
    }: {
        socialId: String;
        name: String;
        orderId: Number;
        country: String;
        timestamp: Date;
        firstName: String;
        lastName: String;
        vtexId: String;
        orderType: String;
        status: String;
    }): Promise<any> {
        const auth = await firebase.auth();
        // @ts-ignore
        const token = await auth.currentUser.getIdToken(true);
        const endpoint = `${nogento.base}/socials/${socialId}/orders/${orderId}`;
        const vtexEndpoint = `${nogento.vtex}/popups/${socialId}/orders/${orderId}`;
        const params = {
            order: {
                name,
                orderNumber: orderId,
                orderDate: format(timestamp, 'MM/DD/YYYY'),
                country,
                firstName,
                lastName,
                vtexId,
                orderType,
                status,
            },
        };
        try {
            const response = await fetch(this.useVtexPopups ? vtexEndpoint : endpoint, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...params,
                }),
            });
            const data = await response.json();
            if (!data.success) throw data;
            this.attachedSocial = socialId;
            return data;
        } catch (e) {
            return e;
        }
    }

    async fetchOpenSocials() {
        // as example for endpoint: `${nogento}/socials/simple?status=OPEN&countryId=102`;
        const auth = await firebase.auth();
        // @ts-ignore
        const token = await auth.currentUser.getIdToken(true);
        const endpoint = `${nogento.socials}?countryId=${this.country}&status=Open`;
        const vtexEndpoint = `${nogento.vtex}/popups/?countryId=${this.country}&status=Open`;
        try {
            const response = await fetch(this.useVtexPopups ? vtexEndpoint : endpoint, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => response.json());
            const data = response;
            if (!(data.success && Array.isArray(data.results) && data.results.length)) return;
            this.openSocials = data.results;
        } catch (e) {
            console.log(e);
        }
    }

    @action
    clearCurrentOrder() {
        this.orderItems = [];
        this.orderDetails = [];
        this.itemTotal = {};
        this.openSocials = null;
        this.attachedSocial = null;
        this.country = 1;
        this.attachSocials = this.attachSocials.bind(this);
    }

    narvarUrl() {
        const { narvarCode, trackingNumber } = this.orderDetails;
        if (narvarUrl && trackingNumber && narvarCode)
            return `${narvarUrl.base}${narvarCode}?tracking_numbers=${trackingNumber}`;
        return null;
    }

    @action
    async fetchOrderDetails(id) {
        try {
            this.clearCurrentOrder();
            const graphql = new graphqlFetch({ urlTag: 'orderDetails' });
            await graphql.useAuth();
            graphql.addType(
                'orders',
                { orderId: Number(id), useSql: true },
                `
                    orderItems {
                        orderId
                        phoenixOrderId
                        fullName
                        status
                        timestamp
                        consultantId
                        firstName
                        lastName
                        country
                        grandTotal
                        uid
                        vtexId
                        phoenixId
                        commissionDateUTC
                        payment {
                            last4
                            firstName
                            lastName
                            paymentType
                            paymentGateway
                            amount
                        }
                        appliedCredit
                        social {
                            socialId
                            socialName
                        }
                        cart {
                            shipping {
                                total
                                method
                                address {
                                    firstName
                                    lastName
                                    address
                                    address2
                                    city
                                    state
                                    postalCode
                                }
                            }
                            tax { 
                                total
                            }
                            subtotal
                            volumes {
                                PV
                                CV
                                QV
                                NV
                                SV
                            }
                            products{
                                sku
                                title
                                name
                                quantity
                                swatchlabel
                                image
                                price
                                discountPrice
                                totalPrice
                                CV
                                PV
                                QV
                                NV
                                SV
                                appliedCredit
                            }
                        },
                        narvarTrackingNumber
                        narvarCode
                        trackingNumber
                        narvarTrackingUrl
                        orderType
                        compVersion
                    }
                `,
            );
            const res = await graphql.execute();
            return res.data.orders.orderItems;
        } catch (error) {
            console.error(error);
        }
    }
}

const orderDetailStore = new OrderDetailStore();
export default orderDetailStore;
