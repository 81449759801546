import { observable, computed, action, runInAction, autorun } from 'mobx';
import { locales as localeDefinitions } from '@btc-frontend/config';
import { getSessionItem, saveSessionItem } from '@btc-frontend/middleware/services/session';
import { formatPrice, formatPriceFull } from '@btc-frontend/middleware/utils/money';
import { find } from 'lodash';

import sessionStore from '@btc-frontend/stores/sessionStore';

class LocaleStore {
    @observable locales;
    @observable activeLocale;

    constructor() {
        this.locales = [];
        this.autoDetected = false;
        this.activeLocale = getSessionItem('selectedLocale') || localeDefinitions[0];
    }

    @action
    autoDetect = () => {
        if (typeof navigator !== 'undefined') {
            const lang = navigator.language || navigator.userLanguage;
            const saved = sessionStore.getKey('autoLocale');
            if (lang && !saved) {
                const found =
                    find(localeDefinitions, { code: lang.toString() }) ||
                    find(localeDefinitions, { lang });
                if (
                    found &&
                    found.code &&
                    found.code != this.activeLocale.code &&
                    found.code != localeDefinitions[0].code
                ) {
                    setTimeout(() => requestAnimationFrame(() => this.setActive(found)), 800);
                    sessionStore.saveKey('autoLocale', found.code);
                }
            } else if (
                lang &&
                saved &&
                saved !== this.activeLocale.code &&
                saved != localeDefinitions[0].code
            ) {
                const found = find(localeDefinitions, { code: saved.toString() });
                found && setTimeout(() => requestAnimationFrame(() => this.setActive(found)), 800);
            }
        }
    };

    @action
    load = params => {
        this.locales = params;
    };

    @action
    selectActive = criteria => {
        const criteriaKeys = Object.keys(criteria);
        this.activeLocale =
            getSessionItem('selectedLocale') ||
            localeDefinitions.find(current => {
                for (let i = 0; i < criteriaKeys.length; i += 1) {
                    if (current[criteriaKeys[i]] !== criteria[criteriaKeys[i]]) return false;
                }
                return true;
            });
    };

    @action
    setActive = async params => {
        /* Fetch a copy of the content data for the selected locale */
        this.activeLocale = JSON.parse(JSON.stringify(params));
        sessionStore.getKey('autoLocale') && sessionStore.saveKey('autoLocale', params.code);

        await runInAction('setting active locale', async () => {
            const user = getSessionItem('user') || {};
            saveSessionItem('selectedLocale', {
                ...this.activeLocale,
            });
            await sessionStore.restoreSessions(params);
        });
    };

    @action
    modifyActive = async params => {
        if (this.activeLocale.code !== params.code)
            this.activeLocale = JSON.parse(JSON.stringify(params));
    };

    @action
    formatDate = (date, args = {}) => {
        const active = new Date(date || Date.now());

        // See MDN docs for toLocaleString() arguments: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString

        return active.toLocaleDateString(this.activeLocale.code, args);
    };

    @action
    formatPrice = price => formatPrice(price, this.activeLocale.currency);

    @action
    formatPriceFull = price => formatPriceFull(price, this.activeLocale.currency);
}

const localeStore = new LocaleStore();

export default localeStore;
export { LocaleStore };
