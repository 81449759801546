const yellow = {
    yellow100: '#F3AB27',
    yellow80: '#F5BC52',
    yellow60: '#F8CD7D',
    yellow40: '#FADDA9',
    yellow20: '#FDEED4',
    yellow10: '#FDF6E9',
    yellow1: '#FEFCF8',
};

module.exports = yellow;
