import { analytics } from '@btc-frontend/config';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import UAParser from 'ua-parser-js';

export function identify(userId, traits = {}) {
    if (checkAnalytics() && window.analytics.identify) {
        return window.analytics.identify(userId, mergeDevice(traits));
    }
}

export function traits(traits = {}) {
    if (checkAnalytics() && window.analytics.user) {
        return window.analytics.user().traits(mergeDevice(traits));
    }
}

export function track(eventName, params = {}) {
    if (checkAnalytics() && window.analytics.track) {
        return window.analytics.track(eventName, mergeDevice(params));
    }
}

export function page(page, params = {}) {
    if (checkAnalytics() && window.analytics.page) {
        return window.analytics.page(page, mergeDevice(params));
    }
}

export function reset() {
    if (checkAnalytics() && window.analytics.reset) {
        window.analytics.user().traits({});
        return window.analytics.reset();
    }
}

function checkAnalytics() {
    return !!(__BROWSER__ && analytics.segment.enabled && window.analytics);
}

function mergeDevice(traits) {
    return Object.assign(traits, { device: deviceDetails(), URL: window.location.href });
}

function deviceDetails() {
    const parser = new UAParser();
    const ua = getSafe(() => parser.getResult());
    const orientation =
        ua && ua.device && (ua.device.type === 'mobile' || ua.device.type === 'tablet')
            ? window.innerHeight > window.innerWidth
                ? 'Portrait'
                : 'Landscape'
            : undefined;
    const iPhoneVersion = ua.device.model === 'iPhone' ? getiPhoneVersion() : undefined;
    const iPadVersion = ua.device.model === 'iPad' ? getiPadVersion() : undefined;
    return {
        browser: ua && ua.browser && ua.browser.name,
        vendor: ua && ua.device && ua.device.vendor,
        model: ua && ua.device && ua.device.model,
        type: ua && ua.device && ua.device.type,
        operatingSystem: ua && ua.os && ua.os.name,
        orientation,
        userAgent: ua && ua.ua,
        iPhoneVersion,
        iPadVersion,
    };
}

function getiPhoneVersion() {
    if (window.screen.height / window.screen.width == 812 / 375 && window.devicePixelRatio == 3) {
        return 'iPhone X';
    } else if (
        window.screen.height / window.screen.width == 736 / 414 &&
        window.devicePixelRatio == 3
    ) {
        return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus';
    } else if (
        window.screen.height / window.screen.width == 667 / 375 &&
        window.devicePixelRatio == 3
    ) {
        return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus (display zoom)';
    } else if (
        window.screen.height / window.screen.width == 667 / 375 &&
        window.devicePixelRatio == 2
    ) {
        return 'iPhone 6, 6s, 7 or 8';
    } else if (
        window.screen.height / window.screen.width == 1.775 &&
        window.devicePixelRatio == 2
    ) {
        return 'iPhone 5, 5C, 5S, SE or 6, 6s, 7 and 8 (display zoom)';
    } else if (window.screen.height / window.screen.width == 1.5 && window.devicePixelRatio == 2) {
        return 'iPhone 4 or 4s';
    } else if (window.screen.height / window.screen.width == 1.5 && window.devicePixelRatio == 1) {
        return 'iPhone 1, 3G or 3GS';
    }
}

function getiPadVersion() {
    if (window.screen.height / window.screen.width == 1024 / 768) {
        if (window.devicePixelRatio == 1) {
            return 'iPad 1, iPad 2, iPad Mini 1';
        } else {
            return 'iPad 3, iPad 4, iPad 5, iPad Air 1, iPad Air 2, iPad Mini 2, iPad Mini 3, iPad Mini 4, iPad Pro 9.7';
        }
    } else if (window.screen.height / window.screen.width == 1112 / 834) {
        return 'iPad Pro 10.5';
    } else if (window.screen.height / window.screen.width == 1366 / 1024) {
        return 'iPad Pro 12.9, Pro 12.9 (2nd Gen)';
    }
}
