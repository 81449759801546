import React, { FC, useState, useEffect } from 'react';

import { assetPath, locales } from '@beautycounter/btc-frontend/src/config';

const FlagMaker: React.FC<{
    locale: string | number;
    className: string;
    style: object;
}> = ({ locale, className, style }) => {
    const activeLocale = locale;
    const interpretationSets = {
        US: [1, 'US', 'en-US', 'en-us'],
        CA: [2, 'CA', 'en-CA', 'en-ca', 'fr-ca', 'fr-CA'],
    };

    const localeImages = {
        US: 'images/us-flag.svg',
        CA: 'images/ca-flag.svg',
    };

    const cur = (locale => {
        return Object.keys(interpretationSets).find(
            key => interpretationSets[key].indexOf(locale) > -1,
        );
    })(locale);

    // todo - use image component. source should not be explicit.
    return (
        <img
            className={className}
            style={style}
            alt={cur}
            src={`${assetPath}/${localeImages[cur]}`}
        />
    );
};

export default FlagMaker;
