import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AccountLink.scss';
import cx from 'classnames';

import { writeUserData } from '@btc-frontend/middleware/services/user/index';
import { updateUser } from '@btc-frontend/middleware/services/user/utilities';
import Link from '@btc-frontend/components/Common/Link';
import CountrySelector from '@btc-frontend/components/Common/CountrySelector';
import Button from '@counter-ui/Inputs/Button/Button.js';

import { routeGroups, routeKeys } from '@btc-frontend/middleware/constants/routes';
import ClickOutHandler from 'react-onclickout';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import {
    SIGN_OUT_EVENT_TITLE,
    SIGN_IN_OUT_CATEGORY,
    SIGN_OUT_ACTION,
} from '@beautycounter/constants/sift';
import { newBccomUrl, vtexScope } from '@btc-frontend/config';

@withStyles(s)
@inject(
    'accountStore',
    'userStore',
    'navigationStore',
    'contentStore',
    'interfaceStore',
    'localeStore',
    'analyticsStore',
    'sessionStore',
)
@observer
class AccountLink extends Component {
    state = {
        menuOpen: false,
    };

    @computed
    get config() {
        return getSafe(() => this.props.contentStore.content.header[0]);
    }

    @computed
    get accountTitle() {
        return getSafe(() => this.props.accountStore.accountTitle);
    }

    trackLogoutAction = async () => {
        const { userStore, analyticsStore } = this.props;
        const { userId, user } = userStore;
        const logOutEventPayload = {
            category: SIGN_IN_OUT_CATEGORY,
            action: SIGN_OUT_ACTION,
            userName: userStore.fullName,
            accountTypes: [userStore.userTypeLabel],
        };
        let logOutEventSentFromNogento = false;

        if (!userId) {
            const { success = false } = await analyticsStore.sendSiftLogoutFromNogento({
                ...logOutEventPayload,
                userEmail: user.email,
            });
            logOutEventSentFromNogento = success;
        }

        analyticsStore.customTracked(SIGN_OUT_EVENT_TITLE, {
            ...logOutEventPayload,
            skipSiftEvent: logOutEventSentFromNogento,
        });
    };

    handleClickOutside = event => {
        this.setState({ menuOpen: false });
    };

    handleSelectCountry = async country => {
        await this.setState({ menuOpen: false });
        const previousLocale = this.props.localeStore.activeLocale;
        if (previousLocale.languageId !== country.languageId)
            await writeUserData({ languageId: country.languageId, countryId: country.countryId });
        await this.props.localeStore.setActive(country, true);
        this.props.navigationStore.to({
            url: this.props.navigationStore.path,
        });
    };

    toggleMenu() {
        this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
    }

    followLink(link) {
        const { analyticsStore, userStore, navigationStore } = this.props;

        this.setState({ menuOpen: false });
        if (link.key == routeKeys.logout) {
            this.trackLogoutAction();
            userStore.logout().then(() => {
                this.props.sessionStore.regenerateSiftSessionId();
                navigationStore.to({
                    key: link.key,
                });

                const locale =
                    this.props.localeStore.activeLocale.code.toLocaleLowerCase() || 'en-us';
                const url = `${newBccomUrl}/${locale}/api/vtexid/pub/logout?scope=${vtexScope}&returnUrl=${newBccomUrl}/${locale}`;
                window.location.assign(url);
            });
        } else
            navigationStore.to({
                key: link.key,
            });
    }

    render() {
        const { menuOpen } = this.state;
        const {
            navigationStore: { getRouteGroup },
            userStore: {
                isLogged,
                userInfo: { firstName, lastName, image },
                fullName,
                consultant,
            },
            interfaceStore: { appIsMounted },
            localeStore: { activeLocale },
        } = this.props;

        const languageLabel = getSafe(() => this.config.languageLabel);
        const accountRoutes = getRouteGroup(routeGroups.accountLinks) || [];
        const consultantTitle = consultant && consultant.title;

        return (
            <div className={s.root}>
                {isLogged && appIsMounted && (
                    <ClickOutHandler onClickOut={this.handleClickOutside}>
                        <div className={cx(s.menu, menuOpen && s.menuOpen)}>
                            {image ? (
                                <div
                                    className={s.profileImage}
                                    style={{ backgroundImage: `url(${image})` }}
                                    onClick={() => this.toggleMenu()}
                                    data-testid="accLink-profileImage"
                                />
                            ) : (
                                <div
                                    className={s.profileImage}
                                    onClick={() => this.toggleMenu()}
                                    data-testid="accLink-profileImage"
                                >
                                    <div className={s.initialsProfileImage}>
                                        {`${firstName[0]}${lastName[0]}`.toUpperCase()}
                                    </div>
                                </div>
                            )}
                            {menuOpen && (
                                <ul className={s.menuLinks}>
                                    <div className={s.menuInfos}>
                                        <p className={s.menuUserName}>{fullName.trim()}</p>
                                        <p className={s.menuUserTitle}>
                                            {this.accountTitle || consultantTitle}
                                        </p>

                                        <div className={s.countryContainer}>
                                            <CountrySelector
                                                label={languageLabel}
                                                onSelect={this.handleSelectCountry}
                                            />
                                        </div>
                                    </div>
                                    {accountRoutes.map((link, key) => {
                                        if (link.key === routeKeys.logout) {
                                            return (
                                                <div className={s.logoutContainer}>
                                                    <Button
                                                        className={s.logoutButton}
                                                        testId={`accLink-${link.key}`}
                                                        onClick={e => this.followLink(link, e)}
                                                        key={link.key}
                                                    >
                                                        {link.name}
                                                    </Button>
                                                </div>
                                            );
                                        }
                                        return (
                                            <li
                                                onClick={e => this.followLink(link, e)}
                                                key={link.key}
                                            >
                                                <Link testId={`accLink-${link.key}`}>
                                                    {link.key === routeKeys.account.root
                                                        ? link.navName
                                                        : link.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>
                    </ClickOutHandler>
                )}
            </div>
        );
    }
}

export default AccountLink;
