import { actionTemplate } from './action';
import { routeKeys as keys } from '@btc-frontend/middleware/constants/routes';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import { flags } from '@btc-frontend/config';
import { PWS_EXPIRED, PWS_ACTIVE } from '@beautycounter/constants/pwsStatus';
import userStore from '@btc-frontend/stores/userStore';

// custom action for pwsRenewal
const action = config => {
    let override = false;
    let overrideComponent = null;
    let overrideDependency = null;
    let namespaceOverride = null;

    const pwsStatus = getSafe(() => userStore.subscriptionInfo.pwsStatus);
    if (pwsStatus === PWS_EXPIRED) {
        if (
            (config.disableOverride &&
                config.disableOverride.indexOf('RenewalOverrideContainer') < 0) ||
            !config.disableOverride
        ) {
            override = true;
            overrideComponent = 'RenewalOverrideContainer';
            overrideDependency = {
                contentful: ['translationsRenewal'],
            };
            namespaceOverride = 'renewal';
        }
    }
    if (override) {
        config.dependencies = overrideDependency;
        config.component = overrideComponent;
        config.namespaceOverride = namespaceOverride;
    }
    return actionTemplate(config);
};

export const routeListGenerator = (routeConfig, parentUrl = '') => {
    let list = [];
    if (parentUrl === '/') parentUrl = '';
    for (let i = 0; i < routeConfig.length; i += 1) {
        const config = routeConfig[i];
        const { redirectTo, component, to, children } = config;
        if (Array.isArray(to) && (component || redirectTo)) {
            list = [
                ...list,
                ...config.to.map(to => {
                    const configPart = config;
                    configPart.to = `${parentUrl}${to}`;
                    return action(configPart);
                }),
            ];
        } else if (component || redirectTo) {
            config.to = `${redirectTo || parentUrl + to}`;
            list.push(action(config));
        }
        if (children) {
            list.unshift(...routeListGenerator(children, `${parentUrl}${to}`));
        }
    }
    return list;
};
