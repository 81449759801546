import React, { useState, useRef } from 'react';
import { inject } from 'mobx-react';
import PopupComponent from '@counter-ui/Core/PopupComponent';
import MarkdownWrapper from '@btc-frontend/components/MarkdownWrapper';
import Button from '@btc-frontend/components/Common/Button';
import { getSafe } from '@btc-frontend/middleware/utils/object';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PoliciesProceduresContainer.scss';

const PoliciesProceduresContainer = props => {
    const [policyRead, setPolicyRead] = useState(false);
    const policy = useRef(null);
    const { interfaceStore, analyticsStore, userStore, contentStore } = props;
    const { showPolicyPrompt, phoenixId } = userStore;
    const [showPolicy, setShowPolicy] = useState(showPolicyPrompt);
    const { viewportWidth } = interfaceStore;
    const {
        popUpTitle,
        popUpSubtext,
        policiesProcedures,
        currentVersion,
        acceptCta,
    } = getSafe(() => contentStore._extensions.policiesProcedures.data.configPoliciesAndProcedures[0]) || {};

    const handleTermsScroll = () => {
        const policyHeight = policy.current.getBoundingClientRect().height;

        // Handle cases where browsers might be off by a few, offset by 5 px
        if (policy.current.scrollTop + policyHeight + 5 >= policy.current.scrollHeight) {
            setPolicyRead(true);
        }
    };

    const updateUserPolicy = async (accepted) => {
        setShowPolicy(false);
        await userStore.updateUserPolicy({ version: currentVersion, accepted });
    }

    const handleAccept = async () => {
        analyticsStore.customTracked('BTC Accept New Policy Clicked', {
            phoenixId,
            version: currentVersion,
            scrolledPolicy: policyRead,
        });
        await updateUserPolicy(true);
    };

    const handleClose = async () => {
        analyticsStore.customTracked('BTC Close New Policy Clicked', {
            phoenixId,
            version: currentVersion,
            scrolledPolicy: policyRead,
        });
        await updateUserPolicy(false);
    }

    return (
        <PopupComponent
            showCloseIcon
            visibility={showPolicy}
            viewportWidth={viewportWidth}
            handleClick={handleClose}
            className={s.popup}
        >
            <div className={s.content}>
                <div className={s.header}>{popUpTitle}</div>
                <div>{popUpSubtext}</div>
                <div className={s.terms}>
                    <div
                        className={s.policy}
                        onScroll={handleTermsScroll}
                        ref={policy}
                    >
                        <MarkdownWrapper content={policiesProcedures} />
                    </div>
                    <Button
                        thin
                        thinBorder
                        disabled={!policyRead}
                        onClick={() => handleAccept()}
                        className={s.button}
                    >
                        {acceptCta}
                    </Button>
                </div>
            </div>
        </PopupComponent>
    );
};

export default inject('interfaceStore', 'analyticsStore', 'userStore', 'contentStore')(
    withStyles(s)(PoliciesProceduresContainer),
);
