import {
    getCookieClient,
    getCookieServer,
    setCookieClient,
    setCookieServer,
    setCookieEdge,
    getCookieEdge,
} from '@beautycounter/utils/cookie';
import { getSafe } from '@beautycounter/utils/object';

export function setSignature({ value, name, expires, serverRequest, edgeRequest }) {
    const signature = typeof value === 'object' ? JSON.stringify(value) : String(value);

    if (__BROWSER__) return setCookieClient(name, signature, { expires });
    if (serverRequest) return setCookieServer(name, signature, serverRequest);
    if (edgeRequest) return setCookieEdge(name, signature, edgeRequest);
}

export function getSignature(name, serverRequest, edgeRequest) {
    let raw;

    if (__BROWSER__) raw = getCookieClient(name);
    else if (edgeRequest) raw = getCookieEdge(name, edgeRequest);
    else if (serverRequest)
        raw =
            serverRequest.query && serverRequest.query.settings
                ? decodeURIComponent(serverRequest.query.settings)
                : getCookieServer(name, serverRequest);

    return getSafe(() => JSON.parse(raw)) || null;
}
