import firebase from '@btc-frontend/middleware/firebase/init';
import { nogento } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';

// vtex payment cards
export default async function getVtexCustomerCards() {
    const endpoint = `${nogento.vtex}/accounts/payments`;

    const auth = await firebase.auth();
    const token = await auth.currentUser.getIdToken(true);

    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const { cards } = await response.json();
        return {
            success: true,
            cards,
        };
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}
