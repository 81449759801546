import { contentfulbtc, contentfulbc } from '@btc-frontend/config';
import fetch from '@btc-frontend/middleware/api/fetch';
import queryString from 'query-string';

/**
 * Fetches Contentful schema
 * @param {Object} options
 */

function getContentful(request, options) {
    const { drafts, space: spaceKey } = options;
    const contentful = spaceKey === 'bccom' ? contentfulbc : contentfulbtc;
    const space = contentful.space;
    const accessToken = drafts ? contentful.previewAccessToken : contentful.accessToken;
    const host = drafts ? '//preview.contentful.com' : '//cdn.contentful.com';

    request.access_token = accessToken;
    const query = `${host}/spaces/${space}/entries?${queryString.stringify(request)}`;

    return fetch(query);
}

export default getContentful;
