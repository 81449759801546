import React, { Component } from 'react';
import Icon from '../Icon.jsx';

const viewBox = {
    x: -7,
    y: -7,
    width: 60,
    height: 60,
};

class Tools extends Component {
    render() {
        return (
            <Icon size={{ width: 46, height: 45 }} {...this.props} altText='Tools' viewBox={viewBox}>
                <g id="Symbols" stroke="none" strokeWidth="1.5" fill="none" fillRule="evenodd">
                    <g id="tools" transform="translate(-1.000000, -1.000000)" fill="#000000">
                        <g id="icon-btc-tools">
                            <path
                                d="M38.828772,4.96570387 C36.9424133,4.96570387 34.9806001,5.65466975 33.5469675,6.96370494 C31.5097,8.89280941 30.9060652,11.5797764 31.7360631,13.991157 L25.926078,19.2961943 L30.7551565,23.4299896 L36.4142328,18.2627454 C37.1687763,18.4694352 37.9987742,18.6072284 38.828772,18.6072284 C40.7151308,18.6072284 42.6769439,17.9182625 44.1105766,16.6092273 C46.0723897,14.817916 46.6760245,12.3376388 46.0723897,10.132948 L42.6014896,13.3021911 L38.3760459,12.2687422 L37.1687763,8.3416367 L40.6396765,5.17239363 C40.0360416,5.03460046 39.4324068,4.96570387 38.828772,4.96570387 M38.828772,5.65466975 L39.0551351,5.65466975 L36.3387785,8.13494694 L37.6969568,12.7510184 L42.7523983,13.991157 L45.4687549,11.5108798 C45.5442093,13.2332945 44.8651201,14.817916 43.5069418,16.0580546 C42.2242178,17.2292966 40.5642221,17.8493659 38.7533177,17.8493659 C37.9987742,17.8493659 37.319685,17.7115727 36.5651415,17.504883 L36.1878698,17.4359864 L30.6797021,22.4654373 L26.982439,19.2272977 L32.5660609,14.1289501 L32.4151522,13.7155706 C31.5851544,11.5108798 32.1887892,9.09949918 33.9996936,7.44598105 C35.3578719,6.27473905 37.0178676,5.65466975 38.828772,5.65466975"
                                id="Fill-1"
                            />
                            <path
                                d="M13.4887566,1.67857143 L1.85185185,12.0997024 L1.85185185,20.9821429 L4.89417989,23.5699405 L11.359127,17.6949405 L36.2301587,42.1041667 C37.0667989,42.8035714 38.207672,43.2232143 39.4253638,43.2232143 C40.0330688,43.2232143 40.6415344,43.0833333 41.1739418,42.9434524 C42.3148148,42.5238095 43.2282738,41.6845238 43.6838624,40.6354167 C43.8359788,40.2157738 43.9888558,39.7261905 43.9888558,39.2366071 C43.9888558,38.1875 43.531746,37.2083333 42.771164,36.5089286 L16.2268519,13.4285714 L21.2466931,8.67261905 L13.4887566,1.67857143 Z M13.4887566,2.6577381 L20.1818783,8.74255952 L15.0859788,13.4985119 L42.2387566,37.1383929 C42.8479828,37.7678571 43.2282738,38.5372024 43.2282738,39.3764881 C43.2282738,39.7261905 43.151455,40.1458333 42.9993386,40.4955357 C42.6190476,41.3348214 41.8584656,42.0342262 40.9465278,42.3839286 C40.489418,42.5238095 40.0330688,42.6636905 39.5006614,42.6636905 C38.5119048,42.6636905 37.5992063,42.3139881 36.9146825,41.7544643 L11.5112434,16.7157738 L4.89417989,22.5907738 L2.61243386,20.702381 L2.61243386,12.3794643 L13.4887566,2.6577381 L13.4887566,2.6577381 Z"
                                id="Fill-3"
                            />
                            <path
                                d="M13.0590616,42.3109465 L8.90954169,41.3535391 L7.85330026,37.5922957 L11.0220245,34.7193896 L15.1715444,35.6774809 L16.2277859,39.4387242 L13.0590616,42.3109465 Z M19.471956,27.3343592 L14.2661946,32.05301 C13.5117365,31.8478512 12.6818325,31.7110787 11.8519285,31.7110787 C9.96578312,31.7110787 8.0041919,32.3949412 6.57072139,33.6935959 C3.62833456,36.3613433 3.62833456,40.6012904 6.57072139,43.26767 C8.0041919,44.5676925 9.96578312,45.2515549 11.8519285,45.2515549 C13.7380739,45.2515549 15.6996651,44.5676925 17.1331357,43.26767 C19.1701727,41.3535391 19.7737392,38.6864756 18.9438352,36.292957 L23.9232591,31.7110787 L19.471956,27.3343592 Z M13.285399,43.0631951 L13.5871823,42.7896502 L16.7559066,39.9174279 L17.0576898,39.643883 L16.982244,39.3019518 L15.9260026,35.5407084 L15.775111,35.1303909 L15.3224361,35.0613208 L11.1729162,34.1045973 L10.7202413,33.9678248 L10.418458,34.2413697 L7.24973374,37.1129081 L7.02339629,37.3871369 L7.0988421,37.7290682 L8.15508353,41.4903115 L8.23052935,41.8999451 L8.68320424,41.9690152 L12.8327241,42.9257388 L13.285399,43.0631951 L13.285399,43.0631951 Z M19.471956,28.3601528 L22.9424635,31.7110787 L18.4157145,35.8142533 L18.1139313,36.0871145 L18.2648229,36.4981158 C19.0947269,38.6864756 18.4911603,41.0799941 16.6804608,42.7212639 C15.3978819,43.8838301 13.7380739,44.4993062 11.9273743,44.4993062 C10.1166748,44.4993062 8.45686679,43.8838301 7.17428792,42.7212639 C4.53368435,40.3277454 4.53368435,36.4981158 7.17428792,34.1045973 C8.45686679,32.9420311 10.1166748,32.3265549 11.9273743,32.3265549 C12.6818325,32.3265549 13.3608448,32.4633274 14.115303,32.6684861 L14.5679779,32.8052586 L14.8697612,32.5317137 L19.471956,28.3601528 L19.471956,28.3601528 Z"
                                id="Fill-6"
                            />
                        </g>
                    </g>
                </g>
            </Icon>
        );
    }
}

export default Tools;
